import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Closed from "../components/Closed"
import Questions from "../components/ProjectType/QuestionsV2"
import PreEnrollmentQuestions from "../components/ProjectType/PreEnrollmentQuestions"
import BiddingQuestion from "../components/ProjectType/BiddingQuestions"
import RetroactiveQuestions from "../components/ProjectType/RetroactiveQuestions"
import StandWithProduction from "../components/ProjectType/CustomProjects/StandWithProduction"
import AcademyQuestions from "../components/ProjectType/Academy"
import Opening from "../components/Opening"
import Successful from "../components/Sucessful"
import ThankYou from "../components/ThankYou"
import PhoneNumber from "../components/PhoneNumber"
import PhoneNotExist from "../components/PhoneNotExist"
import Verification from "../components/Verification"
import VerificationFail from "../components/VerificationFail"
import AlreadyRegistered from "../components/AlreadyRegistered"
import axios from "axios"
import queryString from "query-string"
import { API_ROOT, ENV } from "gatsby-env-variables"
import analytics from "../components/Analytics"
import Error from "../components/Error"
import DefaultSurvey from "../constants/DefaultSurvey"

const IndexPage = props => {
  const [errorMessage, setErrorMessage] = useState("")
  const [step, setStep] = useState(0)
  const [uuid, setUuid] = useState("")
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState("")
  const [prefilledData, setPrefilledData] = useState("")
  const [isOptIn, setIsOptIn] = useState(false)
  const { search } = props.location
  const {
    id,
    crewUUID,
    preEnrollmentUUID,
    rosterUUID,
    biddingId,
    candidateId,
    retroactiveId,
    customProjectsId,
  } = queryString.parse(search)
  const [formType, setFormType] = useState("FULL")
  const [customMessage, setCustomMessage] = useState("")
  const [isPodcast, setIsPodcast] = useState(false)
  const [isUnion, setIsUnion] = useState(false)
  const [survey, setSurvey] = useState(DefaultSurvey)
  const [isAcademy, setIsAcademy] = useState(0)
  const [academyType, setAcademyType] = useState(null)
  const [productionCompany, setProductionCompany] = useState("")
  const [projectName, setProjectName] = useState("")
  const [customQuestions, setCustomQuestions] = useState([])

  const apiURL = preEnrollmentUUID
    ? `${API_ROOT}${ENV}/pre-enrollment/get-form-status?preEnrollmentUUID=${preEnrollmentUUID}&rosterUUID=${rosterUUID}`
    : biddingId
    ? `${API_ROOT}${ENV}/bidding/get-form-status?biddingUUID=${biddingId}&candidateUUID=${candidateId}`
    : retroactiveId
    ? `${API_ROOT}${ENV}/retroactive/get-form-status?retroactiveUUID=${retroactiveId}&crewUUID=${crewUUID}`
    : customProjectsId
    ? `${API_ROOT}${ENV}/custom-project/get-form-status?customProjectUUID=${customProjectsId}`
    : `${API_ROOT}${ENV}/production/get-form-status?productionUUID=${id}&crewUUID=${crewUUID}`

  useEffect(() => {
    if (
      search.includes("id") ||
      search.includes("preEnrollmentUUID") ||
      search.includes("biddingId") ||
      search.includes("retroactiveId") ||
      search.includes("customProjectsId")
    ) {
      axios
        .get(apiURL)
        .then(response => {
          if (
            response.data.statusCode === 500 ||
            response.data.devMessage.isClose === 1
          ) {
            setLoading(false)
            setIsOpen(false)
            analytics.page({
              title: "Form Closed",
              path: "/form-closed",
            })
          }
          if (
            response.data.statusCode === 200 &&
            response.data.devMessage.isClose === 0
          ) {
            axios
              .get(`${API_ROOT}${ENV}/lookups`)
              .then(response => {
                setRoles(response.data.devMessage.roles)
                setLoading(false)
              })
              .catch(function (error) {
                setLoading(false)
              })
            setIsOpen(true)
            preEnrollmentUUID &&
              response.data.devMessage.formType !== null &&
              setFormType(response.data.devMessage.formType)
            setCustomMessage(response.data.devMessage.customMessage)
            setSurvey(
              response.data.devMessage.survey
                ? response.data.devMessage.survey
                : survey
            )
            setIsPodcast(response.data.devMessage.productionType)
            setIsUnion(response.data.devMessage.isUnion === 1 ? true : false)
            setIsAcademy(
              response.data.devMessage.isAcademy === 1 ? true : false
            )
            setAcademyType(response.data.devMessage.academyType || "")
            setProductionCompany(
              response.data.devMessage.productionCompany || ""
            )
            setProjectName(response.data.devMessage.projectName || "")
            setCustomQuestions(response.data.devMessage.customQuestions)

            analytics.identify(
              id !== undefined
                ? id
                : preEnrollmentUUID !== undefined
                ? preEnrollmentUUID
                : biddingId !== undefined
                ? biddingId
                : customProjectsId !== undefined
                ? customProjectsId
                : retroactiveId,
              {
                companyId: response?.data?.devMessage?.company?.id,
                productionType:
                  response?.data?.devMessage?.productionType || "",
                individualLink:
                  crewUUID !== undefined
                    ? crewUUID || "N/A"
                    : rosterUUID !== undefined
                    ? rosterUUID || "N/A"
                    : candidateId || "N/A",
              }
            )

            setStep(1)
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    } else {
      setLoading(false)
      analytics.page({
        title: "Form Closed",
        path: "/form-closed",
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      {isOpen && !loading ? (
        <>
          {step === 1 && (
            <Opening
              setStep={setStep}
              customMessage={customMessage}
              formType={formType}
              biddingId={biddingId}
              retroactiveId={retroactiveId}
              customProjectUUID={customProjectsId}
              isAcademy={isAcademy}
            />
          )}

          {step === 2 && retroactiveId !== undefined && (
            <RetroactiveQuestions
              setStep={setStep}
              setUuid={setUuid}
              roles={roles}
              retroactiveUUID={retroactiveId}
              crewUUID={crewUUID}
              prefilledData={prefilledData}
              formType={formType}
              survey={survey}
              setErrorMessage={setErrorMessage}
              customQuestions={customQuestions}
            />
          )}

          {step === 2 && preEnrollmentUUID !== undefined && (
            <PreEnrollmentQuestions
              setStep={setStep}
              setUuid={setUuid}
              roles={roles}
              preEnrollmentUUID={preEnrollmentUUID}
              rosterUUID={rosterUUID}
              prefilledData={prefilledData}
              formType={formType}
              survey={survey}
              setErrorMessage={setErrorMessage}
              customQuestions={customQuestions}
            />
          )}

          {step === 2 && biddingId !== undefined && (
            <BiddingQuestion
              setStep={setStep}
              setUuid={setUuid}
              roles={roles}
              biddingUUID={biddingId}
              candidateUUID={candidateId}
              prefilledData={prefilledData}
              survey={survey}
              setErrorMessage={setErrorMessage}
              customQuestions={customQuestions}
            />
          )}

          {step === 2 && customProjectsId !== undefined && (
            <StandWithProduction
              setStep={setStep}
              setUuid={setUuid}
              customProjectUUID={customProjectsId}
              prefilledData={prefilledData}
              roles={roles}
              survey={survey}
              setErrorMessage={setErrorMessage}
            />
          )}

          {step === 2 && id !== undefined && (
            <>
              {isAcademy ? (
                <AcademyQuestions
                  setStep={setStep}
                  setUuid={setUuid}
                  roles={roles}
                  productionUUID={id}
                  crewUUID={crewUUID}
                  prefilledData={prefilledData}
                  biddingUUID={biddingId}
                  candidateUUID={candidateId}
                  survey={survey}
                  isPodcast={isPodcast === "Podcast"}
                  isUnion={isUnion}
                  setErrorMessage={setErrorMessage}
                  academyType={academyType}
                  productionCompany={productionCompany}
                  projectName={projectName}
                />
              ) : (
                <Questions
                  setStep={setStep}
                  setUuid={setUuid}
                  roles={roles}
                  productionUUID={id}
                  crewUUID={crewUUID}
                  prefilledData={prefilledData}
                  biddingUUID={biddingId}
                  candidateUUID={candidateId}
                  survey={survey}
                  isPodcast={isPodcast === "Podcast"}
                  isUnion={isUnion}
                  setErrorMessage={setErrorMessage}
                  customQuestions={customQuestions}
                />
              )}
            </>
          )}
          {step === 3 && (
            <Successful
              setStep={setStep}
              uuid={uuid}
              setVerifyPhoneNumber={setVerifyPhoneNumber}
              setIsOptIn={setIsOptIn}
              rosterUUID={rosterUUID}
              preEnrollmentUUID={preEnrollmentUUID}
              biddingUUID={biddingId}
              retroactiveUUID={retroactiveId}
              candidateUUID={candidateId}
              customProjectUUID={customProjectsId}
            />
          )}
          {step === 4 && <ThankYou />}
          {step === "phone" && (
            <PhoneNumber
              setStep={setStep}
              setVerifyPhoneNumber={setVerifyPhoneNumber}
              uuid={id}
              preEnrollmentUUID={preEnrollmentUUID}
              rosterUUID={rosterUUID}
              biddingUUID={biddingId}
              retroactiveUUID={retroactiveId}
              candidateUUID={candidateId}
              customProjectUUID={customProjectsId}
            />
          )}
          {step === "noPhone" && <PhoneNotExist setStep={setStep} />}
          {step === "verification" && (
            <Verification
              setStep={setStep}
              verifyPhoneNumber={verifyPhoneNumber}
              setVerifyPhoneNumber={setVerifyPhoneNumber}
              prefilledData={prefilledData}
              setPrefilledData={setPrefilledData}
              uuid={id}
              selfIdUUID={uuid}
              isOptIn={isOptIn}
              setIsOptIn={setIsOptIn}
              rosterUUID={rosterUUID}
              preEnrollmentUUID={preEnrollmentUUID}
              biddingUUID={biddingId}
              retroactiveUUID={retroactiveId}
              candidateUUID={candidateId}
              customProjectUUID={customProjectsId}
            />
          )}
          {step === "verificationFail" && (
            <VerificationFail
              setStep={setStep}
              verifyPhoneNumber={verifyPhoneNumber}
              setVerifyPhoneNumber={setVerifyPhoneNumber}
              isOptIn={isOptIn}
              setIsOptIn={setIsOptIn}
              uuid={id}
              selfIdUUID={uuid}
              rosterUUID={rosterUUID}
              preEnrollmentUUID={preEnrollmentUUID}
              biddingUUID={biddingId}
              retroactiveUUID={retroactiveId}
              candidateUUID={candidateId}
              customProjectUUID={customProjectsId}
            />
          )}

          {step === "registered" && <AlreadyRegistered />}
          {step === "error" && (
            <Error setStep={setStep} message={errorMessage} />
          )}
        </>
      ) : (
        !loading && <Closed />
      )}
    </Layout>
  )
}

export default IndexPage
