import React, { useEffect } from "react"
import Header from "./header"
import { Button } from "@material-ui/core"
import analytics from "./Analytics"
const Error = ({ setStep, message }) => {
  useEffect(() => {
    analytics.page({
      title: "Submission Error",
      path: "/submission-error",
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  return (
    <section className="successful">
      <div className="container">
        <Header />
        <p>
          {message !== "" ? message : "Something went wrong. Please try again"}
        </p>
        <div className="actions">
          <Button
            onClick={() => {
              setStep(1)
            }}
          >
            Start
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Error
