import React, { useState, useEffect } from "react"
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab/"
import { makeStyles } from "@material-ui/core/styles"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import RequiredError from "../shared/RequiredError"

const useStyles = makeStyles(theme => ({
  feedback: {
    border: "1px solid #fff",
    color: "#fff",
    padding: "10px",
    width: "100%",
  },
  oneLine: {
    borderBottom: "1px solid #fff",
    color: "#fff",
    marginLeft: 15,
    marginTop: 10,
    minWidth: 350,
  },
  radioGroup: {
    flexDirection: "row",
    marginLeft: "20px",
    paddingTop: "5px",
  },
  radio: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    minHeight: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      minHeight: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "24px",
    paddingBottom: 0,
    paddingLeft: "5px",
    "@media (max-width: 767px)": {
      minHeight: 25,
      fontSize: "16px",
    },
  },
}))

const CustomQuestion = ({ customA, customQuestion, showError, setCustomA }) => {
  const { allowMultiple, charLimit, questionType, customQuestionChoices } =
    customQuestion
  const [charCount, setCharCount] = useState(0)
  const classes = useStyles()
  const questionId = customQuestion.id
  const updateCustomA = value => {
    if (!value) {
      setCustomA(prev => prev.filter(item => item.id !== questionId))
      return
    }
    const index = customA?.findIndex(item => item.id === questionId)
    if (index === -1) {
      setCustomA(prev => [...prev, { id: questionId, answer: value }])
    } else {
      const updatedCustomA = customA?.map(item =>
        item.id === questionId ? { ...item, answer: value } : item
      )
      setCustomA(updatedCustomA)
    }
  }

  const onChangeTextBox = e => {
    const { value } = e.target
    setCharCount(value.length)
    updateCustomA(value)
  }

  const onSingleChange = e => {
    const { value } = e.target
    const selectedID = customQuestionChoices.filter(i => i.value === value)
    updateCustomA(parseInt(selectedID[0].id))
  }

  const answer = customA?.find(item => item?.id === questionId)?.answer || null
  const error =
    customQuestion?.isRequired === 1 &&
    showError &&
    (answer === null || answer === "")
  const answerChoices = customQuestionChoices?.find(item => item?.id === answer)

  useEffect(() => {
    if (customQuestion?.defaultValue === 1 && !answer) {
      updateCustomA(parseInt(customQuestionChoices[0].id))
    }
  }, [])

  const resolveDefaultValue = () => {
    if (!!answerChoices) {
      return answerChoices.value
    } else if (customQuestion?.defaultValue === 1) {
      return customQuestionChoices[0]?.value
    } else {
      return ""
    }
  }
  const defaultValue = resolveDefaultValue()

  return (
    <div className="question-wrapper">
      {customQuestion.description}
      <div className="field-wrapper">
        {(questionType === "ONE-LINE-TEXT" || questionType === "OPEN-TEXT") && (
          <>
            <TextField
              className={
                questionType === "OPEN-TEXT"
                  ? classes.feedback
                  : classes.oneLine
              }
              multiline={questionType === "OPEN-TEXT"}
              minRows={questionType === "OPEN-TEXT" ? 4 : 0}
              onChange={onChangeTextBox}
              inputProps={{
                maxLength: charLimit,
              }}
              value={answer || ""}
            />

            {charLimit && charLimit > 0 && (
              <div className="char-left">
                <p>
                  {charCount} out of {charLimit} characters left
                </p>
              </div>
            )}
          </>
        )}

        {questionType === "RADIO" && (
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              onChange={onSingleChange}
              defaultValue={defaultValue}
            >
              {customQuestionChoices?.length > 0 &&
                customQuestionChoices.map(choice => (
                  <FormControlLabel
                    key={choice.id}
                    value={choice.value}
                    label={choice.value}
                    control={<Radio className={classes.radio} />}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        )}

        {questionType === "SELECT" && (
          <FormControl className={classes.formControl}>
            {allowMultiple === 1 ? (
              <Autocomplete
                multiple
                options={customQuestionChoices}
                getOptionLabel={option => option.value}
                onChange={(_, newValue) => {
                  updateCustomA(newValue.map(v => parseInt(v.id)))
                }}
                renderOption={(option, { selected }) => (
                  <div id={option.id}>
                    {selected ? (
                      <CheckBoxIcon
                        style={{
                          marginRight: "8px",
                          pointerEvents: "none",
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        style={{
                          marginRight: "8px",
                          pointerEvents: "none",
                        }}
                      />
                    )}

                    {option.value}
                  </div>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    shrink="false"
                    InputProps={{
                      ...params.InputProps,
                      className: classes.select,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <Select
                className={classes.select}
                defaultValue={defaultValue}
                onChange={onSingleChange}
              >
                {customQuestionChoices?.length > 0 &&
                  customQuestionChoices.map(choice => (
                    <MenuItem key={choice.id} value={choice.value}>
                      {choice.value}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        )}
        <RequiredError
          error={error}
          required={customQuestion?.isRequired === 1}
          classes="field-asterisk text3"
        />
      </div>
    </div>
  )
}

export default CustomQuestion
