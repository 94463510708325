const ANSWERS = {
  yearBorn: [
    "18 to 20",
    "21 to 30",
    "31 to 40",
    "41 to 50",
    "51 to 60",
    "61+",
    "Prefer not to say",
  ],
  gender: [
    "Type to search",
    "Woman",
    "Man",
    //"Non-Binary/Gender Non-Conforming/Gender-Queer", -> Update for IV-1232
    //"Intersex", -> Update for IV-1232
    //"Transgender",-> Update for IV-1232
    "Non-binary",
    "Two-Spirit",
    "Genderqueer or genderfluid",
  ],
  pronouns: [
    "Type to search",
    "she/her",
    "he/him",
    "they/them",
    "ze/zir",
    "xe/xir",
  ],
  sexualOrientation: [
    "Type to search",
    "Lesbian",
    "Gay",
    "Bisexual",
    "Queer",
    "Pansexual",
    "Asexual",
    "Straight",
  ],
  birthplace: [
    "Type to search",
    "Canada",
    "Mexico",
    "United Kingdom (UK)",
    "United States of America (USA)",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia-Herzegovina",
    "Botswana",
    "Brazil",
    "Britain",
    "Brunei",
    "Bulgaria",
    "Burkina",
    "Burma (official name Myanmar)",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "England",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia, the",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guyana",
    "Haiti",
    "Holland (also Netherlands)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland, Republic of",
    "Italy",
    "Israel",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Laos",
    "Latvia",
    "Lebanon",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar see Burma",
    "Namibia",
    "Nepal",
    "Netherlands, the (see Holland)",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "the Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saudi Arabia",
    "Scotland",
    "Senegal",
    "Serbia",
    "Seychelles, the",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (UAE)",
    "Uruguay",
    "US Virgin Islands",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wales",
    "Western Samoa",
    "Yemen",
    "Yugoslavia",
    "Zaire",
    "Zambia",
    "Zimbabwe",
    "Unknown",
  ],
  ethnicity: [
    "Type to search",
    // "Black/African Origin", -> Update for IV-1232
    "Black or African",
    "Hispanic",
    "Latina/Latine/Latino/Latinx",
    "Indigenous",
    "South West Asian/Middle Eastern/North African", // Update for IV-1232
    // "Hispanic/Latinx", -> Update for IV-1232
    // "Indigenous Peoples", -> Update for IV-1232
    "Pacific Islander",
    "Central Asian",
    "South Asian",
    "East Asian",
    "Southeast Asian",
    "Jewish",
    // "SWANA (South West Asian/ North African), MENA (Middle Eastern/North African)", -> Update for IV-1232
    "White, European Origin",
    "Mixed race/Multi-ethnic"
  ],
  nationality: [
    "Type to search",
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Argentinian",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Belarusian or Belarusan",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bermudian",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Botswanan",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinese",
    "Burmese/Burman",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Congolese",
    "Costa Rican",
    "Croat or Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djiboutian",
    "Dominican",
    "Ecuadorean",
    "Emirati",
    "Egyptian",
    "Salvadoran",
    "English",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Dutch",
    "Honduran",
    "Hungarian",
    "Icelandic",
    "Indian",
    "Indigenous",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Italian",
    "Israeli",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakh",
    "Kenyan",
    "Kuwaiti",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy or Madagascan",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Moldovan",
    "Monégasque or Monacan",
    "Mongolian",
    "Montenegrin",
    "Moroccan",
    "Mozambican",
    "Namibian",
    "Native American",
    "Nepalese",
    "New Zealand",
    "Nicaraguan",
    "Nigerien",
    "Nigerian",
    "North Korean",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palestinian",
    "Panamanian",
    "Papua New Guinean or Guinean",
    "Paraguayan",
    "Persian",
    "Peruvian",
    "Philippine or Filipino",
    "Polish",
    "Portuguese",
    "Puerto Rican",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saudi Arabian or Saudi",
    "Scottish",
    "Senegalese",
    "Serb or Serbian",
    "Seychellois",
    "Sicilian",
    "Sierra Leonian",
    "Singaporean",
    "Slovak",
    "Slovene or Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamese",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik or Tadjik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Trinidadian",
    "Tobagan",
    "Tobagonian",
    "Tunisian",
    "Turkish",
    "Turkmen or Turkoman",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbek",
    "Vanuatuan",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Western Samoan",
    "Yemeni",
    "Yugoslav",
    "Zaïrean",
    "Zambian",
    "Zimbabwean",
    "Unknown",
  ],
  veteranStatus: ["do", "do not", "Prefer not to say"],
  disability: ["do", "do not", "Prefer not to say"],
  disabilityType: [
    "Type to search",
    "Autism / Neurodivergent",
    "Deaf / Hard of hearing",
    "Blind / Low vision",
    "Mobility impairment",
    "Physical disability",
    "Chronic Illness or pain",
    "Mental health condition",
    "Speech-related disability",
    "Cognitive disability",
    "Learning / behavioral disability (i.e., dyslexia, ADHD)",
    "Unknown",
  ],
  inclusion: ["included", "fine", "unheard"],
  rateRange: [
    "Less than 100",
    "100 - 250",
    "250 - 500",
    "500 - 750",
    "750 - 900",
    "900 - 1000",
    "1000 - 2000",
    "2000 - 3000",
    "3000 - 4000",
    "4000 - 5000",
    "5000+",
  ],
  rateDimension: ["day", "hour", "week", "month", "flat"],
  paySentiment: ["good", "fair", "low", "unsure", "Not Listed"],
  experience: [
    "Always",
    "Often",
    "Sometimes",
    "Rarely",
    "Never",
    "Unsure",
    "Not Listed",
  ],
  biddingExperience: ["comfortable", "feasible", "short"],
  generation: [
    "I am an immigrant",
    "At least one of my parents is an immigrant",
    "At least one of my grandparents is an immigrant",
    "My family immigrated earlier than my grandparents' generation",
    "I do not come from an immigrant family, my family is indigenous to our country of residence",
    "I do not come from an immigrant family, my family is descended from enslaved people",
    "I don't consider myself an immigrant",
    "I don't know when my family immigrated",
    "Not Listed",
  ],
  militaryBranch: [
    "Type to search",
    "Airforce",
    "Army",
    "British Army",
    "Coast Guard",
    "Marines",
    "Navy",
    "Royal Air Force",
    "Royal Marines",
    "Royal Navy",
    "Space Force",
    "The Canadian Army",
    "The Royal Canadian Air Force",
    "The Royal Canadian Navy",
  ],
  duty: ["active", "reserve"],
  referral: [
    "Type to search",
    "my agent/manager/rep",
    "the producer on this job",
    "the director on this job",
    "my department head on this job",
    "FREE THE WORK!",
    "a colleague or industry group",
    "the client (studio, ad agency, brand, etc)",
    "social media",
  ],
  tackles: [
    "Crew benefits (healthcare, 401k, etc)",
    "Diversity, Inclusion, & Equal Opportunity",
    "Environmental sustainability",
    "Parental benefits (childcare, parental leave, etc)",
    "Pay issues (equitable rates, timely pay schedules, etc)",
    "Safety on set",
    "Sustainable working hours",
    "Not Listed",
  ],
  caredMost: [
    "Being treated fairly",
    "Environmental sustainability",
    "Fair pay",
    "The food",
    "Inclusion",
    "Safety",
    "Sustainable working hours",
    "Not Listed",
  ],
  tacklesUnion: ["Yes", "No"],
  tacklesUnion1: [
    "Creativity",
    "Culture",
    "Diversity",
    "Hours",
    "Meals",
    "Networking",
    "Pay",
    "Safety",
    "Not Listed",
  ],
  timeline: [
    "comfortable",
    //"just enough", -> Update for IV-1232
    //"tight", -> Update for IV-1232
    //"unsure", -> Update for IV-1232
    "challenging", // Update for IV-1232
    "unrealistic" // Update for IV-1232
  ],
  
  workOvertime: ["did", "did not"],
  environmental: [
    "I have no concerns",
    "I'm a little concerned",
    "I'm very concerned",
    "I'm unsure",
  ],
  travel: ["am", "am not"],
  travelFrom: ["within state", "from out of state"],
}

export default ANSWERS
