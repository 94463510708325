import React, { useState, useEffect } from "react"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import ANSWERS from "../../constants/StandWithProduction"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    maxWidth: 250,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "25px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },

  radioGroup: {
    flexDirection: "row",
    marginLeft: "20px",
    paddingTop: "5px",
  },
  radio: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  feedback: {
    border: "1px solid #fff",
    color: "#fff",
    padding: "10px",
    width: "100%",
    marginTop: "20px",
  },
}))

const MyExperiencesSection = ({ value, setValue, showError }) => {
  const classes = useStyles()

  const [experienceHate, setExperienceHate] = useState(
    value.experienceHate || null
  )
  const [incidentReport, setIncidentReport] = useState(
    value.incidentReport || ""
  )
  const [representation, setRepresentation] = useState(
    value.representation || null
  )
  const [shareMore, setShareMore] = useState(value.shareMore || "")

  const [incidentReportOpen, setIincidentReportOpen] = useState(false)

  useEffect(() => {
    setValue(prev => ({
      ...prev,
      experienceHate: experienceHate,
      incidentReport: incidentReport,
      representation: representation,
      shareMore: shareMore,
    }))
  }, [experienceHate, incidentReport, representation, shareMore])

  const handleChangeMultiple = (e, type) => {
    let { value } = e.target

    switch (type) {
      case "incidentReport":
        setIncidentReport(value)
        setIincidentReportOpen(false)
        break
    }
  }
  return (
    <div className="question-section">
      <h3>My Experiences</h3>
      <div className="question-wrapper">
        Have you experienced hate, discrimination, or harassment on set?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="experienceHate"
              id="experienceHate"
              defaultValue={experienceHate}
              onChange={e => {
                setExperienceHate(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
              <FormControlLabel
                value="Prefer not to say"
                control={<Radio className={classes.radio} />}
                label="Prefer not to say"
              />
            </RadioGroup>
            <RequiredError
              error={experienceHate === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
      <div className="question-wrapper">
        When an incident occurs on set, to whom do you first report said
        incident?
        <div className="field-wrapper">
          <SingleSelect
            type="incidentReport"
            value={incidentReport}
            options={ANSWERS.reportIncident}
            open={incidentReportOpen}
            setOpen={setIincidentReportOpen}
            handleChangeMultiple={handleChangeMultiple}
          />
          <RequiredError
            error={incidentReport === "" && showError}
            required={true}
            classes="field-asterisk"
          />
        </div>
      </div>
      <div className="question-wrapper">
        Would you like to see more representation for marginalized groups on
        set?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="representation"
              id="representation"
              defaultValue={representation}
              onChange={e => {
                setRepresentation(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
              <FormControlLabel
                value="Unsure"
                control={<Radio className={classes.radio} />}
                label="Unsure"
              />
            </RadioGroup>
            <RequiredError
              error={representation === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
      <div className="question-wrapper">
        Would you like to share more?
        <br />
        <div className="field-wrapper">
          <TextField
            multiline
            defaultValue={shareMore}
            rows={4}
            className={classes.feedback}
            onChange={e => setShareMore(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default MyExperiencesSection
