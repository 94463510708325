import React, { useState, useEffect } from "react"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { PopoverWrapper } from "../style"
import InfoIcon from "../../images/info-icon.svg"

const useStyles = makeStyles(theme => ({
  radioGroup: {
    flexDirection: "row",
    marginLeft: "20px",
    paddingTop: "5px",
  },
  radio: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  infoPaper: {
    padding: theme.spacing(1),
  },
}))

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    padding: 15,
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip)

const MyInterestsSection = ({ value, setValue }) => {
  const classes = useStyles()

  const [cultureSafety, setCultureSafety] = useState(
    value.cultureSafety || null
  )
  const [sexDiscrimination, setSexDiscrimination] = useState(
    value.sexDiscrimination || null
  )
  const [basicSetSafety, setBasicSetSafety] = useState(
    value.basicSetSafety || null
  )
  const [antiHarassment, setAntiHarassment] = useState(
    value.antiHarassment || null
  )
  const [substanceAbuseAwareness, setSubstanceAbuseAwareness] = useState(
    value.substanceAbuseAwareness || null
  )
  const [selfCare, setSelfCare] = useState(value.selfCare || null)
  const [healthcarePlanOptions, setHealthcarePlanOptions] = useState(
    value.healthcarePlanOptions || null
  )
  const [pensionPlan, setPensionPlan] = useState(value.pensionPlan || null)
  const [upwardMobility, setUpwardMobility] = useState(
    value.upwardMobility || null
  )
  const [understandingBudget, setUnderstandingBudget] = useState(
    value.understandingBudget || null
  )
  const [softwareTraining, setSoftwareTraining] = useState(
    value.softwareTraining || null
  )
  const [personalEducation, setPersonalEducation] = useState(
    value.personalEducation || null
  )
  const [drivingTraining, setDrivingTraining] = useState(
    value.drivingTraining || null
  )
  const [etiquette, setEtiquette] = useState(value.etiquette || null)
  const [roleResponsibilities, setRoleResponsibilities] = useState(
    value.roleResponsibilities || null
  )
  const [negotiationTraining, setNegotiationTraining] = useState(
    value.negotiationTraining || null
  )
  const [networking, setNetworking] = useState(value.networking || null)

  useEffect(() => {
    setValue(prev => ({
      ...prev,
      cultureSafety: cultureSafety,
      sexDiscrimination: sexDiscrimination,
      basicSetSafety: basicSetSafety,
      antiHarassment: antiHarassment,
      substanceAbuseAwareness: substanceAbuseAwareness,
      selfCare: selfCare,
      healthcarePlanOptions: healthcarePlanOptions,
      pensionPlan: pensionPlan,
      upwardMobility: upwardMobility,
      understandingBudget: understandingBudget,
      softwareTraining: softwareTraining,
      personalEducation: personalEducation,
      drivingTraining: drivingTraining,
      etiquette: etiquette,
      roleResponsibilities: roleResponsibilities,
      negotiationTraining: negotiationTraining,
      networking: networking,
    }))
  }, [
    cultureSafety,
    sexDiscrimination,
    basicSetSafety,
    antiHarassment,
    substanceAbuseAwareness,
    selfCare,
    healthcarePlanOptions,
    pensionPlan,
    upwardMobility,
    understandingBudget,
    softwareTraining,
    personalEducation,
    drivingTraining,
    etiquette,
    roleResponsibilities,
    negotiationTraining,
    networking,
  ])

  const renderRadios = (name, defaultVal, setValueTopic) => {
    return (
      <div className="field-wrapper">
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.radioGroup}
            name={name}
            id={name}
            defaultValue={defaultVal}
            onChange={e => {
              setValueTopic(e.target.value)
            }}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio disabled={name === "info"} className={classes.radio} />
              }
            />
            <FormControlLabel
              value="2"
              control={
                <Radio disabled={name === "info"} className={classes.radio} />
              }
            />
            <FormControlLabel
              value="3"
              control={
                <Radio disabled={name === "info"} className={classes.radio} />
              }
            />
            <FormControlLabel
              value="4"
              control={
                <Radio disabled={name === "info"} className={classes.radio} />
              }
            />
            <FormControlLabel
              value="5"
              control={
                <Radio disabled={name === "info"} className={classes.radio} />
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  return (
    <div className="question-section interest">
      <h3>My Interests</h3>
      <div className="interests-top-wrapper">
        <p>
          How interested are you in our union providing educational workshops on
          the below topics?
        </p>
      </div>

      <div className="question-wrapper label-con">
        <div className="flex">
          <div />
          <div>{renderRadios("info", null, null)}</div>
        </div>
      </div>
      <div className="question-wrapper">
        <div className="flex ">
          <div className="label-wrapper">
            <div className="not-at-all">
              Not at all
              <br />
              interested
            </div>
          </div>

          <div className="label">
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
            <div className="very-interested">
              Very
              <br />
              interested
            </div>
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Culture safety/ Bias Training</div>
          <div>
            {renderRadios("cultureSafety", cultureSafety, setCultureSafety)}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>
            Sex Discrimination/Title IX{" "}
            <HtmlTooltip
              interactive
              title={
                <PopoverWrapper>
                  <h6>For more information, see:</h6>
                  <p>
                    <a
                      href="https://www.justice.gov/crt/title-ix"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Title IX information
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.eeoc.gov/sexual-harassment#:~:text=It%20is%20unlawful%20to%20harass,harassment%20of%20a%20sexual%20nature"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sexual Harassment definition
                    </a>
                  </p>
                </PopoverWrapper>
              }
            >
              <img className="role-info" src={InfoIcon} alt="Info" />
            </HtmlTooltip>
          </div>
          <div>
            {renderRadios(
              "sexDiscrimination",
              sexDiscrimination,
              setSexDiscrimination
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Basic Set Safety</div>
          <div>
            {renderRadios("basicSetSafety", basicSetSafety, setBasicSetSafety)}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Anti Harrassment/Anti Discrimination</div>
          <div>
            {renderRadios("antiHarassment", antiHarassment, setAntiHarassment)}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Substance Abuse Awareness</div>
          <div>
            {renderRadios(
              "substanceAbuseAwareness",
              substanceAbuseAwareness,
              setSubstanceAbuseAwareness
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Self Care/Avoiding Burnout</div>
          <div>{renderRadios("selfCare", selfCare, setSelfCare)}</div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Understanding your Healthcare Plan and Options</div>
          <div>
            {renderRadios(
              "healthcarePlanOptions",
              healthcarePlanOptions,
              setHealthcarePlanOptions
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Understanding your Retirement /Pension plan</div>
          <div>{renderRadios("pensionPlan", pensionPlan, setPensionPlan)}</div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Upward Mobility within the Production Department</div>
          <div>
            {renderRadios("upwardMobility", upwardMobility, setUpwardMobility)}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Timecards / Purchase Orders/Understanding budgets 101</div>
          <div>
            {renderRadios(
              "understandingBudget",
              understandingBudget,
              setUnderstandingBudget
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>
            Software Training (office, google docs, budgeting- showbiz, hot
            budget, etc)
          </div>
          <div>
            {renderRadios(
              "softwareTraining",
              softwareTraining,
              setSoftwareTraining
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Personal Finance Education</div>
          <div>
            {renderRadios(
              "personalEducation",
              personalEducation,
              setPersonalEducation
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Truck / Driving Training / DOT / DMV Certification</div>
          <div>
            {renderRadios(
              "drivingTraining",
              drivingTraining,
              setDrivingTraining
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>On-Set Etiquette</div>
          <div>{renderRadios("etiquette", etiquette, setEtiquette)}</div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Basic Roles and Responsibilites</div>
          <div>
            {renderRadios(
              "roleResponsibilities",
              roleResponsibilities,
              setRoleResponsibilities
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Negotiation Training</div>
          <div>
            {renderRadios(
              "negotiationTraining",
              negotiationTraining,
              setNegotiationTraining
            )}
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        <div className="flex">
          <div>Networking 101</div>
          <div>{renderRadios("networking", networking, setNetworking)}</div>
        </div>
      </div>
    </div>
  )
}

export default MyInterestsSection
