import React from "react"
import { Checkbox } from "@material-ui/core/"

import PDF from "../../pdf/WIPrivacyPolicy.pdf"

const TermsPrivacy = ({ terms, setTerms }) => {
  return (
    <div className="terms-container">
      <Checkbox
        iconstyle={{ fill: "white" }}
        inputstyle={{ color: "white" }}
        style={{ color: "white" }}
        checked={terms}
        onChange={() => setTerms(!terms)}
      />
      I agree to Working Insights'{" "}
      <a href={PDF} target="_blank " rel="noreferrer">
        Privacy
      </a>
    </div>
  )
}

export default TermsPrivacy
