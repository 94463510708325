export default value => {
  switch (value) {
    case "I am an immigrant":
      return "1st"
    case "At least one of my parents is an immigrant":
      return "2nd"
    case "At least one of my grandparents is an immigrant":
      return "3rd"
    case "My family immigrated earlier than my grandparents' generation":
      return "4th and beyond"
    case "I do not come from an immigrant family, my family is indigenous to our country of residence":
      return "Indigenous"
    case "I do not come from an immigrant family, my family is descended from enslaved people":
      return "Descended from enslaved people"
    case "I don't consider myself an immigrant":
      return "Not an immigrant"
    case "I don't know when my family immigrated":
      return "unknown"
    default:
      return value
  }
}
