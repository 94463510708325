import React, { useEffect, useState } from "react"
import { FormControl, FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ANSWERS from "../../constants/Constant"
import RequiredError from "../shared/RequiredError"
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField"
import AutoCompleteWithObject from "../shared/AutoCompleteWithObject"
import AutoComplete from "../shared/AutoComplete"
import SingleSelect from "../shared/SingleSelect"
import { findQuestion } from "../../util/find"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  rate: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "20px",
    width: "150px",
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
}))

const RoleSection = props => {
  const classes = useStyles()
  const [travelExtra, setTravelExtra] = useState(false)
  const [rolesOpen, setRolesOpen] = useState(false)
  const [secondaryRoleOpen, setSecondaryRoleOpen] = useState(false)
  const [rolesOption, setRolesOption] = useState(props.roles)
  const [podcastRolesOption, setPodcastRolesOption] = useState([])
  const [travelOpen, setTravelOpen] = useState(false)
  const [travelFromOpen, setTravelFromOpen] = useState(false)
  const [referralOpen, setReferralOpen] = useState(false)

  useEffect(() => {
    if (props.travel === "am not") {
      setTravelExtra(true)
    } else {
      setTravelExtra(false)
    }
  }, [props.travel])

  useEffect(() => {
    if (!rolesOpen) {
      if (props.preenrollment) {
        setRolesOption(
          props.roles
            .filter(
              r =>
                r.name !== "Brand/Client" &&
                r.name !== "Account Manager" &&
                r.name !== "Procurement" &&
                r.name !== "Business Affairs"
            )
            .slice(0, 500)
        )
      } else {
        setRolesOption(props.roles.slice(0, 500))
      }

      setPodcastRolesOption(
        props.roles.filter(
          r =>
            r.name === "Host" ||
            r.name === "Co-host" ||
            r.name === "Executive Producer" ||
            r.name === "Editor" ||
            r.name === "Showrunner" ||
            r.name === "Senior Producer" ||
            r.name === "Writer" ||
            r.name === "Director" ||
            r.name === "Producer" ||
            r.name === "Associate Producer" ||
            r.name === "PA(Production Assistant)" ||
            r.name === "Coordinating Producer" ||
            r.name === "Casting Director" ||
            r.name === "SAG Coordinator" ||
            r.name === "Technical Director" ||
            r.name === "Recording Engineer" ||
            r.name === "Tape Syncer" ||
            r.name === "Post Engineer" ||
            r.name === "Assistant Engineer" ||
            r.name === "Composer" ||
            r.name === "Sound Designer" ||
            r.name === "Prefer not to say" ||
            r.name === "Not Listed"
        )
      )
    }
    // eslint-disable-next-line
  }, [rolesOpen])

  const onChangeRole = value => {
    if (props.preenrollment) {
      setRolesOption(
        props.roles.filter(
          role =>
            role.name !== "Brand/Client" &&
            role.name !== "Account Manager" &&
            role.name !== "Procurement" &&
            role.name !== "Business Affairs" &&
            role.name.toLowerCase().includes(value.toLowerCase())
        )
      )
    } else {
      setRolesOption(
        props.roles.filter(role =>
          role.name.toLowerCase().includes(value.toLowerCase())
        )
      )
    }
  }

  return (
    <div className="question-section">
      <h3>{props.preenrollment ? "My role" : "About this project"}</h3>
      <QuestionsWrapper
        customQuestions={props.customQuestions}
        setCustomA={props.setCustomA}
        customA={props.customA}
        showError={props.showError}
      >
        {findQuestion(props.surveySection.questions, "role0")?.visibility ===
          1 && (
          <div className="question-wrapper">
            {props.preenrollment ? "I am a " : "I am the "}
            <FormControl className={classes.formControl}>
              <div className="field-wrapper">
                <AutoCompleteWithObject
                  type="role"
                  value={props.role}
                  setValue={props.setRole}
                  open={rolesOpen}
                  setOpen={setRolesOpen}
                  options={props.isPodcast ? podcastRolesOption : rolesOption}
                  survey={
                    findQuestion(props.surveySection.questions, "role0")
                      ?.required
                  }
                  className="role-chips"
                  setCustomOpen={props.setCustomOpen}
                  queryClass=".role-chips .MuiChip-label"
                  showError={props.showError}
                  onChangeRole={onChangeRole}
                  hideEndAdornment
                />

                <RequiredError
                  error={
                    findQuestion(props.surveySection.questions, "role0")
                      ?.required === 1 &&
                    props.showError &&
                    props.role.length === 0
                  }
                  required={
                    findQuestion(props.surveySection.questions, "role0")
                      ?.required === 1
                  }
                  classes="field-asterisk"
                />
              </div>
            </FormControl>{" "}
            {props.preenrollment ? "." : "on this project."}
            <div className="role-info-text">
              To ensure anonymity, role data will not be shared unless
              aggregated across multiple productions.
            </div>
          </div>
        )}

        {findQuestion(props.surveySection.questions, "role1")?.visibility ===
          1 && (
          <div className="question-wrapper">
            I have{" "}
            <FormControl required className={classes.formControl}>
              <div className="field-wrapper">
                <CurrencyTextField
                  variant="standard"
                  value={props.yoe}
                  id="yoe"
                  type="tel"
                  currencySymbol=""
                  outputFormat="number"
                  error={
                    findQuestion(props.surveySection.questions, "role1")
                      ?.required === 1 &&
                    props.showError &&
                    props.yoe === ""
                  }
                  minimumValue={0}
                  decimalPlacesShownOnBlur={2}
                  digitGroupSeparator=","
                  onChange={(event, value) => props.setYoe(value)}
                  InputProps={{
                    className: classes.rate,
                  }}
                />

                <RequiredError
                  error={
                    findQuestion(props.surveySection.questions, "role1")
                      ?.required === 1 &&
                    props.showError &&
                    props.yoe === ""
                  }
                  required={
                    findQuestion(props.surveySection.questions, "role1")
                      ?.required === 1
                  }
                  classes="field-asterisk"
                />
              </div>
            </FormControl>{" "}
            years of experience in this role.
          </div>
        )}

        {findQuestion(props.surveySection.questions, "role2")?.visibility ===
          1 && (
          <div className="question-wrapper">
            Other roles that I perform regularly include:{" "}
            <FormControl className={classes.formControl}>
              <div className="field-wrapper">
                <AutoCompleteWithObject
                  type="secondaryRole"
                  value={props.secondaryRole}
                  setValue={props.setSecondaryRole}
                  open={secondaryRoleOpen}
                  setOpen={setSecondaryRoleOpen}
                  options={rolesOption}
                  survey={
                    findQuestion(props.surveySection.questions, "role2")
                      ?.required
                  }
                  className="secondrole-chips"
                  setCustomOpen={props.setCustomOpen}
                  queryClass=".secondrole-chips .MuiChip-label"
                  showError={props.showError}
                  onChangeRole={onChangeRole}
                  hideEndAdornment
                />

                <RequiredError
                  error={
                    findQuestion(props.surveySection.questions, "role2")
                      ?.required === 1 &&
                    props.showError &&
                    props.secondaryRole.length === 0
                  }
                  required={
                    findQuestion(props.surveySection.questions, "role2")
                      ?.required === 1
                  }
                  classes="field-asterisk"
                />
              </div>
            </FormControl>
            .
          </div>
        )}

        {!props.preenrollment && (
          <>
            {findQuestion(props.surveySection.questions, "role3")
              ?.visibility === 1 && (
              <div className="question-wrapper">
                My commute for this job from the time I leave my house to the
                time I arrive on set is{" "}
                <FormControl required className={classes.formControl}>
                  <div className="field-wrapper">
                    <CurrencyTextField
                      variant="standard"
                      value={props.commute}
                      id="commute"
                      type="tel"
                      error={
                        findQuestion(props.surveySection.questions, "role3")
                          ?.required === 1 &&
                        props.showError &&
                        props.commute === ""
                      }
                      currencySymbol=""
                      outputFormat="number"
                      decimalPlacesShownOnBlur={0}
                      minimumValue={0}
                      onChange={(event, value) => props.setCommute(value)}
                      InputProps={{
                        className: classes.rate,
                      }}
                    />

                    <RequiredError
                      error={
                        findQuestion(props.surveySection.questions, "role3")
                          ?.required === 1 &&
                        props.showError &&
                        props.commute === ""
                      }
                      required={
                        findQuestion(props.surveySection.questions, "role3")
                          ?.required === 1
                      }
                      classes="field-asterisk"
                    />
                  </div>
                </FormControl>{" "}
                minutes.
              </div>
            )}

            {findQuestion(props.surveySection.questions, "role4")
              ?.visibility === 1 && (
              <div className="question-wrapper">
                I{" "}
                <SingleSelect
                  type="travel"
                  value={props.travel}
                  survey={props.survey}
                  showError={props.showError}
                  options={ANSWERS.travel}
                  open={travelOpen}
                  setOpen={setTravelOpen}
                  handleChangeMultiple={props.handleChangeMultiple}
                />
                <RequiredError
                  error={
                    findQuestion(props.surveySection.questions, "role4")
                      ?.visibility === 1 &&
                    props.showError &&
                    props.travel === ""
                  }
                  required={
                    findQuestion(props.surveySection.questions, "role4")
                      ?.required === 1
                  }
                  classes="field-asterisk"
                />{" "}
                local to the city of this production.
              </div>
            )}

            {travelExtra && (
              <div className="question-wrapper">
                I traveled{" "}
                <div className="field-wrapper">
                  <SingleSelect
                    type="travelFrom"
                    value={props.travelFrom}
                    survey={props.survey}
                    showError={props.showError}
                    options={ANSWERS.travelFrom}
                    open={travelFromOpen}
                    setOpen={setTravelFromOpen}
                    handleChangeMultiple={props.handleChangeMultiple}
                  />
                  {props.showError &&
                    props.travel === "am not" &&
                    props.travelFrom === "" && (
                      <FormHelperText className="error">
                        Please make a selection
                      </FormHelperText>
                    )}{" "}
                  for this job.
                </div>
              </div>
            )}

            {findQuestion(props.surveySection.questions, "role5")
              ?.visibility === 1 && (
              <div className="question-wrapper">
                I was referred for this job by{" "}
                <FormControl className={classes.formControl}>
                  <div className="field-wrapper">
                    <AutoComplete
                      type="referralSource"
                      value={props.referralSource}
                      setValue={props.setReferralSource}
                      open={referralOpen}
                      setOpen={setReferralOpen}
                      options={ANSWERS.referral}
                      survey={
                        findQuestion(props.surveySection.questions, "role5")
                          ?.required
                      }
                      className="referral-chips"
                      setCustomOpen={props.setCustomOpen}
                      queryClass=".referral-chips .MuiChip-label"
                      showError={props.showError}
                      multiple={true}
                    />

                    <RequiredError
                      error={
                        findQuestion(props.surveySection.questions, "role5")
                          ?.required === 1 &&
                        props.showError &&
                        props.referralSource.length === 0
                      }
                      required={
                        findQuestion(props.surveySection.questions, "role5")
                          ?.required === 1
                      }
                      classes="field-asterisk"
                    />
                  </div>
                </FormControl>
                .
              </div>
            )}
          </>
        )}
      </QuestionsWrapper>
    </div>
  )
}

export default RoleSection
