const ANSWERS = {
  jobTitle: [
    "Assistant Production Supervisor/Commercial Coordinator",
    "Bidding Producer",
    "Line Producer",
    "Production Assistant",
    "Production Supervisor",
  ],
  rate: ["Day", "Hour", "Week", "Month", "Flat"],
  grossIncome: [
    "$15,000 or Less",
    "$15,000 to $25,000",
    "$35,000 to $45,000",
    "$45,000 to $55,000",
    "$55,000 to $65,000",
    "$65,000 to $75,000",
    "$75,000 to $85,000",
    "$85,000 to $100,000",
    "$100,000 to $125,000",
    "$125,000 to $150,000",
    "$150,000 to $175,000",
    "$175,000 to $200,000",
    "$200,000 to $231,250",
    "$231,250 or More",
  ],
  yesNoUnsure: ["Yes", "No", "Unsure"],
  yesNo: ["Yes", "No"],
  yesNoPreferNotToSay: ["Yes", "No", "Prefer not to say"],
  workOften: ["Always", "Often", "Sometimes", "Rarely", "Never"],
  healthCare: [
    "P.H.B.P",
    "Through another employer",
    "State/government funded healthcare plan",
    "Through a domestic partner or guardian's plan",
    "Self-pay",
  ],
  education: [
    "Associates Degree",
    "Bachelors Degree",
    "Doctoral Degree",
    "GED",
    "High School Diploma",
    "Masters Degree",
    "Professional Degree",
    "Some College",
  ],
  reportIncident: [
    "1st A.D.",
    "Line Producer",
    "Production Supervisor",
    "Assistant Production Supervisor",
    "Head of Production at company",
    "I don't know",
    "Other",
  ],
}

export default ANSWERS
