const LANGUAGES = {
  nativeLanguage: [
    "Type to search",
    "Afrikaans",
    "Albanian",
    "Arabic",
    "Armenian",
    "Catalan",
    "Cantonese",
    "Danish",
    "Dari",
    "Dutch",
    "English",
    "Estonian",
    "Farsi",
    "Filipino",
    "Finnish",
    "French",
    "Georgian",
    "German",
    "Greek",
    "Gujarati",
    "Haitain Creole",
    "Hebrew",
    "Hindi",
    "Indonesian",
    "isiXhosa",
    "isiZulu",
    "Italian",
    "Japanese",
    "Kiswahili",
    "Korean",
    "Lithuanian",
    "Malayalam",
    "Mandarin",
    "Marathi",
    "Norwegian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Romanian",
    "Russian",
    "Sepedi",
    "Serbian",
    "SeTswana",
    "Sinhala",
    "Sotho",
    "Spanish",
    "Swahili",
    "Swedish",
    "Tagalog",
    "Tamil",
    "Telugu",
    "Thai",
    "Turkish",
    "Urdu",
    "Zulu",
    "!O!ung",
    "!X\u00f3\u00f5",
    "Are'are",
    "Auhelawa",
    "//Ani",
    "//Gana",
    "//Xegwi",
    "/Gwi",
    "/Xam",
    "\u00c0h\u00e0n",
    "\u00c1nc\u00e1",
    "\u00d6mie",
    "\u00d6nge",
    "=/Hua",
    "=/Kx'au//'ein",
    "A-Pucikwar",
    "A'ou",
    "A'tong",
    "Aari",
    "Aas\u00e1x",
    "Ab\u00e9",
    "Abadi",
    "Abaga",
    "Abai Sungai",
    "Abanyom",
    "Abar",
    "Abau",
    "Abaza",
    "Abellen Ayta",
    "Abidji",
    "Abinomn",
    "Abipon",
    "Abishira",
    "Abkhazian",
    "Abom",
    "Abon",
    "Abron",
    "Abu",
    "Abu' Arapesh",
    "Abua",
    "Abui",
    "Abun",
    "Abure",
    "Abureni",
    "Acatepec Me'phaa",
    "Ach\u00e9",
    "Achagua",
    "Achang",
    "Ache",
    "Acheron",
    "Achi",
    "Achinese",
    "Achterhoeks",
    "Achuar-Shiwiar",
    "Achumawi",
    "Acoli",
    "Acro\u00e1",
    "Adabe",
    "Adai",
    "Adamawa Fulfulde",
    "Adamorobe Sign Language",
    "Adang",
    "Adangbe",
    "Adangme",
    "Adap",
    "Adara",
    "Adasen",
    "Adele",
    "Adhola",
    "Adi",
    "Adioukrou",
    "Adithinngithigh",
    "Adivasi Oriya",
    "Adiwasi Garasia",
    "Adnyamathanha",
    "Adonara",
    "Aduge",
    "Adyghe",
    "Adzera",
    "Aeka",
    "Aekyom",
    "Aequian",
    "Aer",
    "Afade",
    "Afar",
    "Afghan Sign Language",
    "Afitti",
    "Afrihili",
    "Afro-Seminole Creole",
    "Agarabi",
    "Agariya",
    "Agatu",
    "Agavotaguerra",
    "Aghem",
    "Aghu",
    "Aghu-Tharnggala",
    "Aghul",
    "Aghwan",
    "Agi",
    "Agob",
    "Agoi",
    "Aguacateco",
    "Aguano",
    "Aguaruna",
    "Aguna",
    "Agusan Manobo",
    "Agutaynen",
    "Agwagwune",
    "Ahanta",
    "Aheu",
    "Ahirani",
    "Ahom",
    "Ahtena",
    "Ahwai",
    "Ai-Cham",
    "Aighon",
    "Aikan\u00e3",
    "Aiklep",
    "Aimaq",
    "Aimele",
    "Aimol",
    "Ainbai",
    "Ainu (China)",
    "Ainu (Japan)",
    "Aiome",
    "Airoran",
    "Aiton",
    "Aja (Benin)",
    "Aja (Sudan)",
    "Ajawa",
    "Aji\u00eb",
    "Ajumbu",
    "Ajy\u00edninka Apurucayali",
    "Ak",
    "Aka",
    "Aka-Bea",
    "Aka-Bo",
    "Aka-Cari",
    "Aka-Jeru",
    "Aka-Kede",
    "Aka-Kol",
    "Aka-Kora",
    "Akan",
    "Akar-Bale",
    "Akaselem",
    "Akawaio",
    "Ake",
    "Akebu",
    "Akei",
    "Akeu",
    "Akha",
    "Akhvakh",
    "Akkadian",
    "Akkala Sami",
    "Aklanon",
    "Akolet",
    "Akoose",
    "Akoye",
    "Akpa",
    "Akpes",
    "Akrukay",
    "Akukem",
    "Akuku",
    "Akum",
    "Akuntsu",
    "Akurio",
    "Akwa",
    "Akyaung Ari Naga",
    "Al-Sayyid Bedouin Sign Language",
    "Alaba-K\u2019abeena",
    "Alabama",
    "Alabat Island Agta",
    "Alacatlatzala Mixtec",
    "Alago",
    "Alagwa",
    "Alak",
    "Alamblak",
    "Alangan",
    "Alanic",
    "Alapmunte",
    "Alawa",
    "Albanian Sign Language",
    "Alcozauca Mixtec",
    "Alege",
    "Alekano",
    "Aleut",
    "Algerian Arabic",
    "Algerian Saharan Arabic",
    "Algerian Sign Language",
    "Algonquin",
    "Ali",
    "Alladian",
    "Allar",
    "Alngith",
    "Alo Phola",
    "Alo\u00e1pam Zapotec",
    "Alor",
    "Alsea",
    "Alu Kurumba",
    "Alugu",
    "Alumu-Tesu",
    "Alune",
    "Aluo",
    "Alur",
    "Alutor",
    "Alviri-Vidari",
    "Alyawarr",
    "Ama (Papua New Guinea)",
    "Ama (Sudan)",
    "Amahai",
    "Amahuaca",
    "Amaimon",
    "Amal",
    "Amanab",
    "Amanay\u00e9",
    "Amara",
    "Amarakaeri",
    "Amarasi",
    "Amatl\u00e1n Zapotec",
    "Amba (Solomon Islands)",
    "Amba (Uganda)",
    "Ambai",
    "Ambakich",
    "Ambala Ayta",
    "Ambelau",
    "Ambele",
    "Amblong",
    "Ambo",
    "Ambo-Pasco Quechua",
    "Ambonese Malay",
    "Ambrak",
    "Ambul",
    "Ambulas",
    "Amdang",
    "Amdo Tibetan",
    "Amele",
    "American Sign Language",
    "Amganad Ifugao",
    "Amharic",
    "Ami",
    "Amis",
    "Amo",
    "Amol",
    "Amoltepec Mixtec",
    "Ampanang",
    "Ampari Dogon",
    "Amri Karbi",
    "Amto",
    "Amundava",
    "Amurdak",
    "Ana Tinga Dogon",
    "Anaang",
    "Anakalangu",
    "Anal",
    "Anam",
    "Anamb\u00e9",
    "Anamgura",
    "Anasi",
    "Ancient Greek (to 1453)",
    "Ancient Hebrew",
    "Ancient Macedonian",
    "Ancient North Arabian",
    "Ancient Zapotec",
    "Andaandi",
    "Andai",
    "Andajin",
    "Andalusian Arabic",
    "Andaman Creole Hindi",
    "Andaqui",
    "Andarum",
    "Andegerebinha",
    "Andh",
    "Andi",
    "Andio",
    "Andoa",
    "Andoque",
    "Andra-Hus",
    "Aneityum",
    "Anem",
    "Aneme Wake",
    "Anfillo",
    "Angaataha",
    "Angait\u00e9",
    "Angal",
    "Angal Enen",
    "Angal Heneng",
    "Angami Naga",
    "Angguruk Yali",
    "Angika",
    "Angkamuthi",
    "Anglo-Norman",
    "Angloromani",
    "Angolar",
    "Angor",
    "Angoram",
    "Angosturas Tunebo",
    "Anguthimri",
    "Ani Phowa",
    "Anii",
    "Animere",
    "Anindilyakwa",
    "Anjam",
    "Ankave",
    "Anmatyerre",
    "Anong",
    "Anor",
    "Anserma",
    "Ansus",
    "Antakarinya",
    "Antankarana Malagasy",
    "Antigua and Barbuda Creole English",
    "Anu-Hkongso Chin",
    "Anuak",
    "Anufo",
    "Anuki",
    "Anus",
    "Anuta",
    "Anyin",
    "Anyin Morofo",
    "Ao Naga",
    "Aoheng",
    "Aore",
    "Ap Ma",
    "Apala\u00ed",
    "Apalachee",
    "Apali",
    "Apasco-Apoala Mixtec",
    "Apatani",
    "Apiak\u00e1",
    "Apinay\u00e9",
    "Apma",
    "Aproumu Aizi",
    "Apurin\u00e3",
    "Aputai",
    "Aquitanian",
    "Arabana",
    "Arabela",
    "Aragonese",
    "Araki",
    "Arakwal",
    "Aralle-Tabulahan",
    "Aramanik",
    "Arammba",
    "Aranadan",
    "Aranama-Tamique",
    "Arandai",
    "Araona",
    "Arapaho",
    "Arapaso",
    "Ararandew\u00e1ra",
    "Arawak",
    "Arawet\u00e9",
    "Arawum",
    "Arb\u00ebresh\u00eb Albanian",
    "Arbore",
    "Archi",
    "Ardham\u0101gadh\u012b Pr\u0101krit",
    "Are",
    "Areba",
    "Arem",
    "Arequipa-La Uni\u00f3n Quechua",
    "Argentine Sign Language",
    "Argobba",
    "Arguni",
    "Arh\u00e2",
    "Arh\u00f6",
    "Arhuaco",
    "Ari",
    "Aribwatsa",
    "Aribwaung",
    "Arifama-Miniafia",
    "Arigidi",
    "Arikap\u00fa",
    "Arikara",
    "Arikem",
    "Arin",
    "Aringa",
    "Arma",
    "Armazic",

    "Armenian Sign Language",
    "Arop-Lokep",
    "Arop-Sissano",
    "Arosi",
    "Arpitan",
    "Arritinngithigh",
    "Arta",
    "Aru\u00e1 (Amazonas State)",
    "Aru\u00e1 (Rodonia State)",
    "Aruamu",
    "Aruek",
    "Aruop",
    "Arutani",
    "Arvanitika Albanian",
    "As",
    "Asaro'o",
    "Asas",
    "Ash\u00e1ninka",
    "Ash\u00e9ninka Pajonal",
    "Ash\u00e9ninka Peren\u00e9",
    "Ashe",
    "Ashkun",
    "Asho Chin",
    "Ashtiani",
    "Asilulu",
    "Askopan",
    "Asoa",
    "Assamese",
    "Assan",
    "Assangori",
    "Assiniboine",
    "Assyrian Neo-Aramaic",
    "Asturian",
    "Asu (Nigeria)",
    "Asu (Tanzania)",
    "Asue Awyu",
    "Asumboa",
    "Asunci\u00f3n Mixtepec Zapotec",
    "Asuri",
    "Ata",
    "Ata Manobo",
    "Atakapa",
    "Atampaya",
    "Atatl\u00e1huca Mixtec",
    "Atayal",
    "Atemble",
    "Athpariya",
    "Ati",
    "Atikamekw",
    "Atohwaim",
    "Atong",
    "Atorada",
    "Atsahuaca",
    "Atsam",
    "Atsugewi",
    "Attapady Kurumba",
    "Atti\u00e9",
    "Atzingo Matlatzinca",
    "Au",
    "Aulua",
    "Aur\u00e1",
    "Aushi",
    "Aushiri",
    "Austral",
    "Australian Aborigines Sign Language",
    "Australian Sign Language",
    "Austrian Sign Language",
    "Auwe",
    "Auye",
    "Auyokawa",
    "Av\u00e1-Canoeiro",
    "Avaric",
    "Avatime",
    "Avau",
    "Avestan",
    "Avikam",
    "Avokaya",
    "Awa (China)",
    "Awa (Papua New Guinea)",
    "Awa-Cuaiquer",
    "Awabakal",
    "Awad Bing",
    "Awadhi",
    "Awak",
    "Awar",
    "Awara",
    "Awbono",
    "Aweer",
    "Awera",
    "Awet\u00ed",
    "Awing",
    "Awiyaana",
    "Awjilah",
    "Awngi",
    "Awngthim",
    "Awtuw",
    "Awu",
    "Awun",
    "Awutu",
    "Awyi",
    "Axamb",
    "Axi Yi",
    "Ayabadhu",
    "Ayacucho Quechua",
    "Ayautla Mazatec",
    "Ayere",
    "Ayerrerenge",
    "Ayi (Papua New Guinea)",
    "Ayiwo",
    "Ayizi",
    "Ayizo Gbe",
    "Aymara",
    "Ayoquesco Zapotec",
    "Ayoreo",
    "Ayu",
    "Ayutla Mixtec",
    "Azerbaijani",
    "Azha",
    "Azhe",
    "Azoy\u00fa Me'phaa",
    "B\u00e4di Kanum",
    "Baan",
    "Baangi",
    "Baatonum",
    "Baba",
    "Baba Malay",
    "Babalia Creole Arabic",
    "Babango",
    "Babanki",
    "Babatana",
    "Babine",
    "Babuza",
    "Bacama",
    "Bacanese Malay",
    "Bactrian",
    "Bada (Indonesia)",
    "Bada (Nigeria)",
    "Badaga",
    "Bade",
    "Badeshi",
    "Badimaya",
    "Badjiri",
    "Badui",
    "Badyara",
    "Baeggu",
    "Baelelea",
    "Baetora",
    "Bafanji",
    "Bafaw-Balong",
    "Bafia",
    "Bafut",
    "Baga Binari",
    "Baga Kaloum",
    "Baga Koga",
    "Baga Manduri",
    "Baga Mboteni",
    "Baga Sitemu",
    "Baga Soban\u00e9",
    "Bagheli",
    "Bagirmi",
    "Bagirmi Fulfulde",
    "Bago-Kusuntu",
    "Bagri",
    "Bagupi",
    "Bagusa",
    "Bagvalal",
    "Baha Buyang",
    "Baham",
    "Bahamas Creole English",
    "Baharna Arabic",
    "Bahau",
    "Bahinemo",
    "Bahing",
    "Bahnar",
    "Bahonsuai",
    "Bai",
    "Baibai",
    "Baikeno",
    "Baima",
    "Baimak",
    "Bainouk-Gunyaamolo",
    "Bainouk-Gunyu\u00f1o",
    "Bainouk-Samik",
    "Baiso",
    "Baissa Fali",
    "Bajan",
    "Bajelani",
    "Baka (Cameroon)",
    "Baka (Sudan)",
    "Bakair\u00ed",
    "Bakaka",
    "Bakhtiari",
    "Baki",
    "Bakoko",
    "Bakole",
    "Bakpinka",
    "Bakumpai",
    "Bakw\u00e9",
    "Balaesang",
    "Balangao",
    "Balangingi",
    "Balanta-Ganja",
    "Balanta-Kentohe",
    "Balantak",
    "Balau",
    "Baldemu",
    "Bali (Democratic Republic of Congo)",
    "Bali (Nigeria)",
    "Balinese",
    "Balinese Malay",
    "Balkan Gagauz Turkish",
    "Balkan Romani",
    "Balo",
    "Baloi",
    "Balti",
    "Baltic Romani",
    "Baluan-Pam",
    "Baluchi",
    "Bamako Sign Language",
    "Bamali",
    "Bambalang",
    "Bambam",
    "Bambara",
    "Bambassi",
    "Bambili-Bambui",
    "Bamenyam",
    "Bamu",
    "Bamukumbit",
    "Bamun",
    "Bamunka",
    "Bamwe",
    "Ban Khor Sign Language",
    "Bana",
    "Banao Itneg",
    "Banaro",
    "Banda (Indonesia)",
    "Banda Malay",
    "Banda-Bambari",
    "Banda-Banda",
    "Banda-Mbr\u00e8s",
    "Banda-Nd\u00e9l\u00e9",
    "Banda-Yangere",
    "Bandi",
    "Bandial",
    "Bandjalang",
    "Bangala",
    "Bangandu",
    "Bangba",
    "Banggai",
    "Banggarla",
    "Bangi",
    "Bangime",
    "Bangka",
    "Bangolan",
    "Bangubangu",
    "Bangwinji",
    "Baniva",
    "Baniwa",
    "Banjar",
    "Bankagooma",
    "Bankal",
    "Bankan Tey Dogon",
    "Bankon",
    "Bannoni",
    "Bantawa",
    "Bantayanon",
    "Bantik",
    "Bantoanon",
    "Baoul\u00e9",
    "Bar\u00e9",
    "Bar\u00ed",
    "Bara Malagasy",
    "Baraamu",
    "Barababaraba",
    "Barai",
    "Barakai",
    "Baram Kayan",
    "Barama",
    "Barambu",
    "Baramu",
    "Barapasi",
    "Baras",
    "Barasana-Eduria",
    "Barbacoas",
    "Barbaram",
    "Barbare\u00f1o",
    "Barclayville Grebo",
    "Bardi",
    "Barein",
    "Bargam",
    "Bari",
    "Bariai",
    "Bariji",
    "Barikanchi",
    "Barikewa",
    "Barok",
    "Barombi",
    "Barro Negro Tunebo",
    "Barrow Point",
    "Baruga",
    "Baruya",
    "Barwe",
    "Barzani Jewish Neo-Aramaic",
    "Basa (Cameroon)",
    "Basa (Nigeria)",
    "Basa-Gumna",
    "Basa-Gurmana",
    "Basap",
    "Basay",
    "Bashkardi",
    "Bashkir",
    "Basketo",
    "Basque",
    "Bassa",
    "Bassa-Kontagora",
    "Bassari",
    "Bassossi",
    "Bata",
    "Batad Ifugao",
    "Batak",
    "Batak Alas-Kluet",
    "Batak Angkola",
    "Batak Dairi",
    "Batak Karo",
    "Batak Mandailing",
    "Batak Simalungun",
    "Batak Toba",
    "Batanga",
    "Batek",
    "Bateri",
    "Bathari",
    "Bati (Cameroon)",
    "Bati (Indonesia)",
    "Bats",
    "Batu",
    "Batui",
    "Batuley",
    "Batyala",
    "Bau",
    "Bau Bidayuh",
    "Bauchi",
    "Baure",
    "Bauria",
    "Bauwaki",
    "Bauzi",
    "Bavarian",
    "Bawm Chin",
    "Bay Miwok",
    "Bayali",
    "Baybayanon",
    "Baygo",
    "Bayono",
    "Bayot",
    "Bayungu",
    "Bazigar",
    "Beami",
    "Beaver",
    "Beba",
    "Bebele",
    "Bebeli",
    "Bebil",
    "Bedjond",
    "Bedoanas",
    "Beeke",
    "Beele",
    "Beembe",
    "Beezen",
    "Befang",
    "Beja",
    "Bekati'",
    "Bekwarra",
    "Bekwel",
    "Belait",
    "Belanda Bor",
    "Belanda Viri",
    "Belarusian",
    "Belhariya",
    "Beli (Papua New Guinea)",
    "Beli (Sudan)",
    "Belize Kriol English",
    "Bella Coola",
    "Bellari",
    "Bemba (Democratic Republic of Congo)",
    "Bemba (Zambia)",
    "Bembe",
    "Ben Tey Dogon",
    "Bena (Nigeria)",
    "Bena (Tanzania)",
    "Benabena",
    "Bench",
    "Bende",
    "Bendi",
    "Beng",
    "Benga",
    "Bengali",
    "Benggoi",
    "Bengkala Sign Language",
    "Bentong",
    "Benyadu'",
    "Beothuk",
    "Bepour",
    "Bera",
    "Berakou",
    "Berau Malay",
    "Berbice Creole Dutch",
    "Berik",
    "Berinomo",
    "Berom",
    "Berta",
    "Berti",
    "Besisi",
    "Besme",
    "Besoa",
    "Betaf",
    "Betawi",
    "Bete",
    "Bete-Bendi",
    "Beti (C\u00f4te d'Ivoire)",
    "Betta Kurumba",
    "Bezhta",
    "Bhadrawahi",
    "Bhalay",
    "Bharia",
    "Bhatola",
    "Bhatri",
    "Bhattiyali",
    "Bhaya",
    "Bhele",
    "Bhilali",
    "Bhili",
    "Bhojpuri",
    "Bhoti Kinnauri",
    "Bhujel",
    "Bhunjia",
    "Biafada",
    "Biage",
    "Biak",
    "Biali",
    "Bian Marind",
    "Biangai",
    "Biao",
    "Biao Mon",
    "Biao-Jiao Mien",
    "Biatah Bidayuh",
    "Bibbulman",
    "Bidhawal",
    "Bidiyo",
    "Bidyara",
    "Bidyogo",
    "Biem",
    "Bierebo",
    "Bieria",
    "Biete",
    "Big Nambas",
    "Biga",
    "Bigambal",
    "Bijori",
    "Bikaru",
    "Bikol",
    "Bikya",
    "Bila",
    "Bilakura",
    "Bilaspuri",
    "Bilba",
    "Bilbil",
    "Bile",
    "Bilin",
    "Bilma Kanuri",
    "Biloxi",
    "Bilua",
    "Bilur",
    "Bima",
    "Bimin",
    "Bimoba",
    "Bina (Nigeria)",
    "Bina (Papua New Guinea)",
    "Binahari",
    "Binandere",
    "Bindal",
    "Bine",
    "Bini",
    "Binji",
    "Binongan Itneg",
    "Bintauna",
    "Bintulu",
    "Binukid",
    "Binumarien",
    "Bipi",
    "Birale",
    "Birao",
    "Birgit",
    "Birhor",
    "Biri",
    "Biritai",
    "Birked",
    "Birri",
    "Birrpayi",
    "Birwa",
    "Biseni",
    "Bishnupriya",
    "Bishuo",
    "Bisis",
    "Bislama",
    "Bisorio",
    "Bissa",
    "Bisu",
    "Bit",
    "Bitare",
    "Bitur",
    "Biwat",
    "Biyo",
    "Biyom",
    "Blablanga",
    "Blafe",
    "Blagar",
    "Blang",
    "Blissymbols",
    "Bo (Laos)",
    "Bo (Papua New Guinea)",
    "Bo-Rukul",
    "Bo-Ung",
    "Boano (Maluku)",
    "Boano (Sulawesi)",
    "Bobongko",
    "Bobot",
    "Bodo (Central African Republic)",
    "Bodo (India)",
    "Bodo Gadaba",
    "Bodo Parja",
    "Bofi",
    "Boga",
    "Bogaya",
    "Boghom",
    "Boguru",
    "Bohtan Neo-Aramaic",
    "Boikin",
    "Bokha",
    "Boko (Benin)",
    "Boko (Democratic Republic of Congo)",
    "Bokobaru",
    "Bokoto",
    "Bokyi",
    "Bola",
    "Bolango",
    "Bole",
    "Bolgarian",
    "Bolgo",
    "Bolia",
    "Bolinao",
    "Bolivian Sign Language",
    "Bolo",
    "Boloki",
    "Bolon",
    "Bolondo",
    "Bolongan",
    "Bolyu",
    "Bom",
    "Boma",
    "Bomboli",
    "Bomboma",
    "Bomitaba",
    "Bomu",
    "Bomwali",
    "Bon Gula",
    "Bonan",
    "Bondei",
    "Bondo",
    "Bondoukou Kulango",
    "Bondum Dom Dogon",
    "Bonerate",
    "Bonerif",
    "Bonggi",
    "Bonggo",
    "Bongili",
    "Bongo",
    "Bongu",
    "Bonjo",
    "Bonkeng",
    "Bonkiman",
    "Bontok",
    "Bookan",
    "Boon",
    "Boor",
    "Bor\u00f4ro",
    "Bora",
    "Borana-Arsi-Guji Oromo",
    "Border Kuna",
    "Borei",
    "Borgu Fulfulde",
    "Borna (Democratic Republic of Congo)",
    "Boro (Ethiopia)",
    "Boro (Ghana)",
    "Borong",
    "Boruca",
    "Boselewa",
    "Bosngun",
    "Bosnian",
    "Bote-Majhi",
    "Botlikh",
    "Botolan Sambal",
    "Bouna Kulango",
    "Bouyei",
    "Bozaba",
    "Bragat",
    "Brahui",
    "Braj",
    "Brazilian Sign Language",
    "Brem",
    "Breri",
    "Breton",
    "Bribri",
    "Brithenig",
    "British Sign Language",
    "Brokkat",
    "Brokpake",
    "Brokskat",
    "Brooke's Point Palawano",
    "Broome Pearling Lugger Pidgin",
    "Brunei",
    "Brunei Bisaya",
    "Bu",
    "Bu-Nao Bunu",
    "Bua",
    "Bualkhaw Chin",
    "Buamu",
    "Bube",
    "Bubi",
    "Bubia",
    "Budeh Stieng",
    "Budibud",
    "Budong-Budong",
    "Budu",
    "Budukh",
    "Buduma",
    "Budza",
    "Bugan",
    "Bugawac",
    "Bughotu",
    "Buginese",
    "Buglere",
    "Bugun",
    "Buhi'non Bikol",
    "Buhid",
    "Buhutu",
    "Bukar-Sadung Bidayuh",
    "Bukat",
    "Bukharic",
    "Bukit Malay",
    "Bukitan",
    "Bukiyip",
    "Buksa",
    "Bukusu",
    "Bukwen",
    "Bulgarian",
    "Bulgarian Sign Language",
    "Bulgebi",
    "Buli (Ghana)",
    "Buli (Indonesia)",
    "Bullom So",
    "Bulo Stieng",
    "Bulu (Cameroon)",
    "Bulu (Papua New Guinea)",
    "Bum",
    "Bumaji",
    "Bumang",
    "Bumbita Arapesh",
    "Bumthangkha",
    "Bun",
    "Buna",
    "Bunaba",
    "Bunak",
    "Bunama",
    "Bundeli",
    "Bung",
    "Bungain",
    "Bunganditj",
    "Bungku",
    "Bungu",
    "Bunoge Dogon",
    "Bunun",
    "Buol",
    "Bura-Pabir",
    "Burak",
    "Buraka",
    "Burarra",
    "Burate",
    "Burduna",
    "Bure",
    "Buriat",
    "Burji",
    "Burmbar",
    "Burmese",
    "Burmeso",
    "Buru (Indonesia)",
    "Buru (Nigeria)",
    "Burui",
    "Burumakok",
    "Burun",
    "Burunge",
    "Burushaski",
    "Burusu",
    "Buruwai",
    "Busa",
    "Busam",
    "Busami",
    "Busang Kayan",
    "Bushi",
    "Bushoong",
    "Buso",
    "Busoa",
    "Bussa",
    "Busuu",
    "Butbut Kalinga",
    "Butmas-Tur",
    "Butuanon",
    "Buwal",
    "Buya",
    "Buyu",
    "Buyuan Jinuo",
    "Bwa",
    "Bwaidoka",
    "Bwanabwana",
    "Bwatoo",
    "Bwe Karen",
    "Bwela",
    "Bwile",
    "Bwisi",
    "Byangsi",
    "Byep",
    "C'lela",
    "C\u00f4\u00f4ng",
    "Ca\u00f1ar Highland Quichua",
    "Caac",
    "Cab\u00e9car",
    "Cabiyar\u00ed",
    "Cacaloxtepec Mixtec",
    "Cacaopera",
    "Cacgia Roglai",
    "Cacua",
    "Caddo",
    "Cafundo Creole",
    "Cagua",
    "Cahuarano",
    "Cahuilla",
    "Cajamarca Quechua",
    "Cajatambo North Lima Quechua",
    "Cajonos Zapotec",
    "Cajun French",
    "Caka",
    "Cakchiquel-Quich\u00e9 Mixed Language",
    "Cakfem-Mushere",
    "Cal\u00f3",
    "Calamian Tagbanwa",
    "Calder\u00f3n Highland Quichua",
    "Callawalla",
    "Caluyanun",
    "Cameroon Mambila",
    "Cameroon Pidgin",
    "Camling",
    "Campalagian",
    "Campidanese Sardinian",
    "Cams\u00e1",
    "Camtho",
    "Camunic",
    "Candoshi-Shapra",
    "Canela",
    "Canichana",
    "Cao Lan",
    "Cao Miao",
    "Capanahua",
    "Capiznon",
    "Cappadocian Greek",
    "Caquinte",
    "Car Nicobarese",
    "Cara",
    "Carabayo",
    "Caramanta",
    "Carapana",
    "Carian",
    "Caribbean Hindustani",
    "Caribbean Javanese",
    "Carijona",
    "Carolina Algonquian",
    "Carolinian",
    "Carpathian Romani",
    "Carrier",
    "Cashibo-Cacataibo",
    "Cashinahua",
    "Casiguran Dumagat Agta",
    "Casuarina Coast Asmat",
    "Catalan Sign Language",
    "Catawba",
    "Cauca",
    "Cavine\u00f1a",
    "Cayubaba",
    "Cayuga",
    "Cayuse",
    "Cebaara Senoufo",
    "Cebuano",
    "Celtiberian",
    "Cemuh\u00ee",
    "Cen",
    "Cent\u00fa\u00fam",
    "Central Asmat",
    "Central Atlas Tamazight",
    "Central Awyu",
    "Central Aymara",
    "Central Bai",
    "Central Berawan",
    "Central Bikol",
    "Central Bontok",
    "Central Cagayan Agta",
    "Central Dusun",
    "Central Grebo",
    "Central Hongshuihe Zhuang",
    "Central Huasteca Nahuatl",
    "Central Huishui Hmong",
    "Central Kanuri",
    "Central Khmer",
    "Central Kurdish",
    "Central Maewo",
    "Central Malay",
    "Central Masela",
    "Central Mashan Hmong",
    "Central Mazahua",
    "Central Melanau",
    "Central Mnong",
    "Central Nahuatl",
    "Central Nicobarese",
    "Central Ojibwa",
    "Central Okinawan",
    "Central Palawano",
    "Central Pame",
    "Central Pashto",
    "Central Pomo",
    "Central Puebla Nahuatl",
    "Central Sama",
    "Central Siberian Yupik",
    "Central Sierra Miwok",
    "Central Subanen",
    "Central Tagbanwa",
    "Central Tarahumara",
    "Central Tunebo",
    "Central Yupik",
    "Central-Eastern Niger Fulfulde",
    "Cerma",
    "Ch\u00e1cobo",
    "Chachapoyas Quechua",
    "Chachi",
    "Chadian Arabic",
    "Chadian Sign Language",
    "Chadong",
    "Chagatai",
    "Chaima",
    "Chak",
    "Chakali",
    "Chakma",
    "Chala",
    "Chaldean Neo-Aramaic",
    "Chalikha",
    "Chamacoco",
    "Chamalal",
    "Chamari",
    "Chambeali",
    "Chambri",
    "Chamicuro",
    "Chamorro",
    "Chan\u00e9",
    "Chang Naga",
    "Changriwa",
    "Changthang",
    "Chantyal",
    "Chara",
    "Chaudangsi",
    "Chaura",
    "Chavacano",
    "Chayahuita",
    "Chayuco Mixtec",
    "Chazumba Mixtec",
    "Che",
    "Chechen",
    "Cheke Holo",
    "Chemakum",
    "Chenapian",
    "Chenchu",
    "Chenoua",
    "Chepang",
    "Chepya",
    "Cherepon",
    "Cherokee",
    "Chesu",
    "Chetco",
    "Chewong",
    "Cheyenne",
    "Chhattisgarhi",
    "Chhintange",
    "Chhulung",
    "Chiangmai Sign Language",
    "Chiapanec",
    "Chibcha",
    "Chicahuaxtla Triqui",
    "Chichicapan Zapotec",
    "Chichimeca-Jonaz",
    "Chickasaw",
    "Chicomuceltec",
    "Chiga",
    "Chigmecatitl\u00e1n Mixtec",
    "Chilcotin",
    "Chilean Quechua",
    "Chilean Sign Language",
    "Chilisso",
    "Chiltepec Chinantec",
    "Chimalapa Zoque",
    "Chimariko",
    "Chimborazo Highland Quichua",
    "Chimila",
    "China Buriat",
    "Chinali",
    "Chinbon Chin",
    "Chincha Quechua",
    "Chinese Pidgin English",
    "Chinese Sign Language",
    "Chinook",
    "Chinook jargon",
    "Chipaya",
    "Chipewyan",
    "Chipiajes",
    "Chippewa",
    "Chiqui\u00e1n Ancash Quechua",
    "Chiquihuitl\u00e1n Mazatec",
    "Chiquitano",
    "Chirip\u00e1",
    "Chiru",
    "Chitimacha",
    "Chitkuli Kinnauri",
    "Chittagonian",
    "Chitwania Tharu",
    "Choapan Zapotec",
    "Chocangacakha",
    "Chochotec",
    "Choctaw",
    "Chodri",
    "Chokri Naga",
    "Chokwe",
    "Chol",
    "Chol\u00f3n",
    "Chong",
    "Choni",
    "Chonyi-Dzihana-Kauma",
    "Chopi",
    "Chorasmian",
    "Chort\u00ed",
    "Chothe Naga",
    "Chrau",
    "Chru",
    "Chuanqiandian Cluster Miao",
    "Chuave",
    "Chug",
    "Chuj",
    "Chuka",
    "Chukot",
    "Chukwa",
    "Chulym",
    "Chumburung",
    "Churahi",
    "Church Slavic",
    "Chut",
    "Chuukese",
    "Chuvantsy",
    "Chuvash",
    "Chuwabu",
    "Ci Gbe",
    "Cia-Cia",
    "Cibak",
    "Cicipu",
    "Cimbrian",
    "Cinda-Regi-Tiyal",
    "Cineni",
    "Cinta Larga",
    "Cisalpine Gaulish",
    "Cishingini",
    "Citak",
    "Ciwogai",
    "Clallam",
    "Classical Armenian",
    "Classical Mandaic",
    "Classical Mongolian",
    "Classical Nahuatl",
    "Classical Newari",
    "Classical Quechua",
    "Classical Syriac",
    "Classical Tibetan",
    "Coahuilteco",
    "Coast Miwok",
    "Coastal Kadazan",
    "Coastal Konjo",
    "Coatecas Altas Zapotec",
    "Coatepec Nahuatl",
    "Coatl\u00e1n Mixe",
    "Coatl\u00e1n Zapotec",
    "Coatzospan Mixtec",
    "Cocama-Cocamilla",
    "Cochimi",
    "Cocopa",
    "Cocos Islands Malay",
    "Coeur d'Alene",
    "Cof\u00e1n",
    "Cogui",
    "Col",
    "Colombian Sign Language",
    "Colonia Tovar German",
    "Colorado",
    "Columbia-Wenatchi",
    "Comaltepec Chinantec",
    "Comanche",
    "Comecrudo",
    "Como Karim",
    "Comox",
    "Con",
    "Congo Swahili",
    "Coos",
    "Copainal\u00e1 Zoque",
    "Copala Triqui",
    "Coptic",
    "Coquille",
    "Cori",
    "Cornish",
    "Corongo Ancash Quechua",
    "Corsican",
    "Costa Rican Sign Language",
    "Cotabato Manobo",
    "Cotoname",
    "Cowlitz",
    "Coxima",
    "Coyaima",
    "Coyotepec Popoloca",
    "Coyutla Totonac",
    "Cree",
    "Creek",
    "Crimean Tatar",
    "Croatia Sign Language",
    "Croatian",
    "Cross River Mbembe",
    "Crow",
    "Cruze\u00f1o",
    "Cua",
    "Cuba Sign Language",
    "Cubeo",
    "Cuiba",
    "Culina",
    "Cumanagoto",
    "Cumbric",
    "Cumeral",
    "Cun",
    "Cuneiform Luwian",
    "Cung",
    "Cupe\u00f1o",
    "Curonian",
    "Curripaco",
    "Cusco Quechua",
    "Cutchi-Swahili",
    "Cuvok",
    "Cuyamecalco Mixtec",
    "Cuyonon",
    "Cwi Bwamu",
    "Cypriot Arabic",
    "Czech",
    "Czech Sign Language",
    "D\u00e2w",
    "Da'a Kaili",
    "Daai Chin",
    "Daakaka",
    "Daantanai'",
    "Daasanach",
    "Daba",
    "Dabarre",
    "Dabe",
    "Dacian",
    "Dadi Dadi",
    "Dadibi",
    "Dadiya",
    "Daga",
    "Dagaari Dioula",
    "Dagba",
    "Dagbani",
    "Dagik",
    "Dagoman",
    "Dahalik",
    "Dahalo",
    "Daho-Doo",
    "Dai",
    "Dai Zhuang",
    "Dair",
    "Dakka",
    "Dakota",
    "Dakpakha",
    "Dalabon",
    "Dalmatian",
    "Daloa B\u00e9t\u00e9",
    "Dama",
    "Damakawa",
    "Damal",
    "Dambi",
    "Dameli",
    "Dampelas",
    "Dan",
    "Danaru",
    "Danau",
    "Dandami Maria",
    "Dangal\u00e9at",
    "Dangaura Tharu",
    "Danish Sign Language",
    "Dano",
    "Danu",
    "Dao",
    "Daonda",
    "Dar Daju Daju",
    "Dar Fur Daju",
    "Dar Sila Daju",
    "Darai",
    "Dargwa",
    "Darkinyung",
    "Darlong",
    "Darmiya",
    "Daro-Matu Melanau",
    "Dass",
    "Datooga",
    "Daungwurrung",
    "Daur",
    "Davawenyo",
    "Dawawa",
    "Dawera-Daweloor",
    "Dawro",
    "Day",
    "Dayi",
    "Daza",
    "Dazaga",
    "Deccan",
    "Dedua",
    "Defaka",
    "Defi Gbe",
    "Deg",
    "Degaru",
    "Degema",
    "Degenan",
    "Degexit'an",
    "Dehu",
    "Dehwari",
    "Dek",
    "Dela-Oenale",
    "Delaware",
    "Delo",
    "Dem",
    "Dema",
    "Demisa",
    "Demta",
    "Den\u00ed",
    "Dendi (Benin)",
    "Dendi (Central African Republic)",
    "Dengese",
    "Dengka",
    "Deno",
    "Denya",
    "Deori",
    "Dera (Indonesia)",
    "Dera (Nigeria)",
    "Desano",
    "Desiya",
    "Dewoin",
    "Dezfuli",
    "Dghwede",
    "Dhaiso",
    "Dhalandji",
    "Dhangu",
    "Dhanki",
    "Dhanwar (Nepal)",
    "Dhao",
    "Dhargari",
    "Dharuk",
    "Dharumbal",
    "Dhatki",
    "Dhimal",
    "Dhivehi",
    "Dhodia",
    "Dhofari Arabic",
    "Dhudhuroa",
    "Dhundari",
    "Dhungaloo",
    "Dhurga",
    "Dhuwal",
    "Dia",
    "Dibabawon Manobo",
    "Dibiyaso",
    "Dibo",
    "Dibole",
    "Dicamay Agta",
    "Didinga",
    "Dido",
    "Dieri",
    "Digaro-Mishmi",
    "Digo",
    "Dii",
    "Dijim-Bwilim",
    "Dilling",
    "Dima",
    "Dimasa",
    "Dimbong",
    "Dime",
    "Dimli (individual language)",
    "Ding",
    "Dinka",
    "Dirari",
    "Dirasha",
    "Diri",
    "Diriku",
    "Dirim",
    "Disa",
    "Ditammari",
    "Ditidaht",
    "Diuwe",
    "Diuxi-Tilantongo Mixtec",
    "Dixon Reef",
    "Dizin",
    "Djabwurrung",
    "Djadjawurrung",
    "Djambarrpuyngu",
    "Djamindjung",
    "Djangun",
    "Djauan",
    "Djawi",
    "Djeebbana",
    "Djimini Senoufo",
    "Djinang",
    "Djinba",
    "Djingili",
    "Djiwarli",
    "Dobel",
    "Dobu",
    "Doe",
    "Doga",
    "Doghoro",
    "Dogos\u00e9",
    "Dogoso",
    "Dogri (individual language)",
    "Dogri (macrolanguage)",
    "Dogrib",
    "Dogul Dom Dogon",
    "Doka",
    "Doko-Uyanga",
    "Dolgan",
    "Dolpo",
    "Dom",
    "Domaaki",
    "Domari",
    "Dombe",
    "Dominican Sign Language",
    "Dompo",
    "Domu",
    "Domung",
    "Dondo",
    "Dong",
    "Dongo",
    "Dongotono",
    "Dongshanba Lalo",
    "Dongxiang",
    "Donno So Dogon",
    "Doondo",
    "Dori'o",
    "Doromu-Koki",
    "Dororo",
    "Dorze",
    "Doso",
    "Dotyali",
    "Doutai",
    "Doyayo",
    "Drents",
    "Drung",
    "Du\u0303ya",
    "Duala",
    "Duano",
    "Duau",
    "Dubli",
    "Dubu",
    "Dugun",
    "Duguri",
    "Dugwor",
    "Duhwa",
    "Duke",
    "Dulbu",
    "Duli",
    "Duma",
    "Dumbea",
    "Dumi",
    "Dumpas",
    "Dumun",
    "Duna",
    "Dungan",
    "Dungmali",
    "Dungra Bhil",
    "Dungu",
    "Dupaninan Agta",
    "Dura",
    "Duri",
    "Duriankere",
    "Duruma",
    "Duruwa",
    "Dusner",
    "Dusun Deyah",
    "Dusun Malang",
    "Dusun Witu",
    "Dutch Sign Language",
    "Dutton World Speedwords",
    "Duungooma",
    "Duupa",
    "Duvle",
    "Duwai",
    "Duwet",
    "Dwang",
    "Dyaabugay",
    "Dyaberdyaber",
    "Dyan",
    "Dyangadi",
    "Dyirbal",
    "Dyugun",
    "Dyula",
    "Dz\u00f9\u00f9ngoo",
    "Dza",
    "Dzalakha",
    "Dzando",
    "Dzao Min",
    "Dzodinka",
    "Dzongkha",
    "E",
    "E'\u00f1apa Woromaipu",
    "E'ma Buyang",
    "Early Tripuri",
    "East Ambae",
    "East Berawan",
    "East Damar",
    "East Futuna",
    "East Kewa",
    "East Limba",
    "East Makian",
    "East Masela",
    "East Nyala",
    "East Tarangan",
    "East Yugur",
    "Eastern Abnaki",
    "Eastern Acipa",
    "Eastern Apur\u00edmac Quechua",
    "Eastern Arrernte",
    "Eastern Balochi",
    "Eastern Bolivian Guaran\u00ed",
    "Eastern Bontok",
    "Eastern Bru",
    "Eastern Canadian Inuktitut",
    "Eastern Cham",
    "Eastern Durango Nahuatl",
    "Eastern Egyptian Bedawi Arabic",
    "Eastern Frisian",
    "Eastern Gorkha Tamang",
    "Eastern Gurung",
    "Eastern Highland Chatino",
    "Eastern Highland Otomi",
    "Eastern Hongshuihe Zhuang",
    "Eastern Huasteca Nahuatl",
    "Eastern Huishui Hmong",
    "Eastern Karaboro",
    "Eastern Karnic",
    "Eastern Katu",
    "Eastern Kayah",
    "Eastern Keres",
    "Eastern Khumi Chin",
    "Eastern Krahn",
    "Eastern Lalu",
    "Eastern Lawa",
    "Eastern Magar",
    "Eastern Maninkakan",
    "Eastern Mari",
    "Eastern Maroon Creole",
    "Eastern Meohang",
    "Eastern Mnong",
    "Eastern Muria",
    "Eastern Ngad'a",
    "Eastern Nisu",
    "Eastern Ojibwa",
    "Eastern Oromo",
    "Eastern Parbate Kham",
    "Eastern Penan",
    "Eastern Pomo",
    "Eastern Qiandong Miao",
    "Eastern Subanen",
    "Eastern Tamang",
    "Eastern Tawbuid",
    "Eastern Xiangxi Miao",
    "Eastern Xwla Gbe",
    "Eastern Yiddish",
    "Ebira",
    "Eblan",
    "Ebri\u00e9",
    "Ebughu",
    "Ecuadorian Sign Language",
    "Ede Cabe",
    "Ede Ica",
    "Ede Idaca",
    "Ede Ije",
    "Edera Awyu",
    "Edolo",
    "Edomite",
    "Edopi",
    "Efai",
    "Efe",
    "Efik",
    "Efutop",
    "Ega",
    "Eggon",
    "Egypt Sign Language",
    "Egyptian (Ancient)",
    "Egyptian Arabic",
    "Ehueun",
    "Eipomek",
    "Eitiep",
    "Ejagham",
    "Ejamat",
    "Ekajuk",
    "Ekari",
    "Eki",
    "Ekit",
    "Ekpeye",
    "El Alto Zapotec",
    "El Hugeirat",
    "El Molo",
    "El Nayar Cora",
    "Elamite",
    "Eleme",
    "Elepi",
    "Elip",
    "Elkei",
    "Elotepec Zapotec",
    "Eloyi",
    "Elseng",
    "Elu",
    "Elymian",
    "Emae",
    "Emai-Iuleha-Ora",
    "Eman",
    "Embaloh",
    "Ember\u00e1-Baud\u00f3",
    "Ember\u00e1-Cat\u00edo",
    "Ember\u00e1-Cham\u00ed",
    "Ember\u00e1-Tad\u00f3",
    "Embu",
    "Emerillon",
    "Emilian",
    "Emplawas",
    "Emumu",
    "En",
    "Enawen\u00e9-Naw\u00e9",
    "Ende",
    "Enga",
    "Engdewu",
    "Engenni",
    "Enggano",
    "Enlhet",
    "Enrekang",
    "Enu",
    "Enwan (Akwa Ibom State)",
    "Enwan (Edu State)",
    "Enxet",
    "Enya",
    "Epena",
    "Epi-Olmec",
    "Epie",
    "Epigraphic Mayan",
    "Eravallan",
    "Erave",
    "Ere",
    "Eritai",
    "Erokwanas",
    "Erre",
    "Erromintxela",
    "Ersu",
    "Eruwa",
    "Erzya",
    "Esan",
    "Ese",
    "Ese Ejja",
    "Eshtehardi",
    "Esimbi",
    "Eskayan",
    "Esperanto",
    "Esselen",
    "Estado de M\u00e9xico Otomi",

    "Estonian Sign Language",
    "Esuma",
    "Etchemin",
    "Etebi",
    "Eten",
    "Eteocretan",
    "Eteocypriot",
    "Ethiopian Sign Language",
    "Etkywan",
    "Eton (Cameroon)",
    "Eton (Vanuatu)",
    "Etruscan",
    "Etulo",
    "Evant",
    "Even",
    "Evenki",
    "Eviya",
    "Ewage-Notu",
    "Ewe",
    "Ewondo",
    "Extremaduran",
    "Eyak",
    "Ezaa",
    "Fa d'Ambu",
    "Fagani",
    "Faire Atta",
    "Faita",
    "Faiwol",
    "Fala",
    "Falam Chin",
    "Fali",
    "Faliscan",
    "Fam",
    "Fanagalo",
    "Fanamaket",
    "Fang (Cameroon)",
    "Fang (Equatorial Guinea)",
    "Fania",
    "Fanti",
    "Far Western Muria",
    "Farefare",
    "Faroese",
    "Fas",
    "Fasu",
    "Fataleka",
    "Fataluku",
    "Fayu",
    "Fe'fe'",
    "Fembe",
    "Fernando Po Creole English",
    "Feroge",
    "Fiji Hindi",
    "Fijian",
    "Filomena Mata-Coahuitl\u00e1n Totonac",
    "Finland-Swedish Sign Language",
    "Finnish Sign Language",
    "Finongan",
    "Fipa",
    "Firan",
    "Fiwaga",
    "Flaaitaal",
    "Flinders Island",
    "Foau",
    "Foi",
    "Foia Foia",
    "Folopa",
    "Foma",
    "Fon",
    "Fongoro",
    "Foodo",
    "Forak",
    "Fordata",
    "Fore",
    "Forest Enets",
    "Fortsenal",
    "Francisco Le\u00f3n Zoque",
    "Frankish",
    "French Sign Language",
    "Friulian",
    "Fulah",
    "Fuliiru",
    "Fulni\u00f4",
    "Fum",
    "Fungwa",
    "Fur",
    "Furu",
    "Futuna-Aniwa",
    "Fuyug",
    "Fw\u00e2i",
    "Fwe",
    "Fyam",
    "Fyer",
    "G\u00fcil\u00e1 Zapotec",
    "Ga",
    "Ga'anda",
    "Ga'dang",
    "Gaa",
    "Gaam",
    "Gabi-Gabi",
    "Gabri",
    "Gabrielino-Fernande\u00f1o",
    "Gadang",
    "Gaddang",
    "Gaddi",
    "Gade",
    "Gade Lohar",
    "Gadjerawang",
    "Gadsup",
    "Gafat",
    "Gagadu",
    "Gagauz",
    "Gagnoa B\u00e9t\u00e9",
    "Gagu",
    "Gahri",
    "Gaikundi",
    "Gail",
    "Gaina",
    "Gal",
    "Galambu",
    "Galatian",
    "Galela",
    "Galeya",
    "Galibi Carib",
    "Galice",
    "Galician",
    "Galindan",
    "Gallurese Sardinian",
    "Galo",
    "Galolen",
    "Gamale Kham",
    "Gambera",
    "Gambian Wolof",
    "Gamilaraay",
    "Gamit",
    "Gamkonora",
    "Gamo",
    "Gamo-Ningi",
    "Gan Chinese",
    "Gana",
    "Ganang",
    "Ganda",
    "Gane",
    "Ganggalida",
    "Ganglau",
    "Gangte",
    "Gangulu",
    "Gants",
    "Ganza",
    "Ganzi",
    "Gao",
    "Gapapaiwa",
    "Garhwali",
    "Garifuna",
    "Garig-Ilgar",
    "Garingbal",
    "Garlali",
    "Garo",
    "Garre",
    "Garrwa",
    "Garus",
    "Garza",
    "Gata'",
    "Gavak",
    "Gavar",
    "Gavi\u00e3o Do Jiparan\u00e1",
    "Gawar-Bati",
    "Gawwada",
    "Gayil",
    "Gayo",
    "Gazi",
    "Gbagyi",
    "Gbanu",
    "Gbanziri",
    "Gbari",
    "Gbati-ri",
    "Gbaya (Central African Republic)",
    "Gbaya (Sudan)",
    "Gbaya-Bossangoa",
    "Gbaya-Bozoum",
    "Gbaya-Mbodomo",
    "Gbayi",
    "Gbesi Gbe",
    "Gbii",
    "Gbin",
    "Gbiri-Niragu",
    "Gboloo Grebo",
    "Ge",
    "Geba Karen",
    "Gebe",
    "Gedaged",
    "Gedeo",
    "Geez",
    "Geji",
    "Geko Karen",
    "Gela",
    "Geme",
    "Gen",
    "Gende",
    "Gengle",
    "Gepo",
    "Gera",
    "German Sign Language",
    "Geruma",
    "Geser-Gorom",
    "Gey",
    "Ghadam\u00e8s",
    "Ghanaian Pidgin English",
    "Ghanaian Sign Language",
    "Ghandruk Sign Language",
    "Ghanongga",
    "Ghari",
    "Ghayavi",
    "Gheg Albanian",
    "Ghera",
    "Ghodoberi",
    "Ghom\u00e1l\u00e1'",
    "Ghomara",
    "Ghotuo",
    "Ghulfan",
    "Giangan",
    "Gibanawa",
    "Gidar",
    "Giiwo",
    "Gikyode",
    "Gilaki",
    "Gilbertese",
    "Gilima",
    "Gilyak",
    "Gimi (Eastern Highlands)",
    "Gimi (West New Britain)",
    "Gimme",
    "Gimnime",
    "Ginuman",
    "Ginyanga",
    "Girawa",
    "Giryama",
    "Githabul",
    "Gitonga",
    "Gitua",
    "Gitxsan",
    "Giyug",
    "Gizrra",
    "Glaro-Twabo",
    "Glavda",
    "Glio-Oubi",
    "Gnau",
    "Goan Konkani",
    "Goaria",
    "Gobasi",
    "Gobu",
    "Godi\u00e9",
    "Godwari",
    "Goemai",
    "Gofa",
    "Gogo",
    "Gogodala",
    "Gokana",
    "Gola",
    "Golin",
    "Golpa",
    "Gondi",
    "Gone Dau",
    "Gongduk",
    "Gonja",
    "Gooniyandi",
    "Gor",
    "Gorakor",
    "Gorap",
    "Goreng",
    "Gorontalo",
    "Gorovu",
    "Gorowa",
    "Gothic",
    "Goundo",
    "Gourmanch\u00e9ma",
    "Gowlan",
    "Gowli",
    "Gowro",
    "Gozarkhani",
    "Grangali",
    "Grass Koiari",
    "Grebo",
    "Greek Sign Language",
    "Green Gelao",
    "Grenadian Creole English",
    "Gresi",
    "Groma",
    "Gronings",
    "Gros Ventre",
    "Gua",
    "Guadeloupean Creole French",
    "Guahibo",
    "Guaj\u00e1",
    "Guajaj\u00e1ra",
    "Guambiano",
    "Guana (Brazil)",
    "Guana (Paraguay)",
    "Guanano",
    "Guanche",
    "Guanyinqiao",
    "Guarani",
    "Guarayu",
    "Guarequena",
    "Guat\u00f3",
    "Guatemalan Sign Language",
    "Guayabero",
    "Gudang",
    "Gudanji",
    "Gude",
    "Gudu",
    "Guduf-Gava",
    "Guerrero Amuzgo",
    "Guerrero Nahuatl",
    "Guevea De Humboldt Zapotec",
    "Gugadj",
    "Gugu Badhun",
    "Gugu Warra",
    "Gugubera",
    "Guguyimidjir",
    "Guhu-Samane",
    "Guianese Creole French",
    "Guibei Zhuang",
    "Guiberoua B\u00e9te",
    "Guibian Zhuang",
    "Guinea Kpelle",
    "Guinean Sign Language",
    "Guiqiong",
    "Gujari",
    "Gula (Central African Republic)",
    "Gula (Chad)",
    "Gula Iro",
    "Gula'alaa",
    "Gulay",
    "Gule",
    "Gulf Arabic",
    "Guliguli",
    "Gumalu",
    "Gumatj",
    "Gumawana",
    "Gumuz",
    "Gun",
    "Gundi",
    "Gunditjmara",
    "Gundungurra",
    "Gungabula",
    "Gungu",
    "Guntai",
    "Gunwinggu",
    "Gunya",
    "Gupa-Abawa",
    "Gupapuyngu",
    "Guragone",
    "Guramalum",
    "Gurani",
    "Gurdjar",
    "Gureng Gureng",
    "Gurgula",
    "Guriaso",
    "Gurinji",
    "Gurmana",
    "Guro",
    "Guruntum-Mbaaru",
    "Gusan",
    "Gusii",
    "Gusilay",
    "Guwa",
    "Guwamu",
    "Guya",
    "Guyanese Creole English",
    "Guyani",
    "Gvoko",
    "Gwa",
    "Gwahatike",
    "Gwak",
    "Gwamhi-Wuri",
    "Gwandara",
    "Gweda",
    "Gweno",
    "Gwere",
    "Gwich\u02bcin",
    "Gyele",
    "Gyem",
    "H\u00e9rtevin",
    "H\u00f5ne",
    "Ha",
    "Habu",
    "Hadiyya",
    "Hadothi",
    "Hadrami",
    "Hadrami Arabic",
    "Hadza",
    "Haeke",
    "Hahon",
    "Hai//om",
    "Haida",
    "Haigwai",
    "Haiphong Sign Language",
    "Haisla",
    "Haitian",
    "Haitian Vodoun Culture Language",
    "Haji",
    "Hajong",
    "Hak\u00f6",
    "Haka Chin",
    "Hakka Chinese",
    "Halang",
    "Halang Doan",
    "Halbi",
    "Halh Mongolian",
    "Halia",
    "Halkomelem",
    "Hamap",
    "Hamba",
    "Hamer-Banna",
    "Hamtai",
    "Han",
    "Hanga",
    "Hanga Hundi",
    "Hangaza",
    "Hani",
    "Hano",
    "Hanoi Sign Language",
    "Hanunoo",
    "Harami",
    "Harari",
    "Harijan Kinnauri",
    "Haroi",
    "Harsusi",
    "Haruai",
    "Haruku",
    "Haryanvi",
    "Harzani",
    "Hasha",
    "Hassaniyya",
    "Hatam",
    "Hattic",
    "Hausa",
    "Hausa Sign Language",
    "Havasupai-Walapai-Yavapai",
    "Haveke",
    "Havu",
    "Hawai'i Creole English",
    "Hawai'i Sign Language (HSL)",
    "Hawaiian",
    "Haya",
    "Hazaragi",
    "Hdi",
    "Hehe",
    "Heiban",
    "Heiltsuk",
    "Helambu Sherpa",
    "Helong",
    "Hema",
    "Hemba",
    "Herd\u00e9",
    "Herero",
    "Hermit",
    "Hernican",
    "Hewa",
    "Heyo",
    "Hiberno-Scottish Gaelic",
    "Hibito",
    "Hidatsa",
    "Hieroglyphic Luwian",
    "Higaonon",
    "Highland Konjo",
    "Highland Oaxaca Chontal",
    "Highland Popoluca",
    "Highland Puebla Nahuatl",
    "Highland Totonac",
    "Hijazi Arabic",
    "Hijuk",
    "Hiligaynon",
    "Himarim\u00e3",
    "Hinduri",
    "Hinukh",
    "Hiri Motu",
    "Hittite",
    "Hitu",
    "Hiw",
    "Hixkary\u00e1na",
    "Hlai",
    "Hlepho Phowa",
    "Hlersu",
    "Hmar",
    "Hmong",
    "Hmong D\u00f4",
    "Hmong Daw",
    "Hmong Don",
    "Hmong Njua",
    "Hmong Shua",
    "Hmwaveke",
    "Ho",
    "Ho Chi Minh City Sign Language",
    "Ho-Chunk",
    "Hoava",
    "Hoby\u00f3t",
    "Hoia Hoia",
    "Holikachuk",
    "Holiya",
    "Holma",
    "Holoholo",
    "Holu",
    "Homa",
    "Honduras Sign Language",
    "Hong Kong Sign Language",
    "Honi",
    "Hopi",
    "Horned Miao",
    "Horo",
    "Horom",
    "Horpa",
    "Hote",
    "Hoti",
    "Hovongan",
    "Hoyahoya",
    "Hozo",
    "Hpon",
    "Hrangkhol",
    "Hre",
    "Hruso",
    "Huachipaeri",
    "Huallaga Hu\u00e1nuco Quechua",
    "Huamal\u00edes-Dos de Mayo Hu\u00e1nuco Quechua",
    "Huambisa",
    "Huarijio",
    "Huastec",
    "Huaulu",
    "Huautla Mazatec",
    "Huaxcaleca Nahuatl",
    "Huaylas Ancash Quechua",
    "Huaylla Wanca Quechua",
    "Huba",
    "Huehuetla Tepehua",
    "Huichol",
    "Huilliche",
    "Huitepec Mixtec",
    "Huizhou Chinese",
    "Hukumina",
    "Hula",
    "Hulaul\u00e1",
    "Huli",
    "Hulung",
    "Humburi Senni Songhay",
    "Humene",
    "Humla",
    "Hun-Saare",
    "Hunde",
    "Hung",
    "Hungana",
    "Hungarian",
    "Hungarian Sign Language",
    "Hungworo",
    "Hunjara-Kaina Ke",
    "Hunnic",
    "Hunsrik",
    "Hunzib",
    "Hupa",
    "Hupd\u00eb",
    "Hupla",
    "Hurrian",
    "Hutterite German",
    "Hwana",
    "Hya",
    "Hyam",
    "I-Wak",
    "I\u00f1apari",
    "Iaai",
    "Iamalele",
    "Iapama",
    "Iatmul",
    "Iau",
    "Ibali Teke",
    "Ibaloi",
    "Iban",
    "Ibanag",
    "Ibani",
    "Ibatan",
    "Iberian",
    "Ibibio",
    "Ibino",
    "Ibu",
    "Ibuoro",
    "Icelandic",
    "Icelandic Sign Language",
    "Iceve-Maci",
    "Ida'an",
    "Idakho-Isukha-Tiriki",
    "Idat\u00e9",
    "Idere",
    "Idesa",
    "Idi",
    "Ido",
    "Idoma",
    "Idon",
    "Idu-Mishmi",
    "Iduna",
    "If\u00e8",
    "Ifo",
    "Igala",
    "Igana",
    "Igbo",
    "Igede",
    "Ignaciano",
    "Igo",
    "Iguta",
    "Igwe",
    "Iha",
    "Iha Based Pidgin",
    "Ihievbe",
    "Ija-Zuba",
    "Ik",
    "Ika",
    "Ikaranggal",
    "Ikizu",
    "Iko",
    "Ikobi",
    "Ikoma-Nata-Isenye",
    "Ikpeng",
    "Ikpeshi",
    "Ikposo",
    "Iku-Gora-Ankwa",
    "Ikulu",
    "Ikwere",
    "Ikwo",
    "Ila",
    "Ile Ape",
    "Ili Turki",
    "Ili'uun",
    "Ilianen Manobo",
    "Illyrian",
    "Iloko",
    "Ilongot",
    "Ilue",
    "Ilwana",
    "Imbabura Highland Quichua",
    "Imbongu",
    "Imeraguen",
    "Imonda",
    "Imroing",
    "Inabaknon",
    "Inapang",
    "Inari Sami",
    "Indian Sign Language",
    "Indo-Portuguese",
    "Indonesian Bajau",
    "Indonesian Sign Language",
    "Indri",
    "Indus Kohistani",
    "Indus Valley Language",
    "Inebu One",
    "Inese\u00f1o",
    "Inga",
    "Ingrian",
    "Ingush",
    "Inlaod Itneg",
    "Inoke-Yate",
    "Inonhan",
    "Inor",
    "Inpui Naga",
    "Interglossa",
    "Interlingua (International Auxiliary Language Association)",
    "Interlingue",
    "International Sign",
    "Intha",
    "Inuinnaqtun",
    "Inuktitut",
    "Inupiaq",
    "Iowa-Oto",
    "Ipalapa Amuzgo",
    "Ipiko",
    "Ipili",
    "Ipulo",
    "Iquito",
    "Ir",
    "Ir\u00e1ntxe",
    "Iranian Persian",
    "Iranun",
    "Iraqw",
    "Irarutu",
    "Iraya",
    "Iresim",
    "Irigwe",
    "Irish",
    "Irish Sign Language",
    "Irula",
    "Isabi",
    "Isanzu",
    "Isarog Agta",
    "Isconahua",
    "Isebe",
    "Isekiri",
    "Ishkashimi",
    "Isinai",
    "Isirawa",
    "Island Carib",
    "Islander Creole English",
    "Isnag",
    "Isoko",
    "Israeli Sign Language",
    "Isthmus Mixe",
    "Isthmus Zapotec",
    "Isthmus-Cosoleacaque Nahuatl",
    "Isthmus-Mecayapan Nahuatl",
    "Isthmus-Pajapan Nahuatl",
    "Istriot",
    "Istro Romanian",
    "Isu (Fako Division)",
    "Isu (Menchum Division)",
    "Italian Sign Language",
    "Itawit",
    "Itelmen",
    "Itene",
    "Iteri",
    "Itik",
    "Ito",
    "Itonama",
    "Itu Mbon Uzo",
    "Itundujia Mixtec",
    "Itz\u00e1",
    "Iu Mien",
    "Ivatan",
    "Ivbie North-Okpela-Arhe",
    "Iwaidja",
    "Iwal",
    "Iwam",
    "Iwur",
    "Ixcatec",
    "Ixcatl\u00e1n Mazatec",
    "Ixil",
    "Ixtayutla Mixtec",
    "Ixtenco Otomi",
    "Iyayu",
    "Iyive",
    "Iyo",
    "Iyo'wujwa Chorote",
    "Iyojwa'ja Chorote",
    "Izere",
    "Izii",
    "Izon",
    "Izora",
    "J\u00fama",
    "Jabut\u00ed",
    "Jad",
    "Jadgali",
    "Jah Hut",
    "Jahanka",
    "Jair Awyu",
    "Jaitmatang",
    "Jakati",
    "Jakun",
    "Jalapa De D\u00edaz Mazatec",
    "Jalkunan",
    "Jamaican Country Sign Language",
    "Jamaican Creole English",
    "Jamaican Sign Language",
    "Jamamad\u00ed",
    "Jambi Malay",
    "Jamiltepec Mixtec",
    "Jamsay Dogon",
    "Jandai",
    "Jandavra",
    "Jangkang",
    "Jangshung",
    "Janji",
    "Japanese Sign Language",
    "Japrer\u00eda",
    "Jaqaru",
    "Jara",
    "Jarai",
    "Jarawa (India)",
    "Jaru",
    "Jauja Wanca Quechua",
    "Jaunsari",
    "Javanese",
    "Javindo",
    "Jawe",
    "Jaya",
    "Jebero",
    "Jeh",
    "Jehai",
    "Jemez",
    "Jenaama Bozo",
    "Jeng",
    "Jennu Kurumba",
    "Jere",
    "Jeri Kuo",
    "Jerung",
    "Jewish Babylonian Aramaic (ca. 200-1200 CE)",
    "Jewish Palestinian Aramaic",
    "Jhankot Sign Language",
    "Jiamao",
    "Jiarong",
    "Jiba",
    "Jibu",
    "Jicarilla Apache",
    "Jiiddu",
    "Jilbe",
    "Jilim",
    "Jimi (Cameroon)",
    "Jimi (Nigeria)",
    "Jina",
    "Jinyu Chinese",
    "Jiongnai Bunu",
    "Jirel",
    "Jiru",
    "Jita",
    "Jju",
    "Joba",
    "Jofotek-Bromnya",
    "Jola-Fonyi",
    "Jola-Kasa",
    "Jonkor Bourmataguil",
    "Jor\u00e1",
    "Jordanian Sign Language",
    "Jorto",
    "Jowulu",
    "Ju",
    "Ju/'hoan",
    "Juang",
    "Judeo-Arabic",
    "Judeo-Berber",
    "Judeo-Georgian",
    "Judeo-Iraqi Arabic",
    "Judeo-Italian",
    "Judeo-Moroccan Arabic",
    "Judeo-Persian",
    "Judeo-Tat",
    "Judeo-Tripolitanian Arabic",
    "Judeo-Tunisian Arabic",
    "Judeo-Yemeni Arabic",
    "Jukun Takum",
    "Jumjum",
    "Jumla Sign Language",
    "Jumli",
    "Jungle Inga",
    "Juquila Mixe",
    "Jur Modo",
    "Jur\u00fana",
    "Juray",
    "Jurchen",
    "Jutish",
    "Juwal",
    "Juxtlahuaca Mixtec",
    "Jwira-Pepesa",
    "K'iche'",
    "K\u00e2te",
    "K\u00e9l\u00e9",
    "K\u00f6lsch",
    "K\u025bl\u025bngaxo Bozo",
    "Kaamba",
    "Kaan",
    "Kaang Chin",
    "Kaansa",
    "Kaba",
    "Kabalai",
    "Kabardian",
    "Kabatei",
    "Kabix\u00ed",
    "Kabiy\u00e8",
    "Kabola",
    "Kabore One",
    "Kabras",
    "Kaburi",
    "Kabutra",
    "Kabuverdianu",
    "Kabwa",
    "Kabwari",
    "Kabyle",
    "Kachama-Ganjule",
    "Kachari",
    "Kachchi",
    "Kachi Koli",
    "Kachin",
    "Kacipo-Balesi",
    "Kaco'",
    "Kadai",
    "Kadar",
    "Kadaru",
    "Kadiw\u00e9u",
    "Kadu",
    "Kaduo",
    "Kafa",
    "Kafoa",
    "Kagan Kalagan",
    "Kagate",
    "Kagayanen",
    "Kagoma",
    "Kagoro",
    "Kagulu",
    "Kahe",
    "Kahua",
    "Kaian",
    "Kaibobo",
    "Kaidipang",
    "Kaiep",
    "Kaikadi",
    "Kaike",
    "Kaiku",
    "Kaimb\u00e9",
    "Kaimbulawa",
    "Kaingang",
    "Kairak",
    "Kairiru",
    "Kairui-Midiki",
    "Kais",
    "Kaivi",
    "Kaiw\u00e1",
    "Kaiy",
    "Kajakse",
    "Kajali",
    "Kajaman",
    "Kakabai",
    "Kakabe",
    "Kakanda",
    "Kakauhua",
    "Kaki Ae",
    "Kakihum",
    "Kako",
    "Kakwa",
    "Kala Lagaw Ya",
    "Kalaallisut",
    "Kalaamaya",
    "Kalabakan",
    "Kalabari",
    "Kalabra",
    "Kalagan",
    "Kalaktang Monpa",
    "Kalam",
    "Kalami",
    "Kalams\u00e9",
    "Kalanadi",
    "Kalanga",
    "Kalao",
    "Kalapuya",
    "Kalarko",
    "Kalasha",
    "Kalenjin",
    "Kalispel-Pend d'Oreille",
    "Kalkoti",
    "Kalkutung",
    "Kalmyk",
    "Kalo Finnish Romani",
    "Kalou",
    "Kaluli",
    "Kalumpang",
    "Kam",
    "Kamakan",
    "Kamang",
    "Kamano",
    "Kamantan",
    "Kamar",
    "Kamara",
    "Kamarian",
    "Kamaru",
    "Kamas",
    "Kamasa",
    "Kamasau",
    "Kamayo",
    "Kamayur\u00e1",
    "Kamba (Brazil)",
    "Kamba (Kenya)",
    "Kambaata",
    "Kambaira",
    "Kambera",
    "Kamberau",
    "Kambiw\u00e1",
    "Kami (Nigeria)",
    "Kami (Tanzania)",
    "Kamo",
    "Kamoro",
    "Kamu",
    "Kamula",
    "Kamviri",
    "Kamwe",
    "Kanakanabu",
    "Kanamar\u00ed",
    "Kanan",
    "Kanashi",
    "Kanasi",
    "Kanauji",
    "Kandas",
    "Kandawo",
    "Kande",
    "Kanembu",
    "Kang",
    "Kanga",
    "Kangean",
    "Kanggape",
    "Kangjia",
    "Kango (Bas-U\u00e9l\u00e9 District)",
    "Kango (Tshopo District)",
    "Kangri",
    "Kaniet",
    "Kanikkaran",
    "Kaningdon-Nindem",
    "Kaningi",
    "Kaningra",
    "Kaninuwa",
    "Kanite",
    "Kanjari",
    "Kanju",
    "Kankanaey",
    "Kannada",
    "Kannada Kurumba",
    "Kano\u00e9",
    "Kanowit-Tanjong Melanau",
    "Kansa",
    "Kantosi",
    "Kanu",
    "Kanufi",
    "Kanuri",
    "Kanyok",
    "Kao",
    "Kaonde",
    "Kap",
    "Kapin",
    "Kapinaw\u00e1",
    "Kapingamarangi",
    "Kapori",
    "Kapriman",
    "Kaptiau",
    "Kapya",
    "Kaqchikel",
    "Kara (Central African Republic)",
    "Kara (Korea)",
    "Kara (Papua New Guinea)",
    "Kara (Tanzania)",
    "Kara-Kalpak",
    "Karachay-Balkar",
    "Karadjeri",
    "Karagas",
    "Karahawyana",
    "Karaim",
    "Karaj\u00e1",
    "Karakhanid",
    "Karami",
    "Karamojong",
    "Karang",
    "Karanga",
    "Karankawa",
    "Karao",
    "Karas",
    "Karata",
    "Karawa",
    "Karbi",
    "Kare (Central African Republic)",
    "Kare (Papua New Guinea)",
    "Karekare",
    "Karelian",
    "Karenggapa",
    "Karey",
    "Kari",
    "Karingani",
    "Karip\u00fana",
    "Karip\u00fana Creole French",
    "Karipuna",
    "Karir\u00ed-Xoc\u00f3",
    "Kariti\u00e2na",
    "Kariya",
    "Kariyarra",
    "Karkar-Yuri",
    "Karkin",
    "Karko",
    "Karnai",
    "Karo (Brazil)",
    "Karo (Ethiopia)",
    "Karok",
    "Karon",
    "Karon Dori",
    "Karore",
    "Karranga",
    "Karuwali",
    "Kasanga",
    "Kasem",
    "Kashaya",
    "Kashmiri",
    "Kashubian",
    "Kasiguranin",
    "Kaska",
    "Kaskean",
    "Kasseng",
    "Kasua",
    "Kataang",
    "Katabaga",
    "Katawixi",
    "Katbol",
    "Katcha-Kadugli-Miri",
    "Kathoriya Tharu",
    "Kathu",
    "Kati",
    "Katkari",
    "Katla",
    "Kato",
    "Katso",
    "Katua",
    "Katuk\u00edna",
    "Kaulong",
    "Kaur",
    "Kaure",
    "Kaurna",
    "Kauwera",
    "Kavalan",
    "Kavet",
    "Kawacha",
    "Kawaiisu",
    "Kawe",
    "Kawi",
    "Kaxarar\u00ed",
    "Kaxui\u00e2na",
    "Kayab\u00ed",
    "Kayagar",
    "Kayan",
    "Kayan Mahakam",
    "Kayan River Kayan",
    "Kayap\u00f3",
    "Kayapa Kallahan",
    "Kayardild",
    "Kayaw",
    "Kayeli",
    "Kayong",
    "Kayort",
    "Kaytetye",
    "Kayupulau",
    "Kazakh",
    "Kazukuru",
    "Ke'o",
    "Keak",
    "Keapara",
    "Kedah Malay",
    "Kedang",
    "Keder",
    "Kehu",
    "Kei",
    "Keiga",
    "Kein",
    "Keiyo",
    "Kekch\u00ed",
    "Kela (Democratic Republic of Congo)",
    "Kela (Papua New Guinea)",
    "Kelabit",
    "Kele (Democratic Republic of Congo)",
    "Kele (Papua New Guinea)",
    "Keley-I Kallahan",
    "Keliko",
    "Kelo",
    "Kelon",
    "Kemak",
    "Kembayan",
    "Kemberano",
    "Kembra",
    "Kemedzung",
    "Kemi Sami",
    "Kemiehua",
    "Kemtuik",
    "Kenaboi",
    "Kenati",
    "Kendayan",
    "Kendeje",
    "Kendem",
    "Kenga",
    "Keningau Murut",
    "Keninjal",
    "Kensiu",
    "Kenswei Nsei",
    "Kenyan Sign Language",
    "Kenyang",
    "Kenyi",
    "Kenzi",
    "Keoru-Ahia",
    "Kepkiriw\u00e1t",
    "Kepo'",
    "Kera",
    "Kerak",
    "Kereho",
    "Kerek",
    "Kerewe",
    "Kerewo",
    "Kerinci",
    "Kesawai",
    "Ket",
    "Ketangalan",
    "Kete",
    "Ketengban",
    "Ketum",
    "Keyagana",
    "Kgalagadi",
    "Kh\u00e1ng",
    "Kh\u00fcn",
    "Khakas",
    "Khalaj",
    "Khaling",
    "Khamba",
    "Khams Tibetan",
    "Khamti",
    "Khamyang",
    "Khana",
    "Khandesi",
    "Khanty",
    "Khao",
    "Kharam Naga",
    "Kharia",
    "Kharia Thar",
    "Khasi",
    "Khayo",
    "Khazar",
    "Khe",
    "Khehek",
    "Khengkha",
    "Khetrani",
    "Khezha Naga",
    "Khiamniungan Naga",
    "Khinalugh",
    "Khirwar",
    "Khisa",
    "Khlor",
    "Khlula",
    "Khmu",
    "Kho'ini",
    "Khoibu Naga",
    "Kholok",
    "Khorasani Turkish",
    "Khorezmian",
    "Khotanese",
    "Khowar",
    "Khua",
    "Khuen",
    "Khumi Chin",
    "Khunsari",
    "Khvarshi",
    "Kibet",
    "Kibiri",
    "Kickapoo",
    "Kikai",
    "Kikuyu",
    "Kildin Sami",
    "Kilivila",
    "Kiliwa",
    "Kilmeri",
    "Kim",
    "Kim Mun",
    "Kimaama",
    "Kimaragang",
    "Kimbu",
    "Kimbundu",
    "Kimki",
    "Kimr\u00e9",
    "Kinabalian",
    "Kinalakna",
    "Kinamiging Manobo",
    "Kinaray-A",
    "Kinga",
    "Kinnauri",
    "Kintaq",
    "Kinuku",
    "Kinyarwanda",
    "Kioko",
    "Kiong",
    "Kiorr",
    "Kiowa",
    "Kiowa Apache",
    "Kipsigis",
    "Kiput",
    "Kir-Balar",
    "Kire",
    "Kirghiz",
    "Kirike",
    "Kirikiri",
    "Kirmanjki (individual language)",
    "Kirya-Konz\u0259l",
    "Kis",
    "Kisa",
    "Kisan",
    "Kisankasa",
    "Kisar",
    "Kisi",
    "Kistane",
    "Kita Maninkakan",
    "Kitan",
    "Kitja",
    "Kitsai",
    "Kituba (Congo)",
    "Kituba (Democratic Republic of Congo)",
    "Kiunum",
    "Kla-Dan",
    "Klamath-Modoc",
    "Klao",
    "Klias River Kadazan",
    "Klingon",
    "Knaanic",
    "Ko",
    "Koalib",
    "Koasati",
    "Koba",
    "Kobiana",
    "Kobol",
    "Kobon",
    "Koch",
    "Kochila Tharu",
    "Koda",
    "Kodaku",
    "Kodava",
    "Kodeoha",
    "Kodi",
    "Kodia",
    "Koenoem",
    "Kofa",
    "Kofei",
    "Kofyar",
    "Koguryo",
    "Kohin",
    "Kohistani Shina",
    "Koho",
    "Kohumono",
    "Koi",
    "Koibal",
    "Koireng",
    "Koitabu",
    "Koiwat",
    "Kok Borok",
    "Kok-Nar",
    "Kokata",
    "Koke",
    "Koki Naga",
    "Koko Babangk",
    "Kokoda",
    "Kokola",
    "Kokota",
    "Kol (Bangladesh)",
    "Kol (Cameroon)",
    "Kol (Papua New Guinea)",
    "Kola",
    "Kolbila",
    "Kolibugan Subanon",
    "Koluwawa",
    "Kom (Cameroon)",
    "Kom (India)",
    "Koma",
    "Komba",
    "Kombai",
    "Kombio",
    "Komering",
    "Komi",
    "Komi-Permyak",
    "Komi-Zyrian",
    "Kominimung",
    "Komo (Democratic Republic of Congo)",
    "Komo (Sudan)",
    "Komodo",
    "Kompane",
    "Komyandaret",
    "Kon Keu",
    "Konai",
    "Konda",
    "Konda-Dora",
    "Koneraw",
    "Kongo",
    "Konkani (individual language)",
    "Konkani (macrolanguage)",
    "Konkomba",
    "Konni",
    "Kono (Guinea)",
    "Kono (Nigeria)",
    "Kono (Sierra Leone)",
    "Konomala",
    "Konongo",
    "Konso",
    "Konyak Naga",
    "Konyanka Maninka",
    "Konzo",
    "Koongo",
    "Koonzime",
    "Koorete",
    "Kopar",
    "Kopkaka",
    "Korafe-Yegha",
    "Korak",
    "Korana",
    "Korandje",
    "Korean Sign Language",
    "Koreguaje",
    "Koresh-e Rostam",
    "Korku",
    "Korlai Creole Portuguese",
    "Koro (C\u00f4te d'Ivoire)",
    "Koro (India)",
    "Koro (Papua New Guinea)",
    "Koro (Vanuatu)",
    "Koro Wachi",
    "Koromf\u00e9",
    "Koromira",
    "Koronadal Blaan",
    "Koroni",
    "Korop",
    "Korop\u00f3",
    "Koroshi",
    "Korowai",
    "Korra Koraga",
    "Korubo",
    "Korupun-Sela",
    "Korwa",
    "Koryak",
    "Kosadle",
    "Kosarek Yale",
    "Kosena",
    "Koshin",
    "Kosraean",
    "Kota (Gabon)",
    "Kota (India)",
    "Kota Bangun Kutai Malay",
    "Kota Marudu Talantang",
    "Kota Marudu Tinagas",
    "Kotafon Gbe",
    "Kotava",
    "Koti",
    "Kott",
    "Kouya",
    "Kovai",
    "Kove",
    "Kowaki",
    "Kowiai",
    "Koy Sanjaq Surat",
    "Koya",
    "Koyaga",
    "Koyo",
    "Koyra Chiini Songhay",
    "Koyraboro Senni Songhai",
    "Koyukon",
    "Kpagua",
    "Kpala",
    "Kpan",
    "Kpasam",
    "Kpati",
    "Kpatili",
    "Kpeego",
    "Kpelle",
    "Kpessi",
    "Kplang",
    "Krache",
    "Krah\u00f4",
    "Kraol",
    "Krenak",
    "Krevinian",
    "Kreye",
    "Krikati-Timbira",
    "Krim",
    "Krio",
    "Kriol",
    "Krisa",
    "Krobu",
    "Krongo",
    "Kru'ng 2",
    "Krymchak",
    "Kryts",
    "Kua",
    "Kua-nsi",
    "Kuamasi",
    "Kuan",
    "Kuanhua",
    "Kuanua",
    "Kuanyama",
    "Kube",
    "Kubi",
    "Kubo",
    "Kubu",
    "Kucong",
    "Kudiya",
    "Kudmali",
    "Kudu-Camo",
    "Kugama",
    "Kugbo",
    "Kui (India)",
    "Kui (Indonesia)",
    "Kuijau",
    "Kuik\u00faro-Kalap\u00e1lo",
    "Kujarge",
    "Kuk",
    "Kukatja",
    "Kuke",
    "Kukele",
    "Kukna",
    "Kuku-Mangk",
    "Kuku-Mu'inh",
    "Kuku-Muminh",
    "Kuku-Ugbanh",
    "Kuku-Uwanh",
    "Kuku-Yalanji",
    "Kula",
    "Kulere",
    "Kulfa",
    "Kulina Pano",
    "Kulisusu",
    "Kullu Pahari",
    "Kulon-Pazeh",
    "Kulung (Nepal)",
    "Kulung (Nigeria)",
    "Kumalu",
    "Kumam",
    "Kuman",
    "Kuman (Russia)",
    "Kumaoni",
    "Kumarbhag Paharia",
    "Kumba",
    "Kumbainggar",
    "Kumbaran",
    "Kumbewaha",
    "Kumhali",
    "Kumiai",
    "Kumukio",
    "Kumyk",
    "Kumzari",
    "Kunama",
    "Kunbarlang",
    "Kunda",
    "Kundal Shahi",
    "Kunduvadi",
    "Kung",
    "Kung-Ekoka",
    "Kungarakany",
    "Kungardutyi",
    "Kunggara",
    "Kunggari",
    "Kungkari",
    "Kuni",
    "Kuni-Boazi",
    "Kunigami",
    "Kunimaipa",
    "Kunja",
    "Kunjen",
    "Kunyi",
    "Kunza",
    "Kuo",
    "Kuot",
    "Kupa",
    "Kupang Malay",
    "Kupia",
    "Kupsabiny",
    "Kur",
    "Kura Ede Nago",
    "Kurama",
    "Kuranko",
    "Kurdish",
    "Kuri",
    "Kuria",
    "Kurichiya",
    "Kurmukar",
    "Kurnai",
    "Kurrama",
    "Kurti",
    "Kurtokha",
    "Kuru\u00e1ya",
    "Kurudu",
    "Kurukh",
    "Kusaal",
    "Kusaghe",
    "Kushi",
    "Kusu",
    "Kusunda",
    "Kutenai",
    "Kutep",
    "Kuthant",
    "Kutong",
    "Kutto",
    "Kutu",
    "Kuturmi",
    "Kuuk-Yak",
    "Kuuku-Ya'u",
    "Kuvi",
    "Kuwaa",
    "Kuwaataay",
    "Kuy",
    "Kven Finnish",
    "Kw'adza",
    "Kwa",
    "Kwa'",
    "Kwaami",
    "Kwadi",
    "Kwaio",
    "Kwaja",
    "Kwak",
    "Kwakiutl",
    "Kwakum",
    "Kwalhioqua-Tlatskanai",
    "Kwama",
    "Kwambi",
    "Kwamera",
    "Kwami",
    "Kwamtim One",
    "Kwang",
    "Kwanga",
    "Kwangali",
    "Kwanja",
    "Kwara'ae",
    "Kwasio",
    "Kwaya",
    "Kwaza",
    "Kwegu",
    "Kwer",
    "Kwerba",
    "Kwerba Mamberamo",
    "Kwere",
    "Kwerisa",
    "Kwese",
    "Kwesten",
    "Kwini",
    "Kwinsu",
    "Kwinti",
    "Kwoma",
    "Kwomtari",
    "Kxoe",
    "Kyak",
    "Kyaka",
    "Kyan-Karyaw Naga",
    "Kyanga",
    "Kyenele",
    "Kyerung",
    "L\u00e1\u00e1 L\u00e1\u00e1 Bwamu",
    "L\u00e1adan",
    "L\u00fc",
    "La'bi",
    "Laal",
    "Laari",
    "Laba",
    "Label",
    "Labir",
    "Labo",
    "Labo Phowa",
    "Labu",
    "Labuk-Kinabatangan Kadazan",
    "Lacandon",
    "Lachi",
    "Lachiguiri Zapotec",
    "Lachix\u00edo Zapotec",
    "Ladakhi",
    "Ladin",
    "Ladino",
    "Ladji Ladji",
    "Laeko-Libuat",
    "Lafofa",
    "Laghu",
    "Laghuu",
    "Lagwan",
    "Laha (Indonesia)",
    "Laha (Viet Nam)",
    "Lahanan",
    "Lahnda",
    "Lahta Karen",
    "Lahu",
    "Lahu Shi",
    "Lahul Lohar",
    "Laimbue",
    "Laitu Chin",
    "Laiyolo",
    "Lak",
    "Laka (Chad)",
    "Laka (Nigeria)",
    "Lakalei",
    "Lake Miwok",
    "Lakha",
    "Laki",
    "Lakkia",
    "Lakon",
    "Lakond\u00ea",
    "Lakota",
    "Lakota Dida",
    "Lala",
    "Lala-Bisa",
    "Lala-Roba",
    "Lalana Chinantec",
    "Lalia",
    "Lama (Togo)",
    "Lama Bai",
    "Lamaholot",
    "Lamalera",
    "Lamang",
    "Lamatuka",
    "Lamba",
    "Lambadi",
    "Lambayeque Quechua",
    "Lambichhong",
    "Lamboya",
    "Lambya",
    "Lame",
    "Lamenu",
    "Lamet",
    "Lamja-Dengsa-Tola",
    "Lamkang",
    "Lamma",
    "Lamnso'",
    "Lamogai",
    "Lampung Api",
    "Lampung Nyo",
    "Lamu",
    "Lamu-Lamu",
    "Lanas Lobu",
    "Landoma",
    "Lang'e",
    "Langam",
    "Langbashe",
    "Langi",
    "Langnian Buyang",
    "Lango (Sudan)",
    "Lango (Uganda)",
    "Langobardic",
    "Langue des signes de Belgique Francophone",
    "Lanima",
    "Lanoh",
    "Lao",
    "Lao Naga",
    "Laomian",
    "Laopang",
    "Laos Sign Language",
    "Lapagu\u00eda-Guivini Zapotec",
    "Laragia",
    "Larantuka Malay",
    "Lardil",
    "Larevat",
    "Large Flowery Miao",
    "Lari",
    "Larike-Wakasihu",
    "Laro",
    "Larteh",
    "Laru",
    "Lasalimu",
    "Lasgerdi",
    "Lashi",
    "Lasi",
    "Late Middle Chinese",
    "Latgalian",
    "Latin",
    "Latu",
    "Latund\u00ea",
    "Latvian",
    "Latvian Sign Language",
    "Lau",
    "Laua",
    "Lauan",
    "Lauje",
    "Laura",
    "Laurentian",
    "Lautu Chin",
    "Lavatbura-Lamusong",
    "Lave",
    "Laven",
    "Lavukaleve",
    "Lawangan",
    "Lawu",
    "Lawunuia",
    "Layakha",
    "Laz",
    "Lealao Chinantec",
    "Leco",
    "Ledo Kaili",
    "Leelau",
    "Lefa",
    "Lega-Mwenga",
    "Lega-Shabunda",
    "Legbo",
    "Legenyem",
    "Lehali",
    "Lehalurup",
    "Lehar",
    "Leinong Naga",
    "Leipon",
    "Lelak",
    "Lele (Chad)",
    "Lele (Democratic Republic of Congo)",
    "Lele (Guinea)",
    "Lele (Papua New Guinea)",
    "Lelemi",
    "Lelepa",
    "Lembena",
    "Lemerig",
    "Lemio",
    "Lemnian",
    "Lemolang",
    "Lemoro",
    "Lenakel",
    "Lenca",
    "Lendu",
    "Lengilu",
    "Lengo",
    "Lengola",
    "Leningitij",
    "Lenje",
    "Lenkau",
    "Lenyima",
    "Lepcha",
    "Lepki",
    "Lepontic",
    "Lere",
    "Lese",
    "Lesing-Gelimi",
    "Letemboi",
    "Leti (Cameroon)",
    "Leti (Indonesia)",
    "Levuka",
    "Lewo",
    "Lewo Eleng",
    "Lewotobi",
    "Leyigha",
    "Lezghian",
    "Lhokpu",
    "Lhomi",
    "Li'o",
    "Liabuku",
    "Liana-Seti",
    "Liangmai Naga",
    "Lianshan Zhuang",
    "Liberia Kpelle",
    "Liberian English",
    "Libido",
    "Libinza",
    "Libon Bikol",
    "Liburnian",
    "Libyan Arabic",
    "Libyan Sign Language",
    "Ligbi",
    "Ligenza",
    "Ligurian",
    "Ligurian (Ancient)",
    "Lihir",
    "Lijili",
    "Lika",
    "Liki",
    "Likila",
    "Likuba",
    "Likum",
    "Likwala",
    "Lilau",
    "Lillooet",
    "Limassa",
    "Limbu",
    "Limbum",
    "Limburgan",
    "Limi",
    "Limilngan",
    "Limos Kalinga",
    "Lindu",
    "Linear A",
    "Lingala",
    "Lingao",
    "Lingarak",
    "Lingkhim",
    "Lingua Franca",
    "Lingua Franca Nova",
    "Lipan Apache",
    "Lipo",
    "Lisabata-Nuniali",
    "Lisela",
    "Lish",
    "Lish\u00e1n Did\u00e1n",
    "Lishana Deni",
    "Lishanid Noshan",
    "Lisu",
    "Literary Chinese",
    "Lithuanian Sign Language",
    "Litzlitz",
    "Liujiang Zhuang",
    "Liuqian Zhuang",
    "Liv",
    "Livvi",
    "Lo-Toga",
    "Loarki",
    "Lobala",
    "Lobi",
    "Lodhi",
    "Logba",
    "Logo",
    "Logol",
    "Logooli",
    "Logorik",
    "Logudorese Sardinian",
    "Lohorung",
    "Loja Highland Quichua",
    "Lojban",
    "Lokaa",
    "Loke",
    "Loko",
    "Lokoya",
    "Lola",
    "Lolak",
    "Lole",
    "Lolo",
    "Loloda",
    "Lolopo",
    "Loma (C\u00f4te d'Ivoire)",
    "Loma (Liberia)",
    "Lomaiviti",
    "Lomavren",
    "Lombard",
    "Lombi",
    "Lombo",
    "Lomwe",
    "Loncong",
    "Long Phuri Naga",
    "Long Wat",
    "Longgu",
    "Longto",
    "Longuda",
    "Loniu",
    "Lonwolwol",
    "Lonzo",
    "Loo",
    "Lopa",
    "Lopi",
    "Lopit",
    "Lorang",
    "Lorediakarkar",
    "Loreto-Ucayali Spanish",
    "Lote",
    "Lotha Naga",
    "Lotud",
    "Lou",
    "Louisiana Creole French",
    "Loun",
    "Loup A",
    "Loup B",
    "Low German",
    "Lower Burdekin",
    "Lower Chehalis",
    "Lower Grand Valley Dani",
    "Lower Silesian",
    "Lower Sorbian",
    "Lower Southern Aranda",
    "Lower Ta'oih",
    "Lower Tanana",
    "Lowland Oaxaca Chontal",
    "Lowland Tarahumara",
    "Loxicha Zapotec",
    "Lozi",
    "Lua'",
    "Luang",
    "Luba-Katanga",
    "Luba-Lulua",
    "Lubila",
    "Lubu",
    "Lubuagan Kalinga",
    "Luchazi",
    "Lucumi",
    "Ludian",
    "Lufu",
    "Lugbara",
    "Luguru",
    "Luhu",
    "Lui",
    "Luimbi",
    "Luiseno",
    "Lukpa",
    "Lule",
    "Lule Sami",
    "Lumba-Yakkha",
    "Lumbee",
    "Lumbu",
    "Lumun",
    "Luna",
    "Lunanakha",
    "Lunda",
    "Lundayeh",
    "Lungalunga",
    "Lungga",
    "Luo (Cameroon)",
    "Luo (Kenya and Tanzania)",
    "Luopohe Hmong",
    "Luri",
    "Lusengo",
    "Lushai",
    "Lushootseed",
    "Lusi",
    "Lusitanian",
    "Lutos",
    "Luvale",
    "Luwati",
    "Luwo",
    "Luxembourgish",
    "Luyana",
    "Luyia",
    "Lwalu",
    "Ly\u00e9l\u00e9",
    "Lycian",
    "Lydian",
    "Lyngngam",
    "Lyons Sign Language",
    "M\u00e1ghd\u00ec",
    "M\u00e9nik",
    "M\u00edskito",
    "M\u00f3cheno",
    "M\u00fcn Chin",
    "M\u00fcnd\u00fc",
    "M\u0101h\u0101r\u0101\u1e63\u1e6dri Pr\u0101krit",
    "Ma (Democratic Republic of Congo)",
    "Ma (Papua New Guinea)",
    "Ma Manda",
    "Ma'anyan",
    "Ma'di",
    "Ma'ya",
    "Maa",
    "Maaka",
    "Maasina Fulfulde",
    "Maay",
    "Maba (Chad)",
    "Maba (Indonesia)",
    "Mabaale",
    "Mabaan",
    "Mabaka Valley Kalinga",
    "Mabire",
    "Maca",
    "Macagu\u00e1n",
    "Macaguaje",
    "Macanese",
    "Macedo-Romanian",
    "Macedonian",
    "Machame",
    "Machiguenga",
    "Machinere",
    "Machinga",
    "Maco",
    "Macuna",
    "Macushi",
    "Mada (Cameroon)",
    "Mada (Nigeria)",
    "Madagascar Sign Language",
    "Madak",
    "Maden",
    "Madhi Madhi",
    "Madi",
    "Madngele",
    "Madurese",
    "Mae",
    "Maek",
    "Maeng Itneg",
    "Mafa",
    "Mafea",
    "Mag-antsi Ayta",
    "Mag-Indi Ayta",
    "Mag\u0268yi",
    "Magahi",
    "Magbukun Ayta",
    "Magdalena Pe\u00f1asco Mixtec",
    "Magoma",
    "Magori",
    "Maguindanaon",
    "Mahali",
    "Mahasu Pahari",
    "Mahican",
    "Mahongwe",
    "Mahou",
    "Mai Brat",
    "Maia",
    "Maiadomu",
    "Maiani",
    "Maii",
    "Mailu",
    "Maindo",
    "Mainfr\u00e4nkisch",
    "Mainstream Kenyah",
    "Mairasi",
    "Maisin",
    "Maithili",
    "Maiwa (Indonesia)",
    "Maiwa (Papua New Guinea)",
    "Maiwala",
    "Majang",
    "Majera",
    "Majhi",
    "Majhwar",
    "Majukayang Kalinga",
    "Mak (China)",
    "Mak (Nigeria)",
    "Makaa",
    "Makah",
    "Makasae",
    "Makasar",
    "Makassar Malay",
    "Makayam",
    "Makhuwa",
    "Makhuwa-Marrevone",
    "Makhuwa-Meetto",
    "Makhuwa-Moniga",
    "Makhuwa-Saka",
    "Makhuwa-Shirima",
    "Maklew",
    "Makolkol",
    "Makonde",
    "Maku'a",
    "Makur\u00e1p",
    "Makuri Naga",
    "Makwe",
    "Makyan Naga",
    "Mal",
    "Mal Paharia",
    "Mal\u00e9ku Ja\u00edka",
    "Mala (Nigeria)",
    "Mala (Papua New Guinea)",
    "Mala Malasar",
    "Malaccan Creole Malay",
    "Malaccan Creole Portuguese",
    "Malagasy",
    "Malalamai",
    "Malango",
    "Malankuravan",
    "Malapandaram",
    "Malaryan",
    "Malas",
    "Malasar",
    "Malavedan",
    "Malawi Lomwe",
    "Malawi Sena",
    "Malay (individual language)",
    "Malay (macrolanguage)",
    "Malayic Dayak",
    "Malaynon",
    "Malayo",
    "Malaysian Sign Language",
    "Malba Birifor",
    "Male (Ethiopia)",
    "Male (Papua New Guinea)",
    "Malecite-Passamaquoddy",
    "Maleng",
    "Maleu-Kilenge",
    "Malfaxal",
    "Malgana",
    "Malgbe",
    "Mali",
    "Maligo",
    "Malila",
    "Malimba",
    "Malimpung",
    "Malinaltepec Me'phaa",
    "Malo",
    "Malol",
    "Maltese",
    "Maltese Sign Language",
    "Malua Bay",
    "Malvi",
    "Malyangapa",
    "Mam",
    "Mama",
    "Mamaa",
    "Mamaind\u00e9",
    "Mamanwa",
    "Mamara Senoufo",
    "Mamasa",
    "Mambae",
    "Mambai",
    "Mamboru",
    "Mambwe-Lungu",
    "Mampruli",
    "Mamuju",
    "Mamulique",
    "Mamusi",
    "Mamvu",
    "Man Met",
    "Manado Malay",
    "Manam",
    "Manambu",
    "Manangba",
    "Manangkari",
    "Manchu",
    "Manda (Australia)",
    "Manda (India)",
    "Manda (Tanzania)",
    "Mandahuaca",
    "Mandaic",
    "Mandan",
    "Mandandanyi",
    "Mandar",
    "Mandara",
    "Mandari",
    "Mandarin Chinese",
    "Mandaya",
    "Mandeali",
    "Mander",
    "Mandingo",
    "Mandinka",
    "Mandjak",
    "Mandobo Atas",
    "Mandobo Bawah",
    "Manem",
    "Mang",
    "Manga Kanuri",
    "Mangala",
    "Mangarayi",
    "Mangareva",
    "Mangas",
    "Mangayat",
    "Mangbetu",
    "Mangbutu",
    "Mangerr",
    "Mangetti Dune !Xung",
    "Mangga Buang",
    "Manggarai",
    "Mango",
    "Mangole",
    "Mangseng",
    "Mangue",
    "Manichaean Middle Persian",
    "Manide",
    "Manikion",
    "Manipa",
    "Manipuri",
    "Mankanya",
    "Manna-Dora",
    "Mannan",
    "Mano",
    "Manombai",
    "Mansaka",
    "Mansi",
    "Mansoanka",
    "Manta",
    "Mantsi",
    "Manumanaw Karen",
    "Manusela",
    "Manx",
    "Manya",
    "Manyawa",
    "Manyika",
    "Manza",
    "Mao Naga",
    "Maonan",
    "Maore Comorian",
    "Maori",
    "Mape",
    "Mapena",
    "Mapia",
    "Mapidian",
    "Mapos Buang",
    "Mapoyo",
    "Mapudungun",
    "Mapun",
    "Maquiritari",
    "Mar\u00fabo",
    "Mara",
    "Mara Chin",
    "Marachi",
    "Maraghei",
    "Maragus",
    "Maram Naga",
    "Marama",
    "Maramba",
    "Maranao",
    "Maranunggu",
    "Mararit",
    "Marau",
    "Marba",
    "Maremgi",
    "Marenje",
    "Marfa",
    "Margany",
    "Marghi Central",
    "Marghi South",
    "Margos-Yarowilca-Lauricocha Quechua",
    "Margu",
    "Mari (East Sepik Province)",
    "Mari (Madang Province)",
    "Mari (Russia)",
    "Maria (India)",
    "Maria (Papua New Guinea)",
    "Maricopa",
    "Maridan",
    "Maridjabin",
    "Marik",
    "Marimanindji",
    "Marind",
    "Maring",
    "Maring Naga",
    "Maringarr",
    "Marino",
    "Mariri",
    "Marithiel",
    "Maritime Sign Language",
    "Maritsau\u00e1",
    "Mariyedi",
    "Marka",
    "Markweeta",
    "Marma",
    "Marovo",
    "Marriammu",
    "Marrucinian",
    "Marshallese",
    "Marsian",
    "Martha's Vineyard Sign Language",
    "Marti Ke",
    "Martu Wangka",
    "Martuyhunira",
    "Maru",
    "Marwari",
    "Marwari (India)",
    "Marwari (Pakistan)",
    "Masaaba",
    "Masadiit Itneg",
    "Masai",
    "Masalit",
    "Masana",
    "Masbatenyo",
    "Mashco Piro",
    "Mashi (Nigeria)",
    "Mashi (Zambia)",
    "Masikoro Malagasy",
    "Masimasi",
    "Masiwang",
    "Maskelynes",
    "Maslam",
    "Masmaje",
    "Massalat",
    "Massep",
    "Mat\u00eds",
    "Matagalpa",
    "Matal",
    "Matbat",
    "Matengo",
    "Matepi",
    "Matigsalug Manobo",
    "Matipuhy",
    "Mato",
    "Mato Grosso Ar\u00e1ra",
    "Mator",
    "Mator-Taygi-Karagas",
    "Mats\u00e9s",
    "Mattole",
    "Matu Chin",
    "Matukar",
    "Matumbi",
    "Matya Samo",
    "Maung",
    "Mauritian Sign Language",
    "Mauwake",
    "Mawa (Chad)",
    "Mawa (Nigeria)",
    "Mawak",
    "Mawan",
    "Mawayana",
    "Mawchi",
    "Mawes",
    "Maxakal\u00ed",
    "Maxi Gbe",
    "Maya Samo",
    "Mayaguduna",
    "Mayangna",
    "Mayawali",
    "Mayeka",
    "Mayi-Kulan",
    "Mayi-Thakurti",
    "Mayi-Yapi",
    "Mayo",
    "Mayogo",
    "Mayoyao Ifugao",
    "Mazagway",
    "Mazaltepec Zapotec",
    "Mazanderani",
    "Mazatl\u00e1n Mazatec",
    "Mazatl\u00e1n Mixe",
    "Mba",
    "Mbala",
    "Mbalanhu",
    "Mbandja",
    "Mbangala",
    "Mbangi",
    "Mbangwe",
    "Mbara (Australia)",
    "Mbara (Chad)",
    "Mbariman-Gudhinma",
    "Mbati",
    "Mbato",
    "Mbay",
    "Mbe",
    "Mbe'",
    "Mbelime",
    "Mbere",
    "Mbesa",
    "Mbo (Cameroon)",
    "Mbo (Democratic Republic of Congo)",
    "Mboi",
    "Mboko",
    "Mbole",
    "Mbonga",
    "Mbongno",
    "Mbosi",
    "Mbowe",
    "Mbre",
    "Mbudum",
    "Mbugu",
    "Mbugwe",
    "Mbuko",
    "Mbukushu",
    "Mbula",
    "Mbula-Bwazza",
    "Mbule",
    "Mbulungish",
    "Mbum",
    "Mbunda",
    "Mbunga",
    "Mburku",
    "Mbwela",
    "Mby\u00e1 Guaran\u00ed",
    "Me'en",
    "Medebur",
    "Media Lengua",
    "Mediak",
    "Median",
    "Mednyj Aleut",
    "Medumba",
    "Mefele",
    "Megam",
    "Megleno Romanian",
    "Mehek",
    "Mehin\u00e1ku",
    "Mehri",
    "Mekeo",
    "Mekmek",
    "Mekwei",
    "Mele-Fila",
    "Melo",
    "Melpa",
    "Memoni",
    "Mendalam Kayan",
    "Mendankwe-Nkwen",
    "Mende (Papua New Guinea)",
    "Mende (Sierra Leone)",
    "Mengaka",
    "Mengen",
    "Mengisa",
    "Menka",
    "Menominee",
    "Mentawai",
    "Menya",
    "Meoswar",
    "Mer",
    "Meramera",
    "Merei",
    "Merey",
    "Meriam",
    "Merlav",
    "Meroitic",
    "Meru",
    "Merwari",
    "Mesaka",
    "Mescalero-Chiricahua Apache",
    "Mese",
    "Meskwaki",
    "Mesme",
    "Mesmes",
    "Mesopotamian Arabic",
    "Mesqan",
    "Messapic",
    "Meta'",
    "Metlat\u00f3noc Mixtec",
    "Mewari",
    "Mewati",
    "Mexican Sign Language",
    "Meyah",
    "Mezontla Popoloca",
    "Mezquital Otomi",
    "Mfinu",
    "Mfumte",
    "Mgbolizhia",
    "Mi'kmaq",
    "Miahuatl\u00e1n Zapotec",
    "Miami",
    "Mian",
    "Miani",
    "Michif",
    "Michigamea",
    "Michoac\u00e1n Mazahua",
    "Michoac\u00e1n Nahuatl",
    "Mid Grand Valley Dani",
    "Mid-Southern Banda",
    "Middle Armenian",
    "Middle Breton",
    "Middle Cornish",
    "Middle Dutch (ca. 1050-1350)",
    "Middle English (1100-1500)",
    "Middle French (ca. 1400-1600)",
    "Middle High German (ca. 1050-1500)",
    "Middle Hittite",
    "Middle Irish (900-1200)",
    "Middle Korean (10th-16th cent.)",
    "Middle Low German",
    "Middle Mongolian",
    "Middle Newar",
    "Middle Watut",
    "Middle Welsh",
    "Midob",
    "Migaama",
    "Migabac",
    "Migum",
    "Miju-Mishmi",
    "Mikasuki",
    "Mili",
    "Miltu",
    "Miluk",
    "Milyan",
    "Min Bei Chinese",
    "Min Dong Chinese",
    "Min Nan Chinese",
    "Min Zhong Chinese",
    "Mina (Cameroon)",
    "Mina (India)",
    "Minaean",
    "Minang",
    "Minangkabau",
    "Minanibai",
    "Minaveha",
    "Minderico",
    "Mindiri",
    "Mingang Doso",
    "Mingrelian",
    "Minica Huitoto",
    "Minidien",
    "Minjungbal",
    "Minkin",
    "Minoan",
    "Minokok",
    "Minriq",
    "Mintil",
    "Minz Zhuang",
    "Miqie",
    "Mirandese",
    "Miraya Bikol",
    "Mirgan",
    "Miriti",
    "Miriwung",
    "Mirpur Panjabi",
    "Miship",
    "Misima-Panaeati",
    "Mising",
    "Mitla Zapotec",
    "Mitlatongo Mixtec",
    "Mittu",
    "Mituku",
    "Miu",
    "Miwa",
    "Mixed Great Andamanese",
    "Mixtepec Mixtec",
    "Mixtepec Zapotec",
    "Miya",
    "Miyako",
    "Miyobe",
    "Mlabri",
    "Mlahs\u00f6",
    "Mlap",
    "Mlomp",
    "Mmaala",
    "Mmen",
    "Mo'da",
    "Moabite",
    "Moba",
    "Mobilian",
    "Mobumrin Aizi",
    "Mobwa Karen",
    "Mochi",
    "Mochica",
    "Mocho",
    "Mocov\u00ed",
    "Modang",
    "Modern Greek (1453-)",
    "Modole",
    "Moere",
    "Mofu-Gudur",
    "Mogholi",
    "Mogofin",
    "Mogum",
    "Mohave",
    "Mohawk",
    "Mohegan-Pequot",
    "Moi (Congo)",
    "Moi (Indonesia)",
    "Moikodi",
    "Moingi",
    "Moji",
    "Mok",
    "Moken",
    "Mokerang",
    "Mokilese",
    "Moklen",
    "Mokole",
    "Mokpwe",
    "Moksela",
    "Moksha",
    "Molale",
    "Molbog",
    "Moldova Sign Language",
    "Molengue",
    "Molima",
    "Molmo One",
    "Molo",
    "Molof",
    "Moloko",
    "Mom Jango",
    "Moma",
    "Momare",
    "Mombo Dogon",
    "Mombum",
    "Momina",
    "Momuna",
    "Mon",
    "Monastic Sign Language",
    "Mond\u00e9",
    "Mondropolon",
    "Mongo",
    "Mongol",
    "Mongolia Buriat",
    "Mongolian",
    "Mongolian Sign Language",
    "Mongondow",
    "Moni",
    "Mono (Cameroon)",
    "Mono (Democratic Republic of Congo)",
    "Mono (Solomon Islands)",
    "Mono (USA)",
    "Monom",
    "Monsang Naga",
    "Montagnais",
    "Montol",
    "Monumbo",
    "Monzombo",
    "Moo",
    "Moose Cree",
    "Mop\u00e1n Maya",
    "Mor (Bomberai Peninsula)",
    "Mor (Mor Islands)",
    "Moraid",
    "Morawa",
    "Morelos Nahuatl",
    "Morerebi",
    "Moresada",
    "Mori Atas",
    "Mori Bawah",
    "Morigi",
    "Morisyen",
    "Moro",
    "Moroccan Arabic",
    "Moroccan Sign Language",
    "Morokodo",
    "Morom",
    "Moronene",
    "Morori",
    "Morouas",
    "Mortlockese",
    "Moru",
    "Mosimo",
    "Mosiro",
    "Moskona",
    "Mossi",
    "Mota",
    "Motlav",
    "Motu",
    "Mouk-Aria",
    "Mountain Koiali",
    "Mouwase",
    "Movima",
    "Moyadan Itneg",
    "Moyon Naga",
    "Mozambican Sign Language",
    "Mozarabic",
    "Mpade",
    "Mpalitjanh",
    "Mpi",
    "Mpiemo",
    "Mpoto",
    "Mpotovoro",
    "Mpumpong",
    "Mpuono",
    "Mpur",
    "Mro-Khimi Chin",
    "Mru",
    "Mser",
    "Mt. Iraya Agta",
    "Mt. Iriga Agta",
    "Mualang",
    "Mubami",
    "Mubi",
    "Muda",
    "Mudburra",
    "Mudhili Gadaba",
    "Mudu Koraga",
    "Muduga",
    "Mufian",
    "Mugom",
    "Muinane",
    "Mukha-Dora",
    "Mukulu",
    "Mulaha",
    "Mulam",
    "Mulao",
    "Mulgi",
    "Mullu Kurumba",
    "Mullukmulluk",
    "Multiple languages",
    "Muluridyi",
    "Mum",
    "Mumuye",
    "Muna",
    "Munda",
    "Mundabli",
    "Mundang",
    "Mundani",
    "Mundari",
    "Mundat",
    "Munduruk\u00fa",
    "Mungaka",
    "Munggui",
    "Mungkip",
    "Muniche",
    "Munit",
    "Munji",
    "Munsee",
    "Muong",
    "Mur Pano",
    "Muratayak",
    "Murik (Malaysia)",
    "Murik (Papua New Guinea)",
    "Murkim",
    "Murle",
    "Murrinh-Patha",
    "Mursi",
    "Murui Huitoto",
    "Murupi",
    "Muruwari",
    "Musak",
    "Musar",
    "Musasa",
    "Musey",
    "Musgu",
    "Mushungulu",
    "Musi",
    "Muskum",
    "Muslim Tat",
    "Musom",
    "Mussau-Emira",
    "Muthuvan",
    "Mutu",
    "Muya",
    "Muyang",
    "Muyuw",
    "Muzi",
    "Mvanip",
    "Mvuba",
    "Mwaghavul",
    "Mwali Comorian",
    "Mwan",
    "Mwani",
    "Mwatebu",
    "Mwera (Chimwera)",
    "Mwera (Nyasa)",
    "Mwimbi-Muthambi",
    "Mycenaean Greek",
    "Myene",
    "Mysian",
    "Mzieme Naga",
    "N'Ko",
    "N/u",
    "N\u00e1-Meo",
    "N\u00eal\u00eamwa-Nixumwak",
    "N\u00fcpode Huitoto",
    "Na",
    "Naaba",
    "Naami",
    "Naasioi",
    "Naba",
    "Nabak",
    "Nabi",
    "Nachering",
    "Nad\u00ebb",
    "Nadruvian",
    "Nafaanra",
    "Nafi",
    "Nafri",
    "Nafusi",
    "Naga Pidgin",
    "Nagarchal",
    "Nage",
    "Nagumi",
    "Nahali",
    "Nahari",
    "Nai",
    "Najdi Arabic",
    "Naka'ela",
    "Nakai",
    "Nakame",
    "Nakanai",
    "Nakara",
    "Nake",
    "Naki",
    "Nakwi",
    "Nal\u00f6go",
    "Nalca",
    "Nali",
    "Nalik",
    "Nalu",
    "Naluo Yi",
    "Nama (Namibia)",
    "Nama (Papua New Guinea)",
    "Namakura",
    "Namat",
    "Nambo",
    "Nambya",
    "Namia",
    "Namiae",
    "Namibian Sign Language",
    "Namla",
    "Namo",
    "Namonuito",
    "Namosi-Naitasiri-Serua",
    "Namuyi",
    "Nanai",
    "Nancere",
    "Nande",
    "Nandi",
    "Nanerig\u00e9 S\u00e9noufo",
    "Nanga Dama Dogon",
    "Nankina",
    "Nanti",
    "Nanticoke",
    "Nanubae",
    "Napo Lowland Quechua",
    "Napu",
    "Nar Phu",
    "Nara",
    "Narak",
    "Narango",
    "Narau",
    "Nari Nari",
    "Narim",
    "Naro",
    "Narom",
    "Narragansett",
    "Narrinyeri",
    "Narua",
    "Narungga",
    "Nasal",
    "Nasarian",
    "Naskapi",
    "Nat\u00fcgu",
    "Natagaimas",
    "Natanzi",
    "Nataoran Amis",
    "Natchez",
    "Nateni",
    "Nathembo",
    "Natioro",
    "Nauete",
    "Naukan Yupik",
    "Nauna",
    "Nauo",
    "Nauru",
    "Navajo",
    "Navut",
    "Nawaru",
    "Nawathinehena",
    "Nawdm",
    "Nawuri",
    "Naxi",
    "Nayi",
    "Nayini",
    "Ncane",
    "Nchumbulu",
    "Nda'nda'",
    "Ndai",
    "Ndaka",
    "Ndaktup",
    "Ndali",
    "Ndam",
    "Ndamba",
    "Ndasa",
    "Ndau",
    "Nde-Gbite",
    "Nde-Nsele-Nta",
    "Ndemli",
    "Ndendeule",
    "Ndengereko",
    "Nding",
    "Ndo",
    "Ndobo",
    "Ndoe",
    "Ndogo",
    "Ndolo",
    "Ndom",
    "Ndombe",
    "Ndonde Hamba",
    "Ndonga",
    "Ndoola",
    "Ndra'ngith",
    "Nduga",
    "Ndumu",
    "Ndunda",
    "Ndunga",
    "Ndut",
    "Ndyuka-Trio Pidgin",
    "Ndzwani Comorian",
    "Neapolitan",
    "Nedebang",
    "Nefamese",
    "Negerhollands",
    "Negeri Sembilan Malay",
    "Negidal",
    "Nehan",
    "Nek",
    "Nekgini",
    "Neko",
    "Neku",
    "Neme",
    "Nemi",
    "Nen",
    "Nend",
    "Nenets",
    "Nengone",
    "Neo",
    "Neo-Hittite",
    "Nepalese Sign Language",
    "Nepali (individual language)",
    "Nepali (macrolanguage)",
    "Nepali Kurux",
    "Nete",
    "New Caledonian Javanese",
    "New Zealand Sign Language",
    "Newari",
    "Neyo",
    "Nez Perce",
    "Ng\u00e4bere",
    "Ngaanyatjarra",
    "Ngad'a",
    "Ngadjunmaya",
    "Ngadjuri",
    "Ngaing",
    "Ngaju",
    "Ngala",
    "Ngalakan",
    "Ngalum",
    "Ngam",
    "Ngamambo",
    "Ngambay",
    "Ngamini",
    "Ngamo",
    "Ngan'gityemerri",
    "Nganakarti",
    "Nganasan",
    "Ngandi",
    "Ngando (Central African Republic)",
    "Ngando (Democratic Republic of Congo)",
    "Ngandyera",
    "Ngangam",
    "Ngantangarra",
    "Nganyaywana",
    "Ngardi",
    "Ngarigu",
    "Ngarinman",
    "Ngarinyin",
    "Ngarla",
    "Ngarluma",
    "Ngas",
    "Ngasa",
    "Ngatik Men's Creole",
    "Ngawn Chin",
    "Ngawun",
    "Ngayawung",
    "Ngazidja Comorian",
    "Ngbaka",
    "Ngbaka Ma'bo",
    "Ngbaka Manza",
    "Ngbee",
    "Ngbinda",
    "Ngbundu",
    "Ngelima",
    "Ngemba",
    "Ngeq",
    "Ngete",
    "Nggem",
    "Nggwahyi",
    "Ngie",
    "Ngiemboon",
    "Ngile",
    "Ngindo",
    "Ngiti",
    "Ngizim",
    "Ngk\u00e2lmpw Kanum",
    "Ngom",
    "Ngomba",
    "Ngombale",
    "Ngombe (Central African Republic)",
    "Ngombe (Democratic Republic of Congo)",
    "Ngong",
    "Ngongo",
    "Ngoni",
    "Ngoshie",
    "Ngu\u00f4n",
    "Ngul",
    "Ngulu",
    "Nguluwan",
    "Ngumbi",
    "Ngunawal",
    "Ngundi",
    "Ngundu",
    "Ngungwel",
    "Ngurimi",
    "Ngurmbur",
    "Ngwaba",
    "Ngwe",
    "Ngwo",
    "Nhanda",
    "Nhengatu",
    "Nhirrpi",
    "Nhuwala",
    "Nias",
    "Nicaragua Creole English",
    "Nicaraguan Sign Language",
    "Niellim",
    "Nigeria Mambila",
    "Nigerian Fulfulde",
    "Nigerian Pidgin",
    "Nigerian Sign Language",
    "Nihali",
    "Nii",
    "Nijadali",
    "Niksek",
    "Nila",
    "Nilamba",
    "Nimadi",
    "Nimanbur",
    "Nimbari",
    "Nimboran",
    "Nimi",
    "Nimo",
    "Nimoa",
    "Ninam",
    "Nindi",
    "Ningera",
    "Ninggerum",
    "Ningil",
    "Ningye",
    "Ninia Yali",
    "Ninzo",
    "Nipsan",
    "Nisa",
    "Nisenan",
    "Nisga'a",
    "Nisi (China)",
    "Niuafo'ou",
    "Niuatoputapu",
    "Niuean",
    "Nivacl\u00e9",
    "Niwer Mil",
    "Njalgulgule",
    "Njebi",
    "Njen",
    "Njerep",
    "Njyem",
    "Nkami",
    "Nkangala",
    "Nkari",
    "Nkem-Nkum",
    "Nkhumbi",
    "Nkongho",
    "Nkonya",
    "Nkoroo",
    "Nkoya",
    "Nkukoli",
    "Nkutu",
    "Nnam",
    "No linguistic content",
    "Nobiin",
    "Nobonob",
    "Nocam\u00e1n",
    "Nocte Naga",
    "Nogai",
    "Noiri",
    "Nokuku",
    "Nomaande",
    "Nomane",
    "Nomatsiguenga",
    "Nomlaki",
    "Nomu",
    "Nong Zhuang",
    "Nonuya",
    "Nooksack",
    "Noon",
    "Noone",
    "Nopala Chatino",
    "Noric",
    "Norn",
    "Norra",
    "North Alaskan Inupiatun",
    "North Ambrym",
    "North Asmat",
    "North Awyu",
    "North Azerbaijani",
    "North Babar",
    "North Bolivian Quechua",
    "North Central Mixe",
    "North Efate",
    "North Fali",
    "North Giziga",
    "North Jun\u00edn Quechua",
    "North Levantine Arabic",
    "North Marquesan",
    "North Mesopotamian Arabic",
    "North Mofu",
    "North Moluccan Malay",
    "North Muyu",
    "North Ndebele",
    "North Nuaulu",
    "North Picene",
    "North Slavey",
    "North Tairora",
    "North Tanna",
    "North Wahgi",
    "North Watut",
    "Northeast Kiwai",
    "Northeast Maidu",
    "Northeast Pashayi",
    "Northeastern Dinka",
    "Northeastern Pomo",
    "Northeastern Thai",
    "Northern Alta",
    "Northern Altai",
    "Northern Amami-Oshima",
    "Northern Betsimisaraka Malagasy",
    "Northern Binukidnon",
    "Northern Bobo Madar\u00e9",
    "Northern Bontok",
    "Northern Catanduanes Bikol",
    "Northern Conchucos Ancash Quechua",
    "Northern Dagara",
    "Northern Dong",
    "Northern East Cree",
    "Northern Ember\u00e1",
    "Northern Frisian",
    "Northern Ghale",
    "Northern Gondi",
    "Northern Grebo",
    "Northern Guiyang Hmong",
    "Northern Haida",
    "Northern Hindko",
    "Northern Huishui Hmong",
    "Northern Kalapuya",
    "Northern Kankanay",
    "Northern Khmer",
    "Northern Kissi",
    "Northern Kurdish",
    "Northern Luri",
    "Northern Mashan Hmong",
    "Northern Muji",
    "Northern Nago",
    "Northern Ngbandi",
    "Northern Nisu",
    "Northern Nuni",
    "Northern Oaxaca Nahuatl",
    "Northern Ohlone",
    "Northern One",
    "Northern Paiute",
    "Northern Pame",
    "Northern Pashto",
    "Northern Pastaza Quichua",
    "Northern Pomo",
    "Northern Puebla Nahuatl",
    "Northern Pumi",
    "Northern Qiandong Miao",
    "Northern Qiang",
    "Northern Rengma Naga",
    "Northern Roglai",
    "Northern Sami",
    "Northern Sierra Miwok",
    "Northern Sorsoganon",
    "Northern Subanen",
    "Northern Tarahumara",
    "Northern Tepehuan",
    "Northern Thai",
    "Northern Tiwa",
    "Northern Tlaxiaco Mixtec",
    "Northern Toussian",
    "Northern Tujia",
    "Northern Tutchone",
    "Northern Uzbek",
    "Northern Yukaghir",
    "Northwest Alaska Inupiatun",
    "Northwest Gbaya",
    "Northwest Maidu",
    "Northwest Oaxaca Mixtec",
    "Northwest Pashayi",
    "Northwestern Dinka",
    "Northwestern Fars",
    "Northwestern Kolami",
    "Northwestern Nisu",
    "Northwestern Ojibwa",
    "Northwestern Tamang",
    "Norwegian Bokm\u00e5l",
    "Norwegian Nynorsk",
    "Norwegian Sign Language",
    "Notre",
    "Notsi",
    "Nottoway",
    "Nottoway-Meherrin",
    "Novial",
    "Noy",
    "Nsenga",
    "Nshi",
    "Nsongo",
    "Ntcham",
    "Ntomba",
    "Nubaca",
    "Nubi",
    "Nubri",
    "Nuer",
    "Nugunu (Australia)",
    "Nugunu (Cameroon)",
    "Nuk",
    "Nukak Mak\u00fa",
    "Nukna",
    "Nukuini",
    "Nukumanu",
    "Nukunul",
    "Nukuoro",
    "Nukuria",
    "Num\u00e8\u00e8",
    "Numana-Nunku-Gbantu-Numbu",
    "Numanggang",
    "Numbami",
    "Nume",
    "Numidian",
    "Nung (Viet Nam)",
    "Nungali",
    "Nunggubuyu",
    "Nungu",
    "Nupbikha",
    "Nupe-Nupe-Tako",
    "Nusa Laut",
    "Nusu",
    "Nuu-chah-nulth",
    "Ny\u00e2layu",
    "Nyabwa",
    "Nyaheun",
    "Nyahkur",
    "Nyakyusa-Ngonde",
    "Nyali",
    "Nyam",
    "Nyamal",
    "Nyambo",
    "Nyamusa-Molo",
    "Nyamwanga",
    "Nyamwezi",
    "Nyaneka",
    "Nyang'i",
    "Nyanga",
    "Nyanga-li",
    "Nyangatom",
    "Nyangbo",
    "Nyangga",
    "Nyangumarta",
    "Nyanja",
    "Nyankole",
    "Nyankpa",
    "Nyarafolo Senoufo",
    "Nyaturu",
    "Nyaw",
    "Nyawaygi",
    "Nyemba",
    "Nyengo",
    "Nyenkha",
    "Nyeu",
    "Nyigina",
    "Nyiha (Malawi)",
    "Nyiha (Tanzania)",
    "Nyika (Malawi and Zambia)",
    "Nyika (Tanzania)",
    "Nyindrou",
    "Nyindu",
    "Nyishi",
    "Nyiyaparli",
    "Nyokon",
    "Nyole",
    "Nyong",
    "Nyore",
    "Nyoro",
    "Nyulnyul",
    "Nyunga",
    "Nyungwe",
    "Nzakambay",
    "Nzakara",
    "Nzanyi",
    "Nzima",
    "O'chi'chi'",
    "O'du",
    "Obanliku",
    "Obispe\u00f1o",
    "Oblo",
    "Obo Manobo",
    "Obokuitai",
    "Obolo",
    "Obulom",
    "Ocaina",
    "Occitan (post 1500)",
    "Ocotepec Mixtec",
    "Ocotl\u00e1n Zapotec",
    "Od",
    "Odiai",
    "Odoodee",
    "Odual",
    "Odut",
    "Ofay\u00e9",
    "Official Aramaic (700-300 BCE)",
    "Ofo",
    "Ogbah",
    "Ogbia",
    "Ogbogolo",
    "Ogbronuagum",
    "Ogea",
    "Oirata",
    "Ojibwa",
    "Ojitl\u00e1n Chinantec",
    "Okanagan",
    "Oki-No-Erabu",
    "Okiek",
    "Oko-Eni-Osayen",
    "Oko-Juwoi",
    "Okobo",
    "Okodia",
    "Okolod",
    "Okpamheri",
    "Okpe (Northwestern Edo)",
    "Okpe (Southwestern Edo)",
    "Oksapmin",
    "Oku",
    "Old Aramaic (up to 700 BCE)",
    "Old Avar",
    "Old Breton",
    "Old Burmese",
    "Old Chinese",
    "Old Cornish",
    "Old Dutch",
    "Old English (ca. 450-1100)",
    "Old French (842-ca. 1400)",
    "Old Frisian",
    "Old Georgian",
    "Old High German (ca. 750-1050)",
    "Old Hittite",
    "Old Hungarian",
    "Old Irish (to 900)",
    "Old Japanese",
    "Old Kentish Sign Language",
    "Old Korean (3rd-9th cent.)",
    "Old Lithuanian",
    "Old Manipuri",
    "Old Marathi",
    "Old Mon",
    "Old Norse",
    "Old Nubian",
    "Old Ossetic",
    "Old Persian (ca. 600-400 B.C.)",
    "Old Proven\u00e7al (to 1500)",
    "Old Russian",
    "Old Saxon",
    "Old Spanish",
    "Old Tamil",
    "Old Tibetan",
    "Old Turkish",
    "Old Uighur",
    "Old Welsh",
    "Olekha",
    "Olkol",
    "Olo",
    "Oloma",
    "Olrat",
    "Olu'bo",
    "Olulumo-Ikom",
    "Oluta Popoluca",
    "Omagua",
    "Omaha-Ponca",
    "Omani Arabic",
    "Ombamba",
    "Ombo",
    "Omejes",
    "Ometepec Nahuatl",
    "Omi",
    "Omok",
    "Omotik",
    "Omurano",
    "Ona",
    "Oneida",
    "Ong",
    "Onin",
    "Onin Based Pidgin",
    "Onjob",
    "Ono",
    "Onobasulu",
    "Onondaga",
    "Ontenu",
    "Ontong Java",
    "Oorlams",
    "Opao",
    "Opata",
    "Opuuo",
    "Orang Kanaq",
    "Orang Seletar",
    "Oraon Sadri",
    "Orej\u00f3n",
    "Oring",
    "Oriya (individual language)",
    "Oriya (macrolanguage)",
    "Orizaba Nahuatl",
    "Orma",
    "Ormu",
    "Ormuri",
    "Oro",
    "Oro Win",
    "Oroch",
    "Oroha",
    "Orok",
    "Orokaiva",
    "Oroko",
    "Orokolo",
    "Oromo",
    "Oroqen",
    "Orowe",
    "Oruma",
    "Orya",
    "Osage",
    "Osatu",
    "Oscan",
    "Osing",
    "Ososo",
    "Ossetian",
    "Ot Danum",
    "Otank",
    "Oti",
    "Otoro",
    "Ottawa",
    "Ottoman Turkish (1500-1928)",
    "Otuho",
    "Otuke",
    "Ouma",
    "Oune",
    "Owa",
    "Owenia",
    "Owiniga",
    "Oy",
    "Oya'oya",
    "Oyda",
    "Ozolotepec Zapotec",
    "Ozumac\u00edn Chinantec",
    "P\u00e1\u00e1fang",
    "P\u00e1ez",
    "P\u00e4ri",
    "P\u00e9mono",
    "P\u00e9v\u00e9",
    "P\u00f6koot",
    "Pa Di",
    "Pa-Hng",
    "Pa'a",
    "Pa'o Karen",
    "Paakantyi",
    "Paama",
    "Paasaal",
    "Pacahuara",
    "Pacaraos Quechua",
    "Pacific Gulf Yupik",
    "Pacoh",
    "Padoe",
    "Paekche",
    "Paelignian",
    "Pagi",
    "Pagibete",
    "Pagu",
    "Pahanan Agta",
    "Pahari-Potwari",
    "Pahi",
    "Pahlavani",
    "Pahlavi",
    "Pai Tavytera",
    "Paic\u00ee",
    "Paipai",
    "Paite Chin",
    "Paiwan",
    "Pak-Tong",
    "Paka\u00e1snovos",
    "Pakanha",
    "Pakistan Sign Language",
    "Paku",
    "Paku Karen",
    "Pal",
    "Palaic",
    "Palaka Senoufo",
    "Palantla Chinantec",
    "Palauan",
    "Paleni",
    "Palenquero",
    "Pali",
    "Palik\u00far",
    "Paliyan",
    "Pallanganmiddang",
    "Palor",
    "Palpa",
    "Palu'e",
    "Paluan",
    "Palumata",
    "Palya Bareli",
    "Pam",
    "Pambia",
    "Pamlico",
    "Pamona",
    "Pamosu",
    "Pampanga",
    "Pamplona Atta",
    "Pana (Burkina Faso)",
    "Pana (Central African Republic)",
    "Panamanian Sign Language",
    "Panamint",
    "Panao Hu\u00e1nuco Quechua",
    "Panar\u00e1",
    "Panasuan",
    "Panawa",
    "Pancana",
    "Panchpargania",
    "Pande",
    "Pangasinan",
    "Pangseng",
    "Pangutaran Sama",
    "Pangwa",
    "Pangwali",
    "Panim",
    "Paniya",
    "Pankarar\u00e9",
    "Pankarar\u00fa",
    "Pankhu",
    "Pannei",
    "Pano",
    "Panoan Katuk\u00edna",
    "Panobo",
    "Panyi Bai",
    "Panytyima",
    "Pao",
    "Papantla Totonac",
    "Papapana",
    "Papar",
    "Papasena",
    "Papel",
    "Papi",
    "Papiamento",
    "Papitalai",
    "Papora",
    "Papuan Malay",
    "Papuma",
    "Par\u00e1 Ar\u00e1ra",
    "Par\u00e1 Gavi\u00e3o",
    "Para Naga",
    "Parachi",
    "Paraguayan Guaran\u00ed",
    "Paraguayan Sign Language",
    "Parakan\u00e3",
    "Paranan",
    "Paranaw\u00e1t",
    "Paraujano",
    "Parauk",
    "Parawen",
    "Pardhan",
    "Pardhi",
    "Pare",
    "Parec\u00eds",
    "Parenga",
    "Parkari Koli",
    "Parkwa",
    "Parsi",
    "Parsi-Dari",
    "Parthian",
    "Parya",
    "Pasi",
    "Pass Valley Yali",
    "Patamona",
    "Patani",
    "Patax\u00f3 H\u00e3-Ha-H\u00e3e",
    "Patep",
    "Pathiya",
    "Patpatar",
    "Pattani",
    "Pattani Malay",
    "Pattapu",
    "Patwin",
    "Paulohi",
    "Paumar\u00ed",
    "Paunaka",
    "Pauri Bareli",
    "Pauserna",
    "Pawaia",
    "Pawnee",
    "Paynamar",
    "Pe",
    "Pe\u00f1oles Mixtec",
    "Pear",
    "Pech",
    "Pecheneg",
    "Pedi",
    "Peere",
    "Pei",
    "Pekal",
    "Pela",
    "Pele-Ata",
    "Pelende",
    "Pemon",
    "Penang Sign Language",
    "Penchal",
    "Pendau",
    "Pengo",
    "Pennsylvania German",
    "Penrhyn",
    "Pentlatch",
    "Perai",
    "Peranakan Indonesian",
    "Peripheral Mongolian",
    "Pero",
    "Persian Sign Language",
    "Peruvian Sign Language",
    "Petapa Zapotec",
    "Petats",
    "Petjo",
    "Pfaelzisch",
    "Phai",
    "Phake",
    "Phala",
    "Phalura",
    "Phana'",
    "Phangduwali",
    "Phende",
    "Philippine Sign Language",
    "Phimbi",
    "Phoenician",
    "Phola",
    "Pholo",
    "Phom Naga",
    "Phong-Kniang",
    "Phrae Pwo Karen",
    "Phrygian",
    "Phu Thai",
    "Phuan",
    "Phudagi",
    "Phuie",
    "Phukha",
    "Phuma",
    "Phunoi",
    "Phuong",
    "Phupa",
    "Phupha",
    "Phuza",
    "Piamatsina",
    "Piame",
    "Piapoco",
    "Piaroa",
    "Picard",
    "Pichis Ash\u00e9ninka",
    "Pictish",
    "Pidgin Delaware",
    "Piemontese",
    "Pijao",
    "Pije",
    "Pijin",
    "Pilag\u00e1",
    "Pileni",
    "Pima Bajo",
    "Pimbwe",
    "Pinai-Hagahai",
    "Pingelapese",
    "Pini",
    "Pinigura",
    "Pinjarup",
    "Pinji",
    "Pinotepa Nacional Mixtec",
    "Pintiini",
    "Pintupi-Luritja",
    "Pinyin",
    "Pipil",
    "Pirah\u00e3",
    "Piratapuyo",
    "Pirlatapa",
    "Piro",
    "Pirriya",
    "Pisabo",
    "Pisaflores Tepehua",
    "Piscataway",
    "Pisidian",
    "Pitcairn-Norfolk",
    "Pite Sami",
    "Piti",
    "Pitjantjatjara",
    "Pitta Pitta",
    "Piu",
    "Piya-Kwonci",
    "Plains Cree",
    "Plains Indian Sign Language",
    "Plains Miwok",
    "Plapo Krumen",
    "Plateau Malagasy",
    "Plautdietsch",
    "Playero",
    "Pnar",
    "Pochuri Naga",
    "Pochutec",
    "Podena",
    "Pogolo",
    "Pohnpeian",
    "Pokang\u00e1",
    "Poke",
    "Pokomo",
    "Polabian",
    "Polari",
    "Polci",
    "Polish Sign Language",
    "Polonombauk",
    "Pom",
    "Pomo",
    "Ponam",
    "Ponares",
    "Pongu",
    "Ponosakan",
    "Pontic",
    "Ponyo-Gongwang Naga",
    "Popti'",
    "Poqomam",
    "Poqomchi'",
    "Porohanon",
    "Port Sandwich",
    "Port Vato",
    "Portuguese Sign Language",
    "Potawatomi",
    "Potigu\u00e1ra",
    "Pottangi Ollar Gadaba",
    "Poumei Naga",
    "Pouye",
    "Powari",
    "Powhatan",
    "Poyan\u00e1wa",
    "Prasuni",
    "Pray 3",
    "Primitive Irish",
    "Principense",
    "Providencia Sign Language",
    "Prussian",
    "Psikye",
    "Pu Ko",
    "Pu-Xian Chinese",
    "Puare",
    "Pudtol Atta",
    "Puelche",
    "Puerto Rican Sign Language",
    "Puimei Naga",
    "Puinave",
    "Pukapuka",
    "Pulaar",
    "Pulabu",
    "Pular",
    "Puluwatese",
    "Pum\u00e9",
    "Puma",
    "Pumpokol",
    "Punan Aput",
    "Punan Bah-Biau",
    "Punan Batu 1",
    "Punan Merah",
    "Punan Merap",
    "Punan Tubu",
    "Punic",
    "Puno Quechua",
    "Punthamara",
    "Punu",
    "Puoc",
    "Puquina",
    "Puragi",
    "Purari",
    "Purepecha",
    "Puri",
    "Purik",
    "Purisime\u00f1o",
    "Puroik",
    "Purubor\u00e1",
    "Purum",
    "Pushto",
    "Putai",
    "Putoh",
    "Putukwam",
    "Puyo",
    "Puyo-Paekche",
    "Puyuma",
    "Pwaamei",
    "Pwapw\u00e2",
    "Pwo Eastern Karen",
    "Pwo Northern Karen",
    "Pwo Western Karen",
    "Pyapun",
    "Pye Krumen",
    "Pyen",
    "Pyu",
    "Pyu (Myanmar)",
    "Q'anjob'al",
    "Qabiao",
    "Qaqet",
    "Qashqa'i",
    "Qatabanian",
    "Qau",
    "Qawasqar",
    "Qila Muji",
    "Qimant",
    "Qiubei Zhuang",
    "Quapaw",
    "Quebec Sign Language",
    "Quechan",
    "Quechua",
    "Quenya",
    "Quer\u00e9taro Otomi",
    "Quetzaltepec Mixe",
    "Queyu",
    "Quiavicuzas Zapotec",
    "Quileute",
    "Quinault",
    "Quinqui",
    "Quioquitani-Quier\u00ed Zapotec",
    "Quiotepec Chinantec",
    "Quiripi",
    "R\u00e9union Creole French",
    "Rabha",
    "Rade",
    "Raetic",
    "Rahambuu",
    "Rajah Kabunsuwan Manobo",
    "Rajasthani",
    "Rajbanshi",
    "Raji",
    "Rajong",
    "Rajput Garasia",
    "Rakahanga-Manihiki",
    "Rakhine",
    "Ralte",
    "Rama",
    "Ramoaaina",
    "Ramopa",
    "Rampi",
    "Rana Tharu",
    "Rang",
    "Rangkas",
    "Ranglong",
    "Rangpuri",
    "Rao",
    "Rapa",
    "Rapanui",
    "Rapoisi",
    "Rapting",
    "Rara Bakati'",
    "Rarotongan",
    "Rasawa",
    "Ratagnon",
    "Ratahan",
    "Rathawi",
    "Rathwi Bareli",
    "Raute",
    "Ravula",
    "Rawa",
    "Rawang",
    "Rawat",
    "Rawngtu Chin",
    "Rawo",
    "Ray\u00f3n Zoque",
    "Razajerdi",
    "Red Gelao",
    "Reel",
    "Rejang",
    "Rejang Kayan",
    "Reli",
    "Rema",
    "Rembarunga",
    "Rembong",
    "Remo",
    "Remontado Dumagat",
    "Rempi",
    "Remun",
    "Rendille",
    "Rengao",
    "Rennell-Bellona",
    "Rennellese Sign Language",
    "Repanbitip",
    "Rer Bare",
    "Rerau",
    "Rerep",
    "Res\u00edgaro",
    "Reshe",
    "Retta",
    "Reyesano",
    "Riang (India)",
    "Riang (Myanmar)",
    "Riantana",
    "Ribun",
    "Rien",
    "Rikbaktsa",
    "Rinc\u00f3n Zapotec",
    "Rinconada Bikol",
    "Ringgou",
    "Ririo",
    "Ritarungo",
    "Riung",
    "Riverain Sango",
    "Rogo",
    "Rohingya",
    "Roma",
    "Romagnol",
    "Romam",
    "Romanian Sign Language",
    "Romano-Greek",
    "Romano-Serbian",
    "Romanova",
    "Romansh",
    "Romany",
    "Romblomanon",
    "Rombo",
    "Romkun",
    "Ron",
    "Ronga",
    "Rongga",
    "Rongmei Naga",
    "Rongpo",
    "Ronji",
    "Roon",
    "Roria",
    "Rotokas",
    "Rotuman",
    "Roviana",
    "Ruching Palaung",
    "Rudbari",
    "Rufiji",
    "Ruga",
    "Rukai",
    "Ruma",
    "Rumai Palaung",
    "Rumu",
    "Runa",
    "Rundi",
    "Runga",
    "Rungtu Chin",
    "Rungus",
    "Rungwa",
    "Russia Buriat",
    "Russian Sign Language",
    "Rusyn",
    "Rutul",
    "Ruuli",
    "Ruund",
    "Rwa",
    "S'gaw Karen",
    "S\u00e1liba",
    "S\u00e3o Paulo Kaing\u00e1ng",
    "S\u00e3otomense",
    "S\u00ecc\u00ect\u00e9 S\u00e9noufo",
    "S\u00f4",
    "Sa",
    "Sa'a",
    "Sa'ban",
    "Sa'och",
    "Saafi-Saafi",
    "Saam",
    "Saamia",
    "Saaroa",
    "Sab\u00fcm",
    "Saba",
    "Sabaean",
    "Sabah Bisaya",
    "Sabah Malay",
    "Saban\u00ea",
    "Sabaot",
    "Sabine",
    "Sabu",
    "Sacapulteco",
    "Sadri",
    "Saek",
    "Saep",
    "Safaliba",
    "Safeyoka",
    "Safwa",
    "Sagala",
    "Sagalla",
    "Saho",
    "Sahu",
    "Saidi Arabic",
    "Saint Lucian Creole French",
    "Saisiyat",
    "Sajalong",
    "Sajau Basap",
    "Sakachep",
    "Sakalava Malagasy",
    "Sakao",
    "Sakata",
    "Sake",
    "Sakirabi\u00e1",
    "Sala",
    "Salampasu",
    "Salar",
    "Salas",
    "Salasaca Highland Quichua",
    "Salchuq",
    "Saleman",
    "Saliba",
    "Salinan",
    "Sallands",
    "Salt-Yui",
    "Saluan",
    "Salum\u00e1",
    "Salvadoran Sign Language",
    "Sam",
    "Sama",
    "Samaritan",
    "Samaritan Aramaic",
    "Samarokena",
    "Samatao",
    "Samba",
    "Samba Daka",
    "Samba Leko",
    "Sambal",
    "Sambalpuri",
    "Sambe",
    "Samberigi",
    "Samburu",
    "Samei",
    "Samo",
    "Samoan",
    "Samogitian",
    "Samosa",
    "Sampang",
    "Samre",
    "Samtao",
    "Samvedi",
    "San Agust\u00edn Mixtepec Zapotec",
    "San Baltazar Loxicha Zapotec",
    "San Blas Kuna",
    "San Dionisio Del Mar Huave",
    "San Felipe Otlaltepec Popoloca",
    "San Francisco Del Mar Huave",
    "San Francisco Matlatzinca",
    "San Jer\u00f3nimo Tec\u00f3atl Mazatec",
    "San Juan Atzingo Popoloca",
    "San Juan Colorado Mixtec",
    "San Juan Guelav\u00eda Zapotec",
    "San Juan Teita Mixtec",
    "San Lu\u00eds Temalacayuca Popoloca",
    "San Marcos Tlalcoyalco Popoloca",
    "San Mart\u00edn Itunyoso Triqui",
    "San Mart\u00edn Quechua",
    "San Mateo Del Mar Huave",
    "San Miguel Creole French",
    "San Miguel El Grande Mixtec",
    "San Miguel Piedras Mixtec",
    "San Pedro Amuzgos Amuzgo",
    "San Pedro Quiatoni Zapotec",
    "San Salvador Kongo",
    "San Vicente Coatl\u00e1n Zapotec",
    "Sanaani Arabic",
    "Sanapan\u00e1",
    "Sandawe",
    "Sanga (Democratic Republic of Congo)",
    "Sanga (Nigeria)",
    "Sanggau",
    "Sangil",
    "Sangir",
    "Sangisari",
    "Sangkong",
    "Sanglechi",
    "Sango",
    "Sangtam Naga",
    "Sangu (Gabon)",
    "Sangu (Tanzania)",
    "Sani",
    "Sanie",
    "Saniyo-Hiyewe",
    "Sankaran Maninka",
    "Sansi",
    "Sanskrit",
    "Santa Ana de Tusi Pasco Quechua",
    "Santa Catarina Albarradas Zapotec",
    "Santa In\u00e9s Ahuatempan Popoloca",
    "Santa In\u00e9s Yatzechi Zapotec",
    "Santa Luc\u00eda Monteverde Mixtec",
    "Santa Mar\u00eda Del Mar Huave",
    "Santa Mar\u00eda La Alta Nahuatl",
    "Santa Mar\u00eda Quiegolani Zapotec",
    "Santa Mar\u00eda Zacatepec Mixtec",
    "Santa Teresa Cora",
    "Santali",
    "Santiago del Estero Quichua",
    "Santiago Xanica Zapotec",
    "Santo Domingo Albarradas Zapotec",
    "Sanum\u00e1",
    "Sap\u00e9",
    "Saparua",
    "Sapo",
    "Saponi",
    "Saposa",
    "Sapuan",
    "Sar",
    "Sara",
    "Sara Dunjo",
    "Sara Kaba",
    "Sara Kaba Deme",
    "Sara Kaba N\u00e1\u00e0",
    "Saramaccan",
    "Sarangani Blaan",
    "Sarangani Manobo",
    "Sarasira",
    "Saraveca",
    "Sardinian",
    "Sari",
    "Sarikoli",
    "Sarli",
    "Sarsi",
    "Sartang",
    "Sarua",
    "Sarudu",
    "Saruga",
    "Sasak",
    "Sasaru",
    "Sassarese Sardinian",
    "Satawalese",
    "Sater\u00e9-Maw\u00e9",
    "Saterfriesisch",
    "Saudi Arabian Sign Language",
    "Sauraseni Pr\u0101krit",
    "Saurashtra",
    "Sauri",
    "Sauria Paharia",
    "Sause",
    "Sausi",
    "Savara",
    "Savi",
    "Savosavo",
    "Sawai",
    "Saweru",
    "Sawi",
    "Sawila",
    "Sawknah",
    "Saxwe Gbe",
    "Saya",
    "Sayula Popoluca",
    "Scots",
    "Scottish Gaelic",
    "Scythian",
    "Sea Island Creole English",
    "Seba",
    "Sebat Bet Gurage",
    "Seberuang",
    "Sebop",
    "Sebuyau",
    "Sechelt",
    "Secoya",
    "Sedang",
    "Sedoa",
    "Seeku",
    "Segai",
    "Segeju",
    "Seget",
    "Sehwi",
    "Seimat",
    "Seit-Kaitetu",
    "Sekani",
    "Sekapan",
    "Sekar",
    "Seke (Nepal)",
    "Seke (Vanuatu)",
    "Seki",
    "Seko Padang",
    "Seko Tengah",
    "Sekpele",
    "Selangor Sign Language",
    "Selaru",
    "Selayar",
    "Selee",
    "Selepet",
    "Selian",
    "Selkup",
    "Selungai Murut",
    "Seluwasan",
    "Semai",
    "Semandang",
    "Semaq Beri",
    "Sembakung Murut",
    "Semelai",
    "Semimi",
    "Semnam",
    "Semnani",
    "Sempan",
    "Sena",
    "Senara S\u00e9noufo",
    "Senaya",
    "Sene",
    "Seneca",
    "Sened",
    "Sengele",
    "Senggi",
    "Sengo",
    "Sengseng",
    "Senhaja De Srair",
    "Sensi",
    "Sentani",
    "Senthang Chin",
    "Sentinel",
    "Sepa (Indonesia)",
    "Sepa (Papua New Guinea)",
    "Sepik Iwam",
    "Sera",
    "Seraiki",
    "Sere",
    "Serer",
    "Seri",
    "Serili",
    "Seroa",
    "Serrano",
    "Seru",
    "Serua",
    "Serudung Murut",
    "Serui-Laut",
    "Seselwa Creole French",
    "Seta",
    "Setaman",
    "Seti",
    "Settla",
    "Severn Ojibwa",
    "Sewa Bay",
    "Seze",
    "Sha",
    "Shabak",
    "Shabo",
    "Shahmirzadi",
    "Shahrudi",
    "Shall-Zwall",
    "Shama-Sambuga",
    "Shamang",
    "Shambala",
    "Shan",
    "Shanenawa",
    "Shanga",
    "Shanghainese",
    "Sharanahua",
    "Shark Bay",
    "Sharwa",
    "Shasta",
    "Shatt",
    "Shau",
    "Shawnee",
    "She",
    "Shehri",
    "Shekhawati",
    "Shekkacho",
    "Sheko",
    "Shelta",
    "Shempire Senoufo",
    "Shendu",
    "Sheni",
    "Sherbro",
    "Sherdukpen",
    "Sherpa",
    "Sheshi Kham",
    "Shi",
    "Shihhi Arabic",
    "Shiki",
    "Shilluk",
    "Shina",
    "Shinabo",
    "Shipibo-Conibo",
    "Shixing",
    "Sholaga",
    "Shom Peng",
    "Shona",
    "Shoo-Minda-Nye",
    "Shor",
    "Shoshoni",
    "Shua",
    "Shuadit",
    "Shuar",
    "Shubi",
    "Shughni",
    "Shumashti",
    "Shumcho",
    "Shuswap",
    "Shuwa-Zamani",
    "Shwai",
    "Shwe Palaung",
    "Sialum",
    "Siamou",
    "Sian",
    "Siane",
    "Siang",
    "Siar-Lak",
    "Siawi",
    "Sibe",
    "Siberian Tatar",
    "Sibu Melanau",
    "Sicanian",
    "Sicel",
    "Sichuan Yi",
    "Sicilian",
    "Siculo Arabic",
    "Sidamo",
    "Sidetic",
    "Sie",
    "Sierra de Ju\u00e1rez Zapotec",
    "Sierra Leone Sign Language",
    "Sierra Negra Nahuatl",
    "Sighu",
    "Sihan",
    "Sihuas Ancash Quechua",
    "Sika",
    "Sikaiana",
    "Sikaritai",
    "Sikiana",
    "Sikkimese",
    "Siksika",
    "Sikule",
    "Sila",
    "Silacayoapan Mixtec",
    "Sileibi",
    "Silesian",
    "Silimo",
    "Siliput",
    "Silopi",
    "Silt'e",
    "Simaa",
    "Simba",
    "Simbali",
    "Simbari",
    "Simbo",
    "Simeku",
    "Simeulue",
    "Simte",
    "Sinagen",
    "Sinasina",
    "Sinaugoro",
    "Sindarin",
    "Sindhi",
    "Sindhi Bhil",
    "Sindihui Mixtec",
    "Singa",
    "Singapore Sign Language",
    "Singpho",
    "Sinicahua Mixtec",
    "Sininkere",
    "Sinsauru",
    "Sinte Romani",
    "Sinyar",
    "Sio",
    "Siona",
    "Sipacapense",
    "Sira",
    "Siraya",
    "Sirenik Yupik",
    "Siri",
    "Siriano",
    "Sirion\u00f3",
    "Sirmauri",
    "Siroi",
    "Sissala",
    "Sissano",
    "Siuslaw",
    "Sivandi",
    "Siwai",
    "Siwi",
    "Siwu",
    "Siyin Chin",
    "Skagit",
    "Skalvian",
    "Skepi Creole Dutch",
    "Skolt Sami",
    "Skou",
    "Slave (Athapascan)",
    "Slavomolisano",
    "Slovak",
    "Slovakian Sign Language",
    "Slovenian",
    "Sm\u00e4rky Kanum",
    "Small Flowery Miao",
    "Snohomish",
    "So (Democratic Republic of Congo)",
    "So'a",
    "Sobei",
    "Sochiapam Chinantec",
    "Soga",
    "Sogdian",
    "Soi",
    "Sok",
    "Sokoro",
    "Solano",
    "Soli",
    "Solong",
    "Solos",
    "Som",
    "Somali",
    "Somba-Siawari",
    "Somrai",
    "Somray",
    "Somyev",
    "Sonaga",
    "Sonde",
    "Songa",
    "Songe",
    "Songlai Chin",
    "Songo",
    "Songomeno",
    "Songoora",
    "Sonha",
    "Sonia",
    "Soninke",
    "Sonsorol",
    "Soo",
    "Sop",
    "Soqotri",
    "Sora",
    "Sori-Harengan",
    "Sorkhei",
    "Sorothaptic",
    "Sorsogon Ayta",
    "Sos Kundi",
    "Sota Kanum",
    "Sou",
    "South African Sign Language",
    "South Awyu",
    "South Azerbaijani",
    "South Bolivian Quechua",
    "South Central Banda",
    "South Central Dinka",
    "South Efate",
    "South Fali",
    "South Giziga",
    "South Lembata",
    "South Levantine Arabic",
    "South Marquesan",
    "South Muyu",
    "South Ndebele",
    "South Nuaulu",
    "South Picene",
    "South Slavey",
    "South Tairora",
    "South Ucayali Ash\u00e9ninka",
    "South Watut",
    "South West Bay",
    "Southeast Ambrym",
    "Southeast Babar",
    "Southeast Ijo",
    "Southeast Pashayi",
    "Southeastern Dinka",
    "Southeastern Ixtl\u00e1n Zapotec",
    "Southeastern Kolami",
    "Southeastern Nochixtl\u00e1n Mixtec",
    "Southeastern Pomo",
    "Southeastern Puebla Nahuatl",
    "Southeastern Tarahumara",
    "Southeastern Tepehuan",
    "Southern Alta",
    "Southern Altai",
    "Southern Amami-Oshima",
    "Southern Aymara",
    "Southern Bai",
    "Southern Balochi",
    "Southern Betsimisaraka Malagasy",
    "Southern Binukidnon",
    "Southern Birifor",
    "Southern Bobo Madar\u00e9",
    "Southern Bontok",
    "Southern Carrier",
    "Southern Catanduanes Bikol",
    "Southern Conchucos Ancash Quechua",
    "Southern Dagaare",
    "Southern Dong",
    "Southern East Cree",
    "Southern Ghale",
    "Southern Gondi",
    "Southern Grebo",
    "Southern Guiyang Hmong",
    "Southern Haida",
    "Southern Hindko",
    "Southern Kalapuya",
    "Southern Kalinga",
    "Southern Kisi",
    "Southern Kiwai",
    "Southern Kurdish",
    "Southern Lolopo",
    "Southern Luri",
    "Southern Ma'di",
    "Southern Mashan Hmong",
    "Southern Mnong",
    "Southern Muji",
    "Southern Nago",
    "Southern Nambiku\u00e1ra",
    "Southern Ngbandi",
    "Southern Nicobarese",
    "Southern Nisu",
    "Southern Nuni",
    "Southern Ohlone",
    "Southern One",
    "Southern Pame",
    "Southern Pashto",
    "Southern Pastaza Quechua",
    "Southern Pomo",
    "Southern Puebla Mixtec",
    "Southern Puget Sound Salish",
    "Southern Pumi",
    "Southern Qiandong Miao",
    "Southern Qiang",
    "Southern Rengma Naga",
    "Southern Rincon Zapotec",
    "Southern Roglai",
    "Southern Sama",
    "Southern Sami",
    "Southern Samo",
    "Southern Sierra Miwok",
    "Southern Sorsoganon",
    "Southern Subanen",
    "Southern Thai",
    "Southern Tiwa",
    "Southern Toussian",
    "Southern Tujia",
    "Southern Tutchone",
    "Southern Uzbek",
    "Southern Yamphu",
    "Southern Yukaghir",
    "Southwest Gbaya",
    "Southwest Palawano",
    "Southwest Pashayi",
    "Southwest Tanna",
    "Southwestern Bontok",
    "Southwestern Dinka",
    "Southwestern Fars",
    "Southwestern Guiyang Hmong",
    "Southwestern Huishui Hmong",
    "Southwestern Nisu",
    "Southwestern Tamang",
    "Southwestern Tarahumara",
    "Southwestern Tepehuan",
    "Southwestern Tlaxiaco Mixtec",
    "Sowa",
    "Sowanda",
    "Soyaltepec Mazatec",
    "Soyaltepec Mixtec",
    "Spanish Sign Language",
    "Spiti Bhoti",
    "Spokane",
    "Squamish",
    "Sranan Tongo",
    "Sri Lankan Creole Malay",
    "Sri Lankan Sign Language",
    "Standard Arabic",
    "Standard Estonian",
    "Standard Latvian",
    "Standard Malay",
    "Standard Moroccan Tamazight",
    "Stellingwerfs",
    "Stod Bhoti",
    "sTodsde",
    "Stoney",
    "Straits Salish",
    "Suabo",
    "Suarmin",
    "Suau",
    "Suba",
    "Suba-Simbiti",
    "Subi",
    "Subiya",
    "Subtiaba",
    "Sudanese Arabic",
    "Sudanese Creole Arabic",
    "Sudest",
    "Sudovian",
    "Suena",
    "Suga",
    "Suganga",
    "Sugut Dusun",
    "Sui",
    "Suki",
    "Suku",
    "Sukuma",
    "Sukur",
    "Sukurum",
    "Sula",
    "Sulka",
    "Sulod",
    "Suma",
    "Sumariup",
    "Sumau",
    "Sumbawa",
    "Sumbwa",
    "Sumerian",
    "Sumi Naga",
    "Sumtu Chin",
    "Sunam",
    "Sundanese",
    "Sunwar",
    "Suoy",
    "Supyire Senoufo",
    "Sur",
    "Surbakhal",
    "Surgujia",
    "Suri",
    "Surigaonon",
    "Surjapuri",
    "Sursurunga",
    "Suru\u00ed",
    "Suru\u00ed Do Par\u00e1",
    "Suruah\u00e1",
    "Surubu",
    "Susquehannock",
    "Susu",
    "Susuami",
    "Suundi",
    "Suwawa",
    "Suy\u00e1",
    "Svan",
    "Swabian",
    "Swampy Cree",
    "Swati",
    "Swedish Sign Language",
    "Swiss German",
    "Swiss-French Sign Language",
    "Swiss-German Sign Language",
    "Swiss-Italian Sign Language",
    "Swo",
    "Syenara Senoufo",
    "Sylheti",
    "Syriac",
    "T'en",
    "T\u00e0y",
    "T\u00e0y Sa Pa",
    "T\u00e0y Tac",
    "T\u00e9\u00e9n",
    "T\u00fcbatulabal",
    "Ta'izzi-Adeni Arabic",
    "Taabwa",
    "Tabaa Zapotec",
    "Tabaru",
    "Tabasco Chontal",
    "Tabasco Nahuatl",
    "Tabasco Zoque",
    "Tabassaran",
    "Tabla",
    "Tabo",
    "Tabriak",
    "Tacahua Mixtec",
    "Tacana",
    "Tachawit",
    "Tachelhit",
    "Tachoni",
    "Tadaksahak",
    "Tadyawan",
    "Tae'",
    "Tafi",
    "Tagabawa",
    "Tagakaulo",
    "Tagal Murut",
    "Tagalaka",
    "Tagargrent",
    "Tagbanwa",
    "Tagbu",
    "Tagdal",
    "Tagin",
    "Tagish",
    "Tagoi",
    "Tagwana Senoufo",
    "Tahaggart Tamahaq",
    "Tahitian",
    "Tahltan",
    "Tai",
    "Tai D\u00f3n",
    "Tai Daeng",
    "Tai Dam",
    "Tai Do",
    "Tai Hang Tong",
    "Tai Hongjin",
    "Tai Laing",
    "Tai Loi",
    "Tai Long",
    "Tai M\u00e8ne",
    "Tai N\u00fca",
    "Tai Pao",
    "Tai Thanh",
    "Tai Ya",
    "Taiap",
    "Taikat",
    "Tainae",
    "Taino",
    "Tairaha",
    "Tairuma",
    "Taita",
    "Taiwan Sign Language",
    "Taje",
    "Tajik",
    "Tajiki Arabic",
    "Tajio",
    "Tajuasohn",
    "Takelma",
    "Takestani",
    "Takia",
    "Takua",
    "Takuu",
    "Takwane",
    "Tal",
    "Tala",
    "Talaud",
    "Taliabu",
    "Talieng",
    "Talinga-Bwisi",
    "Talise",
    "Talodi",
    "Taloki",
    "Talondo'",
    "Talossan",
    "Talu",
    "Talysh",
    "Tama (Chad)",
    "Tama (Colombia)",
    "Tamagario",
    "Taman (Indonesia)",
    "Taman (Myanmar)",
    "Tamanaku",
    "Tamashek",
    "Tamasheq",
    "Tamazola Mixtec",
    "Tambas",
    "Tambora",
    "Tambotalo",
    "Tambunan Dusun",
    "Tami",
    "Tamki",
    "Tamnim Citak",
    "Tampias Lobu",
    "Tampuan",
    "Tampulma",
    "Tanacross",
    "Tanahmerah",
    "Tanaina",
    "Tanapag",
    "Tandaganon",
    "Tandia",
    "Tandroy-Mahafaly Malagasy",
    "Tanema",
    "Tangale",
    "Tangchangya",
    "Tanggu",
    "Tangkhul Naga (India)",
    "Tangkhul Naga (Myanmar)",
    "Tangko",
    "Tanglang",
    "Tangoa",
    "Tanguat",
    "Tangut",
    "Tanimbili",
    "Tanimuca-Retuar\u00e3",
    "Tanjijili",
    "Tanosy Malagasy",
    "Tanudan Kalinga",
    "Tanzanian Sign Language",
    "Tapeba",
    "Tapei",
    "Tapiet\u00e9",
    "Tapirap\u00e9",
    "Tarao Naga",
    "Tareng",
    "Tariana",
    "Tarifit",
    "Tarok",
    "Taroko",
    "Tarpia",
    "Tartessian",
    "Tasawaq",
    "Tase Naga",
    "Tasmanian",
    "Tasmate",
    "Tataltepec Chatino",
    "Tatana",
    "Tatar",
    "Tatuyo",
    "Tauade",
    "Taulil",
    "Taungyo",
    "Taupota",
    "Tause",
    "Taushiro",
    "Tausug",
    "Tauya",
    "Taveta",
    "Tavoyan",
    "Tavringer Romani",
    "Tawala",
    "Tawallammat Tamajaq",
    "Tawand\u00ea",
    "Tawang Monpa",
    "Tawara",
    "Taworta",
    "Tawoyan",
    "Tawr Chin",
    "Tay Boi",
    "Tay Khang",
    "Tayabas Ayta",
    "Tayart Tamajeq",
    "Tayo",
    "Taznatit",
    "Tboli",
    "Tchitchege",
    "Tchumbuli",
    "Te'un",
    "Teanu",
    "Tebul Sign Language",
    "Tebul Ure Dogon",
    "Tecpatl\u00e1n Totonac",
    "Tedaga",
    "Tedim Chin",
    "Tee",
    "Tefaro",
    "Tegali",
    "Tehit",
    "Tehuelche",
    "Tejalapan Zapotec",
    "Teke-Ebo",
    "Teke-Fuumu",
    "Teke-Kukuya",
    "Teke-Laali",
    "Teke-Nzikou",
    "Teke-Tege",
    "Teke-Tsaayi",
    "Teke-Tyee",
    "Tektiteko",
    "Tela-Masbuar",
    "Telefol",
    "Teluti",
    "Tem",
    "Temacine Tamazight",
    "Temascaltepec Nahuatl",
    "Temb\u00e9",
    "Tembo (Kitembo)",
    "Tembo (Motembo)",
    "Teme",
    "Temein",
    "Temi",
    "Temiar",
    "Temoaya Otomi",
    "Temoq",
    "Tempasuk Dusun",
    "Temuan",
    "Tena Lowland Quichua",
    "Tenango Otomi",
    "Tene Kan Dogon",
    "Tenggarong Kutai Malay",
    "Tengger",
    "Tenharim",
    "Tenino",
    "Tenis",
    "Tennet",
    "Teop",
    "Teor",
    "Tepecano",
    "Tepetotutla Chinantec",
    "Tepeuxila Cuicatec",
    "Tepinapa Chinantec",
    "Tepo Krumen",
    "Ter Sami",
    "Tera",
    "Terebu",
    "Terei",
    "Tereno",
    "Teressa",
    "Tereweng",
    "Teribe",
    "Terik",
    "Termanu",
    "Ternate",
    "Ternate\u00f1o",
    "Tesaka Malagasy",
    "Tese",
    "Teshenawa",
    "Teso",
    "Tetela",
    "Tetelcingo Nahuatl",
    "Tetete",
    "Tetum",
    "Tetun Dili",
    "Teutila Cuicatec",
    "Tewa (Indonesia)",
    "Tewa (USA)",
    "Tewe",
    "Texcatepec Otomi",
    "Texistepec Popoluca",
    "Texmelucan Zapotec",
    "Tezoatl\u00e1n Mixtec",
    "Tha",
    "Thachanadan",
    "Thado Chin",
    "Thai Sign Language",
    "Thai Song",
    "Thaiphum Chin",
    "Thakali",
    "Thangal Naga",
    "Thangmi",
    "Thao",
    "Tharaka",
    "Thawa",
    "Thayore",
    "Thaypan",
    "The",
    "Thiin",
    "Tho",
    "Thompson",
    "Thopho",
    "Thracian",
    "Thu Lao",
    "Thudam",
    "Thulung",
    "Thurawal",
    "Thuri",
    "Ti\u00e9fo",
    "Ti\u00e9yaxo Bozo",
    "Tiagbamrin Aizi",
    "Tiale",
    "Tiang",
    "Tibea",
    "Tibetan",
    "Tichurong",
    "Ticuna",
    "Tida\u00e1 Mixtec",
    "Tidikelt Tamazight",
    "Tidong",
    "Tidore",
    "Tiemac\u00e8w\u00e8 Bozo",
    "Tiene",
    "Tifal",
    "Tigak",
    "Tigon Mbembe",
    "Tigre",
    "Tigrinya",
    "Tii",
    "Tijaltepec Mixtec",
    "Tikar",
    "Tikopia",
    "Tilapa Otomi",
    "Tillamook",
    "Tilquiapan Zapotec",
    "Tilung",
    "Tima",
    "Timbe",
    "Timne",
    "Timor Pidgin",
    "Timucua",
    "Timugon Murut",
    "Tinani",
    "Tindi",
    "Tingui-Boto",
    "Tinigua",
    "Tinoc Kallahan",
    "Tinputz",
    "Tippera",
    "Tira",
    "Tirahi",
    "Tiranige Diga Dogon",
    "Tiri",
    "Tiruray",
    "Tita",
    "Titan",
    "Tiv",
    "Tiwa",
    "Tiwi",
    "Tjurruru",
    "Tlachichilco Tepehua",
    "Tlacoapa Me'phaa",
    "Tlacoatzintepec Chinantec",
    "Tlacolulita Zapotec",
    "Tlahuitoltepec Mixe",
    "Tlamacazapa Nahuatl",
    "Tlazoyaltepec Mixtec",
    "Tlingit",
    "To",
    "To'abaita",
    "Toaripi",
    "Toba",
    "Toba-Maskoy",
    "Tobagonian Creole English",
    "Tobanga",
    "Tobati",
    "Tobelo",
    "Tobian",
    "Tobilung",
    "Tobo",
    "Tocantins Asurini",
    "Tocho",
    "Toda",
    "Todrah",
    "Tofanma",
    "Tofin Gbe",
    "Togbo-Vara Banda",
    "Togoyo",
    "Tohono O'odham",
    "Tojolabal",
    "Tok Pisin",
    "Tokano",
    "Tokelau",
    "Tokharian A",
    "Tokharian B",
    "Toku-No-Shima",
    "Tol",
    "Tolaki",
    "Tolomako",
    "Tolowa",
    "Toma",
    "Tomadino",
    "Tombelala",
    "Tombonuo",
    "Tombulu",
    "Tomedes",
    "Tomini",
    "Tommo So Dogon",
    "Tomo Kan Dogon",
    "Tomoip",
    "Tondano",
    "Tondi Songway Kiini",
    "Tonga (Nyasa)",
    "Tonga (Thailand)",
    "Tonga (Tonga Islands)",
    "Tonga (Zambia)",
    "Tongwe",
    "Tonjon",
    "Tonkawa",
    "Tonsawang",
    "Tonsea",
    "Tontemboan",
    "Tooro",
    "Topoiyo",
    "Toposa",
    "Tor\u00e1",
    "Toraja-Sa'dan",
    "Toram",
    "Torau",
    "Tornedalen Finnish",
    "Toro",
    "Toro So Dogon",
    "Toro Tegu Dogon",
    "Toromono",
    "Torona",
    "Torres Strait Creole",
    "Torricelli",
    "Torwali",
    "Tosk Albanian",
    "Totela",
    "Toto",
    "Totoli",
    "Totomachapan Zapotec",
    "Totontepec Mixe",
    "Totoro",
    "Touo",
    "Toura (C\u00f4te d'Ivoire)",
    "Toura (Papua New Guinea)",
    "Towei",
    "Transalpine Gaulish",
    "Traveller Danish",
    "Traveller Norwegian",
    "Traveller Scottish",
    "Tregami",
    "Trememb\u00e9",
    "Tri\u00f3",
    "Trieng",
    "Trimuris",
    "Tring",
    "Tringgus-Sembaan Bidayuh",
    "Trinidad and Tobago Sign Language",
    "Trinidadian Creole English",
    "Trinitario",
    "Truk\u00e1",
    "Trumai",
    "Ts'\u00fcn-Lao",
    "Tsaangi",
    "Tsakhur",
    "Tsakonian",
    "Tsakwambo",
    "Tsamai",
    "Tsat",
    "Tseku",
    "Tsetsaut",
    "Tshangla",
    "Tsikimba",
    "Tsiman\u00e9",
    "Tsimihety Malagasy",
    "Tsimshian",
    "Tsishingini",
    "Tso",
    "Tsoa",
    "Tsogo",
    "Tsonga",
    "Tsotso",
    "Tsou",
    "Tsum",
    "Tsuvadi",
    "Tsuvan",
    "Tswa",
    "Tswapong",
    "Tu",
    "Tuamotuan",
    "Tubar",
    "Tucano",
    "Tugen",
    "Tugun",
    "Tugutil",
    "Tukang Besi North",
    "Tukang Besi South",
    "Tuki",
    "Tukpa",
    "Tukudede",
    "Tukumanf\u00e9d",
    "Tula",
    "Tulehu",
    "Tulishi",
    "Tulu",
    "Tulu-Bohuai",
    "Tuma-Irumu",
    "Tumak",
    "Tumari Kanuri",
    "Tumbuka",
    "Tumi",
    "Tumleo",
    "Tumshuqese",
    "Tumtum",
    "Tumulung Sisaala",
    "Tumzabt",
    "Tundra Enets",
    "Tunen",
    "Tungag",
    "Tunggare",
    "Tunia",
    "Tunica",
    "Tunisian Arabic",
    "Tunisian Sign Language",
    "Tunjung",
    "Tunni",
    "Tunzu",
    "Tuotomb",
    "Tup\u00ed",
    "Tupar\u00ed",
    "Tupinamb\u00e1",
    "Tupinikin",
    "Tupuri",
    "Turaka",
    "Turi",
    "Turiw\u00e1ra",
    "Turka",
    "Turkana",
    "Turkic Khalaj",
    "Turkish Sign Language",
    "Turkmen",
    "Turks And Caicos Creole English",
    "Turoyo",
    "Turumsa",
    "Turung",
    "Tuscarora",
    "Tutelo",
    "Tutong",
    "Tutsa Naga",
    "Tutuba",
    "Tututepec Mixtec",
    "Tututni",
    "Tuvalu",
    "Tuvinian",
    "Tuwali Ifugao",
    "Tuwari",
    "Tuwuli",
    "Tux\u00e1",
    "Tuxin\u00e1wa",
    "Tuyuca",
    "Twana",
    "Twendi",
    "Twents",
    "Twi",
    "Tyap",
    "Tyaraity",
    "Tz'utujil",
    "Tzeltal",
    "Tzotzil",
    "U",
    "Uab Meto",
    "Uamu\u00e9",
    "Uare",
    "Ubaghara",
    "Ubang",
    "Ubi",
    "Ubir",
    "Ubykh",
    "Ucayali-Yur\u00faa Ash\u00e9ninka",
    "Uda",
    "Udi",
    "Udihe",
    "Udmurt",
    "Uduk",
    "Ufim",
    "Ugandan Sign Language",
    "Ugaritic",
    "Ughele",
    "Ugong",
    "Uhami",
    "Uighur",
    "Uisai",
    "Ujir",
    "Ukaan",
    "Ukhwejo",
    "Ukit",
    "Ukpe-Bayobiri",
    "Ukpet-Ehom",
    "Ukrainian",
    "Ukrainian Sign Language",
    "Ukue",
    "Ukuriguma",
    "Ukwa",
    "Ukwuani-Aboh-Ndoni",
    "Ulau-Suain",
    "Ulch",
    "Ulithian",
    "Ullatan",
    "Ulukwumi",
    "Ulumanda'",
    "Ulwa",
    "Uma",
    "Uma' Lasan",
    "Uma' Lung",
    "Umanakaina",
    "Umatilla",
    "Umbindhamu",
    "Umbrian",
    "Umbu-Ungu",
    "Umbugarla",
    "Umbundu",
    "Umbuygamu",
    "Ume Sami",
    "Umeda",
    "Umiida",
    "Umiray Dumaget Agta",
    "Umon",
    "Umot\u00edna",
    "Umpila",
    "Una",
    "Unami",
    "Uncoded languages",
    "Unde Kaili",
    "Undetermined",
    "Uneapa",
    "Uneme",
    "Unggaranggu",
    "Unggumi",
    "Unserdeutsch",
    "Unua",
    "Unubahe",
    "Uokha",
    "Upper Chehalis",
    "Upper Grand Valley Dani",
    "Upper Guinea Crioulo",
    "Upper Kinabatangan",
    "Upper Kuskokwim",
    "Upper Necaxa Totonac",
    "Upper Saxon",
    "Upper Sorbian",
    "Upper Ta'oih",
    "Upper Tanana",
    "Upper Taromi",
    "Upper Umpqua",
    "Ura (Papua New Guinea)",
    "Ura (Vanuatu)",
    "Uradhi",
    "Urak Lawoi'",
    "Urali",
    "Urapmin",
    "Urarina",
    "Urartian",
    "Urat",
    "Urhobo",
    "Uri",
    "Urigina",
    "Urim",
    "Urimo",
    "Uripiv-Wala-Rano-Atchin",
    "Urningangg",
    "Uru",
    "Uru-Eu-Wau-Wau",
    "Uru-Pa-In",
    "Uruangnirin",
    "Uruava",
    "Urub\u00fa-Kaapor",
    "Urub\u00fa-Kaapor Sign Language",
    "Uruguayan Sign Language",
    "Urum",
    "Urumi",
    "Usaghade",
    "Usan",
    "Usarufa",
    "Ushojo",
    "Usila Chinantec",
    "Usku",
    "Uspanteco",
    "Usui",
    "ut-Ma'in",
    "Utarmbung",
    "Ute-Southern Paiute",
    "Utu",
    "Uvbie",
    "Uya",
    "Uyajitaya",
    "Uzbek",
    "Uzbeki Arabic",
    "Uzekwe",
    "V\u00f5ro",
    "Vaagri Booli",
    "Vafsi",
    "Vaghat-Ya-Bijim-Legeri",
    "Vaghri",
    "Vaghua",
    "Vagla",
    "Vai",
    "Vaiphei",
    "Vale",
    "Valencian Sign Language",
    "Valle Nacional Chinantec",
    "Valley Maidu",
    "Valman",
    "Valpei",
    "Vamale",
    "Vame",
    "Vandalic",
    "Vangunu",
    "Vanimo",
    "Vano",
    "Vanuma",
    "Vao",
    "Varhadi-Nagpuri",
    "Varisi",
    "Varli",
    "Vasavi",
    "Vasekela Bushman",
    "Veddah",
    "Vehes",
    "Veluws",
    "Vemgo-Mabas",
    "Venda",
    "Venetian",
    "Venetic",
    "Venezuelan Sign Language",
    "Vengo",
    "Venture\u00f1o",
    "Veps",
    "Vera'a",
    "Vestinian",
    "Vidunda",
    "Viemo",
    "Vietnamese",
    "Vilela",
    "Vili",
    "Villa Viciosa Agta",
    "Vincentian Creole English",
    "Vinmavis",
    "Vinza",
    "Virgin Islands Creole English",
    "Vishavan",
    "Viti",
    "Vitou",
    "Vitu",
    "Vlaams",
    "Vlaamse Gebarentaal",
    "Vlax Romani",
    "Volap\u00fck",
    "Volscian",
    "Vono",
    "Voro",
    "Votic",
    "Vumbu",
    "Vunapu",
    "Vunjo",
    "Vur\u00ebs",
    "Vute",
    "Vwanji",
    "W\u00e1ra",
    "W\u00e3pha",
    "W\u00e8 Northern",
    "W\u00e8 Southern",
    "W\u00e8 Western",
    "Wa",
    "Wa'ema",
    "Waama",
    "Waamwang",
    "Waata",
    "Wab",
    "Wabo",
    "Waboda",
    "Waci Gbe",
    "Wadaginam",
    "Waddar",
    "Wadi Wadi",
    "Wadikali",
    "Wadiyara Koli",
    "Wadjabangayi",
    "Wadjiginy",
    "Wadjigu",
    "Wae Rana",
    "Waffa",
    "Wagawaga",
    "Wagaya",
    "Wagdi",
    "Wageman",
    "Wagi",
    "Wahau Kayan",
    "Wahau Kenyah",
    "Wahgi",
    "Waigali",
    "Waigeo",
    "Wailaki",
    "Wailapa",
    "Waima",
    "Waima'a",
    "Waimaha",
    "Waimiri-Atroari",
    "Waioli",
    "Waiwai",
    "Waja",
    "Wajarri",
    "Wajuk",
    "Waka",
    "Wakabunga",
    "Wakawaka",
    "Wakde",
    "Wakhi",
    "Wakon\u00e1",
    "Wala",
    "Walak",
    "Walangama",
    "Wali (Ghana)",
    "Wali (Sudan)",
    "Waling",
    "Walio",
    "Walla Walla",
    "Wallisian",
    "Walloon",
    "Walmajarri",
    "Walser",
    "Walungge",
    "Wamas",
    "Wambaya",
    "Wambon",
    "Wambule",
    "Wamey",
    "Wamin",
    "Wampanoag",
    "Wampar",
    "Wampur",
    "Wan",
    "Wan\u00e9",
    "Wanambre",
    "Wanap",
    "Wancho Naga",
    "Wanda",
    "Wandala",
    "Wandamen",
    "Wandarang",
    "Wandji",
    "Waneci",
    "Wanga",
    "Wangaaybuwan-Ngiyambaa",
    "Wanggamala",
    "Wangganguru",
    "Wanggom",
    "Wangkayutyuru",
    "Wangkumara",
    "Wanman",
    "Wannu",
    "Wano",
    "Wantoat",
    "Wanukaka",
    "Wanyi",
    "Waorani",
    "Wapan",
    "Wapishana",
    "Wappo",
    "War-Jaintia",
    "Wara",
    "Warao",
    "Warapu",
    "Waray (Australia)",
    "Waray (Philippines)",
    "Wardaman",
    "Wardandi",
    "Warduji",
    "Warembori",
    "Wares",
    "Waris",
    "Waritai",
    "Wariyangga",
    "Warji",
    "Warkay-Bipim",
    "Warlmanpa",
    "Warlpiri",
    "Warluwara",
    "Warnang",
    "Waropen",
    "Warrgamay",
    "Warrwa",
    "Waru",
    "Warumungu",
    "Waruna",
    "Warungu",
    "Warwar Feni",
    "Wasa",
    "Wasco-Wishram",
    "Wasembo",
    "Washo",
    "Waskia",
    "Wasu",
    "Watakataui",
    "Watam",
    "Wathawurrung",
    "Watiwa",
    "Watubela",
    "Waube",
    "Waur\u00e1",
    "Wauyai",
    "Wawa",
    "Wawonii",
    "Waxianghua",
    "Wayampi",
    "Wayana",
    "Wayanad Chetti",
    "Wayor\u00f3",
    "Wayu",
    "Wayuu",
    "Wedau",
    "Weh",
    "Wejewa",
    "Weliki",
    "Welsh",
    "Welsh Romani",
    "Wemale",
    "Wemba Wemba",
    "Weme Gbe",
    "Wergaia",
    "Weri",
    "Wersing",
    "West Albay Bikol",
    "West Ambae",
    "West Berawan",
    "West Central Banda",
    "West Central Oromo",
    "West Coast Bajau",
    "West Damar",
    "West Goodenough",
    "West Kewa",
    "West Lembata",
    "West Makian",
    "West Masela",
    "West Tarangan",
    "West Uvean",
    "West Yugur",
    "West-Central Limba",
    "Western Abnaki",
    "Western Apache",
    "Western Arrarnta",
    "Western Balochi",
    "Western Bolivian Guaran\u00ed",
    "Western Bru",
    "Western Bukidnon Manobo",
    "Western Cham",
    "Western Dani",
    "Western Durango Nahuatl",
    "Western Fijian",
    "Western Frisian",
    "Western Gurung",
    "Western Highland Chatino",
    "Western Highland Purepecha",
    "Western Huasteca Nahuatl",
    "Western Juxtlahuaca Mixtec",
    "Western Kanjobal",
    "Western Karaboro",
    "Western Katu",
    "Western Kayah",
    "Western Keres",
    "Western Krahn",
    "Western Lalu",
    "Western Lawa",
    "Western Magar",
    "Western Maninkakan",
    "Western Mari",
    "Western Mashan Hmong",
    "Western Meohang",
    "Western Muria",
    "Western Neo-Aramaic",
    "Western Niger Fulfulde",
    "Western Ojibwa",
    "Western Panjabi",
    "Western Parbate Kham",
    "Western Penan",
    "Western Sisaala",
    "Western Subanon",
    "Western Tamang",
    "Western Tawbuid",
    "Western Totonac",
    "Western Tunebo",
    "Western Xiangxi Miao",
    "Western Xwla Gbe",
    "Western Yiddish",
    "Westphalien",
    "Wetamut",
    "Wewaw",
    "Weyto",
    "White Gelao",
    "White Lachi",
    "Whitesands",
    "Wiarumus",
    "Wich\u00ed Lhamt\u00e9s G\u00fcisnay",
    "Wich\u00ed Lhamt\u00e9s Nocten",
    "Wich\u00ed Lhamt\u00e9s Vejoz",
    "Wichita",
    "Wik-Epa",
    "Wik-Iiyanh",
    "Wik-Keyangan",
    "Wik-Me'anha",
    "Wik-Mungkan",
    "Wik-Ngathana",
    "Wikalkan",
    "Wikngenchera",
    "Wilawila",
    "Wintu",
    "Winy\u00e9",
    "Wipi",
    "Wiradhuri",
    "Wiraf\u00e9d",
    "Wirangu",
    "Wiru",
    "Wiyot",
    "Woccon",
    "Wogamusin",
    "Wogeo",
    "Woi",
    "Woiwurrung",
    "Wojenaka",
    "Wolane",
    "Wolani",
    "Wolaytta",
    "Woleaian",
    "Wolio",
    "Wolof",
    "Wom (Nigeria)",
    "Wom (Papua New Guinea)",
    "Womo",
    "Wongo",
    "Woods Cree",
    "Woria",
    "Worimi",
    "Worodougou",
    "Worrorra",
    "Wotapuri-Katarqalai",
    "Wotjobaluk",
    "Wotu",
    "Woun Meu",
    "Written Oirat",
    "Wu Chinese",
    "Wuding-Luquan Yi",
    "Wudu",
    "Wuliwuli",
    "Wulna",
    "Wumboko",
    "Wumbvu",
    "Wumeng Nasu",
    "Wunai Bunu",
    "Wunambal",
    "Wunumara",
    "Wurrugu",
    "Wusa Nasu",
    "Wushi",
    "Wusi",
    "Wutung",
    "Wutunhua",
    "Wuvulu-Aua",
    "Wuzlam",
    "Wyandot",
    "Wymysorys",
    "X\u00e2r\u00e2c\u00f9\u00f9",
    "X\u00e2r\u00e2gur\u00e8",
    "Xaasongaxango",
    "Xadani Zapotec",
    "Xakriab\u00e1",
    "Xamtanga",
    "Xanagu\u00eda Zapotec",
    "Xav\u00e1nte",
    "Xer\u00e9nte",
    "Xet\u00e1",
    "Xiang Chinese",
    "Xibe",
    "Xicotepec De Ju\u00e1rez Totonac",
    "Xinca",
    "Xing\u00fa Asurin\u00ed",
    "Xipaya",
    "Xipin\u00e1wa",
    "Xiri",
    "Xiri\u00e2na",
    "Xishanba Lalo",
    "Xokleng",
    "Xukur\u00fa",
    "Xwela Gbe",
    "Y\u00e1mana",
    "Yaaku",
    "Yaba\u00e2na",
    "Yabarana",
    "Yabem",
    "Yaben",
    "Yabong",
    "Yabula Yabula",
    "Yace",
    "Yaeyama",
    "Yafi",
    "Yagara",
    "Yagaria",
    "Yagnobi",
    "Yagomi",
    "Yagua",
    "Yagwoia",
    "Yahadian",
    "Yahang",
    "Yahuna",
    "Yaka (Central African Republic)",
    "Yaka (Congo)",
    "Yaka (Democratic Republic of Congo)",
    "Yakaikeke",
    "Yakama",
    "Yakan",
    "Yakha",
    "Yakoma",
    "Yakut",
    "Yal\u00e1lag Zapotec",
    "Yala",
    "Yalahatan",
    "Yalakalore",
    "Yalarnnga",
    "Yale",
    "Yaleba",
    "Yalunka",
    "Yamap",
    "Yamba",
    "Yambes",
    "Yambeta",
    "Yamdena",
    "Yameo",
    "Yami",
    "Yaminahua",
    "Yamna",
    "Yamongeri",
    "Yamphu",
    "Yan-nhangu",
    "Yana",
    "Yanahuanca Pasco Quechua",
    "Yanda",
    "Yanda Dom Dogon",
    "Yandjibara",
    "Yandruwandha",
    "Yanesha'",
    "Yang Zhuang",
    "Yangben",
    "Yangho",
    "Yangkam",
    "Yangman",
    "Yango",
    "Yangulam",
    "Yangum Dey",
    "Yangum Gel",
    "Yangum Mon",
    "Yankunytjatjara",
    "Yanom\u00e1mi",
    "Yanomam\u00f6",
    "Yansi",
    "Yanyuwa",
    "Yao",
    "Yaosakor Asmat",
    "Yaour\u00e9",
    "Yapese",
    "Yapunda",
    "Yaqay",
    "Yaqui",
    "Yar\u00ed",
    "Yarawata",
    "Yardliyawarra",
    "Yareba",
    "Yareni Zapotec",
    "Yarluyandi",
    "Yarsun",
    "Yasa",
    "Yassic",
    "Yatay",
    "Yatee Zapotec",
    "Yatzachi Zapotec",
    "Yau (Morobe Province)",
    "Yau (Sandaun Province)",
    "Yaul",
    "Yauma",
    "Yaur",
    "Yautepec Zapotec",
    "Yauyos Quechua",
    "Yavitero",
    "Yawa",
    "Yawalapit\u00ed",
    "Yawanawa",
    "Yawarawarga",
    "Yaweyuha",
    "Yawijibaya",
    "Yawiyo",
    "Yawuru",
    "Yaygir",
    "Yazgulyam",
    "Yecuatla Totonac",
    "Yei",
    "Yekhee",
    "Yekora",
    "Yela",
    "Yele",
    "Yelmek",
    "Yelogu",
    "Yemba",
    "Yemsa",
    "Yendang",
    "Yeni",
    "Yeniche",
    "Yerakai",
    "Yeretuar",
    "Yerong",
    "Yerukula",
    "Yessan-Mayo",
    "Yetfa",
    "Yevanic",
    "Yeyi",
    "Yiddish",
    "Yiddish Sign Language",
    "Yidgha",
    "Yidiny",
    "Yil",
    "Yimas",
    "Yimchungru Naga",
    "Yinbaw Karen",
    "Yinchia",
    "Yindjibarndi",
    "Yindjilandji",
    "Yine",
    "Yinggarda",
    "Yinhawangka",
    "Yiningayi",
    "Yintale Karen",
    "Yinwum",
    "Yir Yoront",
    "Yirandali",
    "Yirrk-Mel",
    "Yis",
    "Yitha Yitha",
    "Yiwom",
    "Yoba",
    "Yocobou\u00e9 Dida",
    "Yogad",
    "Yoidik",
    "Yoke",
    "Yokuts",
    "Yol\u014bu Sign Language",
    "Yola",
    "Yoloxochitl Mixtec",
    "Yom",
    "Yombe",
    "Yonaguni",
    "Yong",
    "Yongbei Zhuang",
    "Yongkom",
    "Yongnan Zhuang",
    "Yopno",
    "Yora",
    "Yoron",
    "Yorta Yorta",
    "Yoruba",
    "Yosond\u00faa Mixtec",
    "Yotti",
    "Youjiang Zhuang",
    "Youle Jinuo",
    "Younuo Bunu",
    "Yout Wam",
    "Yoy",
    "Yuanga",
    "Yucatec Maya Sign Language",
    "Yucateco",
    "Yuchi",
    "Yucua\u00f1e Mixtec",
    "Yucuna",
    "Yug",
    "Yugambal",
    "Yugoslavian Sign Language",
    "Yugul",
    "Yuhup",
    "Yuki",
    "Yukpa",
    "Yukuben",
    "Yulu",
    "Yuqui",
    "Yuracare",
    "Yurok",
    "Yuru",
    "Yurut\u00ed",
    "Yutanduchi Mixtec",
    "Yuwana",
    "Yuyu",
    "Z\u00e1paro",
    "Zaachila Zapotec",
    "Zabana",
    "Zacatepec Chatino",
    "Zacatl\u00e1n-Ahuacatl\u00e1n-Tepetzintla Nahuatl",
    "Zaghawa",
    "Zaiwa",
    "Zakhring",
    "Zambian Sign Language",
    "Zan Gula",
    "Zanaki",
    "Zande (individual language)",
    "Zangskari",
    "Zangwal",
    "Zaniza Zapotec",
    "Zapotec",
    "Zaramo",
    "Zari",
    "Zarma",
    "Zarphatic",
    "Zauzou",
    "Zay",
    "Zayein Karen",
    "Zayse-Zergulla",
    "Zaza",
    "Zazao",
    "Zeem",
    "Zeeuws",
    "Zemba",
    "Zeme Naga",
    "Zemgalian",
    "Zenag",
    "Zenaga",
    "Zenzontepec Chatino",
    "Zerenkel",
    "Zhaba",
    "Zhang-Zhung",
    "Zhire",
    "Zhoa",
    "Zhuang",
    "Zia",
    "Zialo",
    "Zigula",
    "Zimakani",
    "Zimba",
    "Zimbabwe Sign Language",
    "Zinza",
    "Zire",
    "Ziriya",
    "Zizilivakan",
    "Zo'\u00e9",
    "Zokhuo",
    "Zoogocho Zapotec",
    "Zoroastrian Dari",
    "Zotung Chin",
    "Zou",
    "Zulgo-Gemzek",
    "Zumaya",
    "Zumbun",
    "Zuni",
    "Zuojiang Zhuang",
    "Zyphe Chin",
  ],
}

export default LANGUAGES
