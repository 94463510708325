const survey = [
  {
    visibility: 1,
    name: "role",
    questions: [
      {
        question: "role0",
        identifier: "role0",
        order: 0,
        visibility: 1,
        required: 1,
      },
      {
        question: "role1",
        identifier: "role1",
        order: 1,
        visibility: 1,
        required: 0,
      },
      {
        question: "role2",
        identifier: "role2",
        order: 2,
        visibility: 1,
        required: 0,
      },
      {
        question: "role3",
        identifier: "role3",
        order: 3,
        visibility: 1,
        required: 0,
      },
      {
        question: "role4",
        identifier: "role4",
        order: 4,
        visibility: 1,
        required: 0,
      },
      {
        question: "role5",
        identifier: "role5",
        order: 5,
        visibility: 1,
        required: 0,
      },
    ],
  },
  {
    visibility: 1,
    name: "compensation",
    questions: [
      {
        question: "comp0",
        identifier: "comp0",
        order: 0,
        visibility: 1,
        required: 0,
      },
      {
        question: "comp1",
        identifier: "comp1",
        order: 1,
        visibility: 1,
        required: 0,
      },
    ],
  },
  {
    visibility: 0,
    name: "experience",
    questions: [
      {
        question: "exp0",
        identifier: "exp0",
        order: 0,
        visibility: 1,
        required: 0,
      },
      {
        question: "exp1",
        identifier: "exp1",
        order: 1,
        visibility: 1,
        required: 0,
      },
      {
        question: "exp2",
        identifier: "exp2",
        order: 2,
        visibility: 1,
        required: 0,
      },
      {
        question: "exp3",
        identifier: "exp3",
        order: 3,
        visibility: 1,
        required: 0,
      },
      {
        question: "exp4",
        identifier: "exp4",
        order: 4,
        visibility: 1,
        required: 0,
      },
    ],
  },
  {
    visibility: 1,
    name: "background",
    questions: [
      {
        question: "background0",
        identifier: "background0",
        order: 0,
        visibility: 1,
        required: 1,
      },
      {
        question: "background1",
        identifier: "background1",
        order: 1,
        visibility: 1,
        required: 0,
      },
      {
        question: "background2",
        identifier: "background2",
        order: 2,
        visibility: 1,
        required: 1,
      },
      {
        question: "background3",
        identifier: "background3",
        order: 3,
        visibility: 1,
        required: 1,
      },
      {
        question: "background4",
        identifier: "background4",
        order: 4,
        visibility: 1,
        required: 0,
      },
      {
        question: "background5",
        identifier: "background5",
        order: 5,
        visibility: 1,
        required: 1,
      },
      {
        question: "background6",
        identifier: "background6",
        order: 6,
        visibility: 0,
        required: 0,
      },
      {
        question: "background7",
        identifier: "background7",
        order: 7,
        visibility: 1,
        required: 1,
      },
      {
        question: "background8",
        identifier: "background8",
        order: 8,
        visibility: 1,
        required: 1,
      },
      {
        question: "background9",
        identifier: "background9",
        order: 9,
        visibility: 1,
        required: 1,
      },
      {
        question: "background11",
        identifier: "background11",
        order: 10,
        visibility: 1,
        required: 1,
      },
      {
        question: "background12",
        identifier: "background12",
        order: 11,
        visibility: 1,
        required: 1,
      },
    ],
  },
  {
    visibility: 1,
    name: "caredAbout",
    questions: [
      {
        question: "cared0",
        identifier: "cared0",
        order: 0,
        visibility: 1,
        required: 0,
      },
      {
        question: "cared1",
        identifier: "cared1",
        order: 1,
        visibility: 1,
        required: 0,
      },
    ],
  },
]

export default survey