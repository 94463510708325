import React, { useState, useEffect } from "react"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import AutoComplete from "../shared/AutoComplete"
import ANSWERS from "../../constants/Constant"
import LANGUAGES from "../../constants/Languages"
import { FormControl, FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Asterisk from "../../images/asterisk-solid.svg"
import { isMobile } from "mobile-device-detect"
import CustomAnswer from "../shared/CustomAnswer"
import { findQuestion } from "../../util/find"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "25px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
  rate: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "20px",
    width: "150px",
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },

  feedback: {
    border: "1px solid #fff",
    color: "#fff",
    padding: "10px",
    width: "50%",
    marginLeft: "20px",
  },
}))

const BackgroundSection = ({
  prefilledData,
  survey,
  value,
  setValue,
  showError,
  proactive,
  surveySection,
  customQuestions,
  setCustomA,
  customA,
}) => {
  const classes = useStyles()

  const [yearBorn, setYearBorn] = useState(
    value.ageRange || prefilledData?.birthDate || ""
  )
  const [gender, setGender] = useState(
    value.gender || prefilledData?.genders?.map(i => i.name) || []
  )
  const [pronouns, setPronouns] = useState(
    value.pronouns || prefilledData?.pronouns?.map(i => i.name) || []
  )
  const [sexualOrientation, setSexualOrientation] = useState(
    value.sexualOrientation ||
      prefilledData?.sexualOrientations?.map(i => i.name) ||
      []
  )
  const [birthplace, setBirthplace] = useState(
    value.birthPlace || prefilledData?.birthPlace || ""
  )
  const [ethnicity, setEthnicity] = useState(
    value?.ethnicity?.length > 0
      ? value.ethnicity
      : prefilledData?.ethnicities?.find(
          e => e.name === "Mixed race/Multi-ethnic"
        )
      ? prefilledData?.ethnicities
          ?.filter(i => i.name === "Mixed race/Multi-ethnic")
          .map(i => i.name)
      : prefilledData?.ethnicities?.map(i => i.name) || []
  )
  const [ethnicity1, setEthnicity1] = useState(value.ethnicity1 || [])
  const [nationality, setNationality] = useState(
    value.ancestry || prefilledData?.nationalities?.map(i => i.name) || []
  )

  const [veteran, setVeteran] = useState(
    value.veteranStatus || prefilledData?.veteranStatus || ""
  )
  const [disability, setDisablity] = useState(
    value.disabilityStatus || prefilledData?.disabilityStatus || ""
  )
  const [disabilityType, setDisabilityType] = useState(
    value.disabilityType ||
      prefilledData?.disabilityTypes?.map(i => i.name) ||
      []
  )
  const [nativeLang, setNativeLang] = useState(
    value.nativeLanguage || prefilledData?.nativeLanguage || ""
  )
  const [secondarayLang, setSecondaryLang] = useState(
    value.secondaryLanguage || prefilledData?.languages?.map(i => i.name) || []
  )
  const [immigrant, setImmigrant] = useState(value.immigrant || "")
  const [generation, setGeneration] = useState(
    value.immigrant || prefilledData?.immigrant || ""
  )
  const [militaryBranch, setMilitaryBranch] = useState(
    value.militaryBranch ||
      prefilledData?.militaryBranches?.map(i => i.name) ||
      []
  )

  const [, setDisabledDisablityType] = useState(false)
  const [langOpen, setLangOpen] = useState(false)
  const [secondLangOpen, setSecondLangOpen] = useState(false)
  const [langOption, setLangOption] = useState(
    LANGUAGES.nativeLanguage.slice(0, 54)
  )

  const [yearOpen, setYearOpen] = useState(false)
  const [genderOpen, setGenderOpen] = useState(false)
  const [pronounsOpen, setPronounsOpen] = useState(false)
  const [sexOpen, setSexOpen] = useState(false)
  const [birthplaceOpen, setBirthplaceOpen] = useState(false)
  const [ethnicityOpen, setEthnicityOpen] = useState(false)
  const [ethnicityOpen1, setEthnicityOpen1] = useState(false)
  const [nationalityOpen, setNationalityOpen] = useState(false)
  const [veteranOpen, setVeteranOpen] = useState(false)
  const [disablilityOpen, setDisabilityOpen] = useState(false)
  const [disabilityTypeOpen, setDisabilityTypeOpen] = useState(false)
  const [showDisabilities, setShowDisabilities] = useState(false)

  const [generationOpen, setGenerationOpen] = useState(false)
  const [militaryBranchOpen, setMilitaryBranchOpen] = useState(false)

  const [showVeteran, setShowVeteran] = useState(false)
  const [generationCustom, setGenerationCustom] = useState("")
  const [generationCustomSet, setGenerationCustomSet] = useState(false)
  const [mixedRace, setMixedRace] = useState(false)

  const [customOpen, setCustomOpen] = useState({ open: false, type: "" })
  const [customAnswer, setCustomAnswer] = useState("")

  useEffect(() => {
    setValue(prev => ({
      ...prev,
      ageRange: yearBorn,
      birthPlace: birthplace,
      immigrant: generation,
      ancestry: nationality,
      nativeLanguage: nativeLang,
      secondaryLanguage: secondarayLang,
      ethnicity: ethnicity,
      ethnicity1: ethnicity1,
      disabilityStatus: disability,
      disabilityType: disabilityType,
      veteranStatus: veteran,
      militaryBranch: militaryBranch,
      gender: gender,
      sexualOrientation: sexualOrientation,
      generationCustom: generationCustom,
    }))
    // eslint-disable-next-line
  }, [
    yearBorn,
    birthplace,
    immigrant,
    nationality,
    nativeLang,
    secondarayLang,
    ethnicity,
    ethnicity1,
    disability,
    disabilityType,
    veteran,
    militaryBranch,
    gender,
    sexualOrientation,
    generationCustom,
  ])

  useEffect(() => {
    if (
      prefilledData?.generations?.length > 0 &&
      prefilledData?.generations[0]?.type === "not listed" &&
      !generationCustomSet
    ) {
      const d = document.getElementById("generation")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData?.generations[0]?.name
        setGenerationCustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [generation])

  useEffect(() => {
    if (ethnicity?.includes("Mixed race/Multi-ethnic")) {
      setMixedRace(true)
      setEthnicity1(
        prefilledData?.ethnicities
          ?.filter(i => i.name !== "Mixed race/Multi-ethnic")
          .map(i => i.name) || []
      )
    } else {
      setEthnicity1([])
      setMixedRace(false)
    }
    // eslint-disable-next-line
  }, [ethnicity])

  useEffect(() => {
    if (disability === "do") {
      setDisabledDisablityType(false)
      setShowDisabilities(true)
    } else {
      setDisabledDisablityType(true)
      setShowDisabilities(false)
    }
  }, [disability])

  useEffect(() => {
    if (veteran === "do") {
      setShowVeteran(true)
    } else {
      setShowVeteran(false)
    }
  }, [veteran])

  const onChangeHandle = value => {
    setLangOption(
      LANGUAGES.nativeLanguage.filter(lang =>
        lang.toLowerCase().includes(value.toLowerCase())
      )
    )
  }

  const handleCustomValue = () => {
    if (
      customOpen.type === "nationality" ||
      customOpen.type === "ethnicity" ||
      customOpen.type === "pronouns" ||
      customOpen.type === "sexualOrientation" ||
      customOpen.type === "disabilityType" ||
      customOpen.type === "militaryBranch" ||
      customOpen.type === "nativeLang" ||
      customOpen.type === "secondLang" ||
      customOpen.type === "generation"
    ) {
      const el = document.getElementsByClassName("MuiChip-label")
      if (el) {
        for (var i = 0; i < el.length; i++) {
          if (el[i].innerHTML === "Not Listed") {
            el[i].parentElement.classList.add("hide-it")
          }
        }
      }
    }

    const custom = document.getElementById(customOpen.type)
    const val = custom && custom.innerHTML

    if (custom !== null) {
      if (["gender", "ethnicity", "ethnicity1"].includes(customOpen.type)) {
        custom.innerHTML = val.replace("Prefer to self describe", customAnswer)
      } else {
        custom.innerHTML = val.replace("Not Listed", customAnswer)
      }
    }
  }

  const handleChangeMultiple = (event, type, data) => {
    let { value } = event.target
    if (type === "yearBorn") {
      value = value.toString()
    }

    const openCustomModal =
      value.includes("Not Listed") || value === "Not Listed"
    const found =
      typeof value !== "string" && value.filter(i => !data.includes(i))

    if (
      (openCustomModal && found.length === 0) ||
      (openCustomModal && data === undefined)
    ) {
      setCustomOpen({ open: true, type: type })
    }

    if (value.includes("Prefer not to say") || value === "Prefer not to say") {
      value =
        typeof value !== "string"
          ? value.filter(i => i === "Prefer not to say")
          : value
    }

    if (found.length > 0) {
      value = value.filter(i => data.includes(i))
    }

    switch (type) {
      case "generation":
        setGeneration(value)
        if (value !== "") {
          setImmigrant(true)
        }
        setGenerationOpen(false)
        break
      case "militaryBranch":
        if (value.includes("all")) {
          setMilitaryBranch(data.filter(i => i !== "Not Listed"))
        } else {
          setMilitaryBranch(value)
        }
        isMobile && setMilitaryBranchOpen(false)
        break

      case "yearBorn":
        setYearBorn(value)
        setYearOpen(false)
        break
      case "gender":
        if (value.includes("all")) {
          // setGender(data.filter(i => i !== "Not Listed"))
          setGender(data.filter(i => i !== "Prefer to self describe"))
        } else {
          setGender(value)
        }
        isMobile && setGenderOpen(false)
        break

      case "sexualOrientation":
        if (value.includes("all")) {
          setSexualOrientation(data.filter(i => i !== "Not Listed"))
        } else {
          setSexualOrientation(value)
        }
        isMobile && setSexOpen(false)
        break
      case "ethnicity":
        if (value.includes("all")) {
          setEthnicity(data.filter(i => i !== "Prefer to self describe"))
        } else {
          setEthnicity(value)
        }
        isMobile && setEthnicityOpen(false)
        break
      case "veteran":
        return setVeteran(value)
      case "disability":
        return setDisablity(value)
      case "disabilityType":
        if (value.includes("all")) {
          setDisabilityType(data.filter(i => i !== "Not Listed"))
        } else {
          setDisabilityType(value)
        }
        isMobile && setDisabilityTypeOpen(false)
        break
      default:
    }

    handleCustomValue()
  }

  const handleCustomClose = () => {
    handleCustomValue()

    switch (customOpen.type) {
      case "gender":
        if (customAnswer.trim() === "") {
          // setGender(gender.filter(i => i !== "Not Listed"))
          setGender(gender.filter(i => i !== "Prefer to self describe"))
        } else {
          gender.push(customAnswer)
        }
        break

      case "birthplace":
        if (customAnswer.trim() === "") {
          setBirthplace(birthplace.filter(i => i !== "Not Listed"))
        } else {
          setBirthplace(customAnswer)
        }
        break
      case "ethnicity":
        if (customAnswer.trim() === "") {
          setEthnicity(ethnicity.filter(i => i !== "Prefer to self describe"))
        } else {
          ethnicity.push(customAnswer)
        }
        break
      case "ethnicity1":
        if (customAnswer.trim() === "") {
          setEthnicity(ethnicity1.filter(i => i !== "Prefer to self describe"))
        } else {
          ethnicity1.push(customAnswer)
        }
        break
      case "sexualOrientation":
        if (customAnswer.trim() === "") {
          setSexualOrientation(
            sexualOrientation.filter(i => i !== "Not Listed")
          )
        } else {
          sexualOrientation.push(customAnswer)
        }
        break
      case "nationality":
        if (customAnswer.trim() === "") {
          setNationality(nationality.filter(i => i !== "Not Listed"))
        } else {
          nationality.push(customAnswer)
        }
        break
      case "disabilityType":
        if (customAnswer.trim() === "") {
          setDisabilityType(disabilityType.filter(i => i !== "Not Listed"))
        } else {
          disabilityType.push(customAnswer)
        }
        break

      case "generation":
        if (customAnswer !== "") {
          setGenerationCustom(customAnswer)
        } else {
          setGeneration("")
        }
        break
      case "militaryBranch":
        if (customAnswer.trim() === "") {
          setMilitaryBranch(militaryBranch.filter(i => i !== "Not Listed"))
        } else {
          militaryBranch.push(customAnswer)
        }
        break

      case "nativeLang":
        customAnswer.trim() !== ""
          ? setNativeLang(customAnswer)
          : setNativeLang("")
        break

      case "secondLang":
        if (customAnswer.trim() === "") {
          setSecondaryLang(secondarayLang.filter(i => i !== "Not Listed"))
        } else {
          secondarayLang.push(customAnswer)
        }
        break
      default:
    }
    setCustomAnswer("")
    setCustomOpen({ open: false, type: "" })
  }

  const handleCustomAnswer = e => {
    const { value } = e.target
    if (
      value.toLowerCase() === "not listed" ||
      value.toLowerCase() === "prefer not to say" ||
      value.toLowerCase() === "prefer to self describe"
    ) {
      setCustomAnswer("")
    } else {
      setCustomAnswer(value)
    }
  }

  return (
    <div className="question-section">
      <h3>{proactive ? "About you" : "My Background"}</h3>
      <QuestionsWrapper
        setCustomA={setCustomA}
        customA={customA}
        showError={showError}
        customQuestions={customQuestions}
      >
        {findQuestion(surveySection.questions, "background0")?.visibility ===
          1 && (
          <div className="question-wrapper">
            I am{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="yearBorn"
                value={yearBorn}
                survey={survey}
                showError={showError}
                options={ANSWERS.yearBorn}
                open={yearOpen}
                setOpen={setYearOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background0")
                    ?.visibility === 1 &&
                  showError &&
                  yearBorn === ""
                }
                required={
                  findQuestion(surveySection.questions, "background0")
                    ?.required === 1
                }
                classes="field-asterisk right2"
              />
            </div>{" "}
            years old.{" "}
          </div>
        )}

        {findQuestion(surveySection.questions, "background12")?.visibility ===
          1 && (
          <div className="question-wrapper">
            I was born in{" "}
            <FormControl required className={classes.formControl}>
              <div className="field-wrapper">
                <AutoComplete
                  type="birthplace"
                  value={birthplace}
                  setValue={setBirthplace}
                  open={birthplaceOpen}
                  setOpen={setBirthplaceOpen}
                  options={ANSWERS.birthplace}
                  survey={
                    findQuestion(surveySection.questions, "background12")
                      ?.required
                  }
                  setCustomOpen={setCustomOpen}
                  showError={showError}
                  multiple={false}
                  hideEndAdornment
                />

                <RequiredError
                  error={
                    findQuestion(surveySection.questions, "background12")
                      ?.visibility === 1 &&
                    showError &&
                    birthplace === ""
                  }
                  required={
                    findQuestion(surveySection.questions, "background12")
                      ?.required === 1
                  }
                  classes="field-asterisk"
                />
              </div>
            </FormControl>
          </div>
        )}

        {findQuestion(surveySection.questions, "background1")?.visibility ===
          1 && (
          <div className="question-wrapper">
            My immigrant status is{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="generation"
                value={generation}
                survey={survey}
                showError={showError}
                options={ANSWERS.generation}
                open={generationOpen}
                setOpen={setGenerationOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background1")
                    ?.visibility === 1 &&
                  showError &&
                  generation === ""
                }
                required={
                  findQuestion(surveySection.questions, "background1")
                    ?.required === 1
                }
                classes="field-asterisk"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "background2")?.visibility ===
          1 && (
          <div className="question-wrapper">
            My ancestry is{" "}
            <FormControl required className={classes.formControl}>
              <div className="field-wrapper">
                <AutoComplete
                  type="nationality"
                  value={nationality}
                  setValue={setNationality}
                  open={nationalityOpen}
                  setOpen={setNationalityOpen}
                  options={ANSWERS.nationality}
                  survey={
                    findQuestion(surveySection.questions, "background2")
                      ?.required
                  }
                  setCustomOpen={setCustomOpen}
                  showError={showError}
                  queryClass=".ancestry-chips .MuiChip-label"
                  className="ancestry-chips"
                  multiple={true}
                  hideEndAdornment
                />

                <RequiredError
                  error={
                    findQuestion(surveySection.questions, "background2")
                      ?.visibility === 1 &&
                    showError &&
                    nationality.length === 0
                  }
                  required={
                    findQuestion(surveySection.questions, "background2")
                      ?.required === 1
                  }
                  classes="field-asterisk"
                />
              </div>{" "}
            </FormControl>
          </div>
        )}

        <div className="question-wrapper">
          {findQuestion(surveySection.questions, "background3")?.visibility ===
            1 && (
            <>
              My primary language is{" "}
              <FormControl required className={classes.formControl}>
                <div className="field-wrapper">
                  <AutoComplete
                    type="nativeLang"
                    value={nativeLang}
                    setValue={setNativeLang}
                    open={langOpen}
                    setOpen={setLangOpen}
                    options={langOption}
                    survey={
                      findQuestion(surveySection.questions, "background3")
                        ?.required
                    }
                    setCustomOpen={setCustomOpen}
                    showError={showError}
                    multiple={false}
                    onChangeHandle={onChangeHandle}
                    hideEndAdornment
                  />

                  <RequiredError
                    error={
                      findQuestion(surveySection.questions, "background3")
                        ?.visibility === 1 &&
                      showError &&
                      nativeLang === ""
                    }
                    required={
                      findQuestion(surveySection.questions, "background3")
                        ?.required === 1
                    }
                    classes="field-asterisk"
                  />
                </div>
              </FormControl>
              .{" "}
            </>
          )}

          {findQuestion(surveySection.questions, "background4")?.visibility ===
            1 && (
            <>
              I also speak{" "}
              <FormControl
                className={classes.formControl}
                style={{ maxWidth: "none" }}
              >
                <div className="field-wrapper">
                  <AutoComplete
                    type="secondLang"
                    value={secondarayLang}
                    setValue={setSecondaryLang}
                    open={secondLangOpen}
                    setOpen={setSecondLangOpen}
                    options={langOption}
                    survey={
                      findQuestion(surveySection.questions, "background4")
                        ?.required
                    }
                    setCustomOpen={setCustomOpen}
                    showError={showError}
                    multiple={true}
                    queryClass=".secondLang-chips .MuiChip-label"
                    className="secondLang-chips"
                    onChangeHandle={onChangeHandle}
                    hideEndAdornment
                  />

                  <RequiredError
                    error={
                      findQuestion(surveySection.questions, "background4")
                        ?.visibility === 1 &&
                      showError &&
                      secondarayLang.length === 0
                    }
                    required={
                      findQuestion(surveySection.questions, "background4")
                        ?.required === 1
                    }
                    classes="field-asterisk"
                  />
                </div>
              </FormControl>
              .
            </>
          )}
        </div>

        {findQuestion(surveySection.questions, "background5")?.visibility ===
          1 && (
          <div className="question-wrapper">
            In terms of ethnicity, I identify as
            <div className="field-wrapper">
              <FormControl
                className={classes.formControl}
                style={{ maxWidth: "none" }}
              >
                <AutoComplete
                  type="ethnicity"
                  value={ethnicity}
                  setValue={setEthnicity}
                  open={ethnicityOpen}
                  setOpen={setEthnicityOpen}
                  options={ANSWERS.ethnicity}
                  survey={
                    findQuestion(surveySection.questions, "background5")
                      ?.required
                  }
                  setCustomOpen={setCustomOpen}
                  showError={showError}
                  queryClass=".ethnicity-chips .MuiChip-label"
                  className="ethnicity-chips"
                  multiple={true}
                />
              </FormControl>
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background5")
                    ?.visibility === 1 &&
                  showError &&
                  ethnicity?.length === 0
                }
                required={
                  findQuestion(surveySection.questions, "background5")
                    ?.required === 1
                }
                classes="field-asterisk right2"
              />
            </div>
            {mixedRace && (
              <>
                {" "}
                The ethnicities I identify with include
                <div className="field-wrapper">
                  <FormControl
                    className={classes.formControl}
                    style={{ maxWidth: "none" }}
                  >
                    <AutoComplete
                      type="ethnicity1"
                      value={ethnicity1}
                      setValue={setEthnicity1}
                      open={ethnicityOpen1}
                      setOpen={setEthnicityOpen1}
                      options={ANSWERS.ethnicity.filter(
                        v => v !== "Mixed race/Multi-ethnic"
                      )}
                      survey={
                        findQuestion(surveySection.questions, "background5")
                          ?.required
                      }
                      setCustomOpen={setCustomOpen}
                      showError={showError}
                      queryClass=".ethnicity-chips .MuiChip-label"
                      className="ethnicity-chips"
                      multiple={true}
                    />
                  </FormControl>
                  .
                  <RequiredError
                    error={
                      findQuestion(surveySection.questions, "background5")
                        ?.visibility === 1 &&
                      showError &&
                      ethnicity.includes("Mixed race/Multi-ethnic") &&
                      ethnicity1.length === 0
                    }
                    required={
                      findQuestion(surveySection.questions, "background5")
                        ?.required === 1
                    }
                    classes="field-asterisk right2"
                  />
                </div>
              </>
            )}
          </div>
        )}

        {findQuestion(surveySection.questions, "background9")?.visibility ===
          1 && (
          <div className="question-wrapper">
            I{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="disability"
                value={disability}
                survey={survey}
                showError={showError}
                options={ANSWERS.disability}
                open={disablilityOpen}
                setOpen={setDisabilityOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background9")
                    ?.visibility === 1 &&
                  showError &&
                  disability === ""
                }
                required={
                  findQuestion(surveySection.questions, "background9")
                    ?.required === 1
                }
                classes="field-asterisk right2"
              />
            </div>{" "}
            identify as a person living with one or more disabilities
            {showDisabilities ? (
              <>
                , including{" "}
                <div className="field-wrapper">
                  <FormControl
                    className={classes.formControl}
                    style={{ maxWidth: "none" }}
                  >
                    <AutoComplete
                      type="disabilityType"
                      value={disabilityType}
                      setValue={setDisabilityType}
                      open={disabilityTypeOpen}
                      setOpen={setDisabilityTypeOpen}
                      options={ANSWERS.disabilityType}
                      survey={
                        findQuestion(surveySection.questions, "background9")
                          ?.required
                      }
                      setCustomOpen={setCustomOpen}
                      showError={showError}
                      queryClass=".disability-chips .MuiChip-label"
                      className="disability-chips"
                      multiple={true}
                    />
                  </FormControl>
                  .
                  {findQuestion(surveySection.questions, "background9")
                    ?.required === 1 &&
                    showError &&
                    disability === "do" &&
                    disabilityType.length === 0 && (
                      <>
                        <FormHelperText className="error">
                          Please make a selection
                        </FormHelperText>
                        <img
                          className="field-asterisk right2"
                          src={Asterisk}
                          alt="Required"
                        />
                      </>
                    )}
                </div>
              </>
            ) : (
              "."
            )}
          </div>
        )}

        {findQuestion(surveySection.questions, "background11")?.visibility ===
          1 && (
          <div className="question-wrapper">
            I{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="veteran"
                value={veteran}
                survey={survey}
                showError={showError}
                options={ANSWERS.veteranStatus}
                open={veteranOpen}
                setOpen={setVeteranOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background11")
                    ?.visibility === 1 &&
                  showError &&
                  veteran === ""
                }
                required={
                  findQuestion(surveySection.questions, "background11")
                    ?.required === 1
                }
                classes="field-asterisk right2"
              />
            </div>{" "}
            identify as a military veteran.{" "}
            {showVeteran && (
              <>
                I served in the{" "}
                <div className="field-wrapper">
                  <FormControl
                    className={classes.formControl}
                    style={{ maxWidth: "none" }}
                  >
                    <AutoComplete
                      type="militaryBranch"
                      value={militaryBranch}
                      setValue={setMilitaryBranch}
                      open={militaryBranchOpen}
                      setOpen={setMilitaryBranchOpen}
                      options={ANSWERS.militaryBranch}
                      survey={
                        findQuestion(surveySection.questions, "background11")
                          ?.required
                      }
                      setCustomOpen={setCustomOpen}
                      showError={showError}
                      queryClass=".military-chips .MuiChip-label"
                      className="military-chips"
                      multiple={true}
                    />
                  </FormControl>

                  <RequiredError
                    error={
                      findQuestion(surveySection.questions, "background11")
                        ?.visibility === 1 &&
                      showError &&
                      militaryBranch.length === 0
                    }
                    required={
                      findQuestion(surveySection.questions, "background11")
                        ?.required === 1
                    }
                    classes="field-asterisk"
                  />
                </div>
              </>
            )}
          </div>
        )}

        {findQuestion(surveySection.questions, "background6")?.visibility ===
          1 && (
          <div className="question-wrapper">
            My pronouns are{" "}
            <div className="field-wrapper">
              <FormControl
                className={classes.formControl}
                style={{ maxWidth: "none" }}
              >
                <AutoComplete
                  type="pronouns"
                  value={pronouns}
                  setValue={setPronouns}
                  open={pronounsOpen}
                  setOpen={setPronounsOpen}
                  options={ANSWERS.pronouns}
                  survey={
                    findQuestion(surveySection.questions, "background6")
                      ?.required
                  }
                  setCustomOpen={setCustomOpen}
                  showError={showError}
                  queryClass=".pronouns-chips .MuiChip-label"
                  className="pronouns-chips"
                  multiple={true}
                />
              </FormControl>

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background6")
                    ?.visibility === 1 &&
                  showError &&
                  pronouns.length === 0
                }
                required={
                  findQuestion(surveySection.questions, "background6")
                    ?.required === 1
                }
                classes="field-asterisk"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "background7")?.visibility ===
          1 && (
          <div className="question-wrapper">
            In terms of gender, I identify as{" "}
            <div className="field-wrapper">
              <FormControl
                className={classes.formControl}
                style={{ maxWidth: "none" }}
              >
                <AutoComplete
                  type="gender"
                  value={gender}
                  setValue={setGender}
                  open={genderOpen}
                  setOpen={setGenderOpen}
                  options={ANSWERS.gender}
                  survey={
                    findQuestion(surveySection.questions, "background7")
                      ?.required
                  }
                  setCustomOpen={setCustomOpen}
                  showError={showError}
                  queryClass=".gender-chips .MuiChip-label"
                  className="gender-chips"
                  multiple={true}
                />
              </FormControl>

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background7")
                    ?.visibility === 1 &&
                  showError &&
                  gender.length === 0
                }
                required={
                  findQuestion(surveySection.questions, "background7")
                    ?.required === 1
                }
                classes="field-asterisk"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "background8")?.visibility ===
          1 && (
          <div className="question-wrapper">
            In terms of sexual orientation, I identify as{" "}
            <div className="field-wrapper">
              <FormControl
                className={classes.formControl}
                style={{ maxWidth: "none" }}
              >
                <AutoComplete
                  type="sexualOrientation"
                  value={sexualOrientation}
                  setValue={setSexualOrientation}
                  open={sexOpen}
                  setOpen={setSexOpen}
                  options={ANSWERS.sexualOrientation}
                  survey={
                    findQuestion(surveySection.questions, "background8")
                      ?.required
                  }
                  setCustomOpen={setCustomOpen}
                  showError={showError}
                  queryClass=".sexual-chips .MuiChip-label"
                  className="sexual-chips"
                  multiple={true}
                />
              </FormControl>
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "background8")
                    ?.visibility === 1 &&
                  showError &&
                  sexualOrientation.length === 0
                }
                required={
                  findQuestion(surveySection.questions, "background8")
                    ?.required === 1
                }
                classes="field-asterisk"
              />
            </div>
          </div>
        )}
      </QuestionsWrapper>
      <CustomAnswer
        handleCustomClose={handleCustomClose}
        handleCustomAnswer={handleCustomAnswer}
        open={customOpen.open}
      />
    </div>
  )
}

export default BackgroundSection
