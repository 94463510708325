import React, { useEffect, useState } from "react"
import Header from "./header"
import { Button, TextField } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import analytics from "./Analytics"
const useStyles = makeStyles(theme => ({
  custom: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "32px",
    paddingBottom: 0,
    paddingLeft: "5px",
    border: "1px solid #fff",
  },
}))
function Verification({
  setStep,
  isOptIn,
  setIsOptIn,
  preEnrollmentUUID,
  biddingUUID,
  retroactiveUUID,
  verifyPhoneNumber,
  setVerifyPhoneNumber,
  setPrefilledData,
  uuid,
  selfIdUUID,
  customProjectUUID,
}) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [sent, setSent] = useState(false)

  useEffect(() => {
    analytics.page({
      title: "Phone Number Verification Step",
      path: "/verification",
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  const handleChange = e => {
    const { value } = e.target
    setCode(value)
    if (value.length > 2) {
      setDisabled(false)
    }
  }

  const submit = () => {
    setLoading(true)

    if (!isOptIn) {
      axios
        .post(`${API_ROOT}${ENV}/twilio/verify-code`, {
          phoneNumber: verifyPhoneNumber,
          code: code,
          uuid: preEnrollmentUUID
            ? preEnrollmentUUID
            : biddingUUID
            ? biddingUUID
            : retroactiveUUID
            ? retroactiveUUID
            : customProjectUUID
            ? customProjectUUID
            : uuid,
          type: preEnrollmentUUID
            ? "PRE-ENROLLMENT"
            : biddingUUID
            ? "BIDDING"
            : retroactiveUUID
            ? "RETROACTIVE"
            : customProjectUUID
            ? "CUSTOM-PROJECT"
            : "PROACTIVE",
        })
        .then(response => {
          if (response.data.statusCode === 200) {
            setLoading(false)
            setVerifyPhoneNumber("")
            setPrefilledData(response.data.devMessage)
            setStep(2)
          } else if (response.data.count && response.data.count === 1) {
            setLoading(false)
            setStep("registered")
          } else {
            setLoading(false)
            setStep("verificationFail")
          }
        })
    } else {
      const apiURL = preEnrollmentUUID
        ? `${API_ROOT}${ENV}/survey-response/pre-enrollment/update`
        : biddingUUID
        ? `${API_ROOT}${ENV}/survey-response/bidding/update`
        : retroactiveUUID
        ? `${API_ROOT}${ENV}/survey-response/retroactive/update`
        : customProjectUUID
        ? `${API_ROOT}${ENV}/survey-response/custom-project/update`
        : `${API_ROOT}${ENV}/survey-response/proactive/update`
      axios
        .post(apiURL, {
          uuid: selfIdUUID,
          phoneNumber: verifyPhoneNumber,
          code: code,
        })
        .then(response => {
          if (response.data.statusCode === 200) {
            setLoading(false)
            setVerifyPhoneNumber("")
            setPrefilledData(response.data.devMessage)
            setIsOptIn(false)
            setStep(4)
          } else if (
            response.data.devMessage.includes("Phone number already registered")
          ) {
            setLoading(false)
            setIsOptIn(false)
            setStep("registered")
          } else {
            setLoading(false)
            setStep("verificationFail")
          }
        })
    }
  }

  const resendCode = () => {
    setLoading(true)
    let type = ""
    let uuidVerify = ""

    if (isOptIn) {
      type = preEnrollmentUUID
        ? "entry-pre-enrollment"
        : biddingUUID
        ? "entry-bidding"
        : retroactiveUUID
        ? "entry-retroactive"
        : customProjectUUID
        ? "entry-custom-project"
        : "entry-self-id"
      uuidVerify = selfIdUUID
    } else {
      type = preEnrollmentUUID
        ? "pre-populated-pre-enrollment"
        : biddingUUID
        ? "pre-populated-bidding"
        : retroactiveUUID
        ? "pre-populated-retroactive"
        : customProjectUUID
        ? "pre-populated-custom-project"
        : "pre-populated-self-id"
      uuidVerify = preEnrollmentUUID
        ? preEnrollmentUUID
        : biddingUUID
        ? biddingUUID
        : retroactiveUUID
        ? retroactiveUUID
        : customProjectUUID
        ? customProjectUUID
        : uuid
    }

    axios
      .post(`${API_ROOT}${ENV}/twilio/get-code`, {
        phoneNumber: verifyPhoneNumber,
        uuid: uuidVerify,
        type: type,
      })
      .then(response => {
        setSent(true)
        if (response.data.statusCode === 200) {
          setLoading(false)
          setStep("verification")
        } else {
          setLoading(false)
          setStep("noPhone")
        }
      })
  }

  return (
    <section className="successful">
      <div className="container">
        <Header />
        <div className="bg">
          <p>Enter 6-digit verification code.</p>
          <TextField
            InputProps={{
              className: classes.custom,
            }}
            onChange={handleChange}
          />
          <p className="resend-code">
            Haven't received it yet?{" "}
            <span aria-hidden="true" onClick={resendCode}>
              Resend code
            </span>
            .
          </p>

          <p className="resend-code">
            <span
              aria-hidden="true"
              className="update-phone"
              onClick={() => {
                isOptIn ? setStep(3) : setStep("phone")
              }}
            >
              Update phone number
            </span>
          </p>
        </div>

        <div className="actions">
          {sent && <p className="resent">Your code has been resent</p>}
          <Button onClick={submit} disabled={disabled || loading}>
            {loading ? (
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="24px"
                height="30px"
                viewBox="0 0 24 30"
              >
                <rect
                  x="0"
                  y="10"
                  width="4"
                  height="10"
                  fill="#333"
                  opacity="0.2"
                >
                  <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                </rect>
                <rect
                  x="8"
                  y="10"
                  width="4"
                  height="10"
                  fill="#333"
                  opacity="0.2"
                >
                  <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                </rect>
                <rect
                  x="16"
                  y="10"
                  width="4"
                  height="10"
                  fill="#333"
                  opacity="0.2"
                >
                  <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                </rect>
              </svg>
            ) : (
              "Verify It’s Me"
            )}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Verification
