// eslint-disable-next-line
export default value => {
  if (
    value?.jobTitle?.length === 0 ||
    value.yearsOfExperience === "" ||
    value.liveIn === "" ||
    value.primaryWork === "" ||
    value.union === null ||
    value.rate === "" ||
    value.rateDimension === "" ||
    value.employmentType === null ||
    value.grossIncome === "" ||
    value.yearlyIncome === null ||
    value.monthlyIncome === "" ||
    value.workOften === "" ||
    value.longHours === "" ||
    value.longHoursRole === "" ||
    value.viaProgram === null ||
    value.opportunity === null
  ) {
    return {
      validation: false,
      page: 1,
    }
  }

  if (value.viaProgram === "Yes" && value.trainingProgram === "") {
    return {
      validation: false,
      page: 1,
    }
  }

  if (
    value.healthcareCovered === null ||
    value.paidLeave === null ||
    value.dependents === null ||
    value.retirementSavings === null ||
    value.education === ""
  ) {
    return {
      validation: false,
      page: 2,
    }
  }

  if (
    value.healthcareCovered === "Yes" &&
    (value.healthcarePlan === null ||
      value.healthcarePlan === "" ||
      value.healthcareSource === "" ||
      value.workWithoutHealthcare === null ||
      value.workWithoutHealthcare === "" ||
      value.monthlyInsurancePlan === "")
  ) {
    return {
      validation: false,
      page: 2,
    }
  }

  if (
    value.ageRange === "" ||
    value.birthPlace === "" ||
    value.ancestry.length === 0 ||
    value.nativeLanguage === "" ||
    value.ethnicity.length === 0 ||
    value.disabilityStatus === "" ||
    value.veteranStatus === "" ||
    value.gender.length === 0 ||
    value.sexualOrientation.length === 0
  ) {
    return {
      validation: false,
      page: 3,
    }
  }

  if (value.veteranStatus === "do" && value.militaryBranch.length === 0) {
    return {
      validation: false,
      page: 3,
    }
  }

  if (value.disabilityStatus === "do" && value.disabilityType.length === 0) {
    return {
      validation: false,
      page: 3,
    }
  }

  if (
    value.experienceHate === null ||
    value.incidentReport === "" ||
    value.representation === null
  )
    return {
      validation: false,
      page: 4,
    }

  return {
    validation: true,
  }
}
