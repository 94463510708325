import React, { useState } from "react"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import ANSWERS from "../../constants/Constant"
import { findQuestion } from "../../util/find"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"
const CareAboutSection = ({
  survey,
  surveySection,
  showError,
  caredMost,
  environmental,
  handleChangeMultiple,
  customQuestions,
  customA,
  setCustomA,
}) => {
  const [caredMostOpen, setCaredMostOpen] = useState(false)
  const [environmentalOpen, setEnvironmentalOpen] = useState(false)
  return (
    <div className="question-section">
      <h3>What do you care about?</h3>
      <QuestionsWrapper
        customQuestions={customQuestions}
        setCustomA={setCustomA}
        customA={customA}
        showError={showError}
      >
        {findQuestion(surveySection.questions, "cared1")?.visibility === 1 && (
          <div className="question-wrapper">
            What do you care about most?{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="caredMost"
                value={caredMost}
                survey={survey}
                showError={showError}
                options={ANSWERS.caredMost}
                open={caredMostOpen}
                setOpen={setCaredMostOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "cared1")?.required ===
                    1 &&
                  showError &&
                  caredMost === ""
                }
                required={
                  findQuestion(surveySection.questions, "cared1")?.required ===
                  1
                }
                classes="field-asterisk text3"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "cared2")?.visibility === 1 && (
          <div className="question-wrapper">
            How much do waste (food, materials, etc) and emissions associated
            with production, and their environmental impact concern you?{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="environmental"
                value={environmental}
                survey={survey}
                showError={showError}
                options={ANSWERS.environmental}
                open={environmentalOpen}
                setOpen={setEnvironmentalOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "cared2")?.required ===
                    1 &&
                  showError &&
                  environmental === ""
                }
                required={
                  findQuestion(surveySection.questions, "cared2")?.required ===
                  1
                }
                classes="field-asterisk text3"
              />
            </div>
          </div>
        )}
      </QuestionsWrapper>
    </div>
  )
}

export default CareAboutSection
