import React, { useState } from "react"
import { FormControl, Popover } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import ANSWERS from "../../constants/Constant"
import CurrencyDropdown from "../shared/CurrencyDropdown"
import { findQuestion } from "../../util/find"
import InfoIcon from "../../images/info-icon.svg"
import { PopoverWrapper } from "../style"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  popover: {
    pointerEvents: "none",
  },
  infoPaper: {
    padding: theme.spacing(1),
  },
}))

const CompensationSection = ({
  survey,
  surveySection,
  showError,
  handleChangeMultiple,
  rate,
  paySentiment,
  setCustomA,
  customA,
  customQuestions,
}) => {
  const classes = useStyles()
  const [compEl, setCompEl] = useState(null)
  const [currency, setCurrency] = useState("USD")
  const [rateDimensionOpen, setRateDimensionOpen] = useState(false)
  const [paySentimentOpen, setPaySentimentOpen] = useState(false)

  const compensationInfoOpen = Boolean(compEl)

  const handlePopoverOpen = event => {
    setCompEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setCompEl(null)
  }

  return (
    <div className="question-section">
      <QuestionsWrapper
        customQuestions={customQuestions}
        setCustomA={setCustomA}
        customA={customA}
        showError={showError}
      >
        {findQuestion(surveySection.questions, "comp0")?.visibility === 1 && (
          <div className="question-wrapper">
            My rate on this job is{" "}
            <FormControl required className={classes.formControl}>
              <div className="field-wrapper">
                <CurrencyDropdown
                  currency={currency}
                  setCurrency={setCurrency}
                />
              </div>
            </FormControl>
            <div className="field-wrapper">
              <SingleSelect
                type="dayRate"
                value={rate}
                survey={survey}
                showError={showError}
                options={ANSWERS.rateRange}
                open={rateDimensionOpen}
                setOpen={setRateDimensionOpen}
                handleChangeMultiple={handleChangeMultiple}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "comp0")?.required ===
                    1 &&
                  showError &&
                  rate === ""
                }
                required={
                  findQuestion(surveySection.questions, "comp0")?.required === 1
                }
                classes="field-asterisk"
              />
            </div>
            per day.{" "}
            <img
              aria-hidden="true"
              aria-owns={compensationInfoOpen ? "comp-popover" : undefined}
              onMouseEnter={e => handlePopoverOpen(e, "comp")}
              onMouseLeave={() => handlePopoverClose("comp")}
              className="role-info"
              src={InfoIcon}
              alt="Info"
            />
            <Popover
              id="comp-popover"
              className={classes.popover}
              classes={{
                infoPaper: classes.infoPaper,
              }}
              open={compensationInfoOpen}
              anchorEl={compEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={() => handlePopoverClose("comp")}
              disableRestoreFocus
              PaperProps={{
                style: {
                  width: "100%",
                  maxWidth: "320px",
                  padding: "15px",
                  background: "#c4c4c4",
                },
              }}
            >
              <PopoverWrapper>
                <h6>
                  Remember, your answers are anonymous and aggregated across
                  multiple productions!
                </h6>
                <p>
                  We require a minimum of 5 inputs to report on any given
                  metric. Compensation information is aggregated to help
                  establish industry baselines for rates of pay. Help us empower
                  you with information to better advocate for yourself and
                  others.
                </p>
              </PopoverWrapper>
            </Popover>
          </div>
        )}

        {findQuestion(surveySection.questions, "comp1")?.visibility === 1 && (
          <div className="question-wrapper">
            I feel that this rate is{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="paySentiment"
                value={paySentiment}
                survey={survey}
                showError={showError}
                options={ANSWERS.paySentiment}
                open={paySentimentOpen}
                setOpen={setPaySentimentOpen}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "comp1")?.required ===
                    1 &&
                  showError &&
                  paySentiment === ""
                }
                required={
                  findQuestion(surveySection.questions, "comp1")?.required === 1
                }
                classes="field-asterisk"
              />
            </div>
          </div>
        )}
      </QuestionsWrapper>
    </div>
  )
}

export default CompensationSection
