import React from "react"
import { Button, Dialog, FormControl, TextField } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import { ModalWrapper } from "../style"
const useStyles = makeStyles(theme => ({
  custom: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "32px",
    paddingBottom: 0,
    paddingLeft: "5px",
    border: "1px solid #fff",
  },
}))

const CustomAnswer = ({ handleCustomClose, handleCustomAnswer, open }) => {
  const classes = useStyles()
  return (
    <Dialog
      onClose={handleCustomClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <ModalWrapper>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="28"
          viewBox="0 0 27 28"
          onClick={handleCustomClose}
        >
          <g fill="none" fillRule="evenodd" strokeLinecap="square">
            <g stroke="#FFF">
              <g>
                <g>
                  <path
                    d="M26.707 0.283L0.06 25.942"
                    transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000)"
                  />
                  <path
                    d="M26.707 0.283L0.06 25.942"
                    transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000) translate(13.500000, 13.000000) scale(-1, 1) translate(-13.500000, -13.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>

        <FormControl>
          <TextField
            placeholder="Enter your answer"
            autoFocus
            InputProps={{
              className: classes.custom,
            }}
            onChange={handleCustomAnswer}
          />
        </FormControl>
        <Button onClick={handleCustomClose}>Done</Button>
      </ModalWrapper>
    </Dialog>
  )
}

export default CustomAnswer
