import React, { useState, useEffect } from "react"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import ANSWERS from "../../constants/StandWithProduction"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    maxWidth: 250,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "25px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
  rate: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "20px",
    width: "150px",
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
  radioGroup: {
    flexDirection: "row",
    marginLeft: "20px",
    paddingTop: "5px",
  },
  radio: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
}))

const MyResourceSection = ({ value, setValue, showError }) => {
  const classes = useStyles()
  const [showHealthCareDetail, setShowHealthCareDetail] = useState(false)

  const [healthCareCoverage, setHealthCareCoverage] = useState(
    value.healthcareCovered || null
  )
  const [healthcarePlan, setHealthcarePlan] = useState(
    value.healthcarePlan || null
  )

  const [healthCareSource, setHealthCareSource] = useState(
    value.healthcareSource || ""
  )
  const [healthCareSourceOpen, setHealthCareSourceOpen] = useState(false)

  const [withOutHealthCare, setWithOutHealthCare] = useState(
    value.workWithoutHealthcare || null
  )
  const [healthcareCost, setHealthcareCost] = useState(
    value.monthlyInsurancePlan || ""
  )
  const [paidLeave, setPaidLeave] = useState(value.paidLeave || null)
  const [dependents, setDependents] = useState(value.dependents || null)
  const [retirementSaving, setRetirementSaving] = useState(
    value.retirementSavings || null
  )
  const [education, setEducation] = useState(value.education || "")
  const [educationOpen, setEducationOpen] = useState(false)

  useEffect(() => {
    setValue(prev => ({
      ...prev,
      healthcareCovered: healthCareCoverage,
      healthcarePlan: healthcarePlan,
      healthcareSource: healthCareSource,
      workWithoutHealthcare: withOutHealthCare,
      monthlyInsurancePlan: healthcareCost,
      paidLeave: paidLeave,
      dependents: dependents,
      retirementSavings: retirementSaving,
      education: education,
    }))
  }, [
    healthCareCoverage,
    healthcarePlan,
    healthCareSource,
    withOutHealthCare,
    healthcareCost,
    paidLeave,
    dependents,
    retirementSaving,
    education,
  ])

  useEffect(() => {
    if (healthCareCoverage === "Yes") {
      setShowHealthCareDetail(true)
    } else {
      setShowHealthCareDetail(false)
    }
  }, [healthCareCoverage])

  const handleChangeMultiple = (e, type, data) => {
    let { value } = e.target

    switch (type) {
      case "healthCareSource":
        setHealthCareSource(value)
        setHealthCareSourceOpen(false)
        break
      case "education":
        setEducation(value)
        setEducationOpen(false)
        break
    }
  }

  return (
    <div className="question-section">
      <h3>My Resources</h3>
      <div className="question-wrapper">
        Do you currently have healthcare coverage?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="healthCareCoverage"
              id="healthCareCoverage"
              defaultValue={healthCareCoverage}
              onChange={e => {
                if(e.target.value === 'No'){
                  setHealthcarePlan('')
                  setHealthCareSource('')
                  setHealthcareCost('')
                  setWithOutHealthCare('')
                  
                }
                setHealthCareCoverage(e.target.value)
                
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
            </RadioGroup>
            <RequiredError
              error={healthCareCoverage === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
      {showHealthCareDetail && (
        <>
          <div className="question-wrapper">
            Are you happy with your healthcare plan?
            <div className="field-wrapper">
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.radioGroup}
                  name="healthcarePlan"
                  id="healthcarePlan"
                  defaultValue={healthcarePlan}
                  onChange={e => {
                    setHealthcarePlan(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio className={classes.radio} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio className={classes.radio} />}
                    label="No"
                  />
                </RadioGroup>
                <RequiredError
                  error={
                    showHealthCareDetail && healthcarePlan === null && showError
                  }
                  required={true}
                  classes="field-asterisk"
                />
              </FormControl>
            </div>
          </div>
          <div className="question-wrapper">
            What is the source of your healthcare?
            <div className="field-wrapper">
              <SingleSelect
                type="healthCareSource"
                value={healthCareSource}
                options={ANSWERS.healthCare}
                open={healthCareSourceOpen}
                setOpen={setHealthCareSourceOpen}
                handleChangeMultiple={handleChangeMultiple}
              />
              <RequiredError
                error={
                  showHealthCareDetail && healthCareSource === "" && showError
                }
                required={true}
                classes="field-asterisk"
              />
            </div>
          </div>
          <div className="question-wrapper">
            Have you ever gone without healthcare coverage while working in the
            commercial production industry?
            <div className="field-wrapper">
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.radioGroup}
                  name="withOutHealthCare"
                  id="withOutHealthCare"
                  defaultValue={withOutHealthCare}
                  onChange={e => {
                    setWithOutHealthCare(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio className={classes.radio} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio className={classes.radio} />}
                    label="No"
                  />
                </RadioGroup>
                <RequiredError
                  error={
                    showHealthCareDetail &&
                    withOutHealthCare === null &&
                    showError
                  }
                  required={true}
                  classes="field-asterisk"
                />
              </FormControl>
            </div>
          </div>
          <div className="question-wrapper">
            What is your average monthly out-of-pocket cost for your health
            insurance plan?{" "}
            <FormControl required className={classes.formControl}>
              <div className="field-wrapper">
                <CurrencyTextField
                  variant="standard"
                  id="healthcareCost"
                  type="tel"
                  error={showError && healthcareCost === ""}
                  value={healthcareCost}
                  currencySymbol="$"
                  outputFormat="number"
                  decimalPlacesShownOnBlur={0}
                  minimumValue={0}
                  onChange={(_, value) => setHealthcareCost(value)}
                  InputProps={{
                    className: classes.rate,
                  }}
                />
                <RequiredError
                  error={
                    showHealthCareDetail && healthcareCost === "" && showError
                  }
                  required={true}
                  classes="field-asterisk"
                />
              </div>
            </FormControl>
            / month
          </div>
        </>
      )}

      <div className="question-wrapper">
        Is paid family leave important for your work in this industry?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="paidLeave"
              id="paidLeave"
              defaultValue={paidLeave}
              onChange={e => {
                setPaidLeave(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
            </RadioGroup>
            <RequiredError
              error={paidLeave === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
      <div className="question-wrapper">
        Do you have dependents?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="dependents"
              id="dependents"
              defaultValue={dependents}
              onChange={e => {
                setDependents(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
            </RadioGroup>
            <RequiredError
              error={dependents === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
      <div className="question-wrapper">
        Do you have retirement savings?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="retirementSaving"
              id="retirementSaving"
              defaultValue={retirementSaving}
              onChange={e => {
                setRetirementSaving(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
            </RadioGroup>
            <RequiredError
              error={retirementSaving === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
      <div className="question-wrapper">
        What the highest level of education you've completed?
        <div className="field-wrapper">
          <SingleSelect
            type="education"
            value={education}
            options={ANSWERS.education}
            open={educationOpen}
            setOpen={setEducationOpen}
            handleChangeMultiple={handleChangeMultiple}
          />
          <RequiredError
            error={education === "" && showError}
            required={true}
            classes="field-asterisk"
          />
        </div>
      </div>
    </div>
  )
}

export default MyResourceSection
