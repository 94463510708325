import React, { useEffect } from "react"
import { Button } from "@material-ui/core/"
import Header from "./header"
import analytics from "./Analytics"
const Opening = ({
  setStep,
  customMessage,
  biddingId,
  retroactiveId,
  customProjectUUID,
  isAcademy,
}) => {
  useEffect(() => {
    analytics.page({
      title: "Opening Step",
      path: "/opening",
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  return (
    <section className="opening">
      <div className="container">
        <Header />
        <div>
          <h2>
            {customProjectUUID
              ? "STAND WITH PRODUCTION Annual Survey"
              : "Lights. Camera. Actionable change."}
          </h2>

          {customMessage && <h4>{customMessage}</h4>}

          <div className="body-text">
            {biddingId ? (
              <>
                <p>
                  Our platform empowers you to tell your story safely and
                  privately. The anonymous survey works to establish industry
                  baselines (kind of like a census for the production industry)
                  to create a picture of the production landscape and pinpoint
                  needs. Your answers will never be linked to you and will be
                  aggregated to provide client and industry-level reports to
                  promote transparency, accuracy, and industry accountability.
                </p>

                <p>
                  We're on our way to creating more equitable production sets
                  across the globe. But we can't do it alone.
                </p>

                <p>You in?</p>
              </>
            ) : retroactiveId ? (
              <>
                <p>
                  Our platform empowers you to tell your story safely
                  and privately. The anonymous survey works to establish
                  industry baselines (kind of like a census for the production
                  industry) to create a picture of the production landscape and
                  pinpoint needs. Your answers will never be linked to you and
                  will be aggregated to provide client and industry-level
                  reports to promote transparency, accuracy, and industry
                  accountability.
                </p>

                <p>
                  We're on our way to creating more equitable production sets
                  across the globe. But we can't do it alone.
                </p>

                <p>You in?</p>
              </>
            ) : customProjectUUID ? (
              <>
                <p>
                  Please take 15 minutes to complete the following survey. Your
                  answers are completely confidential and will provide insight
                  into the needs of our union members so that we can better
                  identify the gaps in our current working environments and
                  prioritize the resources needed by our members.
                </p>

                <p>
                  By completing the survey, you are actively participating in an
                  effort to better support this community and create a more
                  sustainable present and future for our industry.
                </p>

                <p>Your time is appreciated!</p>
              </>
            ) : isAcademy ? (
              <>
                <p>
                  The Academy of Motion Picture Arts & Sciences has partnered
                  with Working Insights to track new representation and
                  inclusion standards as part of their{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.oscars.org/about/academy-aperture"
                  >
                    "Academy Aperture" initiative
                  </a>
                  .
                </p>

                <p>
                  Working Insights empowers cast and crew members to
                  self-identify privately and securely to enable the films on
                  which they work to qualify for the Oscars in the Best Picture
                  category.
                </p>

                <p>
                  Questions? Reach us at{" "}
                  <a href="mailto:hello@workinginsights.com">
                    hello@workinginsights.com
                  </a>
                  .
                </p>
              </>
            ) : (
              <>
                <p>
                  Our platform advocates on behalf of
                  crews. Our anonymous Working Insights survey works to establish industry
                  baselines to create a picture of the landscape and pinpoint
                  needs.
                </p>
                <p>
                  Your answers will never be linked to you. Results aggregate to
                  industry-level reports to help us guide and promote
                  transparency, accuracy, and industry accountability to the
                  production companies, agencies, brands, tv & film studios.
                </p>
                <p>
                  We can only raise up the production industry with your help.
                </p>
                <p>You in?</p>{" "}
                <p>
                  Need to reach a person?{" "}
                  <a href="mailto:hello@workinginsights.com">
                    hello@workinginsights.com
                  </a>
                </p>
              </>
            )}
          </div>
        </div>

        <div className="actions">
          <Button
            onClick={() => {
              analytics.track("Start Survey")
              setStep(2)
            }}
          >
            Let's do this
          </Button>

          <Button
            onClick={() => {
              analytics.track("Previously Enrolled")
              setStep("phone")
            }}
          >
            I'm already enrolled
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Opening
