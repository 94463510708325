import React, { useState } from "react"
import ANSWERS from "../../constants/Constant"
import SingleSelect from "../shared/SingleSelect"
import RequiredError from "../shared/RequiredError"
import { findQuestion } from "../../util/find"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"

const useStyles = makeStyles(theme => ({
  radioGroup: {
    flexDirection: "row",
    marginLeft: "20px",
    paddingTop: "5px",
  },
  radio: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
}))

const BiddingExperience = ({
  survey,
  surveySection,
  showError,
  handleChangeMultiple,
  payment,
  setPayment,
  competitive,
  setCompetitive,
  turnAround,
  customQuestions,
  setCustomA,
  customA,
}) => {
  const classes = useStyles()
  const [turnAroundOpen, setTurnAroundOpen] = useState(false)
  return (
    <div className="question-section">
      <h3>My Experience</h3>
      <p>
        The following are designed to describe your overall experience with the
        bidding process:
      </p>
      <QuestionsWrapper
        customQuestions={customQuestions}
        setCustomA={setCustomA}
        customA={customA}
        showError={showError}
      >
        {findQuestion(surveySection.questions, "exp0")?.visibility === 1 && (
          <div className="question-wrapper">
            I am being paid to bid this job
            <div className="field-wrapper">
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.radioGroup}
                  name="payment"
                  id="payment"
                  defaultValue={payment}
                  onChange={e => {
                    setPayment(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio className={classes.radio} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio className={classes.radio} />}
                    label="No"
                  />
                </RadioGroup>

                <RequiredError
                  error={
                    findQuestion(surveySection.questions, "exp0")?.required ===
                      1 &&
                    showError &&
                    payment === null
                  }
                  required={
                    findQuestion(surveySection.questions, "exp0")?.required ===
                    1
                  }
                  classes="field-asterisk more-right2"
                />
              </FormControl>
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp1")?.visibility === 1 && (
          <div className="question-wrapper">
            I am competitively bidding for this job
            <div className="field-wrapper">
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.radioGroup}
                  name="competitive"
                  id="competitive"
                  defaultValue={competitive}
                  onChange={e => {
                    setCompetitive(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio className={classes.radio} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio className={classes.radio} />}
                    label="No"
                  />
                </RadioGroup>

                <RequiredError
                  error={
                    findQuestion(surveySection.questions, "exp1")?.required ===
                      1 &&
                    showError &&
                    competitive === null
                  }
                  required={
                    findQuestion(surveySection.questions, "exp1")?.required ===
                    1
                  }
                  classes="field-asterisk more-right2"
                />
              </FormControl>
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp2")?.visibility === 1 && (
          <div className="question-wrapper">
            The turnaround on this bid is
            <div className="field-wrapper">
              <SingleSelect
                type="turnAround"
                value={turnAround}
                survey={survey}
                showError={showError}
                options={ANSWERS.biddingExperience}
                handleChangeMultiple={handleChangeMultiple}
                setOpen={setTurnAroundOpen}
                open={turnAroundOpen}
              />

              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp2")?.required ===
                    1 &&
                  showError &&
                  turnAround === null
                }
                required={
                  findQuestion(surveySection.questions, "exp2")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
            </div>
          </div>
        )}
      </QuestionsWrapper>
    </div>
  )
}

export default BiddingExperience
