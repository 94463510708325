// eslint-disable-next-line
export default value => {
  const page1 = [
    "employmentType",
    "grossIncome",
    "jobTitles",
    "liveIn",
    "longHours",
    "longHoursRole",
    "monthlyIncome",
    "opportunity",
    "primaryWork",
    "rate",
    "rateDimension",
    "trainingProgram",
    "union",
    "viaProgram",
    "workOften",
    "yearlyIncome",
    "yearsOfExperience",
  ]

  const page2 = [
    "healthcareCovered",
    "healthcarePlan",
    "healthcareSource",
    "workWithoutHealthcare",
    "monthlyInsurancePlan",
    "paidLeave",
    "dependents",
    "retirementSavings",
    "education",
  ]

  const page3 = [
    "ageRange",
    "birthPlace",
    "generation",
    "immigrant",
    "ancestry",
    "nativeLanguage",
    "secondaryLanguage",
    "ethnicity",
    "disabilityStatus",
    "disabilityType",
    "veteranStatus",
    "militaryBranch",
    "gender",
    "sexualOrientation",
  ]

  const page4 = ["experienceHate", "incidentReport", "representation"]

  if (page1.includes(value)) {
    return { validation: false, page: 1 }
  }

  if (page2.includes(value)) {
    return { validation: false, page: 2 }
  }

  if (page3.includes(value)) {
    return { validation: false, page: 3 }
  }

  if (page4.includes(value)) {
    return { validation: false, page: 4 }
  }
}
