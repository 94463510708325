import React from "react"
import { FormHelperText } from "@material-ui/core"
import Asterisk from "../../images/asterisk-solid.svg"
const RequiredImg = ({ error, required, classes }) => {
  if (required)
    return (
      <>
        {error && (
          <FormHelperText className="error lang">
            Please make a selection
          </FormHelperText>
        )}

        <img className={classes} alt="Required" src={Asterisk} />
      </>
    )
  else return null
}

export default RequiredImg