import React, { useState, useEffect } from "react"
import { Button, Dialog, FormControl, FormHelperText } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import Header from "./header"
import MuiPhoneNumber from "material-ui-phone-number"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import analytics from "./Analytics"
import { ModalWrapper } from "./style"
import { isValidPhoneNumber } from "libphonenumber-js"
import TermsPrivacy from "./shared/Terms"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 500,
    position: "relative",
    "@media (max-width: 767px)": {
      minWidth: 200,
      width: "100%",
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "16px",
    paddingBottom: 0,
    border: "1px solid #fff",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#fff",
    marginBottom: "10px",
  },
}))

function Successful({
  preEnrollmentUUID,
  biddingUUID,
  retroactiveUUID,
  setIsOptIn,
  setStep,
  setVerifyPhoneNumber,
  uuid,
  customProjectUUID,
}) {
  const classes = useStyles()
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [open, setOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [country, setCountry] = useState("US")
  const [errorMessage, setErrorMessage] = useState(
    "The number entered does not match the length for the country selected."
  )
  const [terms, setTerms] = useState(false)

  useEffect(() => {
    analytics.page({
      title: "Sucessful Step",
      path: "/successful",
    })

    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  const handlePhoneChange = (e, countryData) => {
    setPhone(e)
    setCountry(countryData.countryCode.toUpperCase())
    if (e.length > 8) {
      setShowError(false)
      setDisabled(false)
    } else {
      setShowError(true)
    }
  }

  const validation = () => {
    if (isValidPhoneNumber(phone, country)) {
      return true
    } else {
      setShowError(true)
      return false
    }
  }

  const submit = () => {
    if (validation()) {
      setLoading(true)
      setVerifyPhoneNumber(phone)
      const data = {
        uuid: uuid,
        phoneNumber: phone,
        type: preEnrollmentUUID
          ? "entry-pre-enrollment"
          : biddingUUID
          ? "entry-bidding"
          : retroactiveUUID
          ? "entry-retroactive"
          : customProjectUUID
          ? "entry-custom-project"
          : "entry-self-id",
      }

      axios
        .post(`${API_ROOT}${ENV}/twilio/get-code`, data)
        .then(response => {
          analytics.track("Opt in")
          if (response.data.statusCode === 200) {
            setLoading(false)
            setIsOptIn(true)
            setStep("verification")
          } else {
            if (response.data.devMessage.includes("not supported")) {
              setErrorMessage("Please enter a mobile phone number.")
              setShowError(true)
            } else {
              alert(response.data.devMessage)
            }
            setLoading(false)
          }
        })
        .catch(error => {
          setLoading(false)
        })
    }
  }
  return (
    <section className="successful">
      <div className="container">
        <Header />
        <div className="bg">
          <h3>Submission Successful!</h3>
          <p>
            We imagine you'll be on lots of projects, so would you like us to
            retain your info for next time? We'll ask your permission per
            production and you can always change your information.
          </p>
          <FormControl className={classes.formControl}>
            <label htmlFor="phone" className={classes.label}>Phone Number</label>

            <MuiPhoneNumber
              defaultCountry={"us"}
              disableAreaCodes={true}
              autoFormat={false}
              countryCodeEditable={false}
              preferredCountries={["us"]}
              onChange={(e, countryData) => handlePhoneChange(e, countryData)}
              InputProps={{
                className: classes.select,
              }}
            />
            <ExpandMoreIcon className="dropdown-icon" fontSize="small" />

            {showError && (
              <FormHelperText className="error lang">
                {errorMessage}
              </FormHelperText>
            )}
            <p className="change-country">Click to change country code</p>
          </FormControl>
          <div className="actions success">
            <TermsPrivacy terms={terms} setTerms={setTerms} />
            <Button onClick={submit} disabled={disabled || loading || !terms}>
              {loading ? (
                <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="30px"
                  viewBox="0 0 24 30"
                >
                  <rect
                    x="0"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="8"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="16"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </svg>
              ) : (
                "Submit"
              )}
            </Button>
            <a
              href="#"
              onClick={() => {
                analytics.track("No, thanks")
                setOpen(true)
              }}
            >
              No thanks.
            </a>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        >
          <ModalWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="28"
              viewBox="0 0 27 28"
              onClick={() => {
                setOpen(false)
              }}
            >
              <g fill="none" fillRule="evenodd" strokeLinecap="square">
                <g stroke="#FFF">
                  <g>
                    <g>
                      <path
                        d="M26.707 0.283L0.06 25.942"
                        transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000)"
                      />
                      <path
                        d="M26.707 0.283L0.06 25.942"
                        transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000) translate(13.500000, 13.000000) scale(-1, 1) translate(-13.500000, -13.000000)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div className="prompt">
              <h3>We respect that, but hear us out.</h3>
              <p>
                You're getting booked left and right, so you'll probably take
                another one of these surveys again soon. Save your info now, so
                you can fly through it faster later.
              </p>
              <p>
                Don't worry, we'll keep it encrypted. That's tech talk for
                'safe'.
              </p>
              <div className="actions">
                <Button
                  onClick={() => {
                    analytics.track(`"No, thanks" (2nd Opt out)`)
                    setStep(4)
                  }}
                >
                  No, Thanks
                </Button>
                <Button
                  onClick={() => {
                    analytics.track(`"Ok, let's enroll" (2nd Opt in)`)
                    setOpen(false)
                  }}
                >
                  Ok, let's enroll
                </Button>
              </div>
            </div>
          </ModalWrapper>
        </Dialog>
      </div>
    </section>
  )
}

export default Successful
