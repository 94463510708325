import Analytics from "analytics"
import googleAnalytics from "@analytics/google-analytics"
import { GA_ID } from "gatsby-env-variables"
const analytics = Analytics({
  app: "self-id",
  plugins: [
    googleAnalytics({
      measurementIds: [GA_ID],
    }),
  ],
})

/* export the instance for usage in your app */
export default analytics
