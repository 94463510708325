import React, { useState } from "react"
import RequiredError from "../shared/RequiredError"
import AutoComplete from "../shared/AutoComplete"
import SingleSelect from "../shared/SingleSelect"
import ANSWERS from "../../constants/Constant"
import { FormControl, Popover } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import { PopoverWrapper } from "../style"
import CurrencyDropdown from "../shared/CurrencyDropdown"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import { findQuestion } from "../../util/find"
import InfoIcon from "../../images/info-icon.svg"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  rate: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "20px",
    width: "150px",
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  infoPaper: {
    padding: theme.spacing(1),
  },
}))

const RetroactiveExperience = ({
  survey,
  surveySection,
  showError,
  handleChangeMultiple,
  timeline,
  workOvertime,
  currency,
  setCurrency,
  rate,
  setRate,
  payRate,
  referred,
  setReferred,
  setCustomOpen,
  setCustomA,
  customA,
  customQuestions,
}) => {
  const classes = useStyles()
  const [timelineOpen, setTimelineOpen] = useState(false)
  const [workOvertimeOpen, setWorkOvertimeOpen] = useState(false)
  const [payRateOpen, setPayRateOpen] = useState(false)
  const [referredOpen, setReferredOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const rateInfoOpen = Boolean(anchorEl)

  const handlePopoverOpen = (event, target) => {
    if (target === "comp") {
      setAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = target => {
    if (target === "comp") {
      setAnchorEl(null)
    }
  }
  return (
    <div className="question-section">
      <h3>My Experience</h3>
      <p>
        The following are designed to describe your overall experience with this
        brand:
      </p>
      <QuestionsWrapper
        customA={customA}
        customQuestions={customQuestions}
        setCustomA={setCustomA}
        showError={showError}
      >
        {findQuestion(surveySection.questions, "exp0")?.visibility === 1 && (
          <div className="question-wrapper">
            Timelines are{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="timeline"
                value={timeline}
                survey={survey}
                showError={showError}
                options={ANSWERS.timeline}
                open={timelineOpen}
                setOpen={setTimelineOpen}
                handleChangeMultiple={handleChangeMultiple}
              />{" "}
              on projects with this brand.
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp0")?.required ===
                    1 &&
                  showError &&
                  timeline === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp0")?.required === 1
                }
                classes="field-asterisk timeline"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp1")?.visibility === 1 && (
          <div className="question-wrapper">
            I{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="workOvertime"
                value={workOvertime}
                survey={survey}
                showError={showError}
                options={ANSWERS.workOvertime}
                open={workOvertimeOpen}
                setOpen={setWorkOvertimeOpen}
                handleChangeMultiple={handleChangeMultiple}
              />{" "}
              work overtime on this project.
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp1")?.required ===
                    1 &&
                  showError &&
                  workOvertime === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp1")?.required === 1
                }
                classes="field-asterisk timeline"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp2")?.visibility === 1 && (
          <div className="question-wrapper">
            <div className="field-wrapper">
              <span>My general rate is</span>
              <FormControl required className={classes.formControl}>
                <div className="field-wrapper">
                  <CurrencyDropdown
                    currency={currency}
                    setCurrency={setCurrency}
                  />
                </div>
              </FormControl>

              <FormControl
                required
                className={classes.formControl}
                style={{ width: 150 }}
              >
                <div className="field-wrapper">
                  <CurrencyTextField
                    variant="standard"
                    value={rate}
                    id="rate"
                    type="tel"
                    currencySymbol={""}
                    outputFormat="string"
                    error={
                      findQuestion(surveySection.questions, "exp2")
                        ?.required === 1 &&
                      showError &&
                      rate === ""
                    }
                    minimumValue={0}
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setRate(value)}
                    InputProps={{
                      className: classes.rate,
                    }}
                  />
                </div>
              </FormControl>
            </div>
            <div className="field-wrapper">
              /{" "}
              <SingleSelect
                type="payRate"
                value={payRate}
                survey={survey}
                showError={showError}
                options={ANSWERS.rateDimension}
                open={payRateOpen}
                setOpen={setPayRateOpen}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp2")?.required ===
                    1 &&
                  showError &&
                  payRate === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp2")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
              <img
                aria-hidden="true"
                aria-owns={rateInfoOpen ? "comp-popover" : undefined}
                onMouseEnter={e => handlePopoverOpen(e, "comp")}
                onMouseLeave={() => handlePopoverClose("comp")}
                className="role-info"
                src={InfoIcon}
                alt="Info"
              />
              <Popover
                id="comp-popover"
                className={classes.popover}
                classes={{
                  infoPaper: classes.infoPaper,
                }}
                open={rateInfoOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={() => handlePopoverClose("comp")}
                disableRestoreFocus
                PaperProps={{
                  style: {
                    width: "100%",
                    maxWidth: "320px",
                    padding: "15px",
                    background: "#c4c4c4",
                  },
                }}
              >
                <PopoverWrapper>
                  <h6>
                    Remember, your answers are anonymous and aggregated across
                    multiple productions!
                  </h6>
                  <p>
                    We require a minimum of 5 inputs to report on any given
                    metric. Compensation information is aggregated to help
                    establish industry baselines for rates of pay. Help us
                    empower you with information to better advocate for yourself
                    and others.
                  </p>
                </PopoverWrapper>
              </Popover>
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp3")?.visibility === 1 && (
          <div className="question-wrapper">
            I was first referred to this brand by{" "}
            <FormControl className={classes.formControl}>
              <div className="field-wrapper">
                <AutoComplete
                  type="referred"
                  value={referred}
                  setValue={setReferred}
                  open={referredOpen}
                  setOpen={setReferredOpen}
                  options={ANSWERS.referral}
                  survey={
                    findQuestion(surveySection.questions, "exp3")?.required
                  }
                  className="referral-chips"
                  setCustomOpen={setCustomOpen}
                  queryClass=".referral-chips .MuiChip-label"
                  showError={showError}
                  multiple={true}
                />
                <RequiredError
                  error={
                    findQuestion(surveySection.questions, "exp3")?.required ===
                      1 &&
                    showError &&
                    referred === ""
                  }
                  required={
                    findQuestion(surveySection.questions, "exp3")?.required ===
                    1
                  }
                  classes="field-asterisk more-right2"
                />
              </div>
            </FormControl>
            .
          </div>
        )}
      </QuestionsWrapper>
    </div>
  )
}

export default RetroactiveExperience
