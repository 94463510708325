import React, { useState, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core/"
import { Autocomplete, createFilterOptions } from "@material-ui/lab/"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { isMobile } from "mobile-device-detect"
import { useLongPress } from "use-long-press"

const useStyles = makeStyles(theme => ({
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
}))

const AutoComplete = ({
  type,
  value,
  setValue,
  options,
  survey,
  open,
  setOpen,
  className,
  setCustomOpen,
  queryClass,
  showError,
  multiple,
  onChangeHandle,
  standWithProduction,
  hideEndAdornment = false,
}) => {
  const classes = useStyles()
  const _filterOptions = createFilterOptions()

  const [enabled] = useState(true)
  const callback = useCallback(event => {
    if (!multiple) {
      setValue(event.target.childNodes[1].data)
    } else {
      setValue(prev => [...prev, event.target.childNodes[1].data])
    }
    // eslint-disable-next-line
  }, [])
  const bind = useLongPress(enabled ? callback : null, {
    filterEvents: event => true,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: 25,
    cancelOutsideElement: true,
    detect: "pointer",
  })

  return (
    <Autocomplete
      value={value}
      multiple={multiple}
      id={type}
      className={className}
      open={open}
      limitTags={2}
      ListboxProps={{
        style: { maxHeight: isMobile ? "25vh" : "auto" },
      }}
      onClose={() => {
        setOpen(false)
      }}
      onOpen={() => {
        if (
          type !== "birthplace" &&
          type !== "nationality" &&
          type !== "nativeLang" &&
          type !== "secondLang"
        ) {
          setOpen(true)
        }
      }}
      disableCloseOnSelect
      onInputChange={(_, v) => {
        if (
          type === "birthplace" ||
          type === "nationality" ||
          type === "nativeLang" ||
          type === "secondLang"
        ) {
          if (v.length === 0) {
            setOpen(false)
          } else {
            if (value !== v) setOpen(true)
            else setOpen(false)
          }
        }
      }}
      options={options}
      filterOptions={(options, state) => {
        let results = _filterOptions(options, state)
        if (standWithProduction === undefined) {
          if (["gender", "ethnicity", "ethnicity1"].includes(type)) {
            results.push("Prefer to self describe")
          } else {
            results.push("Not Listed")
          }

          results.push("Prefer not to say")
        }

        return results
      }}
      renderOption={(option, { selected }) => {
        if (option !== "Type to search") {
          return (
            <div {...bind()}>
              {selected ? (
                <CheckBoxIcon
                  style={{
                    marginRight: "8px",
                    pointerEvents: "none",
                  }}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  style={{
                    marginRight: "8px",
                    pointerEvents: "none",
                  }}
                />
              )}

              {option}
            </div>
          )
        } else {
          return <i {...bind()}>{option}</i>
        }
      }}
      getOptionDisabled={option => {
        if (
          option === "Type to search" ||
          value?.includes("Prefer not to say")
        ) {
          return true
        }
      }}
      onChange={(e, newValue) => {
        if (multiple) {
          const element = document.querySelectorAll(queryClass)
          if (
            ["gender", "ethnicity", "ethnicity1"].includes(type) &&
            newValue.length > 0 &&
            newValue[newValue.length - 1] === "Prefer to self describe"
          ) {
            setCustomOpen({ open: true, type: type })
          }
          if (
            newValue.length > 0 &&
            newValue[newValue.length - 1] === "Not Listed"
          ) {
            setCustomOpen({ open: true, type: type })
          }

          if (newValue.length > 0 && newValue[1] === "Prefer not to say") {
            const el = document.getElementsByClassName("hide-it")

            el.length > 0 && el[0].classList.remove("hide-it")

            setValue(["Prefer not to say"])
          } else {
            if (["gender", "ethnicity", "ethnicity1"].includes(type)) {
              setValue(newValue.filter(i => i !== "Prefer to self describe"))
            } else {
              setValue(newValue.filter(i => i !== "Not Listed"))
            }
          }

          if (element) {
            const notListIndex = newValue.indexOf("Not Listed")

            for (var i = 0; i < element.length; i++) {
              element[i].parentElement.classList.remove("hide-it")
              if (element[i].innerHTML === "Not Listed") {
                i - 1 === notListIndex
                  ? element[i - 1]?.parentElement.classList.add("hide-it")
                  : element[i]?.parentElement.classList.add("hide-it")
              }

              if (newValue.length > 0 && newValue[1] === "Prefer not to say") {
                element[i]?.parentElement.classList.remove("hide-it")
              }

              if (newValue.length === 1 && newValue[0] === "Not Listed") {
                setValue([])
              }

              newValue.length === 1 &&
                i === 1 &&
                notListIndex === 0 &&
                setValue([])
            }
          }
        } else {
          const selectedValue = e.target.innerHTML.split("</svg>")

          if (selectedValue.length > 0 && selectedValue[1] === "Not Listed") {
            setCustomOpen({ open: true, type: type })
          }

          setValue(newValue)
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          error={
            survey === 1 && showError && (value?.length === 0 || value === "")
          }
          onChange={ev => {
            if (
              (type === "secondLang" || type === "nativeLang") &&
              (ev.target.value !== "" || ev.target.value !== null)
            ) {
              onChangeHandle(ev.target.value)
            }
          }}
          placeholder={type === "birthplace" && "Enter birth country"}
          shrink="false"
          InputProps={{
            ...params.InputProps,
            className: classes.select,
            endAdornment: !hideEndAdornment && (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default AutoComplete
