import React from "react"
import Header from "./header"
const Closed = () => (
  <section className="opening">
    <div className="container">
      <Header />
      <div>
        <h2>This survey is no longer available.</h2>
        
      </div>

      {/* <div className="actions">
        <Button onClick={() => setStep(2)}>Start</Button>
      </div> */}
    </div>
  </section>
)

export default Closed
