import React, { useState, useEffect } from "react"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import ANSWERS from "../../constants/Constant"
import { findQuestion } from "../../util/find"
import QuestionsWrapper from "../ProjectType/QuestionsWrapper"

const PreenrollmentExperience = ({
  survey,
  surveySection,
  showError,
  handleChangeMultiple,
  prefilledData,
  experience1,
  experience2,
  experience3,
  experience4,
  experience5,
  setExperience1Custom,
  setExperience2Custom,
  setExperience3Custom,
  setExperience4Custom,
  setExperience5Custom,
  customQuestions,
  setCustomA,
  customA,
}) => {
  const [experienceOpen1, setExperienceOpen1] = useState(false)
  const [experienceOpen2, setExperienceOpen2] = useState(false)
  const [experienceOpen3, setExperienceOpen3] = useState(false)
  const [experienceOpen4, setExperienceOpen4] = useState(false)
  const [experienceOpen5, setExperienceOpen5] = useState(false)
  const [experience1CustomSet, setExperience1CustomSet] = useState(false)
  const [experience2CustomSet, setExperience2CustomSet] = useState(false)
  const [experience3CustomSet, setExperience3CustomSet] = useState(false)
  const [experience4CustomSet, setExperience4CustomSet] = useState(false)
  const [experience5CustomSet, setExperience5CustomSet] = useState(false)

  useEffect(() => {
    if (
      prefilledData !== "" &&
      prefilledData?.experiences !== undefined &&
      prefilledData?.experiences.length > 0 &&
      prefilledData.experiences[0].type === "not listed" &&
      !experience1CustomSet
    ) {
      const d = document.getElementById("experience1")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData.experiences[0].name
        setExperience1Custom(prefilledData.experiences[0].name)
        setExperience1CustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [experience1])

  useEffect(() => {
    if (
      prefilledData !== "" &&
      prefilledData?.experiences !== undefined &&
      prefilledData?.experiences.length > 0 &&
      prefilledData.experiences[1].type === "not listed" &&
      !experience2CustomSet
    ) {
      const d = document.getElementById("experience2")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData.experiences[1].name
        setExperience2Custom(prefilledData.experiences[1].name)
        setExperience2CustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [experience2])

  useEffect(() => {
    if (
      prefilledData !== "" &&
      prefilledData?.experiences !== undefined &&
      prefilledData?.experiences.length > 0 &&
      prefilledData.experiences[2].type === "not listed" &&
      !experience3CustomSet
    ) {
      const d = document.getElementById("experience3")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData.experiences[2].name
        setExperience3Custom(prefilledData.experiences[2].name)
        setExperience3CustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [experience3])

  useEffect(() => {
    if (
      prefilledData !== "" &&
      prefilledData?.experiences !== undefined &&
      prefilledData?.experiences.length > 0 &&
      prefilledData.experiences[3].type === "not listed" &&
      !experience4CustomSet
    ) {
      const d = document.getElementById("experience4")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData.experiences[3].name
        setExperience4Custom(prefilledData.experiences[3].name)
        setExperience4CustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [experience4])

  useEffect(() => {
    if (
      prefilledData !== "" &&
      prefilledData?.experiences !== undefined &&
      prefilledData?.experiences.length > 0 &&
      prefilledData.experiences[4].type === "not listed" &&
      !experience5CustomSet
    ) {
      const d = document.getElementById("experience5")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData.experiences[4].name
        setExperience5Custom(prefilledData.experiences[4].name)
        setExperience5CustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [experience5])

  return (
    <div className="question-section">
      <h3>My Experience</h3>
      <p>
        The following are designed to describe your overall experience in the
        industry. Rate the following:
      </p>
      <QuestionsWrapper
        customQuestions={customQuestions}
        setCustomA={setCustomA}
        customA={customA}
        showError={showError}
      >
        {findQuestion(surveySection.questions, "exp0")?.visibility === 1 && (
          <div className="question-wrapper">
            I feel that my voice matters{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="experience1"
                value={experience1}
                survey={survey}
                showError={showError}
                options={ANSWERS.experience}
                open={experienceOpen1}
                setOpen={setExperienceOpen1}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp0")?.required ===
                    1 &&
                  showError &&
                  experience1 === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp0")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp1")?.visibility === 1 && (
          <div className="question-wrapper">
            I feel included in decision-making
            <div className="field-wrapper">
              <SingleSelect
                type="experience2"
                value={experience2}
                survey={survey}
                showError={showError}
                options={ANSWERS.experience}
                open={experienceOpen2}
                setOpen={setExperienceOpen2}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp1")?.required ===
                    1 &&
                  showError &&
                  experience2 === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp1")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp2")?.visibility === 1 && (
          <div className="question-wrapper">
            I have what I need to do my job well{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="experience3"
                value={experience3}
                survey={survey}
                showError={showError}
                options={ANSWERS.experience}
                open={experienceOpen3}
                setOpen={setExperienceOpen3}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp2")?.required ===
                    1 &&
                  showError &&
                  experience3 === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp2")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp3")?.visibility === 1 && (
          <div className="question-wrapper">
            I feel safe in the workplace{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="experience4"
                value={experience4}
                survey={survey}
                showError={showError}
                options={ANSWERS.experience}
                open={experienceOpen4}
                setOpen={setExperienceOpen4}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp3")?.required ===
                    1 &&
                  showError &&
                  experience4 === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp3")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
            </div>
          </div>
        )}

        {findQuestion(surveySection.questions, "exp4")?.visibility === 1 && (
          <div className="question-wrapper">
            I am hired for my skill{" "}
            <div className="field-wrapper">
              <SingleSelect
                type="experience5"
                value={experience5}
                survey={survey}
                showError={showError}
                options={ANSWERS.experience}
                open={experienceOpen5}
                setOpen={setExperienceOpen5}
                handleChangeMultiple={handleChangeMultiple}
              />
              .
              <RequiredError
                error={
                  findQuestion(surveySection.questions, "exp4")?.required ===
                    1 &&
                  showError &&
                  experience5 === ""
                }
                required={
                  findQuestion(surveySection.questions, "exp4")?.required === 1
                }
                classes="field-asterisk more-right2"
              />
            </div>
          </div>
        )}
      </QuestionsWrapper>
    </div>
  )
}

export default PreenrollmentExperience
