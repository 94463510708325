import React, { useState, useEffect } from "react"
import Header from "../../header"
import Asterisk from "../../../images/asterisk-solid.svg"
import {
  Button,
  CircularProgress,
  FormControl,
  Snackbar as MaterialSnackbar,
} from "@material-ui/core"
import MyCareerSection from "../../sections/MyCareer"
import MyResourceSection from "../../sections/MyResources"
import BackgroundSection from "../../sections/Background"
import MyExperiencesSection from "../../sections/MyExperiences"
import MyInterestsSection from "../../sections/MyInterests"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import validation from "../../../util/validation"
import LANGUAGES from "../../../constants/Languages"
import ANSWERS from "../../../constants/Constant"
import imigrantLookups from "../../../util/imigrantLookups"
import analytics from "../../Analytics"
import backendValidationMapping from "../../../util/backendValidationMapping"
import MuiAlert from "@material-ui/lab/Alert"
import { findSection } from "../../../util/find"

function StandWithProduction({
  prefilledData,
  customProjectUUID,
  survey,
  setUuid,
  setStep,
  setErrorMessage,
}) {

  const BACKGROUND_SECTION = findSection(survey, "background")

  const [page, setPage] = useState(1)
  const [value, setValue] = useState({})
  const [careerValue, setCareerValue] = useState({})
  const [resourcesValue, setResourcesValue] = useState({})
  const [backgroundValue, setBackgroundValue] = useState({})
  const [experiencesValue, setExperiencesValue] = useState({})
  const [interestValue, setInterestvalue] = useState({})
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [open, setOpen] = useState(false)
  const [apiError, setApiError] = useState("")
  useEffect(() => {
    if (
      Object.keys(careerValue).length !== 0 &&
      Object.keys(resourcesValue).length !== 0 &&
      Object.keys(backgroundValue).length !== 0 &&
      Object.keys(experiencesValue).length !== 0 &&
      Object.keys(interestValue).length !== 0
    ) {
      const ethnicityObj = [
        ...(backgroundValue.ethnicity || []),
        ...(backgroundValue.ethnicity1 || []),
      ]

      setValue(prev => ({
        ...prev,
        customProjectUUID: customProjectUUID,
        updateId: prefilledData.updateId || "",
        copyFrom: prefilledData.copyFrom || "",

        employmentType: careerValue.employmentType,
        grossIncome: careerValue.grossIncome,
        jobTitles: careerValue.jobTitle,
        liveIn: careerValue.liveIn,
        longHours: careerValue.longHours,
        longHoursRole: careerValue.longHoursRole,
        monthlyIncome: careerValue.monthlyIncome,
        opportunity: careerValue.opportunity,
        primaryWork: careerValue.primaryWork,
        rate: careerValue.rate,
        rateDimension: careerValue.rateDimension,
        trainingProgram: careerValue.trainingProgram,
        union: careerValue.union,
        viaProgram: careerValue.viaProgram,
        workOften: careerValue.workOften,
        yearlyIncome: careerValue.yearlyIncome,
        yearsOfExperience: careerValue.yearsOfExperience,

        healthcareCovered: resourcesValue.healthcareCovered,
        healthcarePlan: resourcesValue.healthcarePlan,
        healthcareSource: resourcesValue.healthcareSource,
        workWithoutHealthcare: resourcesValue.workWithoutHealthcare,
        monthlyInsurancePlan: resourcesValue.monthlyInsurancePlan,
        paidLeave: resourcesValue.paidLeave,
        dependents: resourcesValue.dependents,
        retirementSavings: resourcesValue.retirementSavings,
        education: resourcesValue.education,

        ageRange: backgroundValue.ageRange,
        birthPlace: backgroundValue.birthPlace,

        generation: [
          {
            value:
              backgroundValue?.immigrant === "Not Listed"
                ? backgroundValue.generationCustom
                : imigrantLookups(backgroundValue?.immigrant),
            type:
              backgroundValue?.immigrant === "Not Listed"
                ? "not listed"
                : "listed",
          },
        ],

        immigrant: backgroundValue?.immigrant,

        ancestry:
          backgroundValue?.ancestry?.length > 0
            ? backgroundValue?.ancestry
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.nationality.includes(g) ||
                    backgroundValue?.ancestry?.includes("Prefer not to say")
                      ? "listed"
                      : "not listed",
                }))
            : [],
        nativeLanguage:
          backgroundValue?.nativeLanguage === ""
            ? ""
            : {
                value: backgroundValue?.nativeLanguage,
                type:
                  LANGUAGES.nativeLanguage.includes(
                    backgroundValue?.nativeLanguage
                  ) ||
                  backgroundValue?.nativeLanguage?.includes("Prefer not to say")
                    ? "listed"
                    : "not listed",
              },
        secondaryLanguage:
          backgroundValue?.secondaryLanguage?.length > 0
            ? backgroundValue.secondaryLanguage
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    LANGUAGES?.nativeLanguage?.includes(g) ||
                    backgroundValue?.secondaryLanguage?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],
        ethnicity: ethnicityObj
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.ethnicity.includes(g) ||
              ethnicityObj?.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),
        disabilityStatus: backgroundValue.disabilityStatus,
        disabilityType:
          backgroundValue?.disabilityType?.length > 0
            ? backgroundValue.disabilityType
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.disabilityType.includes(g) ||
                    backgroundValue?.disabilityType?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],
        veteranStatus: backgroundValue?.veteranStatus,
        militaryBranch:
          backgroundValue?.militaryBranch?.length > 0
            ? backgroundValue?.militaryBranch
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.militaryBranch.includes(g) ||
                    backgroundValue?.militaryBranch?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],
        gender: backgroundValue?.gender
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.gender.includes(g) ||
              backgroundValue?.gender?.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),
        sexualOrientation: backgroundValue?.sexualOrientation
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.sexualOrientation.includes(g) ||
              backgroundValue?.sexualOrientation?.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),

        experienceHate: experiencesValue.experienceHate,
        incidentReport: experiencesValue.incidentReport,
        representation: experiencesValue.representation,
        shareMore: experiencesValue.shareMore,

        cultureSafety: parseInt(interestValue.cultureSafety) || null,
        sexDiscrimination: parseInt(interestValue.sexDiscrimination) || null,
        basicSetSafety: parseInt(interestValue.basicSetSafety) || null,
        antiHarassment: parseInt(interestValue.antiHarassment) || null,
        substanceAbuseAwareness:
          parseInt(interestValue.substanceAbuseAwareness) || null,
        selfCare: parseInt(interestValue.selfCare) || null,
        healthcarePlanOptions:
          parseInt(interestValue.healthcarePlanOptions) || null,
        pensionPlan: parseInt(interestValue.pensionPlan) || null,
        upwardMobility: parseInt(interestValue.upwardMobility) || null,
        understandingBudget:
          parseInt(interestValue.understandingBudget) || null,
        softwareTraining: parseInt(interestValue.softwareTraining) || null,
        personalEducation: parseInt(interestValue.personalEducation) || null,
        drivingTraining: parseInt(interestValue.drivingTraining) || null,
        etiquette: parseInt(interestValue.etiquette) || null,
        roleResponsibilities:
          parseInt(interestValue.roleResponsibilities) || null,
        negotiationTraining:
          parseInt(interestValue.negotiationTraining) || null,
        networking: parseInt(interestValue.networking) || null,
      }))
    }
    // eslint-disable-next-line
  }, [
    careerValue,
    resourcesValue,
    backgroundValue,
    experiencesValue,
    interestValue,
  ])

  const onClickNext = () => {
    if (page < 5) {
      setPage(page + 1)
    }
  }

  const onClickPrev = () => {
    if (page !== 1) {
      setPage(page - 1)
    }
  }

  const onClickSubmit = () => {
    setLoading(true)
    const validDataCheck = validation(value)
    if (!validDataCheck.validation) {
      setShowError(true)
      setPage(validDataCheck.page)
      setLoading(false)
    } else {
      axios
        .post(`${API_ROOT}${ENV}/survey-response/custom-project/signup`, value)
        .then(response => {
          if (response.data.statusCode === 200) {
            setUuid(response.data.devMessage)

            analytics.track("Completed Survey", {
              category: "Stand with Production Survey",
            })

            setLoading(false)
            prefilledData === "" ? setStep(3) : setStep(4)
          } else {
            const err = backendValidationMapping(response.data.path)
            setApiError(err.page)
            setOpen(true)
            setLoading(false)
          }
        })
        .catch(error => {
          setErrorMessage(error)
          setStep("error")
          setLoading(false)
        })
    }
  }

  return (
    <section className="questions">
      <div className="container">
        <Header />
        <p className="indicates">
          <img src={Asterisk} alt="Required" />
          indicates required field
        </p>
        <div className="bg">
          <FormControl>
            <div className="questionaire proactive">
              {page === 1 && (
                <MyCareerSection
                  value={careerValue}
                  setValue={setCareerValue}
                  showError={showError}
                />
              )}

              {page === 2 && (
                <MyResourceSection
                  value={resourcesValue}
                  setValue={setResourcesValue}
                  showError={showError}
                />
              )}
              {page === 3 && (
                <BackgroundSection
                  value={backgroundValue}
                  setValue={setBackgroundValue}
                  prefilledData={prefilledData}
                  survey={survey}
                  showError={showError}
                  surveySection={BACKGROUND_SECTION}
                />
              )}
              {page === 4 && (
                <MyExperiencesSection
                  value={experiencesValue}
                  setValue={setExperiencesValue}
                  showError={showError}
                />
              )}
              {page === 5 && (
                <MyInterestsSection
                  value={interestValue}
                  setValue={setInterestvalue}
                  showError={showError}
                />
              )}
            </div>
          </FormControl>

          <div className="actions proactive">
            {page !== 1 && (
              <Button style={{ marginRight: "20px" }} onClick={onClickPrev}>
                Back
              </Button>
            )}
            {page !== 5 && <Button onClick={onClickNext}>Next</Button>}
            {page === 5 && (
              <Button onClick={onClickSubmit} disabled={loading}>
                {loading ? <CircularProgress color="black" /> : "Submit"}
              </Button>
            )}

            <div className="current-page">
              <span>
                {page} of {5}
              </span>
            </div>
          </div>
        </div>
      </div>

      <MaterialSnackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={1000}
        onClose={() => {
          setPage(apiError)
          setOpen(false)
        }}
        disableWindowBlurListener
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => {
            setOpen(false)
            setPage(apiError)
          }}
          severity={"error"}
        >
          All fields are required!
        </MuiAlert>
      </MaterialSnackbar>
    </section>
  )
}

export default StandWithProduction
