import React from "react"
import { FormControl, MenuItem, Select } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    minHeight: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      minHeight: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "24px",
    paddingBottom: 0,
    paddingLeft: "5px",
    "@media (max-width: 767px)": {
      minHeight: 25,
      fontSize: "16px",
    },
  },
}))

const SingleSelect = ({
  type,
  value,
  survey,
  showError,
  options,
  open,
  setOpen,
  handleChangeMultiple,
}) => {
  const classes = useStyles()
  return (
    <FormControl required className={classes.formControl}>
      <Select
        id={type}
        IconComponent={ArrowDropDownIcon}
        className={classes.select}
        value={value}
        onChange={e => handleChangeMultiple(e, type)}
        error={
          type === "yearBorn" &&
          survey[3].questions[0].required === 1 &&
          showError &&
          value === ""
            ? true
            : type === "disability" &&
              survey[3].questions[9].required === 1 &&
              showError &&
              value === ""
            ? true
            : type === "veteran" &&
              survey[3].questions[10].required === 1 &&
              showError &&
              value === ""
            ? true
            : type === "tackles" &&
              survey[4].questions[0].required === 1 &&
              showError &&
              value === ""
            ? true
            : type === "caredMost" &&
              survey[4].questions[1].required === 1 &&
              showError &&
              value === ""
            ? true
            : false
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {options.map((option, index) => (
          <MenuItem style={{whiteSpace: 'normal'}} key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SingleSelect
