import React, { useState, useEffect } from "react"
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
} from "@material-ui/core/"

import ANSWERS from "../../constants/Constant"
import LANGUAGES from "../../constants/Languages"
import Header from "../header"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import analytics from "../Analytics"
import Asterisk from "../../images/asterisk-solid.svg"
import formValidations from "../../util/formValidations"
import imigrantLookups from "../../util/imigrantLookups"
import { findSection, findQuestion } from "../../util/find"
import RoleSection from "../sections/Roles"
import PreenrollmentExperience from "../sections/PreenrollmentExperience"
import BackgroundSection from "../sections/Background"
import CustomAnswer from "../shared/CustomAnswer"
import CustomQuestion from "../sections/CustomQuestion"

function PreEnrollmentQuestions({
  roles,
  setStep,
  setUuid,
  preEnrollmentUUID,
  rosterUUID,
  prefilledData,
  formType,
  survey,
  setErrorMessage,
  customQuestions,
}) {
  const ROLE_SECTION = findSection(survey, "role")
  const BACKGROUND_SECTION = findSection(survey, "background")
  const EXPERIENCE_SECTION = findSection(survey, "experience")

  const [inclusion, setInclusion] = useState("")

  const [role, setRole] = useState([])

  const [currentService] = useState(false)
  const [yoe, setYoe] = useState("")
  const [secondaryRole, setSecondaryRole] = useState([])

  const [duty] = useState("")
  const [experience1, setExperience1] = useState("")
  const [experience2, setExperience2] = useState("")
  const [experience3, setExperience3] = useState("")
  const [experience4, setExperience4] = useState("")
  const [experience5, setExperience5] = useState("")

  const [customOpen, setCustomOpen] = useState({ open: false, type: "" })

  const [customAnswer, setCustomAnswer] = useState("")

  const [veteranYearsOfService] = useState("")

  const [experience1Custom, setExperience1Custom] = useState("")
  const [experience2Custom, setExperience2Custom] = useState("")
  const [experience3Custom, setExperience3Custom] = useState("")
  const [experience4Custom, setExperience4Custom] = useState("")
  const [experience5Custom, setExperience5Custom] = useState("")

  const [customA, setCustomA] = useState([])
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [backgroundValue, setBackgroundValue] = useState({})

  useEffect(() => {
    analytics.page({
      title: "Pre Enrollment Questions Step",
      path: "/questions-preenrollment",
    })

    window.scrollTo({ top: 0, behavior: "smooth" })
    if (prefilledData !== "") {
      setRole(
        findQuestion(ROLE_SECTION.questions, "role0")?.visibility === 1
          ? prefilledData?.primaryRoles || []
          : []
      )
      setYoe(
        findQuestion(ROLE_SECTION.questions, "role1")?.visibility === 1
          ? prefilledData?.yearsOfExperience
          : ""
      )
      setSecondaryRole(
        findQuestion(ROLE_SECTION.questions, "role2")?.visibility === 1
          ? prefilledData?.secondaryRole || []
          : []
      )

      if (
        prefilledData?.experiences &&
        prefilledData?.experiences?.length > 0
      ) {
        setExperience1(
          findQuestion(EXPERIENCE_SECTION.questions, "exp0")?.visibility === 1
            ? prefilledData?.experiences[0]?.type === "not listed"
              ? "Not Listed"
              : prefilledData?.experiences[0]?.name?.trim()
            : ""
        )
        setExperience2(
          findQuestion(EXPERIENCE_SECTION.questions, "exp1")?.visibility === 1
            ? prefilledData?.experiences[1].type === "not listed"
              ? "Not Listed"
              : prefilledData?.experiences[1].name.trim()
            : ""
        )
        setExperience3(
          findQuestion(EXPERIENCE_SECTION.questions, "exp2")?.visibility === 1
            ? prefilledData?.experiences[2].type === "not listed"
              ? "Not Listed"
              : prefilledData?.experiences[2].name.trim()
            : ""
        )
        setExperience4(
          findQuestion(EXPERIENCE_SECTION.questions, "exp3")?.visibility === 1
            ? prefilledData?.experiences[3].type === "not listed"
              ? "Not Listed"
              : prefilledData?.experiences[3].name.trim()
            : ""
        )
        setExperience5(
          findQuestion(EXPERIENCE_SECTION.questions, "exp4")?.visibility === 1
            ? prefilledData?.experiences[4].type === "not listed"
              ? "Not Listed"
              : prefilledData?.experiences[4].name.trim()
            : ""
        )
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleCustomValue = () => {
    if (customOpen.type === "secondaryRole" || customOpen.type === "role") {
      const el = document.getElementsByClassName("MuiChip-label")
      if (el) {
        for (var i = 0; i < el.length; i++) {
          if (el[i].innerHTML === "Not Listed") {
            el[i].parentElement.classList.add("hide-it")
          }
        }
      }
    }

    const custom = document.getElementById(customOpen.type)
    const val = custom && custom.innerHTML

    if (custom !== null) {
      custom.innerHTML = val.replace("Not Listed", customAnswer)
    }
  }

  const handleChangeMultiple = (event, type, data) => {
    let { value } = event.target
    if (type === "yearBorn") {
      value = value.toString()
    }

    const openCustomModal =
      value.includes("Not Listed") || value === "Not Listed"
    const found =
      typeof value !== "string" && value.filter(i => !data.includes(i))

    if (
      (openCustomModal && found.length === 0) ||
      (openCustomModal && data === undefined)
    ) {
      setCustomOpen({ open: true, type: type })
    }

    if (value.includes("Prefer not to say") || value === "Prefer not to say") {
      value =
        typeof value !== "string"
          ? value.filter(i => i === "Prefer not to say")
          : value
    }

    if (found.length > 0) {
      value = value.filter(i => data.includes(i))
    }

    switch (type) {
      case "experience1":
        setExperience1(value)
        break
      case "experience2":
        setExperience2(value)
        break
      case "experience3":
        setExperience3(value)
        break
      case "experience4":
        setExperience4(value)
        break
      case "experience5":
        setExperience5(value)
        break
      case "inclusion":
        return setInclusion(value)
      default:
    }

    handleCustomValue()
  }

  const handleCustomClose = () => {
    handleCustomValue()
    switch (customOpen.type) {
      case "role":
        if (customAnswer.trim() === "") {
          setRole(role.filter(i => i.name !== "Not Listed"))
        } else {
          role.push({ id: "000", name: `${customAnswer}` })
        }
        break
      case "secondaryRole":
        secondaryRole.push({ id: "000", name: `${customAnswer}` })
        break
      case "experience1":
        customAnswer.trim() !== ""
          ? setExperience1Custom(customAnswer)
          : setExperience1("")
        break
      case "experience2":
        customAnswer.trim() !== ""
          ? setExperience2Custom(customAnswer)
          : setExperience2("")

        break
      case "experience3":
        customAnswer.trim() !== ""
          ? setExperience3Custom(customAnswer)
          : setExperience3("")
        break
      case "experience4":
        customAnswer.trim() !== ""
          ? setExperience4Custom(customAnswer)
          : setExperience4("")
        break
      case "experience5":
        customAnswer.trim() !== ""
          ? setExperience5Custom(customAnswer)
          : setExperience5("")
        break
      default:
    }

    setCustomAnswer("")
    setCustomOpen({ open: false, type: "" })
  }

  const handleCustomAnswer = e => {
    const { value } = e.target
    if (
      value.toLowerCase() === "not listed" ||
      value.toLowerCase() === "prefer not to say"
    ) {
      setCustomAnswer("")
    } else {
      setCustomAnswer(value)
    }
  }

  const validation = () => {
    const verifyCustomQuestions = customQuestions?.some(
      i => i?.isRequired === 1 && !customA?.find(q => q.id === i.id)?.answer
    )
    if (verifyCustomQuestions) {
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background11")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background11")?.required ===
          1,
        backgroundValue?.veteranStatus === "" ||
          (backgroundValue?.veteranStatus === "do" &&
            backgroundValue?.militaryBranch?.length === 0)
      )
    ) {
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background9")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background9")?.required ===
          1,
        backgroundValue?.disabilityStatus === ""
      )
    ) {
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background9")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background9")?.required ===
          1,
        backgroundValue?.disabilityStatus === "do" &&
          backgroundValue?.disabilityType?.length === 0
      )
    ) {
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background8")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background8")?.required ===
          1,
        backgroundValue?.sexualOrientation?.length === 0
      )
    ) {
      scrollTo("sexualOrientation")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background7")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background7")?.required ===
          1,
        backgroundValue?.gender?.length === 0
      )
    ) {
      scrollTo("gender")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background6")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background6")?.required ===
          1,
        backgroundValue?.pronouns?.length === 0
      )
    ) {
      scrollTo("pronouns")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background5")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background5")?.required ===
          1,
        backgroundValue?.ethnicity?.length === 0 ||
          (backgroundValue?.ethnicity?.includes("Mixed race/Multi-ethnic") &&
            backgroundValue?.ethnicity1?.length === 0)
      )
    ) {
      scrollTo("ethnicity")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background4")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background4")?.required ===
          1,
        backgroundValue?.secondaryLanguage?.length === 0
      )
    ) {
      scrollTo("nativeLang")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background3")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background3")?.required ===
          1,
        backgroundValue?.nativeLanguage === ""
      )
    ) {
      scrollTo("nativeLang")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background2")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background2")?.required ===
          1,
        backgroundValue?.ancestry?.length === 0
      )
    ) {
      scrollTo("nationality")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background1")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background1")?.required ===
          1,
        backgroundValue?.immigrant === ""
      )
    ) {
      scrollTo("generation")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background12")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background12")?.required ===
          1,
        backgroundValue?.birthPlace === ""
      )
    ) {
      scrollTo("birthplace")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(BACKGROUND_SECTION.questions, "background0")
          ?.visibility === 1,
        findQuestion(BACKGROUND_SECTION.questions, "background0")?.required ===
          1,
        backgroundValue?.ageRange === ""
      )
    ) {
      scrollTo("yearBorn")
      setShowError(true)
      return false
    }

    if (formType === "FULL" && EXPERIENCE_SECTION?.visibility === 1) {
      if (
        formValidations(
          findQuestion(EXPERIENCE_SECTION.questions, "exp4")?.visibility === 1,
          findQuestion(EXPERIENCE_SECTION.questions, "exp4")?.required === 1,
          experience5 === ""
        )
      ) {
        scrollTo("experience5")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(EXPERIENCE_SECTION.questions, "exp3")?.visibility === 1,
          findQuestion(EXPERIENCE_SECTION.questions, "exp3")?.required === 1,
          experience4 === ""
        )
      ) {
        scrollTo("experience4")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(EXPERIENCE_SECTION.questions, "exp2")?.visibility === 1,
          findQuestion(EXPERIENCE_SECTION.questions, "exp2")?.required === 1,
          experience3 === ""
        )
      ) {
        scrollTo("experience3")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(EXPERIENCE_SECTION.questions, "exp1")?.visibility === 1,
          findQuestion(EXPERIENCE_SECTION.questions, "exp1")?.required === 1,
          experience2 === ""
        )
      ) {
        scrollTo("experience2")
        setShowError(true)
        return false
      }
      if (
        formValidations(
          findQuestion(EXPERIENCE_SECTION.questions, "exp0")?.visibility === 1,
          findQuestion(EXPERIENCE_SECTION.questions, "exp0")?.required === 1,
          experience1 === ""
        )
      ) {
        scrollTo("experience1")
        setShowError(true)
        return false
      }
    }

    if (
      formValidations(
        findQuestion(ROLE_SECTION.questions, "role2")?.visibility === 1,
        findQuestion(ROLE_SECTION.questions, "role2")?.required === 1,
        secondaryRole.length === 0
      )
    ) {
      scrollTo("secondaryRole")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(ROLE_SECTION.questions, "role1")?.visibility === 1,
        findQuestion(ROLE_SECTION.questions, "role1")?.required === 1,
        yoe === ""
      )
    ) {
      scrollTo("yoe")
      setShowError(true)
      return false
    }

    if (
      formValidations(
        findQuestion(ROLE_SECTION.questions, "role0")?.visibility === 1,
        findQuestion(ROLE_SECTION.questions, "role0")?.required === 1,
        role.length === 0
      )
    ) {
      scrollTo("role")
      setShowError(true)
      return false
    }

    return true
  }

  const scrollTo = id => {
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      })
    }
  }

  const submit = () => {
    let primaryRole = []
    let secondRole = []
    role.forEach(i => {
      if (i.id !== "001" && i.id !== "000") {
        primaryRole.push(parseInt(i.id))
      }

      if (i.id === "000") {
        primaryRole.push(`${i.name}`)
      }
    })

    secondaryRole.forEach(i => {
      if (i.id !== "001" && i.id !== "000") {
        secondRole.push(parseInt(i.id))
      }

      if (i.id === "000") {
        secondRole.push(`${i.name}`)
      }
    })

    const ethnicityObj = [
      ...(backgroundValue.ethnicity || []),
      ...(backgroundValue.ethnicity1 || []),
    ]

    if (validation()) {
      setLoading(true)

      const data = {
        birthDate: backgroundValue.ageRange,
        birthPlace: backgroundValue.birthPlace,

        generation: [
          {
            value:
              backgroundValue?.immigrant === "Not Listed"
                ? backgroundValue.generationCustom
                : imigrantLookups(backgroundValue?.immigrant),
            type:
              backgroundValue?.immigrant === "Not Listed"
                ? "not listed"
                : "listed",
          },
        ],

        immigrant: backgroundValue?.immigrant,

        nationality:
          backgroundValue?.ancestry?.length > 0
            ? backgroundValue?.ancestry
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.nationality.includes(g) ||
                    backgroundValue?.ancestry?.includes("Prefer not to say")
                      ? "listed"
                      : "not listed",
                }))
            : [],

        nativeLanguage:
          backgroundValue?.nativeLanguage === ""
            ? ""
            : {
                value: backgroundValue?.nativeLanguage,
                type:
                  LANGUAGES.nativeLanguage.includes(
                    backgroundValue?.nativeLanguage
                  ) ||
                  backgroundValue?.nativeLanguage?.includes("Prefer not to say")
                    ? "listed"
                    : "not listed",
              },

        secondaryLanguage:
          backgroundValue?.secondaryLanguage?.length > 0
            ? backgroundValue.secondaryLanguage
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    LANGUAGES?.nativeLanguage?.includes(g) ||
                    backgroundValue?.secondaryLanguage?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],

        ethnicity: ethnicityObj
          .filter(g => g !== "Prefer to self describe")
          .map(g => ({
            value: g,
            type:
              ANSWERS.ethnicity.includes(g) ||
              ethnicityObj.includes("Prefer not to say")
                ? "listed"
                : "prefer to self describe",
          })),

        disabilityStatus: backgroundValue.disabilityStatus,
        disabilityType:
          backgroundValue?.disabilityType?.length > 0
            ? backgroundValue.disabilityType
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.disabilityType.includes(g) ||
                    backgroundValue?.disabilityType?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],
        veteranStatus: backgroundValue?.veteranStatus,
        militaryBranch:
          backgroundValue?.militaryBranch?.length > 0
            ? backgroundValue?.militaryBranch
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.militaryBranch.includes(g) ||
                    backgroundValue?.militaryBranch?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],

        gender: backgroundValue?.gender
          .filter(g => g !== "Prefer to self describe")
          .map(g => ({
            value: g,
            type:
              ANSWERS.gender.includes(g) ||
              backgroundValue?.gender?.includes("Prefer not to say")
                ? "listed"
                : "prefer to self describe",
          })),
        sexualOrientation: backgroundValue?.sexualOrientation
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.sexualOrientation.includes(g) ||
              backgroundValue?.sexualOrientation?.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),

        pronouns: [],

        inclusion: inclusion,
        preEnrollmentUUID: preEnrollmentUUID,
        rosterUUID: rosterUUID,
        roleId: primaryRole,
        secondaryRole: secondRole,
        customQuestions: customA,

        yearsOfExperience: yoe,
        experience: [
          {
            value: experience1Custom !== "" ? experience1Custom : experience1,
            type: experience1Custom !== "" ? "not listed" : "listed",
            column: 1,
          },
          {
            value: experience2Custom !== "" ? experience2Custom : experience2,
            type: experience2Custom !== "" ? "not listed" : "listed",
            column: 2,
          },
          {
            value: experience3Custom !== "" ? experience3Custom : experience3,
            type: experience3Custom !== "" ? "not listed" : "listed",
            column: 3,
          },
          {
            value: experience4Custom !== "" ? experience4Custom : experience4,
            type: experience4Custom !== "" ? "not listed" : "listed",
            column: 4,
          },
          {
            value: experience5Custom !== "" ? experience5Custom : experience5,
            type: experience5Custom !== "" ? "not listed" : "listed",
            column: 5,
          },
        ],

        veteranYearsOfService: veteranYearsOfService,
        currentService: currentService ? 1 : 0,
        duty: duty,

        updateId: prefilledData.updateId || "",
        copyFrom: prefilledData.copyFrom || "",
      }

      if (rosterUUID === "" || rosterUUID === undefined) {
        delete data.rosterUUID
      }

      if (backgroundValue?.disabilityType.length === 0) {
        delete data.disabilityType
      }

      if (formType === "NO_EXPERIENCE") {
        delete data.experience
      }

      if (prefilledData === "") {
        delete data.updateId
        delete data.copyFrom
      }

      axios
        .post(`${API_ROOT}${ENV}/survey-response/pre-enrollment/signup`, data)
        .then(response => {
          if (response.data.statusCode === 200) {
            setUuid(response.data.devMessage)

            analytics.track("Completed Survey", {
              category: "Pre-Enrollment Survey",
            })

            setLoading(false)
            prefilledData === "" ? setStep(3) : setStep(4)
          } else {
            if (response?.data?.message?.includes("custom role")) {
              setRole([])
              primaryRole = []
              setShowError(true)
            } else {
              setErrorMessage(response.data.devMessage)
              setStep("error")
            }
            setLoading(false)
          }
        })
        .catch(function (error) {
          setErrorMessage(error)
          setStep("error")
          setLoading(false)
        })
    } else {
      return false
    }
  }

  const roleQuestions = customQuestions?.filter(q => q.section === "role")
  const backgroundQuestions = customQuestions?.filter(
    q => q.section === "background"
  )
  const experienceQuestions = customQuestions?.filter(
    q => q.section === "experience"
  )
  const customSection = customQuestions?.find(q => q.section === "custom")

  return (
    <section className="questions">
      <div className="container">
        <Header />
        {prefilledData !== "" && (
          <p className="returning">
            Please review and update your information below.
          </p>
        )}

        <p className="indicates">
          <img src={Asterisk} alt="Required" />
          indicates required field
        </p>
        <div className="bg">
          <FormControl>
            <div className="questionaire">
              {/* Role Section */}
              {ROLE_SECTION?.visibility === 1 && (
                <RoleSection
                  survey={survey}
                  surveySection={ROLE_SECTION}
                  showError={showError}
                  handleChangeMultiple={handleChangeMultiple}
                  roles={roles}
                  role={role}
                  setRole={setRole}
                  yoe={yoe}
                  setYoe={setYoe}
                  secondaryRole={secondaryRole}
                  setSecondaryRole={setSecondaryRole}
                  setCustomOpen={setCustomOpen}
                  preenrollment
                  customQuestions={roleQuestions}
                  setCustomA={setCustomA}
                  customA={customA}
                />
              )}
              {/* Experience Section */}
              {EXPERIENCE_SECTION?.visibility === 1 && (
                <PreenrollmentExperience
                  survey={survey}
                  surveySection={EXPERIENCE_SECTION}
                  showError={showError}
                  handleChangeMultiple={handleChangeMultiple}
                  prefilledData={prefilledData}
                  experience1={experience1}
                  experience2={experience2}
                  experience3={experience3}
                  experience4={experience4}
                  experience5={experience5}
                  setExperience1Custom={setExperience1Custom}
                  setExperience2Custom={setExperience2Custom}
                  setExperience3Custom={setExperience3Custom}
                  setExperience4Custom={setExperience4Custom}
                  setExperience5Custom={setExperience5Custom}
                  customQuestions={experienceQuestions}
                  setCustomA={setCustomA}
                  customA={customA}
                />
              )}

              {/* Background Section */}
              {BACKGROUND_SECTION?.visibility === 1 && (
                <>
                  <BackgroundSection
                    value={backgroundValue}
                    setValue={setBackgroundValue}
                    survey={survey}
                    surveySection={BACKGROUND_SECTION}
                    showError={showError}
                    prefilledData={prefilledData}
                    customQuestions={backgroundQuestions}
                    setCustomA={setCustomA}
                    customA={customA}
                  />

                  {!!customSection && (
                    <CustomQuestion
                      customQuestion={customSection}
                      setCustomA={setCustomA}
                      customA={customA}
                      showError={showError}
                    />
                  )}
                </>
              )}

              <div className="actions">
                <Button type="submit" onClick={submit} disabled={loading}>
                  {loading ? <CircularProgress color="black" /> : "Submit"}
                </Button>
                {showError && (
                  <FormHelperText className="error lang">
                    Please complete all required fields
                  </FormHelperText>
                )}
              </div>
            </div>
          </FormControl>
        </div>
      </div>

      <CustomAnswer
        handleCustomAnswer={handleCustomAnswer}
        handleCustomClose={handleCustomClose}
        open={customOpen.open}
      />
    </section>
  )
}

export default PreEnrollmentQuestions
