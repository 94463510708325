import React from "react"
import CustomQuestion from "../sections/CustomQuestion"

function QuestionsWrapper({
  customQuestions,
  setCustomA,
  customA,
  showError,
  children,
}) {
  const topSection = customQuestions?.find(q => q.order === 0)
  const bottomSection = customQuestions?.find(q => q.order === 1)
  return (
    <>
      {!!topSection && (
        <CustomQuestion
          customQuestion={topSection}
          setCustomA={setCustomA}
          customA={customA}
          showError={showError}
        />
      )}
      {children}
      {!!bottomSection && (
        <CustomQuestion
          customQuestion={bottomSection}
          setCustomA={setCustomA}
          customA={customA}
          showError={showError}
        />
      )}
    </>
  )
}

export default QuestionsWrapper
