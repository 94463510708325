import React, { useState, useEffect } from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import ANSWERS from "../../constants/Constant"
import LANGUAGES from "../../constants/Languages"
import { ModalWrapper } from "../style"
import Header from "../header"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import analytics from "../Analytics"
import Asterisk from "../../images/asterisk-solid.svg"
import formValidations from "../../util/formValidations"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import CareAboutSection from "../sections/CareAbout"
import BackgroundSection from "../sections/Background"
import RoleSection from "../sections/Roles"
import CompensationSection from "../sections/Compensation"
import FeedbackSection from "../sections/Feedback"
import { findSection, findQuestion } from "../../util/find"
import imigrantLookups from "../../util/imigrantLookups"
import CustomQuestion from "../sections/CustomQuestion"

const useStyles = makeStyles(theme => ({
  custom: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "32px",
    paddingBottom: 0,
    paddingLeft: "5px",
    border: "1px solid #fff",
  },
}))

function Questions({
  roles,
  setStep,
  setUuid,
  productionUUID,
  crewUUID,
  prefilledData,
  survey,
  isPodcast,
  isUnion,
  setErrorMessage,
  customQuestions,
}) {
  const classes = useStyles()

  let pageOrder = []
  let roleObj = []
  let compObj = []

  const ROLE_SECTION = findSection(survey, "role")
  const COMPENSATION_SECTION = findSection(survey, "compensation")
  const BACKGROUND_SECTION = findSection(survey, "background")
  const CARED_ABOUT_SECTION = findSection(survey, "caredAbout")

  const [page, setPage] = useState("")
  const [pageOrderObj, setPageOrderObj] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [hasBoth, setHasBoth] = useState(false)
  const [inclusion, setInclusion] = useState("")
  const [role, setRole] = useState([])
  const [rating, setRating] = useState(3)
  const [feedback, setFeedback] = useState("")
  const [workDays, setWorkDays] = useState("")
  const [travel, setTravel] = useState("")
  const [currentService] = useState(false)
  const [yoe, setYoe] = useState("")
  const [commute, setCommute] = useState("")
  const [secondaryRole, setSecondaryRole] = useState([])
  const [rateDimension, setRateDimension] = useState("")
  const [paySentiment, setPaySentiment] = useState("")
  const [duty] = useState("")
  const [tackles, setTackles] = useState("")
  const [caredMost, setCaredMost] = useState("")
  const [tacklesCustom, setTacklesCustom] = useState("")
  const [careMoreCustom, setCaredMostCustom] = useState("")
  const [customOpen, setCustomOpen] = useState({ open: false, type: "" })
  const [customAnswer, setCustomAnswer] = useState("")
  const [rate, setRate] = useState("")
  const [veteranYearsOfService] = useState("")
  const [paysentimentCustom, setPaySentimentCustom] = useState("")
  const [referralSource, setReferralSource] = useState([])
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = useState("USD")
  const [unionTackles] = useState("")
  const [travelFrom, setTravelFrom] = useState("")
  const [environmental, setEnvironmental] = useState("")
  const [backgroundValue, setBackgroundValue] = useState({})
  const [customA, setCustomA] = useState([])

  useEffect(() => {
    analytics.page({
      title: "Questions Step",
      path: "/questions",
    })

    calculatePage()

    window.scrollTo({ top: 0, behavior: "smooth" })
    if (prefilledData !== "") {
      setRole(
        findQuestion(ROLE_SECTION.questions, "role0")?.visibility === 1
          ? prefilledData?.primaryRoles || []
          : []
      )
      setYoe(
        findQuestion(ROLE_SECTION.questions, "role1")?.visibility === 1
          ? prefilledData?.yearsOfExperience
          : ""
      )
      setSecondaryRole(
        findQuestion(ROLE_SECTION.questions, "role2")?.visibility === 1
          ? prefilledData?.secondaryRole || []
          : []
      )
      setCommute(
        findQuestion(ROLE_SECTION.questions, "role3")?.visibility === 1
          ? prefilledData?.commute
          : ""
      )

      setTravel(
        findQuestion(ROLE_SECTION.questions, "role4")?.visibility === 1
          ? prefilledData?.travel
          : ""
      )
      setTravelFrom(
        findQuestion(ROLE_SECTION.questions, "role4")?.visibility === 1
          ? prefilledData?.traveledFrom
          : ""
      )
      setRate(
        findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility === 1
          ? prefilledData?.dayRate
          : ""
      )
      setRateDimension(
        findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility === 1
          ? prefilledData?.rateDimension
          : ""
      )
      setWorkDays(
        findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility === 1
          ? prefilledData?.workDays
          : ""
      )
      setPaySentiment(
        findQuestion(COMPENSATION_SECTION.questions, "comp1")?.visibility === 1
          ? prefilledData?.paySentiments?.length > 0
            ? prefilledData?.paySentiments[0]?.name
            : ""
          : ""
      )

      setCurrency(
        findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility === 1
          ? prefilledData?.currency
          : "USD"
      )
      setReferralSource(
        findQuestion(ROLE_SECTION.questions, "role5")?.visibility === 1
          ? prefilledData?.referralSources
            ? prefilledData?.referralSources?.map(i => i.name) || []
            : []
          : []
      )
      setCaredMost(
        findQuestion(CARED_ABOUT_SECTION.questions, "cared1")?.visibility === 1
          ? prefilledData?.caredMost?.map(i => i.name) || []
          : []
      )
      setEnvironmental(
        findQuestion(CARED_ABOUT_SECTION.questions, "cared2")?.visibility === 1
          ? prefilledData?.environmentalConcern
          : ""
      )
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const el = document.getElementsByClassName("MuiChip-label")
    if (el) {
      for (var i = 0; i < el.length; i++) {
        if (el[i].innerHTML === "Not Listed") {
          el[i].parentElement.classList.add("hide-it")
        }
      }
    }
  }, [page])

  useEffect(() => {
    if (showError) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [showError])

  useEffect(() => {
    const totalPage = hasBoth ? pageOrderObj.length - 1 : pageOrderObj.length
    window.scrollTo({ top: 0, behavior: "smooth" })
    if (currentPage === totalPage) {
      setPage("last")
    }
    // eslint-disable-next-line
  }, [currentPage])

  const calculatePage = () => {
    pageOrder = survey.filter(i => i.visibility === 1)
    compObj = pageOrder.filter(i => i.name === "compensation")
    roleObj = pageOrder.filter(i => i.name === "role")

    pageOrder.forEach((i, index) => {
      if (i.name === "compensation") {
        swapPositions(pageOrder, index, pageOrder.length - 1)
      }
      if (i.name === "role") {
        swapPositions(pageOrder, index, pageOrder.length - 1)
      }

      if (i.name === "background") {
        swapPositions(pageOrder, index, 1)
      }

      if (i.name === "caredAbout") {
        swapPositions(pageOrder, index, 0)
      }
    })

    pageOrder.push({
      visibility: 1,
      name: "last",
    })

    if (roleObj.length > 0) {
      const roleIndex = pageOrder.findIndex(x => x.name === "role")

      pageOrder.splice(roleIndex, 0, {
        visibility: 1,
        name: "interstitial",
      })
    }

    if (roleObj.length > 0 && compObj.length > 0) {
      setHasBoth(true)
    }

    setPageOrderObj(pageOrder)
    setPage(pageOrder[0].name)
  }

  const onClickNext = () => {
    if (validation()) {
      pageOrderObj.forEach((i, index) => {
        if (page === i.name) {
          setCurrentPage(currentPage + 1)
          setPage(pageOrderObj[index + 1].name)
        }
      })
      setShowError(false)
    }
  }

  const onClickPrev = () => {
    pageOrderObj.forEach((i, index) => {
      if (page === i.name) {
        if (
          pageOrderObj[index].name === "compensation" &&
          pageOrderObj[index - 1].name === "role"
        ) {
          setPage(pageOrderObj[index - 2].name)
        } else {
          setPage(pageOrderObj[index - 1].name)
        }
        setCurrentPage(currentPage - 1)
      }
    })
  }

  const swapPositions = (array, a, b) =>
    ([array[a], array[b]] = [array[b], array[a]])

  const handleCustomValue = () => {
    if (
      customOpen.type === "secondaryRole" ||
      customOpen.type === "role" ||
      customOpen.type === "referralSource"
    ) {
      const el = document.getElementsByClassName("MuiChip-label")
      if (el) {
        for (var i = 0; i < el.length; i++) {
          if (el[i].innerHTML === "Not Listed") {
            el[i].parentElement.classList.add("hide-it")
          }
        }
      }
    }

    const custom = document.getElementById(customOpen.type)
    const val = custom && custom.innerHTML

    if (custom !== null) {
      custom.innerHTML = val.replace("Not Listed", customAnswer)
    }
  }

  const handleChangeMultiple = (event, type, data) => {
    let { value } = event.target

    const openCustomModal =
      value.includes("Not Listed") || value === "Not Listed"
    const found =
      typeof value !== "string" && value.filter(i => !data.includes(i))

    if (
      (openCustomModal && found.length === 0) ||
      (openCustomModal && data === undefined)
    ) {
      setCustomOpen({ open: true, type: type })
    }

    if (value.includes("Prefer not to say") || value === "Prefer not to say") {
      value =
        typeof value !== "string"
          ? value.filter(i => i === "Prefer not to say")
          : value
    }

    if (found.length > 0) {
      value = value.filter(i => data.includes(i))
    }

    switch (type) {
      case "tackles":
        setTackles(value)
        break
      case "caredMost":
        setCaredMost(value)
        break
      case "environmental":
        setEnvironmental(value)
        break
      case "dayRate":
        setRate(value)
        break
      case "travel":
        setTravel(value)
        break
      case "travelFrom":
        setTravelFrom(value)
        break
      case "rateDimension":
        setRateDimension(value)
        break
      case "paySentiment":
        setPaySentiment(value)
        break
      case "inclusion":
        return setInclusion(value)
      default:
    }

    handleCustomValue()
  }

  const handleCustomClose = () => {
    handleCustomValue()
    switch (customOpen.type) {
      case "paySentiment":
        if (customAnswer !== "") {
          setPaySentimentCustom(customAnswer)
        } else {
          setPaySentiment("")
        }
        break
      case "role":
        if (customAnswer.trim() === "") {
          setRole(role.filter(i => i.name !== "Not Listed"))
        } else {
          role.push({ id: "000", name: `${customAnswer}` })
        }
        break
      case "secondaryRole":
        secondaryRole.push({ id: "000", name: `${customAnswer}` })
        break

      case "referralSource":
        if (customAnswer.trim() === "") {
          setReferralSource(referralSource.filter(i => i !== "Not Listed"))
        } else {
          referralSource.push(customAnswer)
        }
        break
      case "tackles":
        customAnswer.trim() !== ""
          ? setTacklesCustom(customAnswer)
          : setTackles("")
        break
      case "caredMost":
        customAnswer.trim() !== ""
          ? setCaredMostCustom(customAnswer)
          : setCaredMost("")
        break
      default:
    }
    setCustomAnswer("")
    setCustomOpen({ open: false, type: "" })
  }

  const handleCustomAnswer = e => {
    const { value } = e.target
    if (
      value.toLowerCase() === "not listed" ||
      value.toLowerCase() === "prefer not to say"
    ) {
      setCustomAnswer("")
    } else {
      setCustomAnswer(value)
    }
  }

  const verifyCustomQuestions = array => {
    return array?.some(
      i => i?.isRequired === 1 && !customA?.find(q => q.id === i.id)?.answer
    )
  }

  const validation = () => {
    if (CARED_ABOUT_SECTION?.visibility === 1 && currentPage === 1) {
      if (isUnion && tackles !== "" && unionTackles === "") {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "caredAbout") + 1)
        setPage("caredAbout")
        setShowError(true)
        return false
      }
      if (
        formValidations(
          findQuestion(CARED_ABOUT_SECTION.questions, "cared2")?.visibility ===
            1,
          findQuestion(CARED_ABOUT_SECTION.questions, "cared2")?.required === 1,
          environmental === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "caredAbout") + 1)
        setPage("caredAbout")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(CARED_ABOUT_SECTION.questions, "cared1").visibility ===
            1,
          findQuestion(CARED_ABOUT_SECTION.questions, "cared1").required === 1,
          caredMost === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "caredAbout") + 1)
        setPage("caredAbout")
        setShowError(true)
        return false
      }
      if (verifyCustomQuestions(careQuestions)) {
        setPage("caredAbout")
        setShowError(true)
        return false
      }
      if (
        formValidations(
          findQuestion(CARED_ABOUT_SECTION.questions, "cared0").visibility ===
            1,
          findQuestion(CARED_ABOUT_SECTION.questions, "cared0").required === 1,
          tackles === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "caredAbout") + 1)
        setPage("caredAbout")
        setShowError(true)
        return false
      }
    }

    if (currentPage === 2) {
      if (verifyCustomQuestions(backgroundQuestions)) {
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background11")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background11")
            ?.required === 1,
          backgroundValue?.veteranStatus === "" ||
            (backgroundValue?.veteranStatus === "do" &&
              backgroundValue?.militaryBranch?.length === 0)
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background9")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background9")
            ?.required === 1,
          backgroundValue?.disabilityStatus === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background9")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background9")
            ?.required === 1,
          backgroundValue?.disabilityStatus === "do" &&
            backgroundValue?.disabilityType?.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background8")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background8")
            ?.required === 1,
          backgroundValue?.sexualOrientation?.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background7")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background7")
            ?.required === 1,
          backgroundValue?.gender?.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background6")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background6")
            ?.required === 1,
          backgroundValue?.pronouns?.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background5")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background5")
            ?.required === 1,
          backgroundValue?.ethnicity?.length === 0 ||
            (backgroundValue?.ethnicity?.includes("Mixed race/Multi-ethnic") &&
              backgroundValue?.ethnicity1?.length === 0)
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background4")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background4")
            ?.required === 1,
          backgroundValue?.secondaryLanguage?.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background3")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background3")
            ?.required === 1,
          backgroundValue?.nativeLanguage === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background2")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background2")
            ?.required === 1,
          backgroundValue?.ancestry?.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background1")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background1")
            ?.required === 1,
          backgroundValue?.immigrant === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background12")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background12")
            ?.required === 1,
          backgroundValue?.birthPlace === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(BACKGROUND_SECTION.questions, "background0")
            ?.visibility === 1,
          findQuestion(BACKGROUND_SECTION.questions, "background0")
            ?.required === 1,
          backgroundValue?.ageRange === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "background") + 1)
        setPage("background")
        setShowError(true)
        return false
      }
    }

    if (currentPage === 4) {
      if (
        verifyCustomQuestions(compensationQuestions) ||
        verifyCustomQuestions(roleQuestions) ||
        verifyCustomQuestions([customSection])
      ) {
        setPage("role")
        setShowError(true)
        return false
      }

      if (COMPENSATION_SECTION?.visibility === 1) {
        if (
          formValidations(
            findQuestion(COMPENSATION_SECTION.questions, "comp1")
              ?.visibility === 1,
            findQuestion(COMPENSATION_SECTION.questions, "comp1")?.required ===
              1,
            paySentiment === ""
          )
        ) {
          setCurrentPage(
            pageOrderObj.findIndex(o => o.name === "compensation") + 1
          )
          setPage("compensation")
          setShowError(true)
          return false
        }

        if (
          formValidations(
            findQuestion(COMPENSATION_SECTION.questions, "comp0")
              ?.visibility === 1,
            findQuestion(COMPENSATION_SECTION.questions, "comp0")?.required ===
              1,
            rate === "" || rateDimension === "" || workDays === ""
          )
        ) {
          setCurrentPage(
            pageOrderObj.findIndex(o => o.name === "compensation") + 1
          )
          setPage("compensation")
          setShowError(true)
          return false
        }
      }

      if (
        formValidations(
          findQuestion(ROLE_SECTION.questions, "role5")?.visibility === 1,
          findQuestion(ROLE_SECTION.questions, "role5")?.required === 1,
          referralSource.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(ROLE_SECTION.questions, "role4")?.visibility === 1,
          findQuestion(ROLE_SECTION.questions, "role4")?.required === 1,
          travel === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }

      if (travel === "am not" && travelFrom === "") {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(ROLE_SECTION.questions, "role3")?.visibility === 1,
          findQuestion(ROLE_SECTION.questions, "role3")?.required === 1,
          commute === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(ROLE_SECTION.questions, "role2")?.visibility === 1,
          findQuestion(ROLE_SECTION.questions, "role2")?.required === 1,
          secondaryRole.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(ROLE_SECTION.questions, "role1")?.visibility === 1,
          findQuestion(ROLE_SECTION.questions, "role1")?.required === 1,
          yoe === ""
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }

      if (
        formValidations(
          findQuestion(ROLE_SECTION.questions, "role0")?.visibility === 1,
          findQuestion(ROLE_SECTION.questions, "role0")?.required === 1,
          role.length === 0
        )
      ) {
        setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
        setPage("role")
        setShowError(true)
        return false
      }
    }

    return true
  }

  const submit = () => {
    let primaryRole = []
    let secondRole = []
    console.log(role)
    role.forEach(i => {
      if (i.id !== "001" && i.id !== "000") {
        primaryRole.push(parseInt(i.id))
      }

      if (i.id === "000") {
        primaryRole.push(`${i.name}`)
      }
    })

    secondaryRole.forEach(i => {
      if (i.id !== "001" && i.id !== "000") {
        secondRole.push(parseInt(i.id))
      }

      if (i.id === "000") {
        secondRole.push(`${i.name}`)
      }
    })
    const ethnicityObj = [
      ...(backgroundValue.ethnicity || []),
      ...(backgroundValue.ethnicity1 || []),
    ]
    if (validation()) {
      setLoading(true)
      const data = {
        inclusion: inclusion,
        productionUUID: productionUUID,
        crewUUID: crewUUID,

        birthDate: backgroundValue.ageRange,
        birthPlace: backgroundValue.birthPlace,

        generation: [
          {
            value:
              backgroundValue?.immigrant === "Not Listed"
                ? backgroundValue.generationCustom
                : imigrantLookups(backgroundValue?.immigrant),
            type:
              backgroundValue?.immigrant === "Not Listed"
                ? "not listed"
                : "listed",
          },
        ],

        immigrant: backgroundValue?.immigrant,

        nationality:
          backgroundValue?.ancestry?.length > 0
            ? backgroundValue?.ancestry
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.nationality.includes(g) ||
                    backgroundValue?.ancestry?.includes("Prefer not to say")
                      ? "listed"
                      : "not listed",
                }))
            : [],

        nativeLanguage:
          backgroundValue?.nativeLanguage === ""
            ? ""
            : {
                value: backgroundValue?.nativeLanguage,
                type:
                  LANGUAGES.nativeLanguage.includes(
                    backgroundValue?.nativeLanguage
                  ) ||
                  backgroundValue?.nativeLanguage?.includes("Prefer not to say")
                    ? "listed"
                    : "not listed",
              },

        secondaryLanguage:
          backgroundValue?.secondaryLanguage?.length > 0
            ? backgroundValue.secondaryLanguage
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    LANGUAGES?.nativeLanguage?.includes(g) ||
                    backgroundValue?.secondaryLanguage?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],

        ethnicity: ethnicityObj
          .filter(g => g !== "Prefer to self describe")
          .map(g => ({
            value: g,
            type:
              ANSWERS.ethnicity.includes(g) ||
              ethnicityObj.includes("Prefer not to say")
                ? "listed"
                : "prefer to self describe",
          })),

        disabilityStatus: backgroundValue.disabilityStatus,
        disabilityType:
          backgroundValue?.disabilityType?.length > 0
            ? backgroundValue.disabilityType
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.disabilityType.includes(g) ||
                    backgroundValue?.disabilityType?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],

        veteranStatus: backgroundValue?.veteranStatus,
        militaryBranch:
          backgroundValue?.militaryBranch?.length > 0
            ? backgroundValue?.militaryBranch
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.militaryBranch.includes(g) ||
                    backgroundValue?.militaryBranch?.includes(
                      "Prefer not to say"
                    )
                      ? "listed"
                      : "not listed",
                }))
            : [],
        gender: backgroundValue?.gender
          .filter(g => g !== "Prefer to self describe")
          .map(g => ({
            value: g,
            type:
              ANSWERS.gender.includes(g) ||
              backgroundValue?.gender?.includes("Prefer not to say")
                ? "listed"
                : "prefer to self describe",
          })),
        sexualOrientation: backgroundValue?.sexualOrientation
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.sexualOrientation.includes(g) ||
              backgroundValue?.sexualOrientation?.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),
        pronouns: [],
        roleId: primaryRole,
        secondaryRole: secondRole,

        veteranYearsOfService: veteranYearsOfService,
        currentService: currentService ? 1 : 0,
        duty: duty,

        paySentiment: [
          {
            value:
              paySentiment === "Not Listed" ? paysentimentCustom : paySentiment,
            type: paySentiment === "Not Listed" ? "not listed" : "listed",
          },
        ],

        yearsOfExperience: yoe,
        experience: [],
        tackles: [
          {
            value: tacklesCustom !== "" ? tacklesCustom : tackles,
            type: tacklesCustom !== "" ? "not listed" : "listed",
          },
        ],
        caredMost: [
          {
            value:
              careMoreCustom !== ""
                ? careMoreCustom
                : prefilledData !== ""
                ? caredMost[0]
                : caredMost,
            type: careMoreCustom !== "" ? "not listed" : "listed",
          },
        ],
        environmentalConcern: environmental,
        commute: commute,
        travel: travel,
        traveledFrom: travelFrom,
        dayRate:
          findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility ===
          1
            ? rate
            : "",
        rateDimension:
          findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility ===
          1
            ? rateDimension
            : "",
        workDays:
          findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility ===
          1
            ? workDays
            : 0,
        updateId: prefilledData.updateId || "",
        copyFrom: prefilledData.copyFrom || "",
        referralSource: referralSource
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.referral.includes(g) ||
              referralSource.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),
        rate: rating || "",
        feedback: feedback || "",
        currency:
          findQuestion(COMPENSATION_SECTION.questions, "comp0")?.visibility ===
          1
            ? currency
            : "",
        customQuestions: customA,
      }

      if (crewUUID === "" || crewUUID === undefined) {
        delete data.crewUUID
      }

      if (backgroundValue?.disabilityType.length === 0) {
        delete data.disabilityType
      }

      if (prefilledData === "") {
        delete data.updateId
        delete data.copyFrom
      }

      axios
        .post(`${API_ROOT}${ENV}/survey-response/proactive/signup`, data)
        .then(response => {
          if (response.data.statusCode === 200) {
            setUuid(response.data.devMessage)
            analytics.track("Completed Survey", {
              category: "Proactive Survey",
            })

            setLoading(false)
            prefilledData === "" ? setStep(3) : setStep(4)
          } else {
            if (response?.data?.message?.includes("custom role")) {
              setCurrentPage(pageOrderObj.findIndex(o => o.name === "role") + 1)
              setPage("role")
              setRole([])
              primaryRole = []
              setShowError(true)
            } else {
              setErrorMessage(response.data.devMessage)
              setStep("error")
            }
            setLoading(false)
          }
        })
        .catch(function (error) {
          setErrorMessage(error)
          setStep("error")
          setLoading(false)
        })
    } else {
      return false
    }
  }
  const careQuestions = customQuestions?.filter(q => q.section === "care")

  const roleQuestions = customQuestions?.filter(q => q.section === "role")
  const backgroundQuestions = customQuestions?.filter(
    q => q.section === "background"
  )
  const compensationQuestions = customQuestions?.filter(
    q => q.section === "compensation"
  )

  const customSection = customQuestions?.find(q => q.section === "custom")

  return (
    <section className="questions">
      <div className="container">
        <Header />

        {prefilledData !== "" && (
          <p className="returning">
            Please review and update your information below.
          </p>
        )}

        {page !== "interstitial" && (
          <p className="indicates">
            <img src={Asterisk} alt="Required" />
            indicates required field
          </p>
        )}

        <div className="bg">
          <FormControl>
            <div className="questionaire proactive">
              {page === "caredAbout" &&
                CARED_ABOUT_SECTION?.visibility === 1 && (
                  <CareAboutSection
                    survey={survey}
                    surveySection={CARED_ABOUT_SECTION}
                    showError={showError}
                    caredMost={caredMost}
                    environmental={environmental}
                    handleChangeMultiple={handleChangeMultiple}
                    customQuestions={careQuestions}
                    setCustomA={setCustomA}
                    customA={customA}
                  />
                )}

              {/* Background Section */}
              {page === "background" &&
                BACKGROUND_SECTION?.visibility === 1 && (
                  <BackgroundSection
                    proactive
                    value={backgroundValue}
                    setValue={setBackgroundValue}
                    survey={survey}
                    showError={showError}
                    prefilledData={prefilledData}
                    surveySection={BACKGROUND_SECTION}
                    customQuestions={backgroundQuestions}
                    setCustomA={setCustomA}
                    customA={customA}
                  />
                )}

              {/* Role Section */}
              {(page === "role" || page === "compensation") &&
                (ROLE_SECTION?.visibility === 1 ||
                  COMPENSATION_SECTION?.visibility === 1) && (
                  <>
                    <RoleSection
                      survey={survey}
                      surveySection={ROLE_SECTION}
                      showError={showError}
                      handleChangeMultiple={handleChangeMultiple}
                      roles={roles}
                      isPodcast={isPodcast}
                      role={role}
                      setRole={setRole}
                      secondaryRole={secondaryRole}
                      setSecondaryRole={setSecondaryRole}
                      yoe={yoe}
                      setYoe={setYoe}
                      commute={commute}
                      setCommute={setCommute}
                      travel={travel}
                      travelFrom={travelFrom}
                      referralSource={referralSource}
                      setReferralSource={setReferralSource}
                      setCustomOpen={setCustomOpen}
                      customQuestions={roleQuestions}
                      setCustomA={setCustomA}
                      customA={customA}
                    />

                    {COMPENSATION_SECTION?.visibility === 1 && (
                      <CompensationSection
                        survey={survey}
                        surveySection={COMPENSATION_SECTION}
                        showError={showError}
                        handleChangeMultiple={handleChangeMultiple}
                        rate={rate}
                        paySentiment={paySentiment}
                        customQuestions={compensationQuestions}
                        setCustomA={setCustomA}
                        customA={customA}
                      />
                    )}
                    {!!customSection && (
                      <CustomQuestion
                        customQuestion={customSection}
                        setCustomA={setCustomA}
                        customA={customA}
                        showError={showError}
                      />
                    )}
                  </>
                )}

              {page === "interstitial" && (
                <div className="question-section">
                  <div className="question-wrapper">
                    You're almost done! The following questions will continue to
                    be anonymized and never tied back to you. These questions
                    are for us internally, so we can continue to advocate for a
                    better production industry.
                  </div>
                </div>
              )}

              {page === "last" && (
                <FeedbackSection
                  rating={rating}
                  setRating={setRating}
                  setFeedback={setFeedback}
                  feedback={feedback}
                />
              )}

              <div className="actions proactive">
                {page !== "last" && <Button onClick={onClickNext}>Next</Button>}

                {page === "last" && (
                  <Button type="submit" onClick={submit} disabled={loading}>
                    {loading ? <CircularProgress color="black" /> : "Submit"}
                  </Button>
                )}
                {showError && (
                  <FormHelperText className="error lang">
                    Please complete required fields
                  </FormHelperText>
                )}
                <div className="current-page">
                  {currentPage !== 1 && (
                    <ChevronLeftIcon onClick={onClickPrev} fontSize="large" />
                  )}
                  <span>
                    <var>{currentPage}</var> of{" "}
                    <var>
                      {hasBoth ? pageOrderObj.length - 1 : pageOrderObj.length}
                    </var>
                  </span>
                  {page !== "last" && (
                    <ChevronRightIcon onClick={onClickNext} fontSize="large" />
                  )}
                </div>
              </div>
            </div>
          </FormControl>
        </div>
      </div>

      <Dialog
        onClose={handleCustomClose}
        aria-labelledby="simple-dialog-title"
        open={customOpen.open}
      >
        <ModalWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="28"
            viewBox="0 0 27 28"
            onClick={handleCustomClose}
          >
            <g fill="none" fillRule="evenodd" strokeLinecap="square">
              <g stroke="#FFF">
                <g>
                  <g>
                    <path
                      d="M26.707 0.283L0.06 25.942"
                      transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000)"
                    />
                    <path
                      d="M26.707 0.283L0.06 25.942"
                      transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000) translate(13.500000, 13.000000) scale(-1, 1) translate(-13.500000, -13.000000)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>

          <FormControl>
            <TextField
              placeholder="Enter your answer"
              InputProps={{
                className: classes.custom,
              }}
              onChange={handleCustomAnswer}
            />
          </FormControl>
          <Button onClick={handleCustomClose}>Done</Button>
        </ModalWrapper>
      </Dialog>
    </section>
  )
}

export default Questions
