import React, { useEffect } from "react"
import Header from "./header"
import analytics from "./Analytics"
const ThankYou = () => {
  useEffect(() => {
    analytics.page({
      title: "Thank You Step",
      path: "/thank-you",
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  return (
    <section className="successful">
      <div className="container">
        <Header />
        <h3>Thank you for participating!</h3>
        <p>
          It's integral in paving the way for a more inclusive future in
          production.
        </p>
      </div>
    </section>
  )
}

export default ThankYou
