import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"
import StepRangeSlider from "react-step-range-slider"
const range = [
  { value: 1, step: 1 }, // acts as min value
  { value: 2, step: 1 },
  { value: 3, step: 1 },
  { value: 4, step: 1 },
  { value: 5 }, // acts as max value
]

const useStyles = makeStyles(theme => ({
  feedback: {
    border: "1px solid #fff",
    color: "#fff",
    padding: "10px",
    width: "100%",
  },
}))

const FeedbackSection = ({ rating, setRating, setFeedback, feedback }) => {
  const classes = useStyles()
  return (
    <div className="question-section">
      <h3>Ok, last question...</h3>
      <div className="question-wrapper">
        How do you feel about Working Insights being on this production?
        <div className="slider">
          <StepRangeSlider
            value={rating}
            range={range}
            onChange={value => setRating(parseInt(value))}
          />
          <div className="rating">
            <span>I don't like it</span>
            <span>Indifferent</span>
            <span>
              Love it!
              <br />
              Hope to see
              <br />
              you again.
            </span>
          </div>
        </div>
      </div>

      <div className="question-wrapper">
        Any additional thoughts or feedback?
        <TextField
          multiline
          minRows={4}
          className={classes.feedback}
          onChange={e => setFeedback(e.target.value)}
          value={feedback}
        />
      </div>
    </div>
  )
}

export default FeedbackSection
