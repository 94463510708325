import React, { useState, useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core/"
import { Autocomplete, createFilterOptions } from "@material-ui/lab/"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { useLongPress } from "use-long-press"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
}))

const AutoCompleteWithObject = ({
  type,
  value,
  setValue,
  options,
  survey,
  open,
  setOpen,
  className,
  setCustomOpen,
  queryClass,
  onChangeRole,
  showError,
  bidding,
  academyType,
  hideEndAdornment = false,
}) => {
  const classes = useStyles()
  const [enabled, setEnabled] = useState(true)
  const [preferObj, setPreObj] = useState({})

  useEffect(() => {
    console.log(options)
    const prefer = options.filter(i => i.name === "Prefer not to say")
    setPreObj({
      id: prefer[0]?.id,
      name: "Prefer not to say",
    })
  }, [])

  const _filterOptions = createFilterOptions()
  const filterOptions = (roles, state) => {
    let results = _filterOptions(roles, state)
    results = results.filter(i => i.name !== "Prefer not to say")
    results.push({ id: "001", name: "Not Listed" })
    results.push(preferObj)
    results.unshift({ id: "", name: "Type to search" })
    return results
  }

  const callback = useCallback(event => {
    setValue(prev => [
      ...prev,
      { id: event.target.id, name: event.target.childNodes[1].data },
    ])
    // eslint-disable-next-line
  }, [])
  const bind = useLongPress(enabled ? callback : null, {
    filterEvents: event => true,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: 25,
    cancelOutsideElement: true,
    detect: "pointer",
  })

  return (
    <Autocomplete
      value={value}
      multiple
      id={type}
      className={className}
      open={open}
      limitTags={2}
      onOpen={() => {
        if (bidding && type === "role") {
          setOpen(true)
        }

        if (
          academyType === "Executives" ||
          academyType === "On-Camera talent"
        ) {
          setOpen(true)
        }
      }}
      onClose={() => {
        setOpen(false)
      }}
      disableCloseOnSelect
      filterOptions={filterOptions}
      onInputChange={(_, value) => {
        if (academyType === undefined || academyType === "Crew") {
          if (value.length === 0) {
            if (open) setOpen(false)
          } else {
            if (!open) setOpen(true)
          }
        } else {
          setOpen(true)
        }
      }}
      options={options}
      renderOption={(option, { selected }) => {
        if (option.name !== "Type to search") {
          return (
            <div {...bind()} id={option.id}>
              {selected ? (
                <CheckBoxIcon
                  style={{
                    marginRight: "8px",
                    pointerEvents: "none",
                  }}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  style={{
                    marginRight: "8px",
                    pointerEvents: "none",
                  }}
                />
              )}

              {option.name}
            </div>
          )
        } else {
          return (
            <i {...bind()} id={option.id}>
              {option.name}
            </i>
          )
        }
      }}
      getOptionLabel={option => (option.name ? option.name : "")}
      getOptionDisabled={option => {
        if (
          option.name === "Type to search" ||
          value.filter(r => r.name === "Prefer not to say").length > 0
        ) {
          return true
        }
      }}
      onChange={(e, newValue) => {
        const element = document.querySelectorAll(queryClass)

        if (
          newValue.length > 0 &&
          newValue[newValue.length - 1].name === "Not Listed"
        ) {
          setCustomOpen({ open: true, type: type })
        }

        if (
          newValue.length > 0 &&
          newValue[newValue.length - 1].name === "Prefer not to say"
        ) {
          const el = document.getElementsByClassName("hide-it")

          el.length > 0 && el[0].classList.remove("hide-it")

          setValue(newValue.filter(i => i.name === "Prefer not to say"))
        } else {
          setValue(newValue.filter(i => i.name !== "Not Listed"))
        }

        if (element) {
          const notListIndex = newValue.findIndex(r => r.name === "Not Listed")
          for (var i = 0; i < element.length; i++) {
            element[i].parentElement.classList.remove("hide-it")

            if (element[i].innerHTML === "Not Listed") {
              i - 1 === notListIndex
                ? element[i - 1]?.parentElement.classList.add("hide-it")
                : element[i]?.parentElement.classList.add("hide-it")
            }

            if (newValue.length > 0 && newValue[1] === "Prefer not to say") {
              element[i]?.parentElement.classList.remove("hide-it")
            }

            if (newValue.length === 1 && newValue[0].name === "Not Listed") {
              setValue([])
            }

            newValue.length === 1 &&
              i === 1 &&
              notListIndex === 0 &&
              setValue([])
          }
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          onChange={ev => {
            if (
              type === "role" &&
              (ev.target.value !== "" || ev.target.value !== null)
            ) {
              onChangeRole(ev.target.value)
            }
          }}
          placeholder={type === "role" && value.length === 0 && "Enter role"}
          error={survey === 1 && showError && value.length === 0}
          shrink="false"
          InputProps={{
            ...params.InputProps,
            className: classes.select,
            endAdornment: !hideEndAdornment && (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default AutoCompleteWithObject
