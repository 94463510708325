import React, { useState, useEffect } from "react"
import {
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import ANSWERS from "../../constants/Constant"
import LANGUAGES from "../../constants/Languages"
import { ModalWrapper } from "../style"
import Header from "../header"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import analytics from "../Analytics"
import { isMobile } from "mobile-device-detect"
import Asterisk from "../../images/asterisk-solid.svg"
import formValidations from "../../util/formValidations"
import SingleSelect from "../shared/SingleSelect"
import AutoComplete from "../shared/AutoComplete"
import AutoCompleteWithObject from "../shared/AutoCompleteWithObject"
import RequiredError from "../shared/RequiredError"
import imigrantLookups from "../../util/imigrantLookups"
import ExampleReport from "../../pdf/example-report.pdf"

const EXECUTIVES_ROLES = [
  {
    id: "001",
    name: "Chief Creative Officer (CCO)",
  },
  {
    id: "001",
    name: "Chief Executive Officer (CEO)",
  },
  {
    id: "001",
    name: "Chief Marketing Officer (CMO)",
  },
  {
    id: "001",
    name: "Director of Distribution",
  },
  {
    id: "001",
    name: "Director of Public Relations",
  },
  {
    id: "001",
    name: "EVP Marketing",
  },
  {
    id: "001",
    name: "Head of Public Relations",
  },
  {
    id: "001",
    name: "President of Distribution",
  },
  {
    id: "001",
    name: "President of Marketing",
  },
  {
    id: "001",
    name: "President of Public Relations",
  },
  {
    id: "001",
    name: "Public Relations Officer",
  },
  {
    id: "001",
    name: "SVP Marketing",
  },
  {
    id: "001",
    name: "VP Distribution",
  },
  {
    id: "001",
    name: "VP Marketing",
  },
  {
    id: "001",
    name: "VP Public Relations",
  },
]

const ON_CAMERA_TALENT = [
  {
    id: "001",
    name: "Lead Actor",
  },
  {
    id: "001",
    name: "Supporting Actor",
  },
  {
    id: "001",
    name: "Ensemble Cast",
  },
  {
    id: "001",
    name: "Voiceover Artist",
  },
]

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "25px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },

  custom: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "32px",
    paddingBottom: 0,
    paddingLeft: "5px",
    border: "1px solid #fff",
  },
}))

function AcademyQuestions({
  roles,
  setStep,
  setUuid,
  productionUUID,
  crewUUID,
  prefilledData,
  survey,
  setErrorMessage,
  academyType,
  productionCompany,
  projectName,
}) {
  const classes = useStyles()

  const [yearBorn] = useState("")
  const [gender, setGender] = useState([])
  const [pronouns] = useState([])
  const [sexualOrientation, setSexualOrientation] = useState([])
  const [birthplace] = useState("")
  const [ethnicity, setEthnicity] = useState([])
  const [ethnicity1, setEthnicity1] = useState([])
  const [nationality] = useState([])
  const [veteran, setVeteran] = useState("")
  const [disability, setDisablity] = useState("")
  const [disabilityType, setDisabilityType] = useState([])
  const [, setDisabledDisablityType] = useState(false)
  const [inclusion] = useState("")
  const [nativeLang] = useState("")
  const [secondarayLang] = useState([])
  const [role, setRole] = useState([])
  const [rating] = useState(3)
  const [feedback] = useState("")
  const [genderOpen, setGenderOpen] = useState(false)
  const [sexOpen, setSexOpen] = useState(false)
  const [ethnicityOpen, setEthnicityOpen] = useState(false)
  const [ethnicityOpen1, setEthnicityOpen1] = useState(false)
  const [veteranOpen, setVeteranOpen] = useState(false)
  const [disablilityOpen, setDisabilityOpen] = useState(false)
  const [disabilityTypeOpen, setDisabilityTypeOpen] = useState(false)
  const [showDisabilities, setShowDisabilities] = useState(false)
  const [, setGenerationOpen] = useState(false)
  const [militaryBranchOpen, setMilitaryBranchOpen] = useState(false)
  const [workDays] = useState("")
  const [travel] = useState("")
  const [immigrant, setImmigrant] = useState(false)
  const [currentService] = useState(false)
  const [yoe] = useState("")
  const [commute] = useState("")
  const [secondaryRole] = useState([])
  const [rateDimension] = useState("")
  const [paySentiment] = useState("")
  const [generation, setGeneration] = useState("")
  const [duty] = useState("")
  const [tackles] = useState("")
  const [caredMost] = useState("")
  const [tacklesCustom] = useState("")
  const [careMoreCustom] = useState("")
  const [rolesOpen, setRolesOpen] = useState(false)
  const [rolesOption, setRolesOption] = useState(roles.slice(0, 100))
  const [customOpen, setCustomOpen] = useState({ open: false, type: "" })
  const [customAnswer, setCustomAnswer] = useState("")
  const [rate] = useState("")
  const [veteranYearsOfService] = useState("")
  const [militaryBranch, setMilitaryBranch] = useState([])
  const [paysentimentCustom] = useState("")
  const [generationCustom, setGenerationCustom] = useState("")
  const [referralSource] = useState([])
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showVeteran, setShowVeteran] = useState(false)
  const [generationCustomSet, setGenerationCustomSet] = useState(false)
  const [currency] = useState("USD")
  const [travelFrom] = useState("")
  const [mixedRace, setMixedRace] = useState(false)
  const [environmental] = useState("")
  const [member, setMember] = useState(false)
  const [consent, setConsent] = useState(false)

  useEffect(() => {
    if (!immigrant) {
      setGeneration("")
    }
  }, [immigrant])

  useEffect(() => {
    analytics.page({
      title: "Questions Step",
      path: "/questions",
    })

    window.scrollTo({ top: 0, behavior: "smooth" })
    if (prefilledData !== "") {
      setRole(
        survey[0].questions[0].visibility === 1
          ? prefilledData?.primaryRoles || []
          : []
      )

      setImmigrant(prefilledData?.immigrant === 1 ? true : false)
      setGeneration(
        survey[3].questions[1].visibility === 1 ? prefilledData?.immigrant : ""
      )

      setEthnicity(
        survey[3].questions[5].visibility === 1
          ? prefilledData?.ethnicities?.find(
              e => e.name === "Mixed race/Multi-ethnic"
            )
            ? prefilledData?.ethnicities
                ?.filter(i => i.name === "Mixed race/Multi-ethnic")
                .map(i => i.name) || []
            : prefilledData?.ethnicities?.map(i => i.name) || []
          : []
      )

      setGender(
        survey[3].questions[7].visibility === 1
          ? prefilledData?.genders?.map(i => i.name) || []
          : []
      )
      setSexualOrientation(
        survey[3].questions[8].visibility === 1
          ? prefilledData?.sexualOrientations?.map(i => i.name) || []
          : []
      )
      setDisablity(
        survey[3].questions[9].visibility === 1
          ? prefilledData?.disabilityStatus
          : ""
      )
      setDisabilityType(
        survey[3].questions[9].visibility === 1
          ? prefilledData?.disabilityTypes?.map(i => i.name) || []
          : []
      )
      setVeteran(
        survey[3].questions[10].visibility === 1
          ? prefilledData?.veteranStatus
          : ""
      )

      setMilitaryBranch(
        survey[3].questions[10].visibility === 1
          ? prefilledData?.militaryBranches?.map(i => i.name) || []
          : []
      )
    }
    // eslint-disable-next-line 
  }, []) 

  useEffect(() => {
    if (
      prefilledData !== "" &&
      prefilledData?.generations.length > 0 &&
      prefilledData?.generations[0]?.type === "not listed" &&
      !generationCustomSet
    ) {
      const d = document.getElementById("generation")
      if (d !== null && d.innerHTML === "Not Listed") {
        d.innerHTML = prefilledData.generations[0].name
        setGenerationCustomSet(true)
      }
    }
    // eslint-disable-next-line
  }, [generation])

  useEffect(() => {
    if (ethnicity?.includes("Mixed race/Multi-ethnic")) {
      setMixedRace(true)
      setEthnicity1(
        prefilledData?.ethnicities
          ?.filter(i => i.name !== "Mixed race/Multi-ethnic")
          .map(i => i.name) || []
      )
    } else {
      setEthnicity1([])
      setMixedRace(false)
    }
    // eslint-disable-next-line
  }, [ethnicity])

  useEffect(() => {
    const prefer = roles.filter(i => i.name === "Prefer not to say")
    EXECUTIVES_ROLES.push({
      id: prefer[0]?.id,
      name: "Prefer not to say",
    })
    ON_CAMERA_TALENT.push({
      id: prefer[0]?.id,
      name: "Prefer not to say",
    })
    if (!rolesOpen) {
      academyType === "Executives"
        ? setRolesOption(EXECUTIVES_ROLES)
        : academyType === "On-Camera talent"
        ? setRolesOption(ON_CAMERA_TALENT)
        : setRolesOption(roles.slice(0, 500))
    }
    // eslint-disable-next-line
  }, [rolesOpen])

  useEffect(() => {
    if (disability === "do") {
      setDisabledDisablityType(false)
      setShowDisabilities(true)
    } else {
      setDisabledDisablityType(true)
      setShowDisabilities(false)
    }
  }, [disability])

  useEffect(() => {
    if (veteran === "do") {
      setShowVeteran(true)
    } else {
      setShowVeteran(false)
    }
  }, [veteran])

  useEffect(() => {
    if (showError) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [showError])

  const onChangeRole = value => {
    academyType === "Executives"
      ? setRolesOption(
          EXECUTIVES_ROLES.filter(role =>
            role.name.toLowerCase().includes(value.toLowerCase())
          )
        )
      : academyType === "On-Camera talent"
      ? setRolesOption(
          ON_CAMERA_TALENT.filter(role =>
            role.name.toLowerCase().includes(value.toLowerCase())
          )
        )
      : setRolesOption(
          roles.filter(role =>
            role.name.toLowerCase().includes(value.toLowerCase())
          )
        )
  }

  const handleCustomValue = () => {
    if (
      customOpen.type === "nationality" ||
      customOpen.type === "role" ||
      customOpen.type === "ethnicity" ||
      customOpen.type === "ethnicity1" ||
      customOpen.type === "gender" ||
      customOpen.type === "sexualOrientation" ||
      customOpen.type === "disabilityType" ||
      customOpen.type === "militaryBranch"
    ) {
      const el = document.getElementsByClassName("MuiChip-label")
      if (el) {
        for (var i = 0; i < el.length; i++) {
          if (el[i].innerHTML === "Not Listed") {
            el[i].parentElement.classList.add("hide-it")
          }
        }
      }
    }

    const custom = document.getElementById(customOpen.type)
    const val = custom && custom.innerHTML

    if (custom !== null) {
      custom.innerHTML = val.replace("Not Listed", customAnswer)
    }
  }

  const handleChangeMultiple = (event, type, data) => {
    let { value } = event.target
    if (type === "yearBorn") {
      value = value.toString()
    }

    const openCustomModal =
      value.includes("Not Listed") || value === "Not Listed"
    const found =
      typeof value !== "string" && value.filter(i => !data.includes(i))

    if (
      (openCustomModal && found.length === 0) ||
      (openCustomModal && data === undefined)
    ) {
      setCustomOpen({ open: true, type: type })
    }

    if (value.includes("Prefer not to say") || value === "Prefer not to say") {
      value =
        typeof value !== "string"
          ? value.filter(i => i === "Prefer not to say")
          : value
    }

    if (found.length > 0) {
      value = value.filter(i => data.includes(i))
    }

    switch (type) {
      case "generation":
        setGeneration(value)
        if (value !== "") {
          setImmigrant(true)
        }
        setGenerationOpen(false)
        break
      case "militaryBranch":
        if (value.includes("all")) {
          setMilitaryBranch(data.filter(i => i !== "Not Listed"))
        } else {
          setMilitaryBranch(value)
        }
        isMobile && setMilitaryBranchOpen(false)
        break

      case "gender":
        if (value.includes("all")) {
          setGender(data.filter(i => i !== "Not Listed"))
        } else {
          setGender(value)
        }
        isMobile && setGenderOpen(false)
        break

      case "sexualOrientation":
        if (value.includes("all")) {
          setSexualOrientation(data.filter(i => i !== "Not Listed"))
        } else {
          setSexualOrientation(value)
        }
        isMobile && setSexOpen(false)
        break
      case "ethnicity":
        if (value.includes("all")) {
          setEthnicity(data.filter(i => i !== "Not Listed"))
        } else {
          setEthnicity(value)
        }
        isMobile && setEthnicityOpen(false)
        break
      case "ethnicity1":
        if (value.includes("all")) {
          setEthnicity1(data.filter(i => i !== "Not Listed"))
        } else {
          setEthnicity1(value)
        }
        isMobile && setEthnicityOpen1(false)
        break
      case "veteran":
        return setVeteran(value)
      case "disability":
        return setDisablity(value)
      case "disabilityType":
        if (value.includes("all")) {
          setDisabilityType(data.filter(i => i !== "Not Listed"))
        } else {
          setDisabilityType(value)
        }
        isMobile && setDisabilityTypeOpen(false)
        break
      default:
    }

    handleCustomValue()
  }

  const handleCustomClose = () => {
    handleCustomValue()
    switch (customOpen.type) {
      case "gender":
        if (customAnswer.trim() === "") {
          setGender(gender.filter(i => i !== "Not Listed"))
        } else {
          gender.push(customAnswer)
        }
        break

      case "ethnicity":
        if (customAnswer.trim() === "") {
          setEthnicity(ethnicity.filter(i => i !== "Not Listed"))
        } else {
          ethnicity.push(customAnswer)
        }
        break
      case "ethnicity1":
        if (customAnswer.trim() === "") {
          setEthnicity1(ethnicity1.filter(i => i !== "Not Listed"))
        } else {
          ethnicity1.push(customAnswer)
        }
        break
      case "sexualOrientation":
        if (customAnswer.trim() === "") {
          setSexualOrientation(
            sexualOrientation.filter(i => i !== "Not Listed")
          )
        } else {
          sexualOrientation.push(customAnswer)
        }
        break

      case "disabilityType":
        if (customAnswer.trim() === "") {
          setDisabilityType(disabilityType.filter(i => i !== "Not Listed"))
        } else {
          disabilityType.push(customAnswer)
        }
        break

      case "generation":
        if (customAnswer !== "") {
          setGenerationCustom(customAnswer)
        } else {
          setGeneration("")
        }
        break
      case "militaryBranch":
        if (customAnswer.trim() === "") {
          setMilitaryBranch(militaryBranch.filter(i => i !== "Not Listed"))
        } else {
          militaryBranch.push(customAnswer)
        }
        break
      case "role":
        if (customAnswer.trim() === "") {
          setRole(role.filter(i => i.name !== "Not Listed"))
        } else {
          role.push({ id: "000", name: `${customAnswer}` })
        }
        break
      default:
    }
    setCustomAnswer("")
    setCustomOpen({ open: false, type: "" })
  }

  const handleCustomAnswer = e => {
    const { value } = e.target
    if (
      value.toLowerCase() === "not listed" ||
      value.toLowerCase() === "prefer not to say"
    ) {
      setCustomAnswer("")
    } else {
      setCustomAnswer(value)
    }
  }

  const validation = () => {
    if (!consent) {
      setShowError(true)
      return false
    }
    if (
      formValidations(
        true,
        true,
        veteran === "" || (veteran === "do" && militaryBranch.length === 0)
      )
    ) {
      setShowError(true)
      return false
    }

    if (formValidations(true, true, disability === "")) {
      setShowError(true)
      return false
    }

    if (
      formValidations(
        true,
        true,
        disability === "do" && disabilityType.length === 0
      )
    ) {
      setShowError(true)
      return false
    }

    if (formValidations(true, true, sexualOrientation.length === 0)) {
      setShowError(true)
      return false
    }

    if (formValidations(true, true, gender.length === 0)) {
      setShowError(true)
      return false
    }

    if (
      formValidations(
        true,
        true,
        ethnicity.length === 0 ||
          (ethnicity.includes("Mixed race/Multi-ethnic") &&
            ethnicity1.length === 0)
      )
    ) {
      setShowError(true)
      return false
    }

    if (formValidations(true, true, role.length === 0)) {
      setShowError(true)
      return false
    }

    return true
  }

  const submit = () => {
    let primaryRole = []
    let secondRole = []
    role.forEach(i => {
      if (i.id !== "001" && i.id !== "000") {
        primaryRole.push(parseInt(i.id))
      } else {
        primaryRole.push(`${i.name}`)
      }
    })

    secondaryRole.forEach(i => {
      if (i.id !== "001" && i.id !== "000") {
        secondRole.push(parseInt(i.id))
      }

      if (i.id === "000") {
        secondRole.push(`${i.name}`)
      }
    })
    const ethnicityObj = [...ethnicity, ...ethnicity1]

    if (validation()) {
      setLoading(true)
      const data = {
        birthDate: yearBorn,
        birthPlace: birthplace,
        nativeLanguage:
          nativeLang === ""
            ? ""
            : {
                value: nativeLang,
                type:
                  LANGUAGES.nativeLanguage.includes(nativeLang) ||
                  nativeLang.includes("Prefer not to say")
                    ? "listed"
                    : "not listed",
              },
        veteranStatus: veteran,
        disabilityStatus: disability,
        inclusion: inclusion,
        productionUUID: productionUUID,
        crewUUID: crewUUID,
        roleId: primaryRole,
        secondaryRole: secondRole,
        disabilityType:
          disabilityType.length > 0 &&
          disabilityType
            .filter(g => g !== "Not Listed")
            .map(g => ({
              value: g,
              type:
                ANSWERS.disabilityType.includes(g) ||
                disabilityType.includes("Prefer not to say")
                  ? "listed"
                  : "not listed",
            })),
        gender: gender
          .filter(g => g !== "Prefer to self describe")
          .map(g => ({
            value: g,
            type:
              ANSWERS.gender.includes(g) || gender.includes("Prefer not to say")
                ? "listed"
                : "prefer to self describe",
          })),
        pronouns: pronouns
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.pronouns.includes(g) ||
              pronouns.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),
        sexualOrientation: sexualOrientation
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.sexualOrientation.includes(g) ||
              sexualOrientation.includes("Prefer not to say")
                ? "listed"
                : "Not listed",
          })),
        ethnicity: ethnicityObj
          .filter(g => g !== "Prefer to self describe")
          .map(g => ({
            value: g,
            type:
              ANSWERS.ethnicity.includes(g) ||
              ethnicityObj.includes("Prefer not to say")
                ? "listed"
                : "prefer to self describe",
          })),
        nationality:
          nationality.length > 0
            ? nationality
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.nationality.includes(g) ||
                    nationality.includes("Prefer not to say")
                      ? "listed"
                      : "not listed",
                }))
            : [],
        secondaryLanguage:
          secondarayLang.length > 0
            ? secondarayLang
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    LANGUAGES.nativeLanguage.includes(g) ||
                    secondarayLang.includes("Prefer not to say")
                      ? "listed"
                      : "not listed",
                }))
            : [],
        paySentiment: [
          {
            value:
              paySentiment === "Not Listed" ? paysentimentCustom : paySentiment,
            type: paySentiment === "Not Listed" ? "not listed" : "listed",
          },
        ],
        generation: [
          {
            value:
              generation === "Not Listed"
                ? generationCustom
                : imigrantLookups(generation),
            type: generation === "Not Listed" ? "not listed" : "listed",
          },
        ],
        yearsOfExperience: yoe,
        experience: [],
        tackles: [
          {
            value: tacklesCustom !== "" ? tacklesCustom : tackles,
            type: tacklesCustom !== "" ? "not listed" : "listed",
          },
        ],
        caredMost: [
          {
            value:
              careMoreCustom !== ""
                ? careMoreCustom
                : prefilledData !== ""
                ? caredMost[0]
                : caredMost,
            type: careMoreCustom !== "" ? "not listed" : "listed",
          },
        ],
        environmentalConcern: environmental,
        commute: commute,
        travel: travel,
        traveledFrom: travelFrom,
        dayRate: survey[1].questions[0].visibility === 1 ? rate : "",
        rateDimension:
          survey[1].questions[0].visibility === 1 ? rateDimension : "",
        workDays: survey[1].questions[0].visibility === 1 ? workDays : 0,
        immigrant: generation,
        veteranYearsOfService: veteranYearsOfService,
        currentService: currentService ? 1 : 0,
        duty: duty,
        militaryBranch:
          militaryBranch.length > 0
            ? militaryBranch
                .filter(g => g !== "Not Listed")
                .map(g => ({
                  value: g,
                  type:
                    ANSWERS.militaryBranch.includes(g) ||
                    militaryBranch.includes("Prefer not to say")
                      ? "listed"
                      : "not listed",
                }))
            : [],
        updateId: prefilledData.updateId || "",
        copyFrom: prefilledData.copyFrom || "",
        referralSource: referralSource
          .filter(g => g !== "Not Listed")
          .map(g => ({
            value: g,
            type:
              ANSWERS.referral.includes(g) ||
              referralSource.includes("Prefer not to say")
                ? "listed"
                : "not listed",
          })),
        rate: rating || "",
        feedback: feedback || "",
        currency: survey[1].questions[0].visibility === 1 ? currency : "",
        academyMember: member ? 1 : 0,
        academyConsent: consent ? 1 : 0,
      }

      if (crewUUID === "" || crewUUID === undefined) {
        delete data.crewUUID
      }

      if (disabilityType.length === 0) {
        delete data.disabilityType
      }

      if (prefilledData === "") {
        delete data.updateId
        delete data.copyFrom
      }

      axios
        .post(`${API_ROOT}${ENV}/survey-response/proactive/signup`, data)
        .then(response => {
          if (response.data.statusCode === 200) {
            setUuid(response.data.devMessage)
            analytics.track("Completed Survey", {
              category: "Proactive Survey",
            })

            setLoading(false)
            prefilledData === "" ? setStep(3) : setStep(4)
          } else {
            if (response?.data?.message?.includes("custom role")) {
              setRole([])
              primaryRole = []
              setShowError(true)
            } else {
              setErrorMessage(response.data.devMessage)
              setStep("error")
            }
            setLoading(false)
          }
        })
        .catch(function (error) {
          setErrorMessage(error)
          setStep("error")
          setLoading(false)
        })
    } else {
      return false
    }
  }

  return (
    <section className="questions">
      <div className="container">
        <Header />

        {prefilledData !== "" && (
          <p className="returning">
            Please review and update your information below.
          </p>
        )}

        <p className="indicates">
          <img src={Asterisk} alt="Required" />
          indicates required field
        </p>

        <div className="bg">
          <FormControl>
            <div className="questionaire proactive">
              <div className="question-section">
                <div className="question-wrapper">Title: {projectName}</div>
                <div className="question-wrapper divider-custom">
                  Production company: {productionCompany}
                </div>

                <div className="question-wrapper">
                  I am the{" "}
                  <FormControl className={classes.formControl}>
                    <div className="field-wrapper">
                      <AutoCompleteWithObject
                        type="role"
                        value={role}
                        setValue={setRole}
                        open={rolesOpen}
                        setOpen={setRolesOpen}
                        options={rolesOption}
                        survey={1}
                        className="role-chips"
                        setCustomOpen={setCustomOpen}
                        queryClass=".role-chips .MuiChip-label"
                        showError={showError}
                        onChangeRole={onChangeRole}
                        academyType={academyType}
                      />

                      <RequiredError
                        error={true && showError && role.length === 0}
                        required={true}
                        classes="field-asterisk"
                      />
                    </div>
                  </FormControl>{" "}
                  on this project.
                  <div className="role-info-text">
                    To ensure anonymity, role data will not be shared unless
                    aggregated across multiple productions.
                  </div>
                </div>

                <div className="question-wrapper">
                  In terms of ethnicity, I identify as
                  <div className="field-wrapper">
                    <FormControl
                      className={classes.formControl}
                      style={{ maxWidth: "none" }}
                    >
                      <AutoComplete
                        type="ethnicity"
                        value={ethnicity}
                        setValue={setEthnicity}
                        open={ethnicityOpen}
                        setOpen={setEthnicityOpen}
                        options={ANSWERS.ethnicity}
                        survey={1}
                        setCustomOpen={setCustomOpen}
                        showError={showError}
                        queryClass=".ethnicity-chips .MuiChip-label"
                        className="ethnicity-chips"
                        multiple={true}
                      />
                    </FormControl>
                    .
                    <RequiredError
                      error={true && showError && ethnicity.length === 0}
                      required={true}
                      classes="field-asterisk right2"
                    />
                  </div>
                  {mixedRace && (
                    <>
                      {" "}
                      The ethnicities I identify with include
                      <div className="field-wrapper">
                        <FormControl
                          className={classes.formControl}
                          style={{ maxWidth: "none" }}
                        >
                          <AutoComplete
                            type="ethnicity1"
                            value={ethnicity1}
                            setValue={setEthnicity1}
                            open={ethnicityOpen1}
                            setOpen={setEthnicityOpen1}
                            options={ANSWERS.ethnicity.filter(
                              v => v !== "Mixed race/Multi-ethnic"
                            )}
                            survey={1}
                            setCustomOpen={setCustomOpen}
                            showError={showError}
                            queryClass=".ethnicity-chips .MuiChip-label"
                            className="ethnicity-chips"
                            multiple={true}
                          />
                        </FormControl>
                        .
                        <RequiredError
                          error={
                            true &&
                            showError &&
                            ethnicity.includes("Mixed race/Multi-ethnic") &&
                            ethnicity1.length === 0
                          }
                          required={true}
                          classes="field-asterisk right2"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="question-wrapper">
                  My gender is{" "}
                  <div className="field-wrapper">
                    <FormControl
                      className={classes.formControl}
                      style={{ maxWidth: "none" }}
                    >
                      <AutoComplete
                        type="gender"
                        value={gender}
                        setValue={setGender}
                        open={genderOpen}
                        setOpen={setGenderOpen}
                        options={ANSWERS.gender}
                        survey={1}
                        setCustomOpen={setCustomOpen}
                        showError={showError}
                        queryClass=".gender-chips .MuiChip-label"
                        className="gender-chips"
                        multiple={true}
                      />
                    </FormControl>

                    <RequiredError
                      error={true && showError && gender.length === 0}
                      required={true}
                      classes="field-asterisk"
                    />
                  </div>
                </div>

                <div className="question-wrapper">
                  My sexual orientation is{" "}
                  <div className="field-wrapper">
                    <FormControl
                      className={classes.formControl}
                      style={{ maxWidth: "none" }}
                    >
                      <AutoComplete
                        type="sexualOrientation"
                        value={sexualOrientation}
                        setValue={setSexualOrientation}
                        open={sexOpen}
                        setOpen={setSexOpen}
                        options={ANSWERS.sexualOrientation}
                        survey={1}
                        setCustomOpen={setCustomOpen}
                        showError={showError}
                        queryClass=".sexual-chips .MuiChip-label"
                        className="sexual-chips"
                        multiple={true}
                      />
                    </FormControl>
                    .
                    <RequiredError
                      error={
                        true && showError && sexualOrientation.length === 0
                      }
                      required={true}
                      classes="field-asterisk"
                    />
                  </div>
                </div>

                <div className="question-wrapper">
                  I{" "}
                  <div className="field-wrapper">
                    <SingleSelect
                      type="disability"
                      value={disability}
                      survey={survey}
                      showError={showError}
                      options={ANSWERS.disability}
                      open={disablilityOpen}
                      setOpen={setDisabilityOpen}
                      handleChangeMultiple={handleChangeMultiple}
                    />

                    <RequiredError
                      error={true && showError && disability === ""}
                      required={true}
                      classes="field-asterisk right2"
                    />
                  </div>{" "}
                  live with one or more disabilities
                  {showDisabilities ? (
                    <>
                      , including{" "}
                      <div className="field-wrapper">
                        <FormControl
                          className={classes.formControl}
                          style={{ maxWidth: "none" }}
                        >
                          <AutoComplete
                            type="disabilityType"
                            value={disabilityType}
                            setValue={setDisabilityType}
                            open={disabilityTypeOpen}
                            setOpen={setDisabilityTypeOpen}
                            options={ANSWERS.disabilityType}
                            survey={1}
                            setCustomOpen={setCustomOpen}
                            showError={showError}
                            queryClass=".disability-chips .MuiChip-label"
                            className="disability-chips"
                            multiple={true}
                          />
                        </FormControl>
                        .
                        {true &&
                          showError &&
                          disability === "do" &&
                          disabilityType.length === 0 && (
                            <>
                              <FormHelperText className="error">
                                Please make a selection
                              </FormHelperText>
                              <img
                                className="field-asterisk right2"
                                src={Asterisk}
                                alt="Required"
                              />
                            </>
                          )}
                      </div>
                    </>
                  ) : (
                    "."
                  )}
                </div>

                <div className="question-wrapper">
                  I{" "}
                  <div className="field-wrapper">
                    <SingleSelect
                      type="veteran"
                      value={veteran}
                      survey={survey}
                      showError={showError}
                      options={ANSWERS.veteranStatus}
                      open={veteranOpen}
                      setOpen={setVeteranOpen}
                      handleChangeMultiple={handleChangeMultiple}
                    />

                    <RequiredError
                      error={true && showError && veteran === ""}
                      required={true}
                      classes="field-asterisk right2"
                    />
                  </div>{" "}
                  identify as a military veteran.{" "}
                  {showVeteran && (
                    <>
                      I served in the{" "}
                      <div className="field-wrapper">
                        <FormControl
                          className={classes.formControl}
                          style={{ maxWidth: "none" }}
                        >
                          <AutoComplete
                            type="militaryBranch"
                            value={militaryBranch}
                            setValue={setMilitaryBranch}
                            open={militaryBranchOpen}
                            setOpen={setMilitaryBranchOpen}
                            options={ANSWERS.militaryBranch}
                            survey={1}
                            setCustomOpen={setCustomOpen}
                            showError={showError}
                            queryClass=".military-chips .MuiChip-label"
                            className="military-chips"
                            multiple={true}
                          />
                        </FormControl>

                        <RequiredError
                          error={
                            true && showError && militaryBranch.length === 0
                          }
                          required={true}
                          classes="field-asterisk"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="question-wrapper cb">
                  <Checkbox
                    iconstyle={{ fill: "white" }}
                    inputstyle={{ color: "white" }}
                    style={{ color: "white" }}
                    onChange={() => setMember(!member)}
                    checked={member}
                  />
                  I am a member of the Academy of Motion Picture Arts & Sciences
                </div>

                <div className="question-wrapper cb">
                  <Checkbox
                    iconstyle={{ fill: "white" }}
                    inputstyle={{ color: "white" }}
                    style={{ color: "white" }}
                    onChange={() => setConsent(!consent)}
                    checked={consent}
                  />
                  I consent to providing my individual identification
                  information with The Academy of as part of the new
                  representation and inclusion standards for Oscars eligibility
                  in the Best Picture category.{" "}
                  <img src={Asterisk} alt="required" />
                </div>

                <div className="question-wrapper cb">
                  <a href={ExampleReport} target="_blank" rel="noreferrer">
                    View example report
                  </a>{" "}
                  to see how information is delivered to The Academy.
                </div>
              </div>

              <div className="actions proactive">
                <Button type="submit" onClick={submit} disabled={loading}>
                  {loading ? <CircularProgress color="black" /> : "Submit"}
                </Button>

                {showError && (
                  <FormHelperText className="error lang">
                    Please complete required fields
                  </FormHelperText>
                )}
              </div>
            </div>
          </FormControl>
        </div>
      </div>

      <Dialog
        onClose={handleCustomClose}
        aria-labelledby="simple-dialog-title"
        open={customOpen.open}
      >
        <ModalWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="28"
            viewBox="0 0 27 28"
            onClick={handleCustomClose}
          >
            <g fill="none" fillRule="evenodd" strokeLinecap="square">
              <g stroke="#FFF">
                <g>
                  <g>
                    <path
                      d="M26.707 0.283L0.06 25.942"
                      transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000)"
                    />
                    <path
                      d="M26.707 0.283L0.06 25.942"
                      transform="translate(-1013.000000, -444.000000) translate(384.000000, 419.000000) translate(629.000000, 26.000000) translate(13.500000, 13.000000) scale(-1, 1) translate(-13.500000, -13.000000)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>

          <FormControl>
            <TextField
              placeholder="Enter your answer"
              InputProps={{
                className: classes.custom,
              }}
              onChange={handleCustomAnswer}
            />
          </FormControl>
          <Button onClick={handleCustomClose}>Done</Button>
        </ModalWrapper>
      </Dialog>
    </section>
  )
}

export default AcademyQuestions
