import React, { useState, useEffect } from "react"
import RequiredError from "../shared/RequiredError"
import SingleSelect from "../shared/SingleSelect"
import AutoCompleteInv from "../shared/AutoComplete"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ANSWERS from "../../constants/StandWithProduction"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import { State, City } from "country-state-city"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 120,
    // maxWidth: 250,
    height: 45,
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "25px",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
  rate: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "26px",
    paddingBottom: 0,
    paddingLeft: "5px",
    paddingRight: "20px",
    width: "150px",
    borderBottom: "1px solid #fff",
    "@media (max-width: 767px)": {
      height: 25,
      fontSize: "16px",
    },
  },
  radioGroup: {
    flexDirection: "row",
    marginLeft: "20px",
    paddingTop: "5px",
  },
  radio: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  feedback: {
    border: "1px solid #fff",
    color: "#fff",
    padding: "10px",
    width: "50%",
    marginLeft: "20px",
  },
  custom: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "32px",
    paddingBottom: 0,
    paddingLeft: "5px",
    marginLeft: "10px",
    borderBottom: "1px solid #fff",
  },
  clear: {
    fill: "#fff",
  },
}))

const notInList = ["AS", "MP", "GU", "PR", "UM", "VI"]

const MyCareerSection = ({ value, setValue, showError }) => {
  const classes = useStyles()

  const [jobTitle, setJobTitle] = useState(value.jobTitle || [])
  const [jobTitleOpen, setJobTitleOpen] = useState(false)
  const [experience, setExperience] = useState(value.yearsOfExperience || "")

  const [union, setUnion] = useState(value.union || null)
  const [rate, setRate] = useState(value.rate || "")
  const [rateDimension, setRateDimension] = useState(value.rateDimension || "")
  const [rateDimensionOpen, setRateDimensionOpen] = useState(false)

  const [employee, setEmployee] = useState(value.employmentType || null)
  const [grossIncome, setGrossIncome] = useState(value.grossIncome || "")
  const [grossIncomeOpen, setGrossIncomOpen] = useState(false)

  const [yearlyIncome, setYearlyIncome] = useState(value.yearlyIncome || null)
  const [monthlyIncome, setMonthlyIncome] = useState(value.monthlyIncome || "")

  const [workOften, setWorkOften] = useState(value.workOften || "")
  const [workOftenOpen, setWorkOftenOpen] = useState(false)

  const [longHours, setLongHours] = useState(value.longHours || "")
  const [longHoursRole, setLongHoursRole] = useState(value.longHoursRole || "")
  const [longHoursRoleOpen, setLongHoursRoleOpen] = useState(false)

  const [viaProgram, setViaProgram] = useState(value.viaProgram || null)
  const [trainingProgram, setTrainingProgram] = useState(
    value.trainingProgram || ""
  )

  const [opportunity, setOpportunity] = useState(value.opportunity || null)
  const states = State.getStatesOfCountry("US").filter(
    s => s.isoCode.length === 2 && !notInList.includes(s.isoCode)
  )

  const [liveIn, setLiveIn] = useState(value.liveIn || "")
  const [selectedState, setSelectedState] = useState("")
  const [cities, setCities] = useState([])

  const [primaryWork, setPrimaryWork] = useState(value.primaryWork || "")
  const [workState, setWorkState] = useState("")
  const [workCity, setWorkCity] = useState("")

  useEffect(() => {
    setCities(City.getCitiesOfState("US", selectedState))
  }, [selectedState])

  useEffect(() => {
    setWorkCity(City.getCitiesOfState("US", workState))
  }, [workState])

  useEffect(() => {
    setValue(prev => ({
      ...prev,
      jobTitle: jobTitle,
      yearsOfExperience: experience,
      liveIn: liveIn,
      primaryWork: primaryWork,
      union: union,
      rate: rate,
      rateDimension: rateDimension,
      employmentType: employee,
      grossIncome: grossIncome,
      yearlyIncome: yearlyIncome,
      monthlyIncome: monthlyIncome,
      workOften: workOften,
      longHours: longHours,
      longHoursRole: longHoursRole,
      viaProgram: viaProgram,
      trainingProgram: trainingProgram,
      opportunity: opportunity,
    }))
  }, [
    jobTitle,
    experience,
    union,
    rate,
    rateDimension,
    employee,
    grossIncome,
    yearlyIncome,
    monthlyIncome,
    workOften,
    longHours,
    longHoursRole,
    viaProgram,
    trainingProgram,
    opportunity,
    liveIn,
    primaryWork,
  ])

  const handleChangeMultiple = (e, type, data) => {
    let { value } = e.target

    switch (type) {
      case "rateDimension":
        setRateDimension(value)
        setRateDimensionOpen(false)
        break
      case "grossIncome":
        setGrossIncome(value)
        setGrossIncomOpen(false)
        break
      case "workOften":
        setWorkOften(value)
        setWorkOftenOpen(false)
        break
      case "longHoursRole":
        setLongHoursRole(value)
        setLongHoursRoleOpen(false)
        break
    }
  }

  return (
    <div className="question-section">
      <h3>My Career</h3>
      <div className="question-wrapper">
        What is your job title? (select all that apply)
        <FormControl required className={classes.formControl}>
          <div className="field-wrapper">
            <AutoCompleteInv
              type="jobTitle"
              value={jobTitle}
              setValue={setJobTitle}
              multiple={true}
              options={ANSWERS.jobTitle}
              open={jobTitleOpen}
              setOpen={setJobTitleOpen}
              standWithProduction
            />
            <RequiredError
              error={jobTitle.length === 0 && showError}
              required={true}
              classes="field-asterisk"
            />
          </div>
        </FormControl>
      </div>

      <div className="question-wrapper">
        How many years of experience do you have?
        <FormControl required className={classes.formControl}>
          <div className="field-wrapper">
            <CurrencyTextField
              variant="standard"
              id="experience"
              type="tel"
              error={showError && experience === ""}
              value={experience}
              currencySymbol=""
              decimalPlaces={0}
              outputFormat="number"
              decimalPlacesShownOnBlur={0}
              minimumValue={0}
              onChange={(_, value) => setExperience(value)}
              InputProps={{
                className: classes.rate,
              }}
            />
            <RequiredError
              error={experience === "" && showError}
              required={true}
              classes="field-asterisk right2"
            />
          </div>
        </FormControl>
        years.
      </div>

      <div className="question-wrapper">
        Where do you currently live?
        {liveIn !== "" ? (
          <div className="field-wrapper">
            <FormControl>
              <TextField
                defaultValue={liveIn}
                disabled
                multiline
                InputProps={{
                  className: classes.custom,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSelectedState("")
                          setCities([])
                          setLiveIn("")
                        }}
                      >
                        <CloseIcon className={classes.clear} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
        ) : (
          <>
            <FormControl className={classes.formControl}>
              <div className="field-wrapper">
                <Autocomplete
                  id="states"
                  options={states}
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  onChange={(_, newValue) => {
                    setCities([])
                    setSelectedState(newValue?.isoCode)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      shrink="false"
                      inputProps={{
                        ...params.inputProps,
                        className: classes.select,
                      }}
                    />
                  )}
                />
                <RequiredError
                  error={liveIn === "" && showError}
                  required={true}
                  classes="field-asterisk"
                />
              </div>
            </FormControl>
            {selectedState !== "" && cities.length > 0 && (
              <FormControl className={classes.formControl}>
                <div className="field-wrapper">
                  <Autocomplete
                    id="cities"
                    options={cities}
                    getOptionLabel={option => option.name}
                    renderOption={option => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    onChange={(_, newValue) => {
                      setLiveIn(`${newValue?.name}, ${newValue?.stateCode}`)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        shrink="false"
                        inputProps={{
                          ...params.inputProps,
                          className: classes.select,
                        }}
                      />
                    )}
                  />
                  <RequiredError
                    error={liveIn === "" && showError}
                    required={true}
                    classes="field-asterisk"
                  />
                </div>
              </FormControl>
            )}
          </>
        )}
      </div>

      <div className="question-wrapper">
        Where do you primarily work?
        {primaryWork !== "" ? (
          <FormControl>
            <TextField
              defaultValue={primaryWork}
              multiline
              disabled
              InputProps={{
                className: classes.custom,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setWorkCity([])
                        setWorkState("")
                        setPrimaryWork("")
                      }}
                    >
                      <CloseIcon className={classes.clear} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        ) : (
          <>
            <FormControl className={classes.formControl}>
              <div className="field-wrapper">
                <Autocomplete
                  id="workStates"
                  options={states}
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  onChange={(_, newValue) => {
                    setWorkCity([])
                    setWorkState(newValue?.isoCode)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      shrink="false"
                      inputProps={{
                        ...params.inputProps,
                        className: classes.select,
                      }}
                    />
                  )}
                />
                <RequiredError
                  error={primaryWork === "" && showError}
                  required={true}
                  classes="field-asterisk"
                />
              </div>
            </FormControl>
            {workState !== "" && workCity.length > 0 && (
              <FormControl className={classes.formControl}>
                <div className="field-wrapper">
                  <Autocomplete
                    id="workCities"
                    options={workCity}
                    getOptionLabel={option => option.name}
                    renderOption={option => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    onChange={(_, newValue) => {
                      setPrimaryWork(
                        `${newValue?.name}, ${newValue?.stateCode}`
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        shrink="false"
                        inputProps={{
                          ...params.inputProps,
                          className: classes.select,
                        }}
                      />
                    )}
                  />
                  <RequiredError
                    error={primaryWork === "" && showError}
                    required={true}
                    classes="field-asterisk"
                  />
                </div>
              </FormControl>
            )}
          </>
        )}
      </div>

      <div className="question-wrapper">
        Do you work primarily on union or non-union projects?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="union"
              id="union"
              defaultValue={union}
              onChange={e => {
                setUnion(e.target.value)
              }}
            >
              <FormControlLabel
                value="Union"
                control={<Radio className={classes.radio} />}
                label="Union"
              />
              <FormControlLabel
                value="Non-union"
                control={<Radio className={classes.radio} />}
                label="Non-union"
              />
              <FormControlLabel
                value="50/50"
                control={<Radio className={classes.radio} />}
                label="50/50"
              />
            </RadioGroup>
            <RequiredError
              error={union === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>

      <div className="question-wrapper">
        How much is your typical rate?
        <FormControl required className={classes.formControl}>
          <div className="field-wrapper">
            <CurrencyTextField
              variant="standard"
              id="rate"
              type="tel"
              error={showError && rate === ""}
              value={rate}
              currencySymbol="$"
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              minimumValue={0}
              onChange={(_, value) => setRate(value)}
              InputProps={{
                className: classes.rate,
              }}
            />
            <RequiredError
              error={rate === "" && showError}
              required={true}
              classes="field-asterisk right2"
            />
          </div>
        </FormControl>
        per{" "}
        <div className="field-wrapper">
          <SingleSelect
            type="rateDimension"
            value={rateDimension}
            options={ANSWERS.rate}
            open={rateDimensionOpen}
            setOpen={setRateDimensionOpen}
            handleChangeMultiple={handleChangeMultiple}
          />
          <RequiredError
            error={rateDimension === "" && showError}
            required={true}
            classes="field-asterisk"
          />
        </div>
      </div>

      <div className="question-wrapper">
        Do you consider yourself a full-time or part-time freelance employee?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="employee"
              id="employee"
              defaultValue={employee}
              onChange={e => {
                setEmployee(e.target.value)
              }}
            >
              <FormControlLabel
                value="Full-time"
                control={<Radio className={classes.radio} />}
                label="Full-time"
              />
              <FormControlLabel
                value="Part-time"
                control={<Radio className={classes.radio} />}
                label="Part-time"
              />
              <FormControlLabel
                value="50/50"
                control={<Radio className={classes.radio} />}
                label="50/50"
              />
            </RadioGroup>
            <RequiredError
              error={employee === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>

      <div className="question-wrapper">
        In fiscal year 2022, what was your total gross income (before taxes)
        from commercial productions? ${" "}
        <div className="field-wrapper">
          <SingleSelect
            type="grossIncome"
            value={grossIncome}
            options={ANSWERS.grossIncome}
            open={grossIncomeOpen}
            setOpen={setGrossIncomOpen}
            handleChangeMultiple={handleChangeMultiple}
          />
          <RequiredError
            error={grossIncome === "" && showError}
            required={true}
            classes="field-asterisk"
          />
        </div>
      </div>

      <div className="question-wrapper">
        Would you consider this to be an average reflection of your yearly
        income?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="yearlyIncome"
              id="yearlyIncome"
              defaultValue={yearlyIncome}
              onChange={e => {
                setYearlyIncome(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
              <FormControlLabel
                value="Unsure"
                control={<Radio className={classes.radio} />}
                label="Unsure"
              />
            </RadioGroup>
            <RequiredError
              error={yearlyIncome === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>

      <div className="question-wrapper">
        What percentage of your monthly income is spent on housing & costs of
        living?
        <FormControl required className={classes.formControl}>
          <div className="field-wrapper">
            <CurrencyTextField
              variant="standard"
              id="monthlyIncome"
              type="tel"
              error={showError && monthlyIncome === ""}
              value={monthlyIncome}
              currencySymbol=""
              outputFormat="number"
              decimalPlaces={0}
              decimalCharacter="."
              digitGroupSeparator=","
              minimumValue={0}
              onChange={(_, value) => setMonthlyIncome(value)}
              InputProps={{
                className: classes.rate,
              }}
            />
            <RequiredError
              error={monthlyIncome === "" && showError}
              required={true}
              classes="field-asterisk right2"
            />
          </div>
        </FormControl>
        %
      </div>

      <div className="question-wrapper">
        How often do you work longer than 12 hours per day on projects?
        <div className="field-wrapper">
          <SingleSelect
            type="workOften"
            value={workOften}
            options={ANSWERS.workOften}
            open={workOftenOpen}
            setOpen={setWorkOftenOpen}
            handleChangeMultiple={handleChangeMultiple}
          />
          <RequiredError
            error={workOften === "" && showError}
            required={true}
            classes="field-asterisk"
          />
        </div>
      </div>

      <div className="question-wrapper">
        What's the longest day you've ever worked & in which role?
        <FormControl required className={classes.formControl}>
          <div className="field-wrapper">
            <CurrencyTextField
              variant="standard"
              id="longHours"
              type="tel"
              error={showError && longHours === ""}
              value={longHours}
              currencySymbol=""
              outputFormat="number"
              decimalPlaces={0}
              decimalCharacter="."
              digitGroupSeparator=","
              minimumValue={0}
              onChange={(_, value) => setLongHours(value)}
              InputProps={{
                className: classes.rate,
              }}
            />
            <RequiredError
              error={longHours === "" && showError}
              required={true}
              classes="field-asterisk right2"
            />
          </div>
        </FormControl>
        hours as a{" "}
        <div className="field-wrapper">
          <SingleSelect
            type="longHoursRole"
            value={longHoursRole}
            options={ANSWERS.jobTitle}
            open={longHoursRoleOpen}
            setOpen={setLongHoursRoleOpen}
            handleChangeMultiple={handleChangeMultiple}
          />
          <RequiredError
            error={longHoursRole === "" && showError}
            required={true}
            classes="field-asterisk"
          />
        </div>
      </div>

      <div className="question-wrapper">
        Did you enter the business via a training program (e.g. Streetlights,
        Manifest Works, etc.)?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="viaProgram"
              id="viaProgram"
              defaultValue={viaProgram}
              onChange={e => {
                if (e.target.value === "No") {
                  setTrainingProgram("")
                }
                setViaProgram(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
            </RadioGroup>
            <RequiredError
              error={viaProgram === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>

      {viaProgram === "Yes" && (
        <div className="question-wrapper">
          Which programs?
          <div className="field-wrapper">
            <TextField
              className={classes.feedback}
              value={trainingProgram}
              onChange={e => setTrainingProgram(e.target.value)}
            />
            <RequiredError
              error={
                viaProgram === "Yes" && trainingProgram === "" && showError
              }
              required={true}
              classes="field-asterisk right15"
            />
          </div>
        </div>
      )}

      <div className="question-wrapper">
        Do you feel there is opportunity for growth and upward movement from
        your current job classification?
        <div className="field-wrapper">
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              name="opportunity"
              id="opportunity"
              defaultValue={opportunity}
              onChange={e => {
                setOpportunity(e.target.value)
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
              />
              <FormControlLabel
                value="Unsure"
                control={<Radio className={classes.radio} />}
                label="Unsure"
              />
            </RadioGroup>
            <RequiredError
              error={opportunity === null && showError}
              required={true}
              classes="field-asterisk"
            />
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default MyCareerSection
