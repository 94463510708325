import React, { useEffect } from "react"
import Header from "./header"
import { Button } from "@material-ui/core"
import analytics from "./Analytics"
const PhoneNotExist = ({ setStep }) => {
  useEffect(() => {
    analytics.page({
      title: "Phone Number Not Exist Step",
      path: "/phone-not-exist",
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  return (
    <section className="successful">
      <div className="container">
        <Header />
        <div className="bg">
          <p>
            Uh oh. Looks like that phone number isn't in our system. It helps to
            save your number AFTER you've submitted the completed form to ensure
            your info is saved for future use. Don't worry, it will remain
            anonymous.
          </p>
          <div className="actions">
            <Button onClick={() => setStep(2)}>Start</Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PhoneNotExist
