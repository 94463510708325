import React, { useEffect, useState } from "react"
import Header from "./header"
import { Button, FormControl, FormHelperText } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import MuiPhoneNumber from "material-ui-phone-number"
import analytics from "./Analytics"
import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"
import { isValidPhoneNumber } from "libphonenumber-js"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "3px 10px 0 5px",
    minWidth: 500,
    position: "relative",
    "@media (max-width: 767px)": {
      minWidth: 200,
      width: "100%",
    },
  },
  select: {
    color: "#fff",
    fontFamily: "LatoRegular",
    fontSize: "16px",
    paddingBottom: 0,
    border: "1px solid #fff",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#fff",
    marginBottom: "10px",
  },
}))

function PhoneNumber({
  preEnrollmentUUID,
  biddingUUID,
  retroactiveUUID,
  customProjectUUID,
  setStep,
  setVerifyPhoneNumber,
  uuid,
}) {
  const classes = useStyles()
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [showError, setShowError] = useState(false)
  const [country, setCountry] = useState("US")

  useEffect(() => {
    analytics.page({
      title: "Phone Number Step",
      path: "/phone",
    })

    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  const handlePhoneChange = (e, countryData) => {
    setPhone(e)
    setCountry(countryData.countryCode.toUpperCase())
    if (e.length > 8) {
      setShowError(false)
      setDisabled(false)
    } else {
      setShowError(true)
    }
  }

  const validation = () => {
    if (isValidPhoneNumber(phone, country)) {
      return true
    } else {
      setShowError(true)
      return false
    }
  }

  const submit = () => {
    if (validation()) {
      setLoading(true)
      setVerifyPhoneNumber(phone)
      axios
        .post(`${API_ROOT}${ENV}/twilio/get-code`, {
          phoneNumber: phone,
          uuid: preEnrollmentUUID
            ? preEnrollmentUUID
            : biddingUUID
            ? biddingUUID
            : retroactiveUUID
            ? retroactiveUUID
            : customProjectUUID
            ? customProjectUUID
            : uuid,
          type: preEnrollmentUUID
            ? "pre-populated-pre-enrollment"
            : biddingUUID
            ? "pre-populated-bidding"
            : retroactiveUUID
            ? "pre-populated-retroactive"
            : customProjectUUID
            ? "pre-populated-custom-project"
            : "pre-populated-self-id",
        })
        .then(response => {
          if (response.data.statusCode === 200) {
            setLoading(false)
            setStep("verification")
          } else if (response.data.devMessage.includes("is not registered")) {
            setLoading(false)
            setStep("noPhone")
          } else {
            setLoading(false)
            alert(response.data.devMessage)
          }
        })
    }
  }

  return (
    <section className="successful">
      <div className="container">
        <Header />
        <div className="bg">
          <h3>Great to see you again.</h3>
          <p>Enter your phone number to receive a SMS verification code.</p>

          <FormControl className={classes.formControl}>
            <label className={classes.label} htmlFor="phone">Phone Number</label>

            <MuiPhoneNumber
              defaultCountry={"us"}
              disableAreaCodes={true}
              autoFormat={false}
              countryCodeEditable={false}
              preferredCountries={["us"]}
              onChange={(e, countryData) => handlePhoneChange(e, countryData)}
              InputProps={{
                className: classes.select,
              }}
            />
            <ExpandMoreIcon className="dropdown-icon" fontSize="small" />
            {showError && (
              <FormHelperText className="error lang">
                The number entered does not match the length for the country
                selected.
              </FormHelperText>
            )}
            <p className="change-country">Click to change country code</p>
          </FormControl>

          <div className="actions success">
            <Button onClick={submit} disabled={disabled || loading}>
              {loading ? (
                <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="30px"
                  viewBox="0 0 24 30"
                >
                  <rect
                    x="0"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="8"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="16"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </svg>
              ) : (
                "Send code"
              )}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PhoneNumber
