import React, { useEffect } from "react"
import Header from "./header"
import analytics from "./Analytics"
const AlreadyRegistered = () => {
  useEffect(() => {
    analytics.page({
      title: "Phone Number Already Registered Step",
      path: "/already-registered",
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  return (
    <section className="opening">
      <div className="container">
        <Header />
        <div>
          <h3>
            This phone number has already been used for this production and your
            answers have been submitted.
          </h3>
        </div>
      </div>
    </section>
  )
}

export default AlreadyRegistered
